import moment from "moment";
import { amountFormat, amountFormatSatoshisToBTC } from "../../../app/helper/utils";
import Button from "../../atoms/Button";
import Modal from "../Modal";

type Data = {
  date: string;
  fromAddress: string;
  toAddress: string;
  deposit: number;
  fee: number;
};

interface TransferModalProps {
  data: Data;
  open: boolean;
  disableSubmitButton?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const TransferModal = ({ open, disableSubmitButton, data, onClose, onSubmit }: TransferModalProps) => {
  return (
    <Modal open={open} onClose={() => onClose()}>
      <div className="px-1 py-6">
        <div className="text-center mb-8">
          <h1 className="text-dark text-[33px] font-pp-neue-machina font-semibold leading-[120%]">
            Review and Confirm Your Transaction
          </h1>
        </div>
        <div>
          <div className="flex justify-between items-center mb-4">
            <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
              Date & Time
            </div>
            <div className="text-dark text-[14px]">
              {moment(data.date).format("DD MMM YYYY")} | {moment(data.date).format("LT")}
            </div>
          </div>
          <div className="flex justify-between items-center mb-4">
            <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
              From
            </div>
            <div className="text-dark text-[14px]">{data.fromAddress || "-"}</div>
          </div>
          <div className="flex justify-between items-center mb-4">
            <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
              To
            </div>
            <div className="text-dark text-[14px]">{data.toAddress || "-"}</div>
          </div>
          <div className="flex justify-between items-center mb-4">
            <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
              Deposit Amount
            </div>
            <div className="text-dark text-[14px]">{`${amountFormat(data.deposit, "BTC")} BTC`}</div>
          </div>
          <div className="flex justify-between items-center mb-4">
            <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
              Bitcoin Network Fee
            </div>
            <div className="text-dark text-[14px]">{`${amountFormatSatoshisToBTC(data.fee)} BTC`}</div>
          </div>
        </div>
        <div className="flex gap-2 mt-10">
          <Button
            label="Cancel"
            backgroundColor="#F9F5FF"
            color="#8F49FD"
            className="py-[8px] text-[14px] font-inter font-medium"
            onClick={() => onClose()}
          />
          <Button
            label="Initiate Funding"
            backgroundColor="#8F49FD"
            color="#ffffff"
            disabled={disableSubmitButton}
            className="py-[8px] text-[14px] font-inter font-medium"
            onClick={() => onSubmit()}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TransferModal;
