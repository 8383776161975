import { useQuery } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import { Sort, YesOrNoChoice } from "../model/api";

interface QueryProps {
  offset?: number;
  limit?: number;
}
interface PortfolioChartProps {
  year?: string;
  minerId?: string;
  month: string;
}

interface OpportunityListProps extends QueryProps {
  newestFirst?: YesOrNoChoice;
  hideSettled?: YesOrNoChoice;
}

interface OpportunityFundedProps extends QueryProps {
  endDate?: Sort;
  minerId?: string;
  hideSettled?: YesOrNoChoice;
}

const getLpPortfolioChart = async (params: PortfolioChartProps) => {
  return (await apig.lp.getLpPortfolioChart(params)).data;
};

const getLpOpportunityList = async (params: OpportunityListProps) => {
  return (await apig.lp.getLpOpprotunities(params)).data;
};

const getLpOpportunitiesFunded = async (params: OpportunityFundedProps) => {
  return (await apig.lp.getLpOpportunitiesFunded(params)).data;
};

const getOpportunityFunding = async (opportunityId: string) => {
  return (await apig.lp.getOpportunityFunding(opportunityId)).data;
};

const getLpOpportunity = async (opportunityId: string) => {
  return (await apig.lp.getLpOpportunity(opportunityId)).data;
};

const getLpPreviewOpportunity = async (previewOpportunityId: string) => {
  return (await apig.lp.getLpPreviewStreamingOpportunity(previewOpportunityId)).data;
};

const getLpPreviewLoan = async (previewLoanId: string) => {
  return (await apig.lp.getLpPreviewLoan(previewLoanId)).data;
};

const getLpPreviewLease = async (previewLeaseId: string) => {
  return (await apig.lp.getLpPreviewLease(previewLeaseId)).data;
};

const getLiquidityProvidersV2 = async (
  opportunityId: string,
  query: {
    offset?: number;
    limit?: number;
  }
) => {
  return (await apig.lp.getLiquidityProvidersV2(opportunityId, query)).data;
};

const getPortfolioSummary = async () => {
  return (await apig.lp.getPortfolioSummary()).data;
};

const getOpportunitiesSummary = async () => {
  return (await apig.lp.getOpportunitiesSummary()).data;
};

const getOpportunitySummary = async (id: string) => {
  return (await apig.lp.getOpportunitySummary(id)).data;
};

export const useQueryGetLPPortfolioChart = (params: PortfolioChartProps) => {
  return useQuery(["portfolio_chart", params], () => getLpPortfolioChart(params), { refetchOnMount: "always" });
};

export const useQueryGetLpOpportunityList = (params: OpportunityListProps) => {
  return useQuery(["opportunity_list", params], () => getLpOpportunityList(params), {
    refetchOnMount: "always",
    cacheTime: 0,
  });
};

export const useQuerygetLpOpportunitiesFunded = (params: OpportunityFundedProps) => {
  return useQuery(["opportunity_funded", params], () => getLpOpportunitiesFunded(params), { cacheTime: 0 });
};

export const useGetQueryOpportunityFunding = (opportunityId: string, isEnabled: boolean) => {
  return useQuery(["initiate_opportunity", opportunityId], () => getOpportunityFunding(opportunityId), {
    enabled: isEnabled,
  });
};

export const useGetQueryStreamingOpportunity = (opportunityId: string) => {
  return useQuery(["lp_opportunity", opportunityId], () => getLpOpportunity(opportunityId));
};

export const useGetQueryPreviewStreamingOpportunity = (previewOpportunityId: string) => {
  return useQuery(["lp_preview_opportunity", previewOpportunityId], () =>
    getLpPreviewOpportunity(previewOpportunityId)
  );
};

export const useGetQueryPreviewLoan = (previewLoanId: string) => {
  return useQuery(["lp_preview_loan", previewLoanId], () => getLpPreviewLoan(previewLoanId));
};

export const useGetQueryPreviewLease = (previewLeaseId: string) => {
  return useQuery(["lp_preview_lease", previewLeaseId], () => getLpPreviewLease(previewLeaseId));
};

export const useGetQueryOpportunityLiquidityProvidersV2 = (
  opportunityId: string,
  query: {
    offset?: number;
    limit?: number;
  }
) => {
  return useQuery(["liquidity_providers_v2", opportunityId], () => getLiquidityProvidersV2(opportunityId, query), {
    refetchOnMount: "always",
    cacheTime: 0,
    enabled: !!opportunityId,
  });
};

export const useGetQueryPortfolioSummary = () => {
  return useQuery(["portfollio_summary"], () => getPortfolioSummary(), {
    refetchOnMount: "always",
  });
};

export const useGetQueryOpportunitiesSummary = () => {
  return useQuery(["opportunities_lp_summary"], () => getOpportunitiesSummary(), {
    refetchOnMount: "always",
  });
};

export const useGetQueryOpportunitySummary = (id: string) => {
  return useQuery([`opportunity_${id}_lp_summary`], () => getOpportunitySummary(id), {
    refetchOnMount: "always",
  });
};
