import Decimal from "decimal.js";
import {
  amountFormatSatoshisToBTC,
  roundToSignificantDigits,
  roundToSignificantDigitsDiv1000,
} from "../../../app/helper/utils";
import { ReactComponent as BlockchainIcon } from "../../../assets/images/blockchain-icon.svg";
import { ReactComponent as BlockchainLiquidityIcon } from "../../../assets/images/blockchain-liquidity-icon.svg";
import heroCurrentBtcPrice from "../../../assets/images/hero-lp-opportunity-3.png";
import { BlurredValue } from "../../atoms/BlurredValue";
import HeroBox from "../../atoms/HeroBox";
import Loader from "../../atoms/Loader";
import ProgressPercentBar from "../../atoms/ProgressPercentBar";

const reachOutTooltip = "Reach out to learn more";

export const HeroOpportunityDetailsLP = ({
  expectedAnnualizedReturn,
  hashAllocated,
  liquidityProvided,
  isFetching,
}: {
  expectedAnnualizedReturn?: number;
  hashAllocated?: { current: number; maximum: number };
  liquidityProvided?: { current: number; total: number };
  isFetching: boolean;
}) => {
  return (
    <div className="hero-lp-list">
      <div className="container-custom mx-auto !py-16">
        <div className="grid grid-cols-3 gap-4 pb-4">
          <HeroBox title="Expected Annualized Return" backgroundImage={heroCurrentBtcPrice}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <div className="crypto-value">
                {expectedAnnualizedReturn ?? <BlurredValue tooltip={reachOutTooltip}>⛝⛝</BlurredValue>} %
              </div>
            )}
          </HeroBox>
          <HeroBox title="Hashrate allocated" titleIcon={<BlockchainIcon className="w-5 mr-2" />}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <div className="w-full">
                <div className="grid grid-cols-2 gap-5">
                  <div>
                    <p>
                      <small className="text-[11px]">Current Hashrate Allocated</small> <br />
                      <>
                        {hashAllocated ? (
                          <strong>{roundToSignificantDigitsDiv1000(hashAllocated.current)}</strong>
                        ) : (
                          <BlurredValue tooltip={reachOutTooltip}>⛝⛝</BlurredValue>
                        )}{" "}
                        <sup>PH/s</sup>
                      </>
                    </p>
                  </div>
                  <div>
                    <p>
                      <small className="text-[11px]">Maximum Capacity</small> <br />
                      <>
                        {hashAllocated ? (
                          <strong>{roundToSignificantDigitsDiv1000(hashAllocated.maximum)}</strong>
                        ) : (
                          <BlurredValue tooltip={reachOutTooltip}>⛝⛝</BlurredValue>
                        )}{" "}
                        <sup>PH/s</sup>
                      </>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </HeroBox>
          <HeroBox title="Liquidity provided" titleIcon={<BlockchainLiquidityIcon className="w-5 mr-2" />}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <div className="w-full">
                <div className="grid grid-cols-2 gap-5">
                  <div>
                    <p>
                      <small className="text-[11px]">Current Deposits</small> <br />
                      <>
                        {liquidityProvided ? (
                          <strong>{amountFormatSatoshisToBTC(liquidityProvided.current)}</strong>
                        ) : (
                          <BlurredValue tooltip={reachOutTooltip}>⛝⛝</BlurredValue>
                        )}{" "}
                        <sup>BTC</sup>
                      </>
                    </p>
                  </div>
                  <div>
                    <p>
                      <small className="text-[11px]">Maximum Capacity</small> <br />
                      <>
                        {liquidityProvided ? (
                          <strong>{amountFormatSatoshisToBTC(liquidityProvided.total)}</strong>
                        ) : (
                          <BlurredValue tooltip={reachOutTooltip}>⛝⛝</BlurredValue>
                        )}{" "}
                        <sup>BTC</sup>
                      </>
                    </p>
                  </div>
                </div>
                {liquidityProvided && (
                  <ProgressPercentBar
                    value={Number(
                      roundToSignificantDigits(
                        new Decimal(liquidityProvided.current).div(liquidityProvided.total).mul(100).toNumber()
                      )
                    )}
                    showPercentage
                  />
                )}
              </div>
            )}
          </HeroBox>
        </div>
      </div>
    </div>
  );
};
