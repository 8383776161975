import Decimal from "decimal.js";
import { useEffect, useState } from "react";

import Button from "../Button";
import Input from "../Input";
import Tooltip from "../Tooltip";

import { SATOSHIS_TO_BTC } from "../../../app/constants";
import { amountFormat } from "../../../app/helper/utils";
import { useQueryGetUserOnMount } from "../../../app/query/useQueryGetUser";
import { useGetQuerySatoshiPriceInUsd } from "../../../app/query/useQueryGetWallet";

import { ReactComponent as BTCIcon } from "../../../assets/images/bitcoin.svg";
import "./style.scss";

interface InputBTCProps {
  value?: number | string;
  fee: number | undefined;
  opportunityCapacity?: number;
  disabled?: boolean;
  showTooltip?: boolean;
  onChange: (value: number) => void;
}

const InputBTC = ({
  value,
  opportunityCapacity,
  fee,
  onChange,
  disabled = false,
  showTooltip = false,
}: InputBTCProps) => {
  const { data: satoshisToUsd } = useGetQuerySatoshiPriceInUsd();
  const { data: user } = useQueryGetUserOnMount();

  const [amount, setAmount] = useState<string>("");

  useEffect(() => {
    const amount = value ? new Decimal(value).div(SATOSHIS_TO_BTC).toString() : "";
    setAmount(amount);
  }, [value]);

  const maxSatoshis = new Decimal(user?.maximumSpendableSatoshis || 0)
    .minus(user?.reservedBalanceSatoshis || 0)
    .minus(fee || 0);
  const maxBtc = maxSatoshis.gt(0) ? maxSatoshis.div(SATOSHIS_TO_BTC).toNumber() : 0;

  const dollarsPerSatoshi = new Decimal(satoshisToUsd || 0);
  const enteredAmountSatoshis = new Decimal(amount || 0).mul(SATOSHIS_TO_BTC);
  const dollars = enteredAmountSatoshis.mul(dollarsPerSatoshi).toNumber();
  const maxOpportunityCapacity =
    opportunityCapacity && new Decimal(opportunityCapacity).div(SATOSHIS_TO_BTC).toString();

  const onClickMaxValue = (value: string) => {
    if (maxOpportunityCapacity && Number(maxOpportunityCapacity) < Number(value)) {
      setAmount(maxOpportunityCapacity);
      onChange(Number(maxOpportunityCapacity));
    } else {
      onChangeAmount(value);
    }
  };

  const onChangeAmount = (value: string) => {
    setAmount(value);
    onChange(Number(value));
  };

  return (
    <div className="mb-4">
      <div className="mt-4 mb-1 flex items-center justify-between">
        <div>
          <p className="font-medium">Spendable Balance: {maxBtc} BTC</p>
          {opportunityCapacity ? (
            <p className="font-medium">Opportunity Capacity: {maxOpportunityCapacity} BTC</p>
          ) : null}
        </div>
        {showTooltip ? (
          <Tooltip
            width={25}
            height={25}
            arrowPosition="right"
            className="w-[300px] left-auto right-0 bottom-[30px]"
            content="You do not have permission to perform this action. To request access, contact your organization administrators"
          />
        ) : null}
      </div>
      <div className="relative !h-auto">
        <Input
          name="amount"
          type="number"
          className="field-multiple-btc"
          maxValue={maxBtc}
          disabled={disabled || maxBtc <= 0}
          value={amount}
          placeholder="0.00"
          currency={<BTCIcon className="absolute left-56 top-5 z-10" />}
          onChange={(_name, value) => (!disabled && maxBtc > 0 ? onChangeAmount(String(value)) : {})}
        />
        <p className="absolute left-3 top-9 z-10 text-[#BDCCD4] text-[11px] font-pp-neue-machina">
          {dollars === 0 ? "$0.00" : amountFormat(dollars)}
        </p>
        <div className="absolute right-4 top-3">
          <Button
            label="MAX"
            backgroundColor="#F9F5FF"
            color="#8F49FD"
            notAllowed={disabled || maxBtc <= 0}
            className={`font-normal px-2 py-1 ${disabled || maxBtc <= 0 ? "cursor-not-allowed" : ""}`}
            onClick={() => (!disabled && maxBtc > 0 ? onClickMaxValue(String(maxBtc)) : {})}
          />
        </div>
      </div>
    </div>
  );
};

export default InputBTC;
