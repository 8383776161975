import { Button } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Sort } from "../../../app/model/api";

import { ReactComponent as SortDownIcon } from "../../../assets/images/sort-down.svg";
import { ReactComponent as SortUpIcon } from "../../../assets/images/sort-up.svg";

import "./style.scss";

interface TableProps {
  children?: any;
  className?: string;
  colSpan?: number;
  onExportCsv?(): void;
  onSort?: (direction: Sort) => void;
  onClick?: () => void;
}

const Table = ({ children, onExportCsv }: TableProps) => {
  return (
    <div className="custom-table-wrapper">
      <table className="custom-table" cellSpacing="10">
        {children}
      </table>

      {onExportCsv ? (
        <div className="flex justify-end mb-4">
          <Button
            variant="text"
            className="!normal-case !bg-transparent !py-0"
            onClick={onExportCsv}
            startIcon={<SaveAltIcon />}
          >
            Export CSV
          </Button>
        </div>
      ) : null}
    </div>
  );
};

Table.Head = ({ children }: TableProps) => {
  return <thead>{children}</thead>;
};

Table.Body = ({ children, className }: TableProps) => {
  return <tbody className={className || ""}>{children}</tbody>;
};

Table.TH = ({ children, className, onSort }: TableProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [direction, setDirection] = useState<Sort>(Sort.Asc);

  const handleDirection = () => {
    setDirection((prev: any) => {
      if (prev === Sort.Asc) {
        onSort && onSort(Sort.Desc);
        return Sort.Desc;
      } else {
        onSort && onSort(Sort.Asc);
        return Sort.Asc;
      }
    });
  };

  return (
    <th className={`relative ${className || ""}`}>
      {onSort ? (
        <div className="flex gap-4 justify-center">
          {children}
          <button type="button" onClick={() => handleDirection()}>
            {direction === Sort.Asc ? <SortUpIcon className="w-4" /> : <SortDownIcon className="w-4" />}
          </button>
        </div>
      ) : (
        children
      )}
    </th>
  );
};

Table.TR = ({ children, className, onClick }: TableProps) => {
  return (
    <tr
      className={classNames(className, {
        "cursor-pointer": onClick,
      })}
      onClick={onClick}
    >
      {children}
    </tr>
  );
};

Table.TD = ({ children, className, onClick, colSpan = 0 }: TableProps) => {
  return (
    <td
      colSpan={colSpan}
      className={classNames(className, {
        "cursor-pointer": onClick,
      })}
      onClick={onClick}
    >
      {children}
    </td>
  );
};

Table.Loader = ({ children }: TableProps) => {
  return <tr>{children}</tr>;
};

export default Table;
