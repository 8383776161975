import { useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import ProgressBar from "../../components/atoms/ProgressBar";

import "./style.scss";

const ThankYou = () => {
  const location = useLocation();

  return (
    <div className="page-wrapper p-5 xl:pt-24 xl:pb-12">
      <div className="thank-you-wrapper mx-auto">
        <div className="thank-you-holder">
          <div className="flex flex-col justify-center items-center mb-6">
            <div>
              <Logo className="w-32" />
            </div>
            <h1 className="mt-6 mb-4 text-center">{location.state?.title || "Thank you"}</h1>
            <div className="flex flex-col justify-center items-center mx-10 text-center">
              <p>
                {location.state?.content ||
                  "We'll get back to you within one business day. After our team has reviewed your application, the next step is to pass the KYC. For more information about onboarding,"}
                {location?.state?.helperLink && (
                  <a
                    href="https://block-green-helpdesk.webflow.io/"
                    className="text-purple-500 underline dark:text-purple-500 hover:no-underline"
                  >
                    {" "}
                    click here.
                  </a>
                )}
              </p>
            </div>
          </div>

          {location?.state?.progressBar && (
            <ProgressBar dataList={location?.state?.progressBar} current={location?.state?.currentStep || 1} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
