import { Box, Button, Chip, Skeleton, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { formatInTimeZone } from "date-fns-tz";
import { useState } from "react";
import { MINER_ORGANIZATION_BASIC_INFO_ROUTE, ORGANIZATION_DETAILS_ROUTE, ROUTES_LOANS } from "../../../app/constants";
import { ResAdminLoanDto } from "../../../app/model/api";
import { getStatusChip } from "../commons/LoanTitle";
import { UnitSelector } from "../commons/UnitSelector";
import { formatWithCommas, toFormattedBtc } from "../commons/utils";
import { useAdminGetAllLoans } from "../loanQueries";

const COLUMNS = (unit: "SAT" | "BTC"): GridColDef<ResAdminLoanDto>[] => [
  {
    field: "id",
    headerName: "Id",
    sortable: true,
    width: 155,
    display: "flex",
    renderCell: (params) => (
      <Box display="flex" width={"100%"} alignItems={"center"} justifyContent={"space-between"} gap={1}>
        <Button
          href={ROUTES_LOANS.Admin.LoanDetails.replace(":id", params.row.id)}
          variant="text"
          size="small"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            "&:hover": { textDecoration: "underline" },
          }}
        >
          {params.row.id.slice(0, 6) + "..."}
        </Button>
        {params.row.isDemo ? <Chip label="Demo" color={"primary"} variant="filled" /> : null}
      </Box>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created at",
    sortable: true,
    display: "flex",
    width: 120,
    renderCell: (params) => formatInTimeZone(params.row.createdAt, "UTC", "dd MMM yyyy"),
  },
  {
    field: "status",
    headerName: "Status",
    sortable: true,
    width: 155,
    display: "flex",
    align: "center",
    headerAlign: "center",
    renderCell: (params) => getStatusChip(params.row.status),
  },
  {
    field: "principalAmountSatoshi",
    headerName: `Principal amount (${unit})`,
    sortable: true,
    width: 160,
    display: "flex",
    headerAlign: "right",
    align: "right",
    renderCell: (params) =>
      unit === "SAT"
        ? formatWithCommas(+params.row.principalAmountSatoshi)
        : toFormattedBtc(params.row.principalAmountSatoshi),
  },
  {
    field: "minerName",
    headerName: "Miner",
    sortable: true,
    flex: 1,
    renderCell: (params) => (
      <Button
        href={MINER_ORGANIZATION_BASIC_INFO_ROUTE.replace(":id", params.row.minerId)}
        variant="text"
        size="small"
        sx={{
          ml: 1,
          height: 20,
          padding: 0,
          "&:hover": { textDecoration: "underline" },
        }}
      >
        {params.row.minerName}
      </Button>
    ),
  },
  {
    field: "lpName",
    headerName: "LP",
    sortable: true,
    flex: 1,
    renderCell: (params) => (
      <Button
        href={ORGANIZATION_DETAILS_ROUTE.replace(":id", params.row.lpId)}
        variant="text"
        size="small"
        sx={{
          ml: 1,
          height: 20,
          padding: 0,
          "&:hover": { textDecoration: "underline" },
        }}
      >
        {params.row.lpName}
      </Button>
    ),
  },
  {
    field: "durationDays",
    headerName: "Duration (days)",
    sortable: true,
    display: "flex",
    width: 120,
  },
  {
    field: "calculationKind",
    headerName: "Schedule type",
    sortable: true,
    width: 300,
    display: "flex",
  },
];

export const AdminPageAllLoans = () => {
  const { data, isFetching } = useAdminGetAllLoans();
  const [unit, setUnit] = useState<"SAT" | "BTC">("BTC");
  const rows = data?.data.rows ?? [];
  return (
    <Box display="flex" justifyContent={"center"}>
      <Stack width="100%" maxWidth={1800} bgcolor={"white"} padding={2} spacing={1}>
        <Box display="flex" width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
          <Button
            href={ROUTES_LOANS.Admin.CreateLoan}
            variant="outlined"
            size="small"
            sx={{
              ml: 1,
              width: "fit-content",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            Create new loan
          </Button>
          <UnitSelector initial={unit} onChange={setUnit} />
        </Box>
        <Box sx={{ height: "75vh", display: "flex", flexDirection: "column", pt: 2 }}>
          {isFetching && (
            <Box width="100%" pl={2} pr={2}>
              <Skeleton width={"100%"} height={50} variant="rectangular"></Skeleton>
              <Skeleton width={"100%"} height={50} variant="rectangular"></Skeleton>
              <Skeleton width={"100%"} height={50} variant="rectangular"></Skeleton>
              <Skeleton width={"100%"} height={50} variant="rectangular"></Skeleton>
              <Skeleton width={"100%"} height={50} variant="rectangular"></Skeleton>
            </Box>
          )}
          {!isFetching && (
            <DataGridPro
              key={"table-all-loans-" + unit}
              rows={rows}
              rowCount={0}
              columns={COLUMNS(unit)}
              loading={isFetching}
              pagination
              paginationMode="client"
              sortingMode="client"
              disableColumnFilter={false}
              pageSizeOptions={[25, 50, 100]}
              sx={{
                bgcolor: "white",
                boxShadow: 3,
                padding: 1,
                maxHeight: "75vh",
              }}
              slots={{
                noRowsOverlay: () => (
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    width={"100%"}
                    height={"100%"}
                    sx={{ minHeight: "60px" }}
                  >
                    <Typography>No transactions found.</Typography>
                  </Stack>
                ),
              }}
            ></DataGridPro>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
