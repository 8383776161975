import moment from "moment";

interface TableDateCellProps {
  date: string;
}

const TableDateCell = ({ date }: TableDateCellProps) => {
  return (
    <div className="flex flex-col items-center">
      <span className="date-value">{date && moment(date).format("LL")}</span>
      <span className="time-value text-[13px] text-[#6D7282] tracking-[.03em]">
        {date && moment(date).format("LT")}
      </span>
    </div>
  );
};

export default TableDateCell;
