import { TabContext, TabPanel } from "@mui/lab";
import { Box, Container, Skeleton, Tab, Tabs } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { KYM2_MINER_DETAILS_TABS } from "../../../app/constants";
import { useQueryGetMinerByMinerId } from "../../../app/query/useQueryGetAdmin";

export const MinerDetailTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();
  // for now we have to do this here since EnterpriseSettings uses the cache state, but will move it eventually
  const { data, isFetching } = useQueryGetMinerByMinerId(id ?? "");

  const getPathWithIdValue = (path: string): string => {
    return path.replace(":id", id ?? "");
  };

  const currentTab = location.pathname;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  return (
    <Box width={"100%"}>
      <Container sx={{ background: "white", boxShadow: 1 }}>
        <TabContext value={currentTab}>
          <Box display="flex" alignItems={"Center"}>
            <Tabs
              textColor="primary"
              indicatorColor="primary"
              value={currentTab}
              onChange={handleChange}
              aria-label="mining-details-tabs"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {KYM2_MINER_DETAILS_TABS.map((entry) => (
                <Tab
                  key={"tb-miner-details-" + entry.name}
                  label={entry.name}
                  value={getPathWithIdValue(entry.path)}
                  sx={{ fontWeight: "fontWeightBold" }}
                />
              ))}
            </Tabs>
          </Box>
          {isFetching || !data?.data.basicInfo ? (
            <Skeleton height={200} width={"100%"}></Skeleton>
          ) : (
            KYM2_MINER_DETAILS_TABS.map((entry) => (
              <TabPanel key={"tbpanel-kym-" + entry.name} value={getPathWithIdValue(entry.path)} sx={{ mt: -2 }}>
                {entry.tabComponent({
                  minerId: data.data.basicInfo.id,
                  organizationId: data.data.basicInfo.organizationId,
                })}
              </TabPanel>
            ))
          )}
        </TabContext>
      </Container>
    </Box>
  );
};
