import HeroBox from "../../atoms/HeroBox";
import Loader from "../../atoms/Loader";

import { amountFormat, amountFormatSatoshisToBTC } from "../../../app/helper/utils";
import { useGetQueryPortfolioSummary } from "../../../app/query/useQueryGetLP";

import { ReactComponent as CoinsIcon } from "../../../assets/images/coins-icon.svg";
import heroReservedBck from "../../../assets/images/hero-bck-1.png";
import heroBalanceBck from "../../../assets/images/hero-bck-2.png";
import { ReactComponent as InvestmentIcon } from "../../../assets/images/investment-icon.svg";

import "./style.scss";

const HeroPortfolio = () => {
  const { data, isFetching } = useGetQueryPortfolioSummary();

  return (
    <div className="hero-portfolio">
      <div className="container-custom mx-auto !py-16">
        <div className="grid grid-cols-2 gap-4">
          <HeroBox title="overall Daily revenue" titleIcon={<CoinsIcon />} backgroundImage={heroBalanceBck}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <>
                <div className="crypto-value">{amountFormatSatoshisToBTC(data?.dailyRevenu.btc || 0)}</div>
                <sup>BTC</sup>
                <div className="fiat-value">{amountFormat(data?.dailyRevenu.usd)}</div>
              </>
            )}
          </HeroBox>
          <HeroBox title="Overall Revenue Delivered" titleIcon={<InvestmentIcon />} backgroundImage={heroReservedBck}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <>
                <div className="crypto-value">{amountFormatSatoshisToBTC(data?.overallRevenu.btc || 0)}</div>
                <sup>BTC</sup>
                <div className="fiat-value">{amountFormat(data?.overallRevenu.usd)}</div>
              </>
            )}
          </HeroBox>
        </div>
      </div>
    </div>
  );
};

export default HeroPortfolio;
