import { Box, Link, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { formatInTimeZone } from "date-fns-tz";
import { ResLoanMinerDelivery } from "../../../app/model/api";
import { TruncatedCell } from "./TruncatedCopyCell";
import { formatWithCommas, getTransactionUrl, toFormattedBtc } from "./utils";

const COLUMNS = (unit: "SAT" | "BTC"): GridColDef<ResLoanMinerDelivery & { amountBtc: number }>[] => [
  {
    field: "createdAt",
    headerName: "Created at",
    sortable: true,
    flex: 1,
    display: "flex",
    renderCell: (params) => formatInTimeZone(params.row.createdAt, "UTC", "dd MMM yyyy"),
  },
  {
    field: "status",
    headerName: "Status",
    sortable: true,
    flex: 1,
    display: "flex",
  },
  {
    field: "transactionHash",
    headerName: "Transaction hash",
    sortable: true,
    flex: 1,
    display: "flex",
    renderCell: (params) => (
      <TruncatedCell fullText={params.row.transactionHash}>
        <Link target="_blank" href={getTransactionUrl(params.row.transactionHash)}>
          {params.row.transactionHash}
        </Link>
      </TruncatedCell>
    ),
  },
  unit === "SAT"
    ? {
        field: "amountSatoshi",
        headerName: "Amount (SAT)",
        sortable: true,
        flex: 1,
        display: "flex",
        align: "right",
        headerAlign: "right",
        renderCell: (params) => formatWithCommas(params.row.amountSatoshi),
      }
    : {
        field: "amountSatoshi",
        headerName: "Amount (BTC)",
        sortable: true,
        flex: 1,
        display: "flex",
        align: "right",
        headerAlign: "right",
        renderCell: (params) => params.row.amountBtc,
      },
];

export const LoanDeliveriesTable = ({
  unit,
  minerDeliveries,
  height,
}: {
  unit: "SAT" | "BTC";
  minerDeliveries: ResLoanMinerDelivery[];
  height: string | number;
}) => {
  const rows = minerDeliveries.map((x) => ({ ...x, amountBtc: +toFormattedBtc(x.amountSatoshi) }));
  return (
    <Box gap={1} width={"100%"}>
      <Box sx={{ height }} width={"100%"}>
        <DataGridPro
          key={"dg-ldt-" + unit}
          rows={rows}
          rowCount={minerDeliveries.length}
          columns={COLUMNS(unit)}
          pagination
          paginationMode="client"
          sortingMode="client"
          disableColumnFilter={false}
          pageSizeOptions={[25, 50, 100]}
          sx={{
            bgcolor: "white",
            boxShadow: 3,
            padding: 1,
            maxHeight: height,
            width: "100%",
          }}
          slots={{
            noRowsOverlay: () => (
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
                height={"100%"}
                sx={{ minHeight: "60px" }}
              >
                <Typography>No deliveries found for loan.</Typography>
              </Stack>
            ),
          }}
        ></DataGridPro>
      </Box>
    </Box>
  );
};
