import { TabContext, TabPanel } from "@mui/lab";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES_LOANS } from "../../../app/constants";
import Loader from "../../../components/atoms/Loader";
import { AdminPageLoanAudit } from "./AdminPageLoanAudit";
import { AdminPageManageLoan } from "./AdminPageManageLoan";
import { AdminPagePaymentRequest } from "./AdminPagePaymentRequest";

export const ADMIN_LOAN_SECTIONS = [
  {
    name: "Loan details",
    path: ROUTES_LOANS.Admin.LoanDetails,
    tabComponent: (id: string) => <AdminPageManageLoan id={id} />,
  },
  {
    name: "Make payment request",
    path: ROUTES_LOANS.Admin.LoanPaymentRequestPage,
    tabComponent: (id: string) => <AdminPagePaymentRequest id={id} />,
  },
  {
    name: "Audit",
    path: ROUTES_LOANS.Admin.AuditPage,
    tabComponent: (id: string) => <AdminPageLoanAudit id={id} />,
  },
];

export const AdminPageLoanTabs = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const currentTab = location.pathname;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  if (!id) {
    navigate(ROUTES_LOANS.Admin.Loans);
    return <Loader />;
  } else
    return (
      <Stack alignItems={"center"} width={"100%"} padding={3}>
        <Box maxWidth={1800} width={"100%"} mt={-2} bgcolor={"white"}>
          <TabContext value={currentTab}>
            <Box display="flex">
              <Tabs
                value={currentTab}
                onChange={handleChange}
                aria-label="loan-tabs"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                {ADMIN_LOAN_SECTIONS.map((entry) => (
                  <Tab
                    sx={{
                      fontWeight: "fontWeightBold",
                    }}
                    key={"tb-loan-" + entry.name}
                    label={entry.name}
                    value={entry.path.replace(":id", id)}
                  />
                ))}
              </Tabs>
            </Box>

            {ADMIN_LOAN_SECTIONS.map((entry) => (
              <TabPanel key={"tbpanel-loan-" + entry.name} value={entry.path.replace(":id", id)} sx={{ mt: -5 }}>
                {entry.tabComponent(id)}
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </Stack>
    );
};
