interface KYCHeaderProps {
  steps: Array<{ name: string; active: boolean; completed: boolean }>;
}

import "./style.scss";

const KYCHeader = ({ steps }: KYCHeaderProps) => {
  return (
    <div className="kyc py-8">
      <div className={`kyc-bars px-5 grid grid-cols-1 md:grid-cols-${steps.length} md:gap-5`}>
        {steps.map((item, key) => {
          return (
            <div key={key} className={`bar grow ${item.active ? "active" : ""} ${item.completed ? "completed" : ""}`}>
              <div className="line"></div>
              <h4 className="mt-3">{item.name}</h4>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default KYCHeader;
