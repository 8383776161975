import { Box, Button, Container, Paper, Skeleton, TableSortLabel, Tooltip, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useGetAdminBlocks } from "../../../app/query/useQueryGetAdmin";
import { providerConfig } from "../../../app/config/auth0/config";
import { format } from "date-fns";

type Sortable = "height" | "timestamp" | "difficulty" | "rewardSatoshis";

interface Column {
  id: string;
  label: string;
  orderField?: Sortable | undefined;
  minWidth?: number;
  width?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "timestamp", label: "Timestamp", minWidth: 140, orderField: "timestamp" },
  { id: "medianTimestamp", label: "Median timestamp" },
  { id: "height", label: "Block height", minWidth: 170, orderField: "height" },
  { id: "rewardSatoshis", label: "Reward (SAT)", minWidth: 50, orderField: "rewardSatoshis" },
  { id: "totalFeesSatoshis", label: "Total fees (SAT)", minWidth: 50 },
  { id: "difficulty", label: "Difficulty", minWidth: 170, orderField: "difficulty" },
];

export const AdminBlocks = () => {
  const [orderBy, setOrderBy] = React.useState<Sortable>("height");
  const [orderDirection, setOrderDirection] = React.useState<"asc" | "desc">("desc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const { data, isFetching } = useGetAdminBlocks({
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    orderBy,
    orderDirection,
  });
  const totalCount = data?.data.count;
  const rows = data?.data.rows;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getBlockUrl = (height: number) => {
    return `https://mempool.space${providerConfig.environment === "production" ? "" : "/testnet"}/block/${height}`;
  };

  const formatDate = (input: string | Date | number) => {
    const date = new Date(input);
    return format(date, "dd/MM/yy HH:mm");
  };

  return (
    <Box width={"100%"}>
      <Container sx={{ background: "white", maxWidth: 1200, pt: 3, pb: 3 }}>
        <Paper sx={{ boxShadow: 5, pt: 1 }}>
          <TableContainer sx={{ maxHeight: "60vh" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ backgroundColor: "red" }}>
                  {columns.map((column) =>
                    column.orderField ? (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sortDirection={orderBy === column.orderField ? orderDirection : undefined}
                      >
                        <TableSortLabel
                          active={orderBy === column.orderField}
                          direction={orderBy === column.orderField ? orderDirection : undefined}
                          onClick={() => {
                            if (column.orderField) {
                              setOrderBy(column.orderField);
                              setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
                            }
                          }}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ) : (
                      <TableCell key={column.id} align={column.align}>
                        {column.label}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length
                  ? rows.map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.height}>
                          <TableCell align={"left"} sx={{ minWidth: 175, maxWidth: 175 }}>
                            <Tooltip title={row.timestamp}>
                              <Typography noWrap>{formatDate(row.timestamp)}</Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={"left"} sx={{ minWidth: 175, maxWidth: 175 }}>
                            <Tooltip title={row.medianTimestamp}>
                              <Typography noWrap>{formatDate(row.medianTimestamp)}</Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={"left"} sx={{ minWidth: 125, maxWidth: 125 }}>
                            <Button
                              target="_blank"
                              href={getBlockUrl(row.height)}
                              variant="text"
                              size="small"
                              sx={{
                                padding: 0,
                                "&:hover": { textDecoration: "underline" },
                              }}
                            >
                              <Typography variant="body2" noWrap>
                                {row.height}
                              </Typography>
                            </Button>
                          </TableCell>
                          <TableCell align={"left"} sx={{ minWidth: 150, maxWidth: 150 }}>
                            <Typography noWrap>{row.rewardSatoshis}</Typography>
                          </TableCell>
                          <TableCell align={"left"} sx={{ minWidth: 150, maxWidth: 150 }}>
                            <Typography noWrap>{row.totalFeesSatoshis}</Typography>
                          </TableCell>
                          <TableCell align={"left"} sx={{ minWidth: 200, maxWidth: 200 }}>
                            <Typography noWrap>{row.difficulty}</Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>

            {!isFetching && !rows?.length && (
              <Box width="100%" padding={2} display={"flex"}>
                <Typography width={"100%"} variant="caption" color="silver" textAlign={"center"}>
                  No available data
                </Typography>
              </Box>
            )}

            {isFetching && (
              <Box width="100%" pl={2} pr={2}>
                <Skeleton width={"100%"} height={50}></Skeleton>
                <Skeleton width={"100%"} height={50}></Skeleton>
                <Skeleton width={"100%"} height={50}></Skeleton>
                <Skeleton width={"100%"} height={50}></Skeleton>
                <Skeleton width={"100%"} height={50}></Skeleton>
              </Box>
            )}
          </TableContainer>
          {rows?.length && totalCount ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </Paper>
      </Container>
    </Box>
  );
};
