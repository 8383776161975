import { Skeleton, Stack } from "@mui/material";

export const ChartSkeleton = () => {
  return (
    <Stack width="100%" flex="grow" spacing={1}>
      <Stack direction={"row"} spacing={3} alignItems={"flex-end"}>
        <Skeleton variant="rounded" height={"15vh"} width={"10%"} animation="wave"></Skeleton>
        <Skeleton variant="rounded" height={"18vh"} width={"10%"} animation="wave"></Skeleton>
        <Skeleton variant="rounded" height={"20vh"} width={"10%"} animation="wave"></Skeleton>
        <Skeleton variant="rounded" height={"27vh"} width={"10%"} animation="wave"></Skeleton>
        <Skeleton variant="rounded" height={"24vh"} width={"10%"} animation="wave"></Skeleton>
        <Skeleton variant="rounded" height={"25vh"} width={"10%"} animation="wave"></Skeleton>
        <Skeleton variant="rounded" height={"5vh"} width={"10%"} animation="wave"></Skeleton>
        <Skeleton variant="rounded" height={"25vh"} width={"10%"} animation="wave"></Skeleton>
        <Skeleton variant="rounded" height={"23vh"} width={"10%"} animation="wave"></Skeleton>
        <Skeleton variant="rounded" height={"15vh"} width={"10%"} animation="wave"></Skeleton>
      </Stack>
      <Skeleton variant="rounded" height={20} width={"100%"}></Skeleton>
    </Stack>
  );
};
