import moment from "moment";
import { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

import Button from "../../components/atoms/Button";
import Input from "../../components/atoms/Input";
import Select from "../../components/atoms/Select";
import Tooltip from "../../components/atoms/Tooltip";

import { amountFormat, amountFormatSatoshisToBTC, copyToClipboard, getError, reduceHash } from "../../app/helper/utils";
import { UserTypes, WalletAddressWhitelistStatus } from "../../app/model/api";
import { useQueryGetUserOnMount, useQueryGetWhitelist } from "../../app/query/useQueryGetUser";
import {
  useMutationPostWalletWithdrawPreview,
  useMutationPostWalletWithdrawRequest,
} from "../../app/query/useQueryGetWallet";

import {
  ERROR_DEFAULT_MESSAGE,
  ERROR_FORM_VALIDATION_MESSAGE,
  HOME_ROUTE,
  SATOSHIS_TO_BTC,
  WHITELIST_ADDRESS_ROUTE,
  WITHDRAW_REQUEST_SCHEMA,
} from "../../app/constants";
import { Permission } from "../../app/model/user.type";

import { ReactComponent as BTCIcon } from "../../assets/images/bitcoin.svg";
import { ReactComponent as CopyIcon } from "../../assets/images/copy-icon.svg";
import { ReactComponent as TxLinkIcon } from "../../assets/images/txLink-icon.svg";

import classNames from "classnames";
import Decimal from "decimal.js";
import "./style.scss";
import { isCustomError } from "../../app/helper/errors";

const Withdraw = () => {
  const navigate = useNavigate();
  const { data: result } = useQueryGetWhitelist({
    offset: 0,
    limit: 1000,
  });
  const whitelist = result?.rows;
  const { data: user } = useQueryGetUserOnMount();
  const {
    mutateAsync: mutateAsyncPreview,
    data: dataPreview,
    isLoading: isLoadingDataPreview,
  } = useMutationPostWalletWithdrawPreview();
  const {
    mutateAsync: mutateAsyncRequest,
    data: dataRequest,
    isLoading: isLoadingDataRequest,
  } = useMutationPostWalletWithdrawRequest();

  const [errors, setErrors] = useState<any>({});
  const [step, setStep] = useState<number>(1);
  const [address, setAddress] = useState<{ label: string; value: string }>({ label: "", value: "" });
  const [amount, setAmount] = useState<number | string>();

  const addAddressField = { label: "+ Add Address", value: WHITELIST_ADDRESS_ROUTE };
  const maxBtc = new Decimal(user?.maximumSpendableSatoshis || 0)
    .minus(user?.reservedBalanceSatoshis || 0)
    .div(SATOSHIS_TO_BTC)
    .toString();
  const getAvailableAddresses = () => {
    if (whitelist?.length > 0) {
      const list = whitelist
        .filter((item: any) => item.status === WalletAddressWhitelistStatus.Approved)
        .map((item: any) => {
          return { label: `${item.name} (${reduceHash(item.address)})`, value: item.address };
        });

      return [...list, addAddressField];
    }

    return [addAddressField];
  };

  const goBack = () => {
    setStep(step - 1);
  };

  const handleCopy = (text: string) => {
    copyToClipboard(text);
    toast.success("Address copied to clipboard");
  };

  const handlePreviewSubmit = () => {
    const { value } = address;

    WITHDRAW_REQUEST_SCHEMA.validate(Object.assign({ address: value, amount }), { abortEarly: false })
      .then(async (valid) => {
        try {
          await mutateAsyncPreview({ ...valid, amount: SATOSHIS_TO_BTC.mul(amount as number).toNumber() });
          setErrors({});
          setStep(2);
        } catch (error: unknown) {
          if (isCustomError(error)) toast.error(error.error.message);
          else toast.error(ERROR_DEFAULT_MESSAGE);
        }
      })
      .catch((err) => {
        const errors = err.inner?.reduce((prev: any, current: any) => {
          return { ...prev, [current.path]: current.message };
        }, {});
        setErrors(errors);
        toast.error(ERROR_FORM_VALIDATION_MESSAGE);
      });
  };

  const handleRequestSubmit = () => {
    const { value } = address;
    WITHDRAW_REQUEST_SCHEMA.validate(Object.assign({ address: value, amount }), { abortEarly: false })
      .then(async (valid) => {
        await mutateAsyncRequest({
          ...valid,
          amount: SATOSHIS_TO_BTC.mul(amount as number)
            .round()
            .toNumber(),
        });
        setErrors({});
        setStep(3);
      })
      .catch((err) => {
        const errors = err.inner?.reduce((prev: any, current: any) => {
          return { ...prev, [current.path]: current.message };
        }, {});

        setErrors(errors);
        toast.error(ERROR_FORM_VALIDATION_MESSAGE);
      });
  };

  const onAddressChange = (name: string, value: string | number | boolean) => {
    if (value === WHITELIST_ADDRESS_ROUTE) {
      navigate(value);
    }
    const whitelistAddress = whitelist.find((item: any) => item.address === value);
    setAddress({ value: String(value), label: `${whitelistAddress.name} (${reduceHash(whitelistAddress.address)})` });
    setErrors((prev: any) => {
      return { ...prev, [name]: null };
    });
  };

  const canCreateWithdrawal = user?.permissions?.includes(Permission.CREATE_WITHDRAWAL);
  const canPreviewWithdrawal = user?.permissions?.includes(Permission.PREVIEW_WITHDRAWAL);

  return (
    <div className="withdraw-page-wrapper">
      <div
        className={classNames("hero-withdraw", {
          "hero-withdraw--lp": user?.userType === UserTypes.LP,
          "hero-withdraw--miner": user?.userType === UserTypes.Miner,
        })}
      ></div>

      <div className="container-custom mx-auto">
        <div className="mt-8 ml-2 mb-12">
          {step === 1 && (
            <>
              <div className="grid grid-flow-col auto-cols-max gap-5">
                <div className="w-[280px]">
                  <h4>Wallet Address</h4>
                </div>
                <div className="w-[512px]">
                  {whitelist?.length > 0 && (
                    <Select
                      name="address"
                      className="mb-6"
                      value={address}
                      options={getAvailableAddresses()}
                      onChange={(name, value) => onAddressChange(name, value)}
                      error={getError(errors, "address")}
                    />
                  )}
                </div>
              </div>
              <hr className="my-3 h-px !bg-gray-200 border-0 dark:bg-gray-700" />
              <div className="grid grid-flow-col auto-cols-max gap-5 mb-5">
                <div className="w-[280px]">
                  <h4>Amount</h4>
                  <h5>Enter Amount You Want To Withdraw</h5>
                </div>
                <div className="w-[512px]">
                  <p className="input-custom-label">Available: {`${maxBtc} BTC`}</p>

                  <div className="relative">
                    <Input
                      name="amount"
                      type="number"
                      maxValue={maxBtc}
                      value={amount || ""}
                      placeholder="0.00"
                      onChange={(name, value) => {
                        setAmount(value as string | number);
                        return setErrors({ [name]: null });
                      }}
                      currency={<BTCIcon className="absolute left-3 top-3.5 z-10" />}
                      error={getError(errors, "amount")}
                    />
                    <div className="absolute right-2 top-1.5">
                      <Button
                        label="MAX"
                        backgroundColor="#F9F5FF"
                        color="#8F49FD"
                        className="w-16 font-normal px-2 py-1 "
                        onClick={() => {
                          setAmount(maxBtc);
                          return setErrors({ amount: null });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-flow-col auto-cols-max gap-5">
                <div className="w-[280px]"></div>
                <>
                  <Button
                    type="submit"
                    label="Preview Withdrawal"
                    className="w-[250px] px-1 text-white"
                    backgroundColor="#8F49FD"
                    notAllowed={(amount || 0) > maxBtc || !canPreviewWithdrawal}
                    disabled={isLoadingDataPreview}
                    onClick={() =>
                      (amount || 0) <= maxBtc ? (canPreviewWithdrawal ? handlePreviewSubmit() : null) : null
                    }
                  />
                  {!canPreviewWithdrawal ? (
                    <Tooltip
                      width={25}
                      height={25}
                      className="w-[300px] left-[-137px] bottom-[43px]"
                      content="You do not have permission to perform this action. To request access, contact your organization administrators"
                    />
                  ) : null}
                </>
              </div>
              <hr className="my-6 h-px !bg-gray-200 border-0 dark:bg-gray-700" />
            </>
          )}

          {step === 2 && (
            <div>
              {dataPreview.receivingAmountUsd > 100000 && (
                <div className="bg-white border border-purple-400 px-72 py-6 text-center">
                  <p>
                    Withdrawals worth over $100.000 require manual approval from Block Green. This should take less than
                    one business day. You will be notified once you can continue your transaction.
                  </p>
                </div>
              )}

              <div className="grid grid-cols-2 my-8 px-6">
                <div>
                  <h4 className="text-uppercase mb-4">From</h4>

                  <p>Your Block Green Wallet ({reduceHash(String(user?.walletAddress))})</p>
                </div>
                <div>
                  <h4 className="text-uppercase mb-4">To</h4>
                  <p>{address.label}</p>
                </div>
              </div>

              <hr className="my-6 h-0.5 !bg-[#BDCCD4] border-1 dark:bg-gray-400" />

              <div className="grid grid-cols-2 my-8 px-6">
                <div>
                  <h4 className="text-uppercase mb-4">Receiving Amount</h4>
                  <p>
                    {amountFormatSatoshisToBTC(dataPreview.receivingAmountBtc)} BTC (
                    {amountFormat(new Decimal(dataPreview.receivingAmountUsd).mul(100).round().div(100).toNumber())})
                  </p>
                </div>
                <div>
                  <h4 className="text-uppercase mb-4">Estimated Network Fee</h4>
                  <p>
                    {amountFormatSatoshisToBTC(dataPreview.transactionFeeBtc)} BTC (
                    {amountFormat(new Decimal(dataPreview.transactionFeeUsd).mul(100).round().div(100).toNumber())})
                  </p>
                </div>
              </div>

              <hr className="my-6 h-0.5 !bg-[#BDCCD4] border-1 dark:bg-gray-400" />

              <div className="px-6 flex">
                <Button
                  label="Back"
                  onClick={goBack}
                  color="#191C24"
                  className="w-[120px] bg-white border border-gray-300 mr-4"
                />
                <Button
                  label="Request Withdrawal"
                  disabled={isLoadingDataRequest}
                  notAllowed={!canCreateWithdrawal}
                  onClick={() => (canCreateWithdrawal ? handleRequestSubmit() : null)}
                  backgroundColor="#8F49FD"
                  className="text-white w-[220px]"
                />
                {!canCreateWithdrawal ? (
                  <Tooltip
                    width={25}
                    height={25}
                    className="w-[300px] left-[-137px] bottom-[43px]"
                    content="You do not have permission to perform this action. To request access, contact your organization administrators"
                  />
                ) : null}
              </div>
            </div>
          )}

          {step === 3 && (
            <div>
              {dataRequest.receivingAmountUsd > 100000 && (
                <div className="bg-white border border-purple-400 px-72 py-6 text-center">
                  <h3 className="mb-4 pt-0">Withdrawal Initiated</h3>
                  <p>
                    Withdrawals worth over $100.000 require manual approval from Block Green. This should take less than
                    one business day. You will be notified once you can continue your transaction.
                  </p>
                </div>
              )}

              <div className="grid grid-cols-2 my-8 px-6">
                <div>
                  <h4 className="text-uppercase mb-4">Date & Time</h4>

                  <p>{dataRequest.datetime ? moment(dataRequest.datetime).format("d MMM YYYY | HH:mm A ") : ""}</p>
                </div>
                <div>
                  <h4 className="text-uppercase mb-4">Estimated Network Fee</h4>
                  <p>
                    {amountFormatSatoshisToBTC(dataPreview.transactionFeeBtc)} BTC (
                    {amountFormat(new Decimal(dataPreview.transactionFeeUsd).mul(100).round().div(100).toNumber())})
                  </p>
                </div>
              </div>

              <hr className="my-6 h-0.5 !bg-[#BDCCD4] border-1 dark:bg-gray-400" />

              <div className="grid grid-cols-2 my-8 px-6">
                <div>
                  <h4 className="text-uppercase mb-4">Receiving Amount</h4>
                  <p>{`${amountFormatSatoshisToBTC(dataPreview.receivingAmountBtc)} BTC`}</p>
                </div>
                <div>
                  <h4 className="text-uppercase mb-4">Total Withdrawal</h4>
                  <p>
                    {amountFormatSatoshisToBTC(dataRequest.totalWithdrawalBtc)} BTC (
                    {amountFormatSatoshisToBTC(
                      new Decimal(dataPreview.totalWithdrawalUsd).mul(100).round().div(100).toNumber()
                    )}
                    )
                  </p>
                </div>
              </div>

              <hr className="my-6 h-0.5 !bg-[#BDCCD4] border-1 dark:bg-gray-400" />

              <div className="grid grid-cols-2 my-8 px-6">
                <div>
                  <h4 className="text-uppercase mb-4">From</h4>
                  <p>{dataRequest.from}</p>
                </div>
                <div>
                  <h4 className="text-uppercase mb-4">To</h4>
                  <p>{dataRequest.to}</p>
                </div>
              </div>

              <hr className="my-6 h-0.5 !bg-[#BDCCD4] border-1 dark:bg-gray-400" />

              <div className="grid my-8 px-6">
                <div>
                  <h4 className="text-uppercase mb-4">TX Hash</h4>
                  <div className="flex items-center">
                    {dataRequest.txHash}
                    <button onClick={() => handleCopy(dataRequest.txHash)} className="px-2 py-1">
                      <CopyIcon />
                    </button>

                    <Link to={dataRequest?.url} target="_blank" className="px-2 py-1">
                      <TxLinkIcon />
                    </Link>
                  </div>
                </div>
              </div>

              <hr className="my-6 h-0.5 !bg-[#BDCCD4] border-1 dark:bg-gray-400" />

              <div className="px-6">
                <Button
                  label="Back To Dashboard"
                  onClick={() => navigate(HOME_ROUTE)}
                  backgroundColor="#8F49FD"
                  className="text-white w-[220px]"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
