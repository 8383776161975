import { useMutation } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import { CollateralRequestDto, TransferRequestDto } from "../model/api";

interface CollateralOpportunity extends CollateralRequestDto {
  id: string;
}

const transferCoins = async (data: TransferRequestDto): Promise<any> => {
  return await apig.wallet.transferCoins(data);
};

const postCollateralToOpportunity = async (data: CollateralOpportunity) => {
  return await apig.miner.postCollateralToOpportunity(data.id, { amount: data.amount });
};

const useMutationTransferCoins = () => {
  return useMutation((data: TransferRequestDto) => transferCoins(data));
};

const useMutationCollateralToOpportunity = () => {
  return useMutation((data: CollateralOpportunity) => postCollateralToOpportunity(data));
};

export { useMutationTransferCoins, useMutationCollateralToOpportunity };
