import { Stack, Typography } from "@mui/material";
import { amountFormat } from "../../../app/helper/utils";
import { TransactionHistoryMinerOrLpType } from "../../../app/model/api";

interface TableAmountCellProps {
  type: string;
  amountBtc: number;
  amountUsd: number;
}

export const TableAmountCell = ({ type, amountBtc, amountUsd }: TableAmountCellProps) => {
  return (
    <Stack>
      <Typography
        variant="subtitle1"
        color={type === TransactionHistoryMinerOrLpType.Withdrawal ? "#FC7A53" : "#31DF8B"}
      >
        {`${type === TransactionHistoryMinerOrLpType.Withdrawal ? "-" : "+"}${amountFormat(amountBtc, "BTC")}`} BTC
      </Typography>
      <Typography variant="caption">
        {`${type === TransactionHistoryMinerOrLpType.Withdrawal ? "-" : "+"}${amountFormat(amountUsd)}`}
      </Typography>
    </Stack>
  );
};
