import Button from "../../atoms/Button";

import { ReactComponent as ArrowLeftIcon } from "../../../assets/images/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/images/arrow-right.svg";

import "./style.scss";

interface PaginationProps {
  page: number;
  paginationPages: number;
  onPrev: () => void;
  onNext: () => void;
  className?: string;
}

const Pagination = ({ page, paginationPages, onPrev, onNext, className }: PaginationProps) => {
  return (
    <>
      {paginationPages > 1 && (
        <div className={`flex mx-auto w-[400px] gap-12 mt-[50px] h-[25px] ${className}`}>
          <Button
            icon={<ArrowLeftIcon className="w-5" />}
            className="btn-arrow"
            onClick={() => (page !== 0 ? onPrev() : {})}
            notAllowed={page === 1}
          />
          <div className="flex gap-3 items-center">
            <div>
              <div className="text-center -mt-8 mb-2">
                {page} / {paginationPages}
              </div>
              <div className="pagination-bar">
                <div style={{ width: `${(page * 100) / paginationPages}%` }} className="pagination-bar-active"></div>
              </div>
            </div>
          </div>
          <Button
            className="btn-arrow"
            icon={<ArrowRightIcon className="w-5" />}
            onClick={() => (page < paginationPages ? onNext() : {})}
            notAllowed={page === paginationPages}
          />
        </div>
      )}
    </>
  );
};

export default Pagination;
