import { KymRate } from "../../../app/model/user.type";
import { ReactComponent as ScoreGreenIcon } from "../../../assets/images/score-icon-green.svg";
import { ReactComponent as ScoreOrangeIcon } from "../../../assets/images/score-icon-orange.svg";
import { ReactComponent as ScoreRedIcon } from "../../../assets/images/score-icon-red.svg";

type KymRateValues = "LOW" | "MEDIUM" | "HIGH";

export const ScoreIcon = ({ value }: { value: KymRateValues | null | undefined }) => {
  switch (value) {
    case KymRate.LOW:
      return <ScoreRedIcon width={26} height={22} className="text-start" />;
    case KymRate.MEDIUM:
      return <ScoreOrangeIcon width={26} height={22} className="text-start" />;
    case KymRate.HIGH:
      return <ScoreGreenIcon width={26} height={22} className="text-start" />;
    default:
      return <ScoreRedIcon width={26} height={22} className="text-start" />;
  }
};
