import { useMutation } from "@tanstack/react-query";
import { apig } from "../../app/config/query/clientgen";
import {
  ReqCreateDraftLoanDto,
  ReqCreatePaymentRequest,
  ReqGenerateDemo,
  ReqUpdateDraftLoan,
} from "../../app/model/api";

export const useAdminCreateDraftLoan = () => {
  return useMutation((input: ReqCreateDraftLoanDto) => apig.loans.createAdminDraftLoan(input));
};

export const useAdminUpdateDraftLoan = () => {
  return useMutation((input: ReqUpdateDraftLoan) => apig.loans.updateAdminDraftLoan(input));
};

export const useAdminCancelLoan = () => {
  return useMutation((loanId: string) => apig.loans.cancelAdminLoan(loanId));
};

export const useAdminCompleteLoan = () => {
  return useMutation((loanId: string) => apig.loans.completeAdminLoan(loanId));
};

export const useAdminGenerateDemo = () => {
  return useMutation((data: ReqGenerateDemo) => apig.loans.generateDemoLoanData(data));
};

export const useAdminCreatePaymentRequest = () => {
  return useMutation((input: ReqCreatePaymentRequest) => apig.loans.createAdminPaymentRequest(input));
};

export const useAdminDeletePaymentRequest = () => {
  return useMutation((paymentRequestId: string) => apig.loans.deleteAdminPaymentRequest(paymentRequestId));
};
