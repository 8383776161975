import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { v4 } from "uuid";

export const ExplainerAccordion = ({
  title,
  explainers,
}: {
  title: string | JSX.Element;
  explainers: (string | JSX.Element)[];
}) => {
  return (
    <Box maxWidth={800} padding={1}>
      <Accordion sx={{ boxShadow: 3, background: "#F0EBE3" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" alignItems={"center"} gap={1}>
            <InfoIcon color="primary" />
            {typeof title === "string" ? <Typography variant="h6">{title}</Typography> : title}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {explainers.map((x) =>
            typeof x === "string" ? (
              <Typography key={v4()} variant="body1" gutterBottom textAlign={"justify"} sx={{ mb: 2 }}>
                {x}
              </Typography>
            ) : (
              x
            )
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
