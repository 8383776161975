import "./style.scss";

interface ErrorPageProps {
  title?: string;
  status?: string;
  message?: string | JSX.Element;
  fullHeight?: boolean;
}

const ErrorPage = ({ title, status, message, fullHeight = false }: ErrorPageProps) => {
  return (
    <div
      className={`not-found-page flex items-center justify-center px-4 bg-error-background bg-cover ${
        fullHeight ? "h-[100vh]" : "h-[calc(100vh_-_84px)]"
      }`}
    >
      <div className="flex gap-4 items-baseline">
        {title && (
          <div className="bg-white px-[21px] py-[44px] text-[116px]">
            <h1>{title}</h1>
          </div>
        )}

        <div className="flex flex-col items-start gap-4">
          {status && (
            <div className="bg-white px-[21px] py-[44px] text-[116px]">
              <h2>{status}</h2>
            </div>
          )}

          {message && (
            <div className="bg-white px-[21px] py-[44px]">
              <h4>{message}</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
