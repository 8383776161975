import { useQueryClient } from "@tanstack/react-query";
import Decimal from "decimal.js";
import moment from "moment";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import Button from "../../atoms/Button";
import InputBTC from "../../atoms/InputBTC";
import SectionTitle from "../../atoms/SectionTitle";
import TransferConfirmationModal from "../TransferConfirmationModal";
import TransferModal from "../TransferModal";

import {
  ERROR_DEFAULT_MESSAGE,
  ERROR_INITIATE_OPPORTUNITY_LESS_THEN_ZERO_MESSAGE,
  ERROR_INITIATE_OPPORTUNITY_MESSAGE,
  SATOSHIS_TO_BTC,
  SUCCESS_INITIATE_OPPORTUNITY_MESSAGE,
} from "../../../app/constants";
import { roundToSignificantDigits } from "../../../app/helper/utils";
import {
  FundingApprovalStatus,
  OpportunityFullDataDto,
  OpportunityFunding,
  ProtocolWalletType,
} from "../../../app/model/api";
import { Permission } from "../../../app/model/user.type";
import { useMutationFunding } from "../../../app/query/useMutationOpportunity";
import { useMutationInitiateOpportunity } from "../../../app/query/useMutationUser";
import { useMutationTransferCoins } from "../../../app/query/useMutationWallet";
import { useGetQueryOpportunityFunding } from "../../../app/query/useQueryGetLP";
import { useQueryGetUserOnMount } from "../../../app/query/useQueryGetUser";
import { useGetQueryWalletFee } from "../../../app/query/useQueryGetWallet";

import { ReactComponent as SpinnerIcon } from "../../../assets/images/spinner.svg";
import { isCustomError } from "../../../app/helper/errors";

interface FundOpportunityProps {
  price?: number;
  disabled?: boolean;
}

const FundOpportunity = ({ price, disabled = false }: FundOpportunityProps) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const opptunityState = queryClient.getQueryState<OpportunityFullDataDto>(["lp_opportunity", `${id}`]);
  const { data: user, isLoading: isLoadingUser } = useQueryGetUserOnMount();
  const { mutateAsync, isLoading: isLoadingInitiateOpportunity } = useMutationInitiateOpportunity();
  const {
    mutateAsync: transferCoin,
    data: transferCoinResponse,
    isLoading: isLoadingTransfer,
  } = useMutationTransferCoins();
  const { mutateAsync: updateTrancheFundingVisibility, isLoading: isLoadingTrancheFundingVisibility } =
    useMutationFunding();
  const { data: walletFee } = useGetQueryWalletFee();

  const canGetFunding = user?.permissions?.includes(Permission.GET_OPPORTUNITY_FUNDING) || false;
  const canCreateFunding = user?.permissions?.includes(Permission.CREATE_OPPORTUNITY_FUNDING) || false;
  const { data, refetch } = useGetQueryOpportunityFunding(id as string, canGetFunding);

  const [hideFromOthers, setHideFromOthers] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [transferModal, setTransferModal] = useState<boolean>(false);
  const [confirmationTransferModal, setConfirmationTransferModal] = useState<boolean>(false);
  const [disclaimer, setDisclaimer] = useState<boolean>(false);

  const handleSubmit = async () => {
    const userBalanceBtc = user?.balanceSatoshis
      ? new Decimal(user.balanceSatoshis).div(SATOSHIS_TO_BTC).toNumber()
      : 0;

    if (amount > userBalanceBtc) {
      toast.error(ERROR_INITIATE_OPPORTUNITY_MESSAGE);
      return;
    } else if (amount < 0) {
      toast.error(ERROR_INITIATE_OPPORTUNITY_LESS_THEN_ZERO_MESSAGE);
      return;
    }

    try {
      await mutateAsync({
        amountSatoshis: new Decimal(amount).mul(new Decimal(SATOSHIS_TO_BTC)).toString(),
        opportunityId: id as string,
      });
      setAmount(0);
      refetch();
      toast.success(SUCCESS_INITIATE_OPPORTUNITY_MESSAGE);
    } catch (error: unknown) {
      if (isCustomError(error)) toast.error(error.error.message);
      else toast.error(ERROR_DEFAULT_MESSAGE);
    }
  };

  const getButtonText = () => {
    const status = data?.approvalStatus;

    switch (status) {
      case FundingApprovalStatus.AwaitingTransactionApproval:
        return "Transfer Liquidity";
      case FundingApprovalStatus.AwaitingAgreementSignature:
        return "Waiting for Agreement";
      default:
        return "Initiate funding flow";
    }
  };

  const isAwaitingAgreementSignature =
    data && (data as OpportunityFunding)?.approvalStatus === FundingApprovalStatus.AwaitingAgreementSignature;
  const isAwaitingTransaction =
    data && (data as OpportunityFunding)?.approvalStatus === FundingApprovalStatus.AwaitingTransactionApproval;

  const toAddress = data?.opportunity?.protocolWallets.find(
    (item: any) => item.walletType === ProtocolWalletType.SynHashPool
  )?.wallet?.address?.address;

  const handleTransferCoin = async () => {
    try {
      if (!data?.id) throw new Error("Funding id is not defined");
      if (!toAddress) throw new Error("toAddress is not defined");
      if (!data?.amountSatoshis) throw new Error("Amount is not defined");

      await transferCoin({
        amount: new Decimal(data.amountSatoshis).round().toString(),
        receiverAddress: toAddress,
        fundingId: data.id,
      });
      await updateTrancheFundingVisibility({ id: data.id, visibleToOthers: !hideFromOthers });

      setTransferModal(false);
      setConfirmationTransferModal(true);
      refetch();
    } catch (error: unknown) {
      if (isCustomError(error)) toast.error(error.error.message);
      else toast.error(ERROR_DEFAULT_MESSAGE);
    }
  };

  let forecastRewards = 0;
  if (
    opptunityState?.data?.opportunityDetails.expectedReturn &&
    opptunityState?.data?.opportunityDetails.durationOfAgreement
  ) {
    const expectedReturnPerc = new Decimal(opptunityState.data.opportunityDetails.expectedReturn).div(100).plus(1);
    const trancheDurationYears = new Decimal(opptunityState.data.opportunityDetails.durationOfAgreement).div(365.25);
    forecastRewards = expectedReturnPerc
      .pow(trancheDurationYears)
      .mul(
        new Decimal(
          (isAwaitingAgreementSignature || isAwaitingTransaction) && data?.amountSatoshis
            ? new Decimal(data.amountSatoshis).div(SATOSHIS_TO_BTC)
            : new Decimal(amount > 0 ? amount : 0)
        )
      )
      .toNumber();
  }

  const isOverOpportunityCapacity = amount
    ? new Decimal(amount).greaterThan(
        new Decimal(opptunityState?.data?.opportunityDetails?.opportunityCapacity || 0).div(SATOSHIS_TO_BTC)
      )
    : false;

  return (
    <div className="bg-white">
      <SectionTitle title="Fund opportunity" />
      <div className="p-6">
        <div>
          <p className="mb-2 uppercase">
            <strong>Initiate Funding</strong>
          </p>
          <p>
            Commit to funding this opportunity with a certain amount of BTC. This will start the funding process
            outlined below.
          </p>
        </div>
        <InputBTC
          showTooltip={!canCreateFunding}
          opportunityCapacity={opptunityState?.data?.opportunityDetails?.opportunityCapacity || 0}
          value={isAwaitingAgreementSignature || isAwaitingTransaction ? data?.amountSatoshis : 0}
          fee={walletFee?.fee}
          onChange={setAmount}
          disabled={
            isAwaitingAgreementSignature || isAwaitingTransaction || isLoadingUser || disabled || !canCreateFunding
          }
        />

        <div className="grid grid-cols-2 gap-[5px] text-[11px]">
          <div className="bg-[#eeeeee] border border-[#5d5d5d] rounded-[5px] py-[8px] px-[8px] text-center mb-3">
            Forecast rewards:{" "}
            <span className="font-bold text-[11px] block">
              {roundToSignificantDigits(forecastRewards, forecastRewards >= 1 ? 2 : 1)} BTC
            </span>
          </div>
          <div className="bg-[#eeeeee] border border-[#5d5d5d] rounded-[5px] py-[8px] px-[8px] text-center mb-3">
            Hashrate aquired:
            <br />
            <span className="font-bold text-[11px]">
              {amount > 0
                ? roundToSignificantDigits(
                    new Decimal(
                      (isAwaitingAgreementSignature || isAwaitingTransaction) && data?.amountSatoshis
                        ? new Decimal(data?.amountSatoshis).div(SATOSHIS_TO_BTC)
                        : new Decimal(amount)
                    )
                      .div(price || 0)
                      .toNumber()
                  )
                : 0}{" "}
              PH/s
            </span>{" "}
            over{" "}
            <span className="font-bold text-[11px]">
              {opptunityState?.data?.opportunityDetails.durationOfAgreement} days
            </span>
          </div>
        </div>

        {isAwaitingTransaction ? (
          <div className="relative flex mb-[12px]">
            <div className="flex items-center">
              <input
                id="visibility"
                name="hideFromOthers"
                type="checkbox"
                disabled={!canCreateFunding}
                className="h-[16px] w-[16px] rounded-[4px] border-[#D0D5DD] text-purple focus:ring-indigo-600 cursor-pointer"
                onChange={() => setHideFromOthers((prev) => !prev)}
              />
            </div>
            <div className="ml-3">
              <label htmlFor="visibility" className="text-dark text-[14px] cursor-pointer">
                Don't make my funding visible to other LPs
              </label>
            </div>
          </div>
        ) : null}

        {!data ? (
          <div className="relative flex mb-[12px]">
            <div className="flex items-center">
              <input
                id="visibility"
                name="disclaimer"
                type="checkbox"
                disabled={disabled || !canCreateFunding}
                className="h-[16px] w-[16px] rounded-[4px] border-[#D0D5DD] text-purple focus:ring-indigo-600 cursor-pointer"
                onChange={() => setDisclaimer((prev) => !prev)}
              />
            </div>
            <div className="ml-3">
              <label htmlFor="visibility" className="text-dark text-[14px] cursor-pointer">
                I agree to follow the below process to fund this opportunity. I have read and agree to the platform
                disclaimer.
              </label>
            </div>
          </div>
        ) : null}

        <Button
          label={getButtonText()}
          backgroundColor="#8F49FD"
          disabled={isLoadingInitiateOpportunity || isLoadingUser}
          notAllowed={
            (!isAwaitingTransaction && !(amount > 0)) ||
            (!data && !disclaimer) ||
            disabled ||
            !canCreateFunding ||
            isOverOpportunityCapacity
          }
          icon={isAwaitingAgreementSignature ? <SpinnerIcon /> : null}
          className={`text-white font-normal mb-4 ${
            !isAwaitingTransaction && !(amount > 0) ? "cursor-not-allowed" : ""
          }`}
          onClick={() => {
            if (isAwaitingAgreementSignature) {
              // If the agreement signature is still awaiting, do nothing (return early).
              return;
            }

            if (isAwaitingTransaction) {
              // If there is a pending transaction, open the transfer modal.
              setTransferModal(true);
            } else if (canCreateFunding) {
              // If the user can fund, handle the submit action.
              handleSubmit();
            }
          }}
        />
        <div className="bg-[#F3F3F3]">
          <div className="px-4 py-6">
            <p className="uppercase pb-4 font-medium ">How does funding work?</p>
            <ol start={1}>
              <li>1. Send a funding request above</li>
              <li>2. You will receive an agreement by email committing to the funding</li>
              <li>3. Sign this agreement</li>
              <li>4. Initiate the transaction from your Block Green wallet</li>
            </ol>
          </div>
        </div>
      </div>

      {transferModal && user ? (
        <TransferModal
          data={{
            date: moment().toISOString() as string,
            fromAddress: user?.walletAddress as string,
            toAddress: toAddress || "",
            deposit: data?.amountSatoshis ? new Decimal(data.amountSatoshis).div(SATOSHIS_TO_BTC).toNumber() : 0,
            fee: walletFee?.fee || 0,
          }}
          disableSubmitButton={isLoadingTransfer || isLoadingTrancheFundingVisibility}
          open={transferModal}
          onClose={() => setTransferModal(false)}
          onSubmit={() => (canCreateFunding ? handleTransferCoin() : null)}
        />
      ) : null}

      {confirmationTransferModal && transferCoinResponse && user ? (
        <TransferConfirmationModal
          open={confirmationTransferModal}
          data={{
            fromAddress: user?.walletAddress as string,
            toAddress: toAddress || "",
            depoosit: data?.amountSatoshis ? new Decimal(data.amountSatoshis).div(SATOSHIS_TO_BTC).toNumber() : 0,
            fee: walletFee?.fee || 0,
            txHash: transferCoinResponse?.data?.txId,
            txUrl: transferCoinResponse?.data?.url,
          }}
          onClose={() => setConfirmationTransferModal(false)}
        />
      ) : null}
    </div>
  );
};

export default FundOpportunity;
