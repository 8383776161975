import * as qs from "query-string";
import { Auth0QueryStringProps } from "../../model/route.type";

const { invitation }: Auth0QueryStringProps = qs.parse(window.location.search);

const onRedirectCallback = (appState: any) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
  );
};

export const providerConfig = {
  environment: import.meta.env.VITE_ENVIRONMENT ?? "local",
  domain: getConfig().domain,
  clientId: getConfig().clientId,
  ...(getConfig().audience ? { audience: getConfig().audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
  invitation,
  organization: import.meta.env.VITE_AUTH_ORGANIZATION,
};

export const allowDemoHandling =
  providerConfig.environment === "devel" ||
  providerConfig.environment === "staging" ||
  providerConfig.environment === "local";

function getConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.
  const audience =
    import.meta.env.VITE_AUTH_AUDIENCE && import.meta.env.VITE_AUTH_AUDIENCE !== "YOUR_API_IDENTIFIER"
      ? import.meta.env.VITE_AUTH_AUDIENCE
      : null;

  return {
    domain: import.meta.env.VITE_AUTH_DOMAIN || "",
    clientId: import.meta.env.VITE_AUTH_CLIENT_ID || "",
    ...(audience ? { audience } : null),
  };
}
