const Disclaimer = () => {
  return (
    <div className="font-pp-neue-machina">
      <p className="font-bold text-black">Platform Disclaimer for Block Green</p>
      <p>
        IMPORTANT NOTICE: Please read this disclaimer carefully before proceeding to browse hashrate streaming
        opportunities on Block Green. By using this platform, you acknowledge that you have read, understood, and agree
        to be bound by the terms and conditions set forth in this disclaimer.
      </p>
      <br />
      <p className="font-bold text-black">General Information</p>
      <p>
        This platform is a marketplace designed for institutions to explore and purchase Bitcoin hashrate in streaming
        opportunities. The platform aims to facilitate the connection between liquidity providers (LPs) and hashrate
        providers (miners) to help them reach mutually beneficial agreements.
      </p>
      <br />
      <p className="font-bold text-black">Not Investment Advice</p>
      <p>
        The information and materials presented on this platform are for informational purposes only and should not be
        construed as investment advice. Before making any investment decisions, you should consult with your financial
        advisor and conduct your own due diligence.
      </p>
      <br />
      <p className="font-bold text-black">Informational Purposes and Non-Binding Nature</p>
      <p>
        Please note that the information presented on this platform is for informational purposes only and is
        non-binding. The actual terms and conditions of any streaming opportunity or agreement between liquidity
        providers (LPs) and miners shall be governed by the signed agreement between the respective parties. The
        platform does not guarantee the accuracy, completeness, or validity of the information provided, and any
        discrepancies or conflicts between the platform's content and the signed agreement shall be resolved in favor of
        the signed agreement. It is your responsibility to carefully review and understand the terms and conditions of
        any signed agreement before entering into a binding commitment.
      </p>
      <br />
      <p className="font-bold text-black">Informational Only</p>
      <p>
        The platform does not guarantee or warrant any specific level of profit or performance for the hashrate
        streaming opportunities. The actual return may vary and may be affected by numerous factors beyond the control
        of this platform.
      </p>
      <br />
      <p className="font-bold text-black">Regulatory Compliance</p>
      <p>
        As a liquidity provider, you are responsible for ensuring that your hashrate purchases comply with all
        applicable laws, regulations, and guidelines in your jurisdiction. This platform does not guarantee or assume
        any responsibility for the regulatory compliance of the opportunities presented.
      </p>
      <br />
      <p className="font-bold text-black">Risks Involved</p>
      <p>
        Bitcoin hashrate streaming involves significant risks, including but not limited to market volatility,
        regulatory changes, technology failures, and cybersecurity threats. You should carefully consider the risks
        involved and your risk tolerance before purchasing any agreements.
      </p>
      <br />
      <p className="font-bold text-black">No Guarantee of Profit or Performance</p>
      <p>
        The platform does not guarantee or warrant any specific level of profit or performance for the hashrate
        streaming instruments. The actual return may vary and may be affected by numerous factors beyond the control of
        this platform.
      </p>
      <br />
      <p className="font-bold text-black">Third-Party Providers</p>
      <p>
        The platform may feature hashrate streaming opportunities provided by third-party hashrate providers. We do not
        endorse or guarantee the accuracy, reliability, or completeness of the information provided by these third-party
        providers. You should independently verify the information and perform your own due diligence.
      </p>
      <br />
      <p className="font-bold text-black">Limitation of Liability</p>
      <p>
        The platform and its affiliates, directors, officers, employees, agents, and licensors shall not be liable for
        any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to loss
        of profits, loss of data, or business interruption, arising out of or related to your use of this platform or
        reliance on any information or materials provided on this platform.
      </p>
      <br />
      <p>
        By continuing to browse hashrate streaming opportunities on this platform, you confirm that you have read,
        understood, and agreed to the terms and conditions set forth in this disclaimer.
      </p>
    </div>
  );
};

export default Disclaimer;
