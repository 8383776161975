import { useQuery } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import { OpportunityFullDataDto, RequestParams } from "../model/api";

const getMinerOpportunity = async (id: string, params: RequestParams): Promise<OpportunityFullDataDto> => {
  return (await apig.admin.getAdminOpprotunity(id, params)).data;
};

const getOpportunitySummary = async (id: string) => {
  return (await apig.miner.getOpportunitySummary(id)).data;
};

const useGetMinerOpportunity = (id: string) => {
  return useQuery([`opportunity_${id}`], () => getMinerOpportunity(id, {}));
};

const useGetMinerOpportunitySummary = (id: string) => {
  return useQuery([`opportunity_${id}_summary`], () => getOpportunitySummary(id), { refetchOnMount: "always" });
};

export { useGetMinerOpportunity, useGetMinerOpportunitySummary };
