import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";
import { useState } from "react";
import toast from "react-hot-toast";
import { v4 } from "uuid";
import Loader from "../../../components/atoms/Loader";
import { checkExhaustive } from "../../Admin/MinerDetails/Opportunity/simulation/simulationTimelineItems";
import { ExplainerAccordion } from "../commons/ExplainerAccordion";
import { LoanPaymentRequestsTable } from "../commons/LoanPaymentRequestsTable";
import { LoanTitle } from "../commons/LoanTitle";
import { VerticalInfoTable } from "../commons/VerticalInfoTable";
import { formatWithCommas, toFormattedBtc } from "../commons/utils";
import { useAdminDeletePaymentRequest } from "../loanMutations";
import { useAdminGetLoan, useAdminGetLoanBitgoBalance } from "../loanQueries";
import { LoanAssociatedAddressesTable } from "./LoanAssociatedAddressesTable";
import { MakePaymentRequest } from "./MakePaymentRequest";
import { ADMIN_EXPLAINER_PAYMENT_REQUESTS } from "./AdminPageLoanAudit";
import { UnitSelector } from "../commons/UnitSelector";

export const AdminPagePaymentRequest = ({ id }: { id: string }) => {
  const [paymentIdToDelete, setPaymentIdToDelete] = useState<string | undefined>();
  const [destroyKey, setDestroyKey] = useState("initial");
  const { data, refetch } = useAdminGetLoan(id ?? "");
  const { data: bitgodata, refetch: refetchBitgoBalance } = useAdminGetLoanBitgoBalance(id);
  const { mutateAsync: asyncDeletePaymentRequest, isLoading: isDeleting } = useAdminDeletePaymentRequest();
  const [unit, setUnit] = useState<"BTC" | "SAT">("BTC");

  const handleDelete = async () => {
    if (paymentIdToDelete) {
      try {
        const result = await asyncDeletePaymentRequest(paymentIdToDelete);
        setPaymentIdToDelete(undefined);
        switch (result.data.status) {
          case "Done": {
            toast.success("Payment request has been deleted.");
            return;
          }
          case "TooLate": {
            toast.error("Can no longer delete payment request (too close to or passed the execution threshold).");
            return;
          }
          default:
            checkExhaustive(result.data.status);
        }
      } catch (err) {
        toast.error("Unknown issue while trying to delete the payment request.");
      } finally {
        handleUpdates();
      }
    }
  };

  const handleUpdates = () => {
    refetch();
    refetchBitgoBalance();
    setDestroyKey(v4());
  };
  const loan = data?.data;

  if (!loan || !bitgodata) {
    return <Loader />;
  } else if (loan.status === "Draft") {
    return (
      <Box display="flex" width={"100%"} padding={3}>
        <Alert variant="filled" color="warning">
          Can not do payment requests for draft loans.
        </Alert>
      </Box>
    );
  } else {
    const totalOutgoingPaymentsSat =
      +loan.balanceInfoSatoshi.totalPaymentsToLp +
      +loan.balanceInfoSatoshi.totalPaymentsToMiner +
      +loan.balanceInfoSatoshi.totalPaymentsToOther;
    const confirmedOutgoingPaymentsSat =
      +loan.balanceInfoSatoshi.confirmedPaymentsToLp +
      +loan.balanceInfoSatoshi.confirmedPaymentsToMiner +
      +loan.balanceInfoSatoshi.confirmedPaymentsToOther;
    return (
      <Stack width="100%" pt={3} spacing={2}>
        <Box display="flex" width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
          <LoanTitle id={loan.id} status={loan.status} isDemo={loan.isDemo} />
          <UnitSelector initial={unit} onChange={setUnit} />
        </Box>
        <Box key={destroyKey}>
          <Grid container item xs={12} pt={2}>
            <Grid container item xs={12} sm={12} md={4} pr={2}>
              <VerticalInfoTable
                title={"Block green internal balances"}
                explainer={
                  <ExplainerAccordion
                    title={"More details"}
                    explainers={[
                      "These values are calculated using the payment requests that have been made, and the known miner deliveries.",
                      "For safety, all non failed payment requests are included, even ones that are waiting for execution and have not been processed yet.",
                      "Please use caution when using these numbers to decide payment amounts, and double check everything.",
                    ]}
                  />
                }
                entries={[
                  {
                    name: "Total outgoing payments",
                    value:
                      unit === "SAT"
                        ? formatWithCommas(totalOutgoingPaymentsSat)
                        : toFormattedBtc(totalOutgoingPaymentsSat),
                  },
                  {
                    name: "Confirmed outgoing payments",
                    value:
                      unit === "SAT"
                        ? formatWithCommas(confirmedOutgoingPaymentsSat)
                        : toFormattedBtc(confirmedOutgoingPaymentsSat),
                  },
                  {
                    name: "Total payments to LP",
                    value:
                      unit === "SAT"
                        ? formatWithCommas(loan.balanceInfoSatoshi.totalPaymentsToLp)
                        : toFormattedBtc(loan.balanceInfoSatoshi.totalPaymentsToLp),
                  },
                  {
                    name: "Confirmed payments to LP",
                    value:
                      unit === "SAT"
                        ? formatWithCommas(loan.balanceInfoSatoshi.confirmedPaymentsToLp)
                        : toFormattedBtc(loan.balanceInfoSatoshi.confirmedPaymentsToLp),
                  },
                  {
                    name: "Total payments to Miner",
                    value:
                      unit === "SAT"
                        ? formatWithCommas(loan.balanceInfoSatoshi.totalPaymentsToMiner)
                        : toFormattedBtc(loan.balanceInfoSatoshi.totalPaymentsToMiner),
                  },
                  {
                    name: "Confirmed payments to Miner",
                    value:
                      unit === "SAT"
                        ? formatWithCommas(loan.balanceInfoSatoshi.confirmedPaymentsToMiner)
                        : toFormattedBtc(loan.balanceInfoSatoshi.confirmedPaymentsToMiner),
                  },
                  {
                    name: "Total payments to BG",
                    value:
                      unit === "SAT"
                        ? formatWithCommas(loan.balanceInfoSatoshi.totalPaymentsToOther)
                        : toFormattedBtc(loan.balanceInfoSatoshi.totalPaymentsToOther),
                  },
                  {
                    name: "Confirmed payments to BG",
                    value:
                      unit === "SAT"
                        ? formatWithCommas(loan.balanceInfoSatoshi.confirmedPaymentsToOther)
                        : toFormattedBtc(loan.balanceInfoSatoshi.confirmedPaymentsToOther),
                  },
                  {
                    name: "Total deliveries",
                    value:
                      unit === "SAT"
                        ? formatWithCommas(loan.balanceInfoSatoshi.totalDeliveries)
                        : toFormattedBtc(loan.balanceInfoSatoshi.totalDeliveries),
                  },
                  {
                    name: "Confirmed deliveries",
                    value:
                      unit === "SAT"
                        ? formatWithCommas(loan.balanceInfoSatoshi.confirmedDeliveries)
                        : toFormattedBtc(loan.balanceInfoSatoshi.confirmedDeliveries),
                  },
                  {
                    name: "Computed available balance",
                    value:
                      unit === "SAT"
                        ? formatWithCommas(loan.balanceInfoSatoshi.minAvailableBalance)
                        : toFormattedBtc(loan.balanceInfoSatoshi.minAvailableBalance),
                  },
                ]}
              />
            </Grid>
            <Grid container item xs={12} sm={12} md={4} pr={2}>
              <Stack>
                <VerticalInfoTable
                  title={"Bitgo wallet balance"}
                  explainer={
                    <ExplainerAccordion
                      title={"More details"}
                      explainers={[
                        "This section gives information on the loan reward wallet we have in Bitgo. This shows the amounts as provided by Bitgo.",
                        "Any payment requests that are created with amounts higher than what we have in the wallet should be failed by Bitgo.",
                      ]}
                    />
                  }
                  entries={[
                    {
                      name: "Bitgo balance",
                      value:
                        unit === "SAT"
                          ? formatWithCommas(bitgodata?.data?.balance ?? 0)
                          : toFormattedBtc(bitgodata?.data?.balance ?? 0),
                    },
                    {
                      name: "Bitgo confirmed balance",
                      value:
                        unit === "SAT"
                          ? formatWithCommas(bitgodata?.data?.confirmedBalanceSatoshi ?? 0)
                          : toFormattedBtc(bitgodata?.data?.confirmedBalanceSatoshi ?? 0),
                    },
                    {
                      name: "Bitgo spendable balance",
                      value:
                        unit === "SAT"
                          ? formatWithCommas(bitgodata?.data?.spendableBalanceSatoshi)
                          : toFormattedBtc(bitgodata?.data?.spendableBalanceSatoshi),
                    },
                  ]}
                />
                <VerticalInfoTable
                  title={"Payment recommendations"}
                  explainer={
                    <ExplainerAccordion
                      title={"More details"}
                      explainers={[
                        "These are recommendations based on the expected schedule, existing deliveries and already made payments.",
                        "Only past installments are considered for these calculations, ongoing installments (as in the current delivery month) are not considered.",
                        "Please always double check these values, and consider that fees are not calculated.",
                      ]}
                    />
                  }
                  entries={[
                    {
                      name: "For LP",
                      value:
                        unit === "SAT"
                          ? formatWithCommas(loan.balanceInfoSatoshi.recommendedLpPayment)
                          : toFormattedBtc(loan.balanceInfoSatoshi.recommendedLpPayment),
                    },
                    {
                      name: "For Miner",
                      value:
                        unit === "SAT"
                          ? formatWithCommas(loan.balanceInfoSatoshi.recommendedMinerPayment)
                          : toFormattedBtc(loan.balanceInfoSatoshi.recommendedMinerPayment),
                    },
                  ]}
                />
              </Stack>
            </Grid>
            <Grid container item xs={12} sm={12} md={4}>
              <MakePaymentRequest loan={loan} onUpdate={handleUpdates} />
            </Grid>
          </Grid>
          <LoanAssociatedAddressesTable loan={loan} />
          <LoanPaymentRequestsTable
            title="Payment requests"
            unit={unit}
            outgoingPayments={loan.outgoingPayments}
            includeExecutionDetails={true}
            height="30vh"
            onDelete={isDeleting ? undefined : (id) => setPaymentIdToDelete(id)}
            explainer={ADMIN_EXPLAINER_PAYMENT_REQUESTS}
          />

          <Dialog open={!!paymentIdToDelete} onClose={() => setPaymentIdToDelete(undefined)}>
            <DialogTitle id="alert-dialog-title">{"Confirm action"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this payment request (if still possible)?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDelete}>Yes</Button>
              <Button onClick={() => setPaymentIdToDelete(undefined)} autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
          <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isDeleting}>
            <Box display="flex" alignItems="center" gap={2} bgcolor={"white"} padding={2} borderRadius={3}>
              <CircularProgress />
            </Box>
          </Backdrop>
        </Box>
      </Stack>
    );
  }
};
