interface CustomError {
  readonly error: {
    readonly message: string;
  };
}

export const isCustomError = (error: unknown): error is CustomError => {
  return (
    !!error &&
    typeof error === "object" &&
    "error" in error &&
    !!error.error &&
    typeof error.error === "object" &&
    "message" in error.error &&
    !!error.error.message &&
    typeof error.error.message === "string"
  );
};
