import { useCallback } from "react";

import CloudUploadIcon from "@mui/icons-material/CloudUploadOutlined";
import { FormHelperText, Stack } from "@mui/material";
import Dropzone from "react-dropzone";

interface FileInputProps {
  disabled?: boolean;
  multiple?: boolean;
  maxFileSize?: number;
  maxFiles?: number;
  text?: string;
  onFileUpload: (value: File[]) => void;
}

export const MuiFileDragAndDropUploader = ({
  disabled,
  multiple,
  maxFiles,
  maxFileSize,
  text,
  onFileUpload,
}: FileInputProps) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onFileUpload(acceptedFiles);
  }, []);

  return (
    <Dropzone onDrop={onDrop} multiple={multiple} maxSize={maxFileSize} maxFiles={maxFiles} disabled={disabled}>
      {({ getRootProps, getInputProps }) => (
        <Stack
          padding={3}
          {...getRootProps()}
          borderRadius={1}
          border={1.5}
          height={"100%"}
          borderColor={"silver"}
          sx={{
            background: "#f9f9f9",
            borderStyle: "dashed",
            cursor: disabled ? "not-allowed" : "pointer",
            "&:hover": disabled ? undefined : { borderColor: "ThreeDDarkShadow" },
          }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <input {...getInputProps()} />
          <FormHelperText sx={{ textAlign: "center" }}>
            {text || "Click or drag and drop files to upload"}
          </FormHelperText>
          <CloudUploadIcon color={disabled ? "disabled" : "secondary"} />
        </Stack>
      )}
    </Dropzone>
  );
};
