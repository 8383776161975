import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Tooltip } from "@mui/material";

export const ButtonCopyToClipboard = ({ text }: { text: string }) => {
  return (
    <Tooltip title="Copy to clipboard">
      <IconButton onClick={() => navigator.clipboard.writeText(text)} size="small">
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
