import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import BarChartIcon from "@mui/icons-material/BarChart";
import BusinessIcon from "@mui/icons-material/Business";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import HardwareRoundedIcon from "@mui/icons-material/HardwareRounded";
import StairsIcon from "@mui/icons-material/Stairs";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import WalletIcon from "@mui/icons-material/Wallet";
import { WALLET_SECTIONS } from "../../pages/Wallet/walletSections";
import { Role } from "../model/permission.type";
import { NavBarMenuItem } from "../types";
import {
  ACCOUNT_ROUTE,
  ADMIN_DOCUMENT_APPROVAL_ROUTE,
  HOME_ROUTE,
  KYM2_FINANCIALS,
  LIQUIDITY_DELIVERY_ROUTE,
  ORGANIZATION_ROUTE,
  ROUTE_ADMIN_REWARDS_BLOCKS,
  ROUTE_ENTERPRISE_MEMBERS,
  ROUTE_ENTERPRISE_SETTINGS,
  ROUTE_LP_PORTFOLIO,
  ROUTES_LOANS,
  RoutesHashRatePrediction,
  WALLET_DEPOSIT_ROUTE,
  WALLET_ROUTE,
  WALLET_WITHDRAW_ROUTE,
  WHITELIST_ADDRESS_ROUTE,
} from "./routes";
import { ADMIN_TABS_TOOLING } from "./sectionTabsAdminTooling";
import { KYM2_TABS } from "./sectionTabsKym";

const NAVIGATION_LP_MENU: Array<NavBarMenuItem> = [
  {
    name: "Portfolio",
    path: ROUTE_LP_PORTFOLIO,
    icon: <BarChartIcon fontSize={"small"} />,
    activeIcon: <BarChartIcon color="primary" fontSize={"small"} />,
  },
  {
    name: "Wallet",
    path: WALLET_ROUTE,
    icon: <WalletIcon fontSize={"small"} />,
    activeIcon: <WalletIcon color="primary" fontSize={"small"} />,
    children: [
      {
        name: "Wallet",
        path: WALLET_ROUTE,
      },
      {
        name: "Deposit",
        path: WALLET_DEPOSIT_ROUTE,
      },
      {
        name: "Withdraw",
        path: WALLET_WITHDRAW_ROUTE,
      },
      {
        name: "Whitelist address manager",
        path: WHITELIST_ADDRESS_ROUTE,
      },
    ],
  },
  {
    name: "Opportunity list",
    path: HOME_ROUTE,
    icon: <FormatListBulletedIcon fontSize={"small"} />,
    activeIcon: <FormatListBulletedIcon color="primary" fontSize={"small"} />,
  },
  {
    name: "My loans",
    path: ROUTES_LOANS.LP.Loans,
    icon: <SyncAltIcon fontSize={"small"} />,
    activeIcon: <SyncAltIcon color="primary" fontSize={"small"} />,
  },
  {
    name: "Enterprise",
    path: ROUTE_ENTERPRISE_SETTINGS,
    icon: <BusinessIcon fontSize={"small"} />,
    activeIcon: <BusinessIcon color="primary" fontSize={"small"} />,
    role: [Role.ACCESS_MANAGER],
    children: [
      {
        name: "Settings",
        path: ROUTE_ENTERPRISE_SETTINGS,
      },
      {
        name: "Members",
        path: ROUTE_ENTERPRISE_MEMBERS,
      },
    ],
  },
];

const buildMinerMenu = (): NavBarMenuItem[] => {
  const menuItems = [
    {
      name: "My opportunities",
      path: HOME_ROUTE,
      icon: <FormatListBulletedIcon fontSize={"small"} />,
      activeIcon: <FormatListBulletedIcon color="primary" fontSize={"small"} />,
    },
    {
      name: "My loans",
      path: ROUTES_LOANS.Miner.Loans,
      icon: <SyncAltIcon fontSize={"small"} />,
      activeIcon: <SyncAltIcon color="primary" fontSize={"small"} />,
    },
    {
      name: "Wallet",
      path: WALLET_ROUTE,
      icon: <WalletIcon fontSize={"small"} />,
      activeIcon: <WalletIcon color="primary" fontSize={"small"} />,
      children: [...WALLET_SECTIONS],
    },
    {
      name: "Enterprise",
      path: ROUTE_ENTERPRISE_SETTINGS,
      icon: <BusinessIcon fontSize={"small"} />,
      activeIcon: <BusinessIcon color="primary" fontSize={"small"} />,
      role: [Role.ACCESS_MANAGER],
      children: [
        {
          name: "Settings",
          path: ROUTE_ENTERPRISE_SETTINGS,
        },
        {
          name: "Members",
          path: ROUTE_ENTERPRISE_MEMBERS,
        },
      ],
    },
    {
      name: "Mining details",
      path: KYM2_FINANCIALS,
      icon: <HardwareRoundedIcon fontSize={"small"} />,
      activeIcon: <HardwareRoundedIcon color="primary" fontSize={"small"} />,
      children: [...KYM2_TABS],
    },
  ];

  return menuItems;
};

const NAVIGATION_MINER_MENU = buildMinerMenu();

const NAVIGATION_ADMIN_MENU: Array<NavBarMenuItem> = [
  {
    name: "Opportunities",
    path: HOME_ROUTE,
    icon: <FormatListBulletedIcon fontSize={"small"} />,
    activeIcon: <FormatListBulletedIcon color="primary" fontSize={"small"} />,
  },
  {
    name: "Loans",
    path: ROUTES_LOANS.Admin.Loans,
    icon: <SyncAltIcon fontSize={"small"} />,
    activeIcon: <SyncAltIcon color="primary" fontSize={"small"} />,
  },
  {
    name: "Organizations",
    path: ORGANIZATION_ROUTE,
    icon: <CorporateFareIcon fontSize={"small"} />,
    activeIcon: <CorporateFareIcon color="primary" fontSize={"small"} />,
  },
  {
    name: "LP agreements",
    path: ADMIN_DOCUMENT_APPROVAL_ROUTE,
    icon: <CheckCircleOutlineIcon fontSize={"small"} />,
    activeIcon: <CheckCircleOutlineIcon color="primary" fontSize={"small"} />,
  },
  {
    name: "Liquidity delivery",
    path: LIQUIDITY_DELIVERY_ROUTE,
    icon: <StairsIcon fontSize={"small"} />,
    activeIcon: <StairsIcon color="primary" fontSize={"small"} />,
  },
  {
    name: "Admin tooling",
    path: ROUTE_ADMIN_REWARDS_BLOCKS,
    icon: <EmojiEventsIcon fontSize={"small"} />,
    activeIcon: <EmojiEventsIcon color="primary" fontSize={"small"} />,
    children: [...ADMIN_TABS_TOOLING],
  },
  {
    name: "Hashrate model",
    path: RoutesHashRatePrediction.Default,
    icon: <AutoGraphIcon fontSize={"small"} />,
    activeIcon: <AutoGraphIcon color="primary" fontSize={"small"} />,
  },
];

export {
  ACCOUNT_ROUTE,
  NAVIGATION_ADMIN_MENU,
  NAVIGATION_LP_MENU,
  NAVIGATION_MINER_MENU,
  WALLET_DEPOSIT_ROUTE,
  WALLET_ROUTE,
  WALLET_WITHDRAW_ROUTE,
  WHITELIST_ADDRESS_ROUTE,
};
