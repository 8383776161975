import { Box, Button, Chip, Skeleton, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { formatInTimeZone } from "date-fns-tz";
import { useState } from "react";
import { ROUTES_LOANS } from "../../../app/constants";
import { ResLpLoanDto } from "../../../app/model/api";
import { getStatusChip } from "../commons/LoanTitle";
import { UnitSelector } from "../commons/UnitSelector";
import { formatWithCommas, toFormattedBtc } from "../commons/utils";
import { useLpGetLpLoans } from "../loanQueries";

const COLUMNS = (unit: "SAT" | "BTC"): GridColDef<ResLpLoanDto & { principalAmountBtc: number }>[] => [
  {
    field: "id",
    headerName: "Id",
    sortable: true,
    resizable: false,
    width: 150,
    display: "flex",
    renderCell: (params) => (
      <Box display="flex" alignItems={"center"} gap={1}>
        <Button
          href={ROUTES_LOANS.LP.LoanDetails.replace(":id", params.row.id)}
          variant="text"
          size="small"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            "&:hover": { textDecoration: "underline" },
          }}
        >
          {params.row.id.slice(0, 6) + "..."}
        </Button>
        {params.row.isDemo ? <Chip label="Demo" color={"primary"} variant="filled" /> : null}
      </Box>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created at",
    sortable: true,
    flex: 1,
    display: "flex",
    renderCell: (params) => formatInTimeZone(params.row.createdAt, "UTC", "dd MMM yyyy"),
  },
  {
    field: "status",
    headerName: "Status",
    sortable: true,
    flex: 1,
    display: "flex",
    align: "center",
    headerAlign: "center",
    renderCell: (params) => getStatusChip(params.row.status),
  },
  unit === "SAT"
    ? {
        field: "principalAmountSatoshi",
        headerName: "Principal amount (SAT)",
        sortable: true,
        flex: 1,
        display: "flex",
        align: "right",
        headerAlign: "right",
        renderCell: (params) => formatWithCommas(+params.row.principalAmountSatoshi),
      }
    : {
        field: "principalAmountBtc",
        headerName: "Principal amount (BTC)",
        sortable: true,
        flex: 1,
        display: "flex",
        align: "right",
        headerAlign: "right",
        renderCell: (params) => params.row.principalAmountBtc,
      },
  {
    field: "durationDays",
    headerName: "Duration (days)",
    sortable: true,
    flex: 1,
    display: "flex",
  },
  {
    field: "calculationKind",
    headerName: "Schedule type",
    sortable: true,
    flex: 1,
    display: "flex",
  },
];

export const LpPageMyLoans = () => {
  const { data, isFetching } = useLpGetLpLoans();
  const [unit, setUnit] = useState<"BTC" | "SAT">("BTC");

  const rows = (data?.data.rows ?? []).map((x) => ({
    ...x,
    principalAmountBtc: +toFormattedBtc(x.principalAmountSatoshi),
  }));

  return (
    <Box display="flex" justifyContent={"center"}>
      <Stack width="100%" maxWidth={1600} bgcolor={"white"} padding={2} spacing={1}>
        <Box display="flex" alignItems={"center"} justifyContent={"space-between"} pt={2} pb={1}>
          <Typography variant="h6" color="text.secondary">
            Contact{" "}
            <a target="_blank" href="mailto:support@block.green" className="hover:no-underline" rel="noreferrer">
              support@block.green
            </a>{" "}
            if you would like to provide liquidity for a new loan agreement.
          </Typography>
          <Box display="flex" alignItems={"center"} justifyContent={"flex-end"}>
            <UnitSelector initial={unit} onChange={setUnit} />
          </Box>
        </Box>
        <Box sx={{ height: "65vh", display: "flex", flexDirection: "column", pt: 2 }}>
          {isFetching ? (
            <Box width="100%" pl={2} pr={2}>
              <Skeleton width={"100%"} height={50} variant="rectangular"></Skeleton>
              <Skeleton width={"100%"} height={50} variant="rectangular"></Skeleton>
              <Skeleton width={"100%"} height={50} variant="rectangular"></Skeleton>
            </Box>
          ) : (
            <DataGridPro
              rows={rows}
              rowCount={0}
              columns={COLUMNS(unit)}
              loading={isFetching}
              pagination
              paginationMode="client"
              sortingMode="client"
              disableColumnFilter={false}
              pageSizeOptions={[25, 50, 100]}
              sx={{
                bgcolor: "white",
                boxShadow: 3,
                padding: 1,
                maxHeight: "65vh",
              }}
              slots={{
                noRowsOverlay: () => (
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    width={"100%"}
                    height={"100%"}
                    sx={{ minHeight: "60px" }}
                  >
                    <Typography>No loans found.</Typography>
                  </Stack>
                ),
              }}
            ></DataGridPro>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
