import { useQueryGetAdminOpportunitiesSummary } from "../../../app/query/useQueryGetAdmin";

import { Grid, Skeleton } from "@mui/material";
import { amountFormat, amountFormatSatoshisToBTC, roundToSignificantDigits } from "../../../app/helper/utils";
import { AmountHeroCard } from "../../../pages/Admin/HashrateModel/HeroCard";

export const HeroOpportunities = () => {
  const { data, isFetching } = useQueryGetAdminOpportunitiesSummary();

  return (
    <Grid container item padding={2} spacing={2} alignItems={"center"} justifyContent={"center"}>
      <Grid container item xs={12} sm={6} md={6} lg={3} justifyContent={"center"}>
        {isFetching ? (
          <Skeleton height={90} width={"100%"} animation="wave" variant="rounded" />
        ) : (
          <AmountHeroCard
            title="Total hashrate on platform"
            amount={`${roundToSignificantDigits(Number(data?.platformDetails?.totalHashrate))} PH/s`}
          />
        )}
      </Grid>
      <Grid container item xs={12} sm={6} md={6} lg={3} justifyContent={"center"}>
        {isFetching ? (
          <Skeleton height={90} width={"100%"} animation="wave" variant="rounded" />
        ) : (
          <AmountHeroCard
            title="Total aggregated fees (last 30d)"
            amount={`${amountFormatSatoshisToBTC(
              data?.opportunitiesSummary?.aggregatedFees30d?.btc || 0
            )} BTC  ${amountFormat(data?.opportunitiesSummary?.aggregatedFees30d?.usd || 0)}`}
          />
        )}
      </Grid>
      <Grid container item xs={12} sm={6} md={6} lg={3} justifyContent={"center"}>
        {isFetching ? (
          <Skeleton height={90} width={"100%"} animation="wave" variant="rounded" />
        ) : (
          <AmountHeroCard
            title="Total value locked"
            amount={`${amountFormatSatoshisToBTC(
              data?.platformDetails?.totalValueLocked?.btc || 0
            )} BTC  ${amountFormat(data?.platformDetails?.totalValueLocked?.usd || 0)}`}
          />
        )}
      </Grid>
      <Grid container item xs={12} sm={6} md={6} lg={3} justifyContent={"center"}>
        {isFetching ? (
          <Skeleton height={90} width={"100%"} animation="wave" variant="rounded" />
        ) : (
          <AmountHeroCard
            title="Total aggregated fees (last 24h)"
            amount={`${amountFormatSatoshisToBTC(
              data?.opportunitiesSummary?.aggregatedFees24h?.btc || 0
            )} BTC  ${amountFormat(data?.opportunitiesSummary?.aggregatedFees24h?.usd || 0)}`}
          />
        )}
      </Grid>
    </Grid>
  );
};
