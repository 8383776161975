import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Card,
  CardContent,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
import { useQueryGetMinerKym2HostingById } from "../../../app/query/useQueryGetAdmin";
import { StyledTableRow } from "../commons";

export const ReadonlyMinerHosting = ({ organizationId }: { organizationId: string }) => {
  const { data, isFetching, error } = useQueryGetMinerKym2HostingById(organizationId);
  const asicInfo = data?.data;

  return isFetching ? (
    <Skeleton width={"100%"} height={300} />
  ) : error ? (
    <Stack>
      <Alert severity="warning">
        There has been an issue loading the miner data. Please refresh the page to try again.
      </Alert>
    </Stack>
  ) : (
    <Grid container item xs={12}>
      <Card variant="outlined" sx={{ width: "100%", border: 0 }}>
        <CardContent>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <StyledTableRow key={"row-is-host"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Is 3rd party host for other miners
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {asicInfo?.hostsOtherMiners == undefined ? (
                      <CloseIcon color="error" />
                    ) : asicInfo?.hostsOtherMiners ? (
                      "Yes"
                    ) : (
                      "No"
                    )}
                  </TableCell>
                </StyledTableRow>

                <StyledTableRow key={"row-asic-price"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Total current hosted capacity
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {asicInfo?.totalCurrentHostedCapacityPhPerS == undefined ? (
                      <CloseIcon color="error" />
                    ) : (
                      `${asicInfo?.totalCurrentHostedCapacityPhPerS} PH/s`
                    )}
                  </TableCell>
                </StyledTableRow>

                <StyledTableRow key={"row-expected-cap"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Expected hosted capacity in 12 months
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {asicInfo?.expectedCapacityIn12MonthsMPhPerS == undefined ? (
                      <CloseIcon color="error" />
                    ) : (
                      `${asicInfo?.expectedCapacityIn12MonthsMPhPerS} PH/s`
                    )}
                  </TableCell>
                </StyledTableRow>

                <StyledTableRow key={"row-hosting-price"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Average all-in hosting price
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {asicInfo?.averageHostingPricePerClientUsdPerMWh == undefined ? (
                      <CloseIcon color="error" />
                    ) : (
                      `${asicInfo?.averageHostingPricePerClientUsdPerMWh} USD/MWh`
                    )}
                  </TableCell>
                </StyledTableRow>

                <StyledTableRow key={"row-hosting-price"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Energy price hedging strategy
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {asicInfo?.priceHedgingStrategy == undefined ? (
                      <CloseIcon color="error" />
                    ) : (
                      asicInfo?.priceHedgingStrategy
                    )}
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};
