import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import { ResDemoCollateral, ResDemoExcessRewardTransaction, ResDemoReward } from "../../../../../app/model/api";
import {
  AddFundingBuilder,
  AddNeededCollateralBuilder,
  MinerUnderDeliveryBuilder,
  PassTimeWithDeliveryAsExpectedBuilder,
  PublishBuilder,
} from "./BuildStep";
import {
  OpportunityState,
  SimulationActionContext,
  SimulationEvent,
  buildSimulatedOpportunityState,
  checkExhaustive,
  displayEvent,
  getAvailableSimulationAction,
  getEventLabel,
  getSimulationTimelineItems,
} from "./simulationTimelineItems";
import { isInEndState } from "../AdminOpportunityTimeline.utils";

export const SimulationBuilder = ({
  opportunityId,
  status,
  collateralStatus,
  creationDate,
  updatedAt,
  publishDate,
  activeFromDate,
  lps,
  neededFundingBTC,
  fundings,
  rewards,
  collaterals,
  excessRewardRelease,
  onSubmit,
}: {
  opportunityId: string;
  status:
    | "Awaiting Collateral"
    | "Awaiting Funding"
    | "Draft"
    | "Active"
    | "Defaulted"
    | "Cancelled"
    | "Settled"
    | "Closed";
  collateralStatus: "Sufficient" | "Under sufficient";
  creationDate: Date | string | number;
  updatedAt: Date | string | number;
  publishDate: Date | string | number | null | undefined;
  activeFromDate: Date | string | number | null | undefined;
  neededFundingBTC: number;
  lps: { id: string; company?: string; email: string }[];
  fundings: {
    amountSatoshi: number;
    lpId: string;
    lpName: string;
    date: Date;
  }[];
  rewards: ResDemoReward[];
  collaterals: ResDemoCollateral[];
  excessRewardRelease: ResDemoExcessRewardTransaction | undefined;
  onSubmit: (events: SimulationEvent[]) => void;
}) => {
  const [opportunityUpdates, setOpportunityUpdates] = useState<{ events: SimulationEvent[] }>({ events: [] });

  const [selectedAction, setSelectedAction] = useState<SimulationActionContext | { kind: "" }>({ kind: "" });

  const { timeline, availableActions, initialState } = useMemo(() => {
    const initialState: OpportunityState = {
      opportunityId,
      status,
      collateralStatus,
      creationDate,
      updatedAt,
      publishDate,
      activeFromDate,
      neededFundingBTC,
      rewards,
      fundings,
      collaterals,
      excessRewardRelease,
    };
    const newState = buildSimulatedOpportunityState(initialState, opportunityUpdates.events);
    const availableActions = getAvailableSimulationAction(
      newState,
      opportunityUpdates.events.some(
        (x) => x.kind === "pass-time-with-delivery-as-expected" || x.kind === "miner-under-delivery-day"
      )
    );
    const timeline = getSimulationTimelineItems(newState);
    return { initialState, updatedState: newState, timeline, availableActions };
  }, [
    opportunityId,
    status,
    collateralStatus,
    creationDate,
    updatedAt,
    publishDate,
    activeFromDate,
    neededFundingBTC,
    rewards,
    fundings,
    collaterals,
    excessRewardRelease,
    opportunityUpdates.events,
  ]);

  const onAddEvent = (input: SimulationEvent) => {
    setOpportunityUpdates({ events: [...opportunityUpdates.events, input] });
    setSelectedAction({ kind: "" });
  };

  const BuildStep = ({
    input,
    onUpdate,
  }: {
    input: SimulationActionContext;
    onUpdate: (event: SimulationEvent) => void;
  }) => {
    switch (input.kind) {
      case "publish":
        return <PublishBuilder onUpdate={onUpdate} input={input} />;
      case "add-funding":
        return <AddFundingBuilder onUpdate={onUpdate} input={input} lps={lps} />;
      case "miner-under-delivery-day":
        return <MinerUnderDeliveryBuilder onUpdate={onUpdate} />;
      case "pass-time-with-delivery-as-expected":
        return <PassTimeWithDeliveryAsExpectedBuilder onUpdate={onUpdate} input={input} />;
      case "add-needed-collateral":
        return <AddNeededCollateralBuilder onUpdate={onUpdate} />;
      default:
        checkExhaustive(input);
    }
  };

  return (
    <Grid container item xs={12}>
      {initialState && isInEndState(initialState) ? (
        <Alert>Opportunity has reached an end state, no simulation actions available.</Alert>
      ) : null}
      <Grid container item sm={12} md={5}>
        <Stack spacing={2} padding={1}>
          {opportunityUpdates.events.some(
            (x) => x.kind === "pass-time-with-delivery-as-expected" || x.kind === "miner-under-delivery-day"
          ) ? (
            <Alert color="warning">Pass time events have to be submitted before any other events are queued.</Alert>
          ) : undefined}
          {!!availableActions.length && (
            <FormControl sx={{ width: 400 }}>
              <InputLabel id="demo-multiple-name-label">Action</InputLabel>
              <Select
                defaultValue=""
                labelId="demo-multiple-name-label"
                label={"Action"}
                size="small"
                id="demo-multiple-name"
                value={selectedAction.kind}
                fullWidth
                onChange={(newValue) => {
                  const action = availableActions.find((x) => x.kind === newValue.target.value);
                  if (action) setSelectedAction(action);
                }}
              >
                {availableActions.map((action) => (
                  <MenuItem key={action.kind} value={action.kind}>
                    {getEventLabel(action.kind)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {selectedAction.kind && (
            <Box boxShadow={3} borderRadius={3} maxWidth={600}>
              <BuildStep input={selectedAction} onUpdate={onAddEvent} />
            </Box>
          )}
        </Stack>
      </Grid>
      <Grid container item sm={12} md={7} padding={1}>
        {!!opportunityUpdates.events.length && (
          <Stack spacing={2} padding={1} width={"100%"} maxHeight={"30vh"} overflow={"auto"}>
            <Typography>Events to submit</Typography>
            {opportunityUpdates.events.map((item, index) => (
              <Paper key={"paper-description-" + index} sx={{ padding: 1, boxShadow: 6, width: "100%" }}>
                <Typography>{displayEvent(item)}</Typography>
              </Paper>
            ))}
          </Stack>
        )}

        {!!opportunityUpdates.events.length && (
          <Box display="flex" gap={2} padding={1}>
            <LoadingButton
              variant="contained"
              size="small"
              sx={{ width: "fit-content", height: 35 }}
              onClick={() => onSubmit(opportunityUpdates.events)}
            >
              Update opportunity with events
            </LoadingButton>
            <Button
              variant="contained"
              size="small"
              color="warning"
              sx={{ width: "fit-content", height: 35 }}
              onClick={() => {
                setOpportunityUpdates({ events: [] });
                setSelectedAction({ kind: "" });
              }}
            >
              Clear queued events
            </Button>
          </Box>
        )}
      </Grid>
      <Grid container item sm={12} md={12} mt={2}>
        <Box width={"100%"}>
          <Typography>Timeline</Typography>
          <DataGridPro
            rows={timeline.definition}
            rowCount={timeline.definition.length}
            columns={[
              {
                field: "date",
                headerName: "Date",
                sortable: true,
                disableColumnMenu: true,
                renderCell: (params) => format(params.row.date, "dd MMM yyyy"),
                width: 125,
                display: "flex",
              },
              {
                field: "description",
                headerName: "Description",
                sortable: false,
                disableColumnMenu: true,
                flex: 3,
                align: "right",
                headerAlign: "right",
                display: "flex",
              },
              {
                field: "amount",
                headerName: "Amount",
                sortable: false,
                disableColumnMenu: true,
                width: 200,
                align: "right",
                headerAlign: "right",
                display: "flex",
              },
              {
                field: "details",
                headerName: "Details",
                sortable: false,
                disableColumnMenu: true,
                flex: 3,
                renderCell: (params) => params.row.details ?? null,
                display: "flex",
              },
            ]}
            pagination
            paginationMode="client"
            sortingMode="client"
            pageSizeOptions={[50, 100]}
            sx={{
              bgcolor: "white",
              boxShadow: 3,
              maxHeight: "50vh",
            }}
            slots={{
              noRowsOverlay: () => (
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={"100%"}
                  height={"100%"}
                  sx={{ minHeight: "60px" }}
                >
                  <Typography>No events.</Typography>
                </Stack>
              ),
            }}
          ></DataGridPro>
        </Box>
      </Grid>
    </Grid>
  );
};
