import { useParams } from "react-router-dom";

import { TableAmountCell } from "../../atoms/TableAmountCell";

import { Stack, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Decimal from "decimal.js";
import { SATOSHIS_TO_BTC } from "../../../app/constants";
import { useGetQueryOpportunityLiquidityProvidersV2 } from "../../../app/query/useQueryGetLP";
import { useGetQuerySatoshiPriceInUsd } from "../../../app/query/useQueryGetWallet";

const LiquidityProviderTable = () => {
  const { data: satoshisToUsd } = useGetQuerySatoshiPriceInUsd();
  const { id } = useParams();

  const { data: opportunityLiquidityProviders, isLoading: isLoadingOpportunityLiquidityProviders } =
    useGetQueryOpportunityLiquidityProvidersV2(id ?? "", {
      offset: 0,
      limit: 100,
    });

  const rows =
    opportunityLiquidityProviders?.rows.map((x) => ({
      ...x,
      fundedAt: x.fundedAt ? new Date(x.fundedAt) : undefined,
    })) ?? [];

  return (
    <div>
      <Typography variant="h5">Liquidity providers</Typography>
      {!isLoadingOpportunityLiquidityProviders && (
        <DataGridPro
          rows={rows}
          rowSelection={false}
          rowCount={opportunityLiquidityProviders?.count ?? 0}
          columns={[
            {
              field: "fundedAt",
              headerName: "Date",
              type: "dateTime",
              sortable: true,
              disableColumnMenu: true,
              flex: 1,
              display: "flex",
              align: "left",
              headerAlign: "left",
            },
            {
              field: "companyName",
              headerName: "Liquidity provider name",
              sortable: true,
              disableColumnMenu: true,
              display: "flex",
              flex: 1,
            },
            {
              field: "amount",
              headerName: "Amount",
              sortable: false,
              disableColumnMenu: true,
              display: "flex",
              flex: 1,
              renderCell: (params) => (
                <TableAmountCell
                  type="Deposit"
                  amountBtc={new Decimal(params.row.amountSatoshi).div(SATOSHIS_TO_BTC).toNumber()}
                  amountUsd={new Decimal(params.row.amountSatoshi).mul(satoshisToUsd ?? 0).toNumber()}
                />
              ),
            },
          ]}
          pagination
          paginationMode="client"
          sortingMode="client"
          pageSizeOptions={[100]}
          hideFooter={true}
          sx={{
            bgcolor: "white",
            boxShadow: 3,
            padding: 1,
          }}
          slots={{
            noRowsOverlay: () => (
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
                height={"100%"}
                sx={{ minHeight: "60px" }}
              >
                <Typography>No events.</Typography>
              </Stack>
            ),
          }}
        ></DataGridPro>
      )}
    </div>
  );
};

export default LiquidityProviderTable;
