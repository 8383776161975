import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";

export const UnitSelector = ({
  onChange,
  initial,
}: {
  initial: "BTC" | "SAT";
  onChange: (unit: "BTC" | "SAT") => void;
}) => {
  const [unit, setUnit] = useState<"BTC" | "SAT">(initial);

  const handleUnitChange = (_event: React.MouseEvent<HTMLElement>, newUnit?: "BTC" | "SAT") => {
    if (newUnit && newUnit !== unit) {
      setUnit(newUnit);
      onChange(newUnit);
    }
  };

  return (
    <ToggleButtonGroup
      sx={{ height: 30 }}
      color="primary"
      size="small"
      value={unit}
      exclusive
      onChange={handleUnitChange}
    >
      <ToggleButton size="small" value="SAT">
        SAT
      </ToggleButton>
      <ToggleButton size="small" value="BTC">
        BTC
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
