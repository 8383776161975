import classNames from "classnames";
import { useState } from "react";

import { CheckboxProps } from "../../../app/model/form.type";

import "./style.scss";

const Checkbox = ({ name, label, checked, tooltip, disabled = false, onChange }: CheckboxProps) => {
  const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const handleHover = () => {
    if (disabled) {
      setTooltipVisible(true);
    }
  };

  const handleHoverLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <div className="custom-checkbox relative">
      <input
        id={`checkbox_${name}`}
        type="checkbox"
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange && onChange(e.target.name, e.target.checked)}
      />
      <label
        className={classNames({
          "!cursor-not-allowed opacity-40": disabled,
        })}
        htmlFor={`checkbox_${name}`}
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverLeave}
      >
        {label}
      </label>
      {disabled && isTooltipVisible && tooltip?.content ? (
        <div
          className={classNames("tooltip-content", {
            "block mx-auto my-0 right-0 left-[-15px] !bottom-[35px]": isTooltipVisible,
          })}
        >
          {tooltip?.content}
          <div
            className={classNames("arrow-down", {
              "mx-auto": tooltip?.arrowPosition === "center",
              "ml-auto mr-[5px]": tooltip?.arrowPosition === "right",
            })}
          ></div>
        </div>
      ) : null}
    </div>
  );
};

export default Checkbox;
