import classNames from "classnames";

import { ReactComponent as TooltipIcon } from "../../../assets/images/tooltip-icon.svg";

import "./style.scss";

interface TooltipProps {
  content?: string;
  width?: number;
  height?: number;
  className?: string;
  arrowPosition?: "left" | "right" | "center";
}

const Tooltip = ({ content, className, width = 16, height = 16, arrowPosition = "center" }: TooltipProps) => {
  return (
    <div className="relative flex items-center">
      <TooltipIcon width={width} height={height} />
      {content && (
        <div className={`tooltip-content ${className || ""}`}>
          {content}
          <div
            className={classNames("arrow-down", {
              "mx-auto": arrowPosition === "center",
              "ml-auto mr-[5px]": arrowPosition === "right",
            })}
          ></div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
