import { useQuery } from "@tanstack/react-query";
import { apig } from "../../app/config/query/clientgen";

export const useAdminGetAllLoans = () => {
  return useQuery(["useAdminGetAllLoans"], () => apig.loans.getAdminAllLoans(), {
    refetchOnMount: "always",
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
};

export const useAdminGetLoan = (id: string) => {
  return useQuery(["useAdminGetLoan" + id], () => apig.loans.getAdminLoanDetails(id), {
    refetchOnMount: "always",
    cacheTime: 0,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export const useAdminGetLoanBitgoBalance = (id: string) => {
  return useQuery(["useAdminGetLoanBitgoBalance" + id], () => apig.loans.getAdminLoanBitgoBalance(id), {
    refetchOnMount: "always",
    cacheTime: 0,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export const useMinerGetLoan = (id: string) => {
  return useQuery(["useMinerGetLoan" + id], () => apig.loans.getMinerLoan(id), {
    refetchOnMount: "always",
    cacheTime: 0,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export const useMinerGetMinerLoans = () => {
  return useQuery(["useMinerGetMinerLoans"], () => apig.loans.getMinerLoans(), {
    refetchOnMount: "always",
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
};

export const useLpGetLpLoan = (id: string) => {
  return useQuery(["useLpGetLpLoan" + id], () => apig.loans.getLpLoan(id), {
    refetchOnMount: "always",
    cacheTime: 0,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export const useLpGetLpLoans = () => {
  return useQuery(["useLpGetLpLoans"], () => apig.loans.getLpLoans(), {
    refetchOnMount: "always",
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
};
