import { Grid } from "@mui/material";
import { toBase64 } from "../../app/helper/utils";
import { FileIdDto, ReqNewFileDto, ResUploadedFileDto } from "../../app/model/api";
import { ChipFileItem, ExistingFileChipItem, NewFileChipItem } from "../../components/atoms/FileInput/ChipFile";

export const buildChipFileItem = (input: ResUploadedFileDto) => {
  const chip: ChipFileItem = { kind: "existing", id: input.id, name: input.filename };
  return chip;
};

export const mapFiles = async (files: ChipFileItem[]) => {
  return {
    newFiles: await Promise.all(
      files
        .filter((x): x is NewFileChipItem => x.kind === "new")
        .map(async (x) => {
          const result: ReqNewFileDto = {
            name: x.file.name,
            base64: (await toBase64(x.file)) as string,
          };
          return result;
        })
    ),
    existingFiles: files
      .filter((x): x is ExistingFileChipItem => x.kind === "existing")
      .map((x) => {
        const result: FileIdDto = {
          id: x.id,
        };
        return result;
      }),
  };
};

export const UploadGridRow = (props: React.HTMLAttributes<HTMLLIElement>) => (
  <Grid mt={2} container spacing={2} pb={2} pr={2} boxShadow={2} border={1} borderRadius={1} borderColor={"ThreeDFace"}>
    {props.children}
  </Grid>
);
