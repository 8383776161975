import { useMutation } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import { CSVTransactionType } from "../model/api";

interface CSVProps {
  csvTransactionType: CSVTransactionType;
  opportunityId?: string;
}

const useMutationExportCsv = () => {
  return useMutation(async (query: CSVProps) => (await apig.csv.getCsvData(query)).data);
};

export { useMutationExportCsv };
