import { useMutation } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import { ApproveOrRejectUserProps, DataRoomWaitingForAccept } from "../model/admin.type";
import {
  Notification,
  OpportunityCreationDto,
  OpportunityStatusDto,
  OrganizationEnterpriseSettingsDto,
  ReqAdminUserDto,
  ReqCreateOpportunityV2Dto,
  ReqExchangeRatesDto,
  ReqMinerRatingDto,
  ReqProcessDemoOpportunityEvents,
  ReqUpdateOpportunityV2Dto,
  UpdateFundingApprovalStatusDto,
  UpdateTrancheDeliveryDto as UpdateLiquidityDeliveryDto,
} from "../model/api";

interface UpdateOpprotunityStatus extends OpportunityStatusDto {
  id: string;
}

interface DataRoom {
  opportunityId: string;
}

const sendNotification = async (data: Notification): Promise<any> => {
  return await apig.admin.putAdminNotification(data);
};

const approveOrRejectOrganization = async (data: ApproveOrRejectUserProps): Promise<any> => {
  return await apig.admin.patchOrganizationStatus(data.userId, { approved: data.approved });
};

const updateOpportunityStatus = async (data: UpdateOpprotunityStatus): Promise<any> => {
  return await apig.admin.patchAdminOpprotunityStatus(data.id, { status: data.status });
};

const updateFundingApprovalStatus = async (data: UpdateFundingApprovalStatusDto): Promise<any> => {
  return await apig.admin.updateFundingApprovalStatus(data.id as string, { status: data.status });
};

const requestAccessToDataRoom = async (data: DataRoom) => {
  return apig.lp.requestAccessToDataRoom(data);
};

const updateTrancheDelivery = async (data: UpdateLiquidityDeliveryDto) => {
  return apig.admin.updateTrancheDelivery(data);
};

const updateOrganizationEnterpriseSettings = async ({
  id,
  payload,
}: {
  id: string;
  payload: OrganizationEnterpriseSettingsDto;
}) => {
  return apig.admin.updateOrganizationEnterpriseSettings(id, payload);
};

const useMutationAdminApproveOrRejectOrganization = () => {
  return useMutation((data: ApproveOrRejectUserProps) => approveOrRejectOrganization(data));
};

const useMutationAdminSendNotification = () => {
  return useMutation((data: Notification) => sendNotification(data));
};

const useMutationUpdateOpportunityStatus = () => {
  return useMutation((data: UpdateOpprotunityStatus) => updateOpportunityStatus(data));
};

const useMutationUpdateFundingStatus = () => {
  return useMutation((data: UpdateFundingApprovalStatusDto) => updateFundingApprovalStatus(data));
};

const useMutationRequestAccessToDataRoom = () => {
  return useMutation((data: DataRoom) => requestAccessToDataRoom(data));
};

const useMutationLiquidityDelivery = () => {
  return useMutation((data: UpdateLiquidityDeliveryDto) => updateTrancheDelivery(data));
};

const useMutationOrganizationEnterpriseSettings = () => {
  return useMutation(({ id, payload }: { id: string; payload: OrganizationEnterpriseSettingsDto }) =>
    updateOrganizationEnterpriseSettings({ id, payload })
  );
};

const useMutationAdminGenerateFileUrl = () => {
  return useMutation((fileId: string) => apig.admin.generateFileSignedUrl(fileId));
};

const useMutationMinerRating = () => {
  return useMutation((data: ReqMinerRatingDto) => apig.admin.updateMinerRating(data));
};

const useMutationCreateOpportunityV2 = () => {
  return useMutation((data: ReqCreateOpportunityV2Dto) => apig.admin.createOpportunityV2(data));
};

const useMutationProcessDemoOpportunity = () => {
  return useMutation((data: ReqProcessDemoOpportunityEvents) => apig.admin.processDemoOpportunityEvents(data));
};

const useMutationUpdateOpportunityV2 = () => {
  return useMutation((data: ReqUpdateOpportunityV2Dto) => apig.admin.updateOpportunityV2(data));
};

const useMutationDumpMinerData = () => {
  return useMutation({ mutationFn: (minerId: string) => apig.admin.dumpMinerData(minerId) });
};

const useMutationAddAdminUser = () => {
  return useMutation((input: ReqAdminUserDto) => apig.admin.createAdminUser(input));
};

const useMutationApproveMinerOnboarding = () => {
  return useMutation((minerId: string) => apig.admin.approveMinerOnboarding(minerId));
};

const useMutationRejectMinerOnboarding = () => {
  return useMutation((minerId: string) => apig.admin.rejectMinerOnboarding(minerId));
};

const useMutationAdminKym2ApproveMinerKyc = () => {
  return useMutation((minerId: string) => apig.admin.approveMinerKycAndGenerateWallet({ minerId }));
};

export {
  useMutationAddAdminUser,
  useMutationAdminApproveOrRejectOrganization as useMutationAdminApproveOrRejectUser,
  useMutationAdminGenerateFileUrl,
  useMutationAdminKym2ApproveMinerKyc,
  useMutationAdminSendNotification,
  useMutationCreateOpportunityV2,
  useMutationDumpMinerData,
  useMutationMinerRating,
  useMutationOrganizationEnterpriseSettings,
  useMutationRequestAccessToDataRoom,
  useMutationLiquidityDelivery as useMutationTrancheDelivery,
  useMutationUpdateFundingStatus,
  useMutationUpdateOpportunityStatus,
  useMutationUpdateOpportunityV2,
  useMutationApproveMinerOnboarding,
  useMutationRejectMinerOnboarding,
  useMutationProcessDemoOpportunity,
};
