import { ReactComponent as Logo } from "../../assets/images/logo.svg";

import { AppBar, Box, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import { CustomerType, userGradient } from "../../app/helper/colors";
import { LpOnboarding } from "./LpOnboarding";
import { MinerOnboarding } from "./MinerOnboarding";

import Toolbar from "@mui/material/Toolbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ONNBOARDING_ROUTE } from "../../app/constants";

export const Onboarding = () => {
  const [searchParams] = useSearchParams();
  const role = searchParams.get("role") as CustomerType | null;
  const cleanRole: CustomerType | undefined = role === "lp" ? "lp" : role === "miner" ? "miner" : undefined;
  const [customerType, setCustomerType] = React.useState<CustomerType | undefined>(cleanRole);
  const navigate = useNavigate();

  const handleChange = (event: React.MouseEvent<HTMLElement>, newCustomerType: string) => {
    if (newCustomerType) {
      setCustomerType(newCustomerType as CustomerType);
      navigate(`${ONNBOARDING_ROUTE}?role=${newCustomerType}`);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky" color="default" sx={{ minHeight: 65 }}>
        <Toolbar sx={{ padding: 1.5, minHeight: 65 }}>
          <Box pl={1}>
            <Logo width={60} />
          </Box>
          <Box width={"calc(100vw - 200px)"}>
            <Typography variant="h5" component="div" textAlign={"center"} width={"100%"}>
              Onboarding
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Stack
        sx={{
          background: userGradient(customerType),
        }}
        width={"100%"}
        padding={3}
        pb={2}
        alignItems={"center"}
        minHeight={"calc(100vh - 85px)"}
      >
        <Box display="grid" justifyItems={"center"} padding={5} bgcolor={"white"} width="100%" maxWidth={1200}>
          <Typography sx={{ mb: 1 }} color="secondary">
            Please select your role
          </Typography>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={customerType}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="lp">
              <Box display="flex" justifyContent={"center"} width={200}>
                <Typography variant="body1">Liquidity provider</Typography>
              </Box>
            </ToggleButton>
            <ToggleButton value="miner">
              <Box display="flex" justifyContent={"center"} width={200}>
                <Typography variant="body1">Miner</Typography>
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
          <Box width={"100%"}></Box>
          {customerType === "lp" && <LpOnboarding />}
          {customerType === "miner" && <MinerOnboarding />}
        </Box>
      </Stack>
    </Box>
  );
};
