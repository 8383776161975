export type CustomerType = "lp" | "miner";

const gradient = (from: string, to: string) => {
  return `linear-gradient(180deg, ${from} 0%, ${to} 100%);`;
};

const GRADIENT_MINER = gradient("#31df8b", "#bc92ff");
const GRADIENT_LP = gradient("#fc7a53", "#bc92ff");
const GRADIENT_NEUTRAL = gradient("#cccccc", "#bc92ff");

export const userGradient = (kind: CustomerType | undefined) => {
  switch (kind) {
    case "lp":
      return GRADIENT_LP;
    case "miner":
      return GRADIENT_MINER;
    default:
      return GRADIENT_NEUTRAL;
  }
};
