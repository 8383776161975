import { LoadingButton } from "@mui/lab";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import React, { useReducer } from "react";
import toast from "react-hot-toast";
import { ReqUpdateKymFinancialsDto, ResKymFinancialsDto } from "../../app/model/api";
import { useMutationKymFinancials } from "../../app/query/useKymMutations";
import { useQueryGetKym2Financials } from "../../app/query/useKymQueries";
import { ChipFileItem, DisplayFileList } from "../../components/atoms/FileInput/ChipFile";
import { MuiFileDragAndDropUploader } from "../../components/atoms/FileInput/MuiFileDragAndDropUploader";
import { buildChipFileItem, mapFiles } from "./commons";

const UploadGridRow = (props: React.HTMLAttributes<HTMLLIElement>) => (
  <Grid container mt={2} padding={2} spacing={2} boxShadow={2} border={1} borderRadius={1} borderColor={"ThreeDFace"}>
    {props.children}
  </Grid>
);

type Sections = "income" | "balance" | "cash-flow" | "audit-engagement" | "outstanding-liabilities";

type FinancialsAction =
  | { kind: "init"; payload?: ResKymFinancialsDto }
  | {
      kind: "remove";
      section: Sections;
      id: string;
    }
  | { kind: "add"; section: Sections; files: File[] };

interface FinancialInfoState {
  hasUpdates: boolean;
  files: {
    [key in Sections]: ChipFileItem[];
  };
}

const reducer = (state: FinancialInfoState, action: FinancialsAction): FinancialInfoState => {
  switch (action.kind) {
    case "init": {
      return {
        hasUpdates: false,
        files: {
          balance: action.payload?.balanceSheetFiles.map(buildChipFileItem) ?? [],
          income: action.payload?.incomeStatementFiles.map(buildChipFileItem) ?? [],
          "cash-flow": action.payload?.cashFlowStatementFiles.map(buildChipFileItem) ?? [],
          "audit-engagement": action.payload?.auditEngagementLetterFiles.map(buildChipFileItem) ?? [],
          "outstanding-liabilities": action.payload?.outstandingLiabilitiesFiles.map(buildChipFileItem) ?? [],
        },
      };
    }
    case "add": {
      const crt = state.files[action.section] ?? [];
      const newFiles = [
        ...crt.filter((x) => !action.files.some((newFile) => newFile.name === x.id)),
        ...action.files.map((x) => {
          const file: ChipFileItem = { kind: "new", id: x.name, file: x };
          return file;
        }),
      ];
      const newState = {
        hasUpdates: true,
        files: {
          ...state.files,
          [action.section]: newFiles,
        },
      };
      return newState;
    }

    case "remove": {
      const newFiles = (state.files[action.section] ?? []).filter((x) => x.id !== action.id);
      const newState = {
        hasUpdates: true,
        files: {
          ...state.files,
          [action.section]: newFiles,
        },
      };
      return newState;
    }
  }
};

const Title = ({ title }: { title: string }) => {
  return (
    <Typography noWrap variant="subtitle1">
      {title}
    </Typography>
  );
};

export const KymFinancials = () => {
  const {
    refetch: reloadFinancialsData,
    data: financials,
    isFetching: isLoadingFinancials,
  } = useQueryGetKym2Financials();

  const { mutateAsync: saveFinancials, isLoading: isSavingFinancials } = useMutationKymFinancials();

  const [financialsState, dispatch] = useReducer(reducer, {
    hasUpdates: false,
    files: {
      balance: financials?.data.balanceSheetFiles.map(buildChipFileItem) ?? [],
      income: financials?.data.incomeStatementFiles.map(buildChipFileItem) ?? [],
      "cash-flow": financials?.data.cashFlowStatementFiles.map(buildChipFileItem) ?? [],
      "audit-engagement": financials?.data.auditEngagementLetterFiles.map(buildChipFileItem) ?? [],
      "outstanding-liabilities": financials?.data.outstandingLiabilitiesFiles.map(buildChipFileItem) ?? [],
    },
  });

  React.useEffect(() => dispatch({ kind: "init", payload: financials?.data }), [financials]);

  const onFileUpload = (section: Sections) => (newFiles: File[]) => {
    dispatch({ kind: "add", section, files: newFiles });
  };

  const onSave = async () => {
    const request: ReqUpdateKymFinancialsDto = {
      balanceSheetFiles: await mapFiles(financialsState.files["balance"]),
      cashFlowStatementFiles: await mapFiles(financialsState.files["cash-flow"]),
      incomeStatementFiles: await mapFiles(financialsState.files["income"]),
      auditEngagementLetterFiles: await mapFiles(financialsState.files["audit-engagement"]),
      outstandingLiabilitiesFiles: await mapFiles(financialsState.files["outstanding-liabilities"]),
    };
    try {
      const result = await saveFinancials(request);
      if (result.data.kind === false) {
        toast.error("There has been an issue while trying to save your changes. Please try again.");
      } else {
        toast.success("Your updates have been saved successfully.");
        reloadFinancialsData();
      }
    } catch (err) {
      toast.error("There has been an issue while trying to save your changes. Please try again.");
    }
  };

  if (isLoadingFinancials) {
    return (
      <Stack>
        <Skeleton width={"100%"} height={300} />
      </Stack>
    );
  } else {
    return (
      <Stack justifyItems={"center"} alignItems={"center"}>
        <UploadGridRow>
          <Box width={300} minWidth={300} height={125}>
            <MuiFileDragAndDropUploader
              text={"Please provide the Financial Statements for this site for past three years (if available)"}
              onFileUpload={onFileUpload("balance")}
            />
          </Box>
          <Grid container item md>
            <Box>
              <Title title="Balance sheet" />
              <DisplayFileList
                id={"balance"}
                onDelete={(id: string) => dispatch({ kind: "remove", section: "balance", id })}
                onDownload={async (id: string) => console.log(id)}
                files={financialsState.files["balance"]}
              ></DisplayFileList>
            </Box>
          </Grid>
        </UploadGridRow>
        <UploadGridRow>
          <Box width={300} minWidth={300} height={125}>
            <MuiFileDragAndDropUploader
              onFileUpload={onFileUpload("income")}
              text={"Please provide income statements (consolidated entity) for the past three years"}
            />
          </Box>
          <Grid container item md>
            <Box>
              <Title title="Income statement" />
              <DisplayFileList
                id={"income"}
                onDelete={(id: string) => dispatch({ kind: "remove", section: "income", id })}
                onDownload={async (id: string) => console.log(id)}
                files={financialsState.files["income"]}
              ></DisplayFileList>
            </Box>
          </Grid>
        </UploadGridRow>
        <UploadGridRow>
          <Box width={300} minWidth={300} height={125}>
            <MuiFileDragAndDropUploader
              onFileUpload={onFileUpload("cash-flow")}
              text={"Please provide cash flow statements (consolidated entity) for the past three years"}
            />
          </Box>
          <Grid container item md>
            <Box>
              <Title title="Cash flow statement" />
              <DisplayFileList
                id={"cash-flow"}
                onDelete={(id: string) => dispatch({ kind: "remove", section: "cash-flow", id })}
                onDownload={async (id: string) => console.log(id)}
                files={financialsState.files["cash-flow"]}
              ></DisplayFileList>
            </Box>
          </Grid>
        </UploadGridRow>
        <UploadGridRow>
          <Box width={300} minWidth={300} height={125}>
            <MuiFileDragAndDropUploader
              onFileUpload={onFileUpload("audit-engagement")}
              text={"Please upload an Audit Engagement Letter if audited financials are not available"}
            />
          </Box>
          <Grid container item md>
            <Box>
              <Title title="Audit Engagement Letter" />
              <DisplayFileList
                id={"audit-engagement"}
                onDelete={(id: string) => dispatch({ kind: "remove", section: "audit-engagement", id })}
                onDownload={async (id: string) => console.log(id)}
                files={financialsState.files["audit-engagement"]}
              ></DisplayFileList>
            </Box>
          </Grid>
        </UploadGridRow>
        <UploadGridRow>
          <Box width={300} minWidth={300} height={125}>
            <MuiFileDragAndDropUploader
              onFileUpload={onFileUpload("outstanding-liabilities")}
              text={"Please upload an overview of outstanding liabilities & loan agreements"}
            />
          </Box>
          <Grid container item md>
            <Box>
              <Title title="Outstanding liabilities" />
              <DisplayFileList
                id={"outstanding-liabilities"}
                onDelete={(id: string) => dispatch({ kind: "remove", section: "outstanding-liabilities", id })}
                onDownload={async (id: string) => console.log(id)}
                files={financialsState.files["outstanding-liabilities"]}
              ></DisplayFileList>
            </Box>
          </Grid>
        </UploadGridRow>
        <Box mt={1} width={"100%"} display="flex" justifyContent={"flex-end"}>
          <LoadingButton
            variant="contained"
            disabled={!financialsState.hasUpdates}
            onClick={onSave}
            loading={isSavingFinancials}
          >
            Save
          </LoadingButton>
        </Box>
      </Stack>
    );
  }
};
