import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
import toast from "react-hot-toast";
import { useMutationAdminGenerateFileUrl } from "../../../app/query/useMutationAdmin";
import { useQueryGetMinerKymEnergyProfileById } from "../../../app/query/useQueryGetAdmin";
import { DisplayFileList } from "../../../components/atoms/FileInput/ChipFile";
import { MuiFullscreenLoadingBackdrop } from "../../../components/atoms/MuiCircularPercentage/MuiLoadingBackdrop";
import { buildChipFileItem } from "../../KYM/commons";
import { StyledTableRow } from "../commons";

export const ReadonlyMinerEnergyProfile = ({ organizationId }: { organizationId: string }) => {
  const { data, isFetching, error } = useQueryGetMinerKymEnergyProfileById(organizationId);
  const { mutateAsync: generateFileUrl, isLoading: isGettingFile } = useMutationAdminGenerateFileUrl();

  const handleDownload = async (id: string) => {
    try {
      const result = await generateFileUrl(id);
      if (result.data.url) {
        window.open(result.data.url, "_blank", "noopener,noreferrer");
      } else {
        toast.error("Was not able to find the requested file.");
      }
    } catch (err) {
      toast.error("There has been an issue getting the file.");
    }
  };
  const energyProfile = data?.data.payload;

  const ppaFiles = energyProfile?.hedgingSignedPpa?.map(buildChipFileItem) ?? [];

  return isFetching ? (
    <Skeleton width={"100%"} height={300} />
  ) : error ? (
    <Stack>
      <Alert severity="warning">
        There has been an issue loading the miner data. Please refresh the page to try again.
      </Alert>
    </Stack>
  ) : (
    <Grid container item xs={12}>
      <MuiFullscreenLoadingBackdrop isOpen={isGettingFile} />
      <Card variant="outlined" sx={{ width: "100%", border: 0 }}>
        <CardContent>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <StyledTableRow key={"row-energy-sources"}>
                  <TableCell align="left" width={200}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Energy sources
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {!energyProfile?.energyElements?.length ? (
                      <CloseIcon color="error" />
                    ) : (
                      <>
                        {energyProfile?.energyElements.map((es) => (
                          <Chip
                            key={`chip-es-${es.source}`}
                            variant="filled"
                            color="primary"
                            sx={{ maxWidth: 175, mr: 1 }}
                            clickable={false}
                            label={`${es.source} ${es.percentage}%`}
                          ></Chip>
                        ))}
                      </>
                    )}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow key={"row-renewable-share"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Renewable energy share
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {energyProfile?.renewableEnergyPercentage == undefined ? (
                      <CloseIcon color="error" />
                    ) : (
                      `${energyProfile?.renewableEnergyPercentage}%`
                    )}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow key={"row-current-hashrate"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Average energy price
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {energyProfile?.averageAllInEnergyPriceUsdPerMWh == undefined ? (
                      <CloseIcon color="error" />
                    ) : (
                      `${energyProfile?.averageAllInEnergyPriceUsdPerMWh} USD/MWh`
                    )}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow key={"row-hedges"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Hedges energy price
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {energyProfile?.hedgesEnergyPrice == undefined ? (
                      <CloseIcon color="error" />
                    ) : energyProfile?.hedgesEnergyPrice ? (
                      "Yes"
                    ) : (
                      "No"
                    )}
                  </TableCell>
                </StyledTableRow>
                {energyProfile?.hedgesEnergyPrice ? (
                  <>
                    <StyledTableRow key={"row-hedging-mechanism"}>
                      <TableCell align="left" width={250}>
                        <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                          Hedging mechanism
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {energyProfile?.hedgingDescription == undefined ? (
                          <CloseIcon color="error" />
                        ) : (
                          energyProfile?.hedgingDescription
                        )}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow key={"row-ppa-files"}>
                      <TableCell align="left" width={250}>
                        <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                          Signed PPA files
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {ppaFiles.length ? (
                          <DisplayFileList
                            id={"dfl-ppa"}
                            files={ppaFiles}
                            onDownload={handleDownload}
                          ></DisplayFileList>
                        ) : (
                          <CloseIcon color="error" />
                        )}
                      </TableCell>
                    </StyledTableRow>
                  </>
                ) : null}
                <StyledTableRow key={"row-hedging-mechanism"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Curtailment response programs
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {energyProfile?.curtailmentPrograms == undefined ? (
                      <CloseIcon color="error" />
                    ) : (
                      energyProfile?.curtailmentPrograms
                    )}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow key={"row-hedging-mechanism"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Curtailment response programs
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {energyProfile?.minimumPowerTakeOut == undefined ? (
                      <CloseIcon color="error" />
                    ) : (
                      energyProfile?.minimumPowerTakeOut
                    )}
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};
