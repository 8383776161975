import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { head } from "lodash";
import { useState } from "react";
import { SATOSHIS_TO_BTC } from "../../../../../app/constants";
import {
  AddFundingActionContext,
  AddFundingEvent,
  AddNeededCollateralEvent,
  MinerUnderDeliveryDayEvent,
  PassTimeWithDeliveryAsExpectedContext,
  PassTimeWithDeliveryAsExpectedEvent,
  PublishActionContext,
  PublishOpportunityEvent,
  getEventLabel,
} from "./simulationTimelineItems";

export const PublishBuilder = ({
  input,
  onUpdate,
}: {
  input: PublishActionContext;
  onUpdate: (event: PublishOpportunityEvent) => void;
}) => {
  const [publishDate, setPublishDate] = useState(input.minDate);
  return (
    <Stack spacing={2} padding={2} alignItems={"center"}>
      <Typography>{getEventLabel(input.kind)}</Typography>
      <Typography variant="caption">
        Changes the opportunity from draft to published, allowing for LP agreements to take place.
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Publish date"
          format="dd/MM/yyyy"
          value={publishDate}
          minDate={input.minDate}
          maxDate={new Date()}
          onChange={(e) => {
            if (e) setPublishDate(e);
          }}
          slotProps={{ textField: { size: "small", fullWidth: true } }}
        />
      </LocalizationProvider>
      <Button
        sx={{ width: "fit-content" }}
        variant="contained"
        onClick={() => onUpdate({ kind: "publish", publishDate })}
      >
        Add
      </Button>
    </Stack>
  );
};

export const AddFundingBuilder = ({
  input,
  onUpdate,
  lps,
}: {
  input: AddFundingActionContext;
  onUpdate: (event: AddFundingEvent) => void;
  lps: { id: string; company?: string; email: string }[];
}) => {
  const [fundingDate, setFundingDate] = useState(input.minDate);
  const [amount, setAmount] = useState(input.maxAmountSatoshi / SATOSHIS_TO_BTC.toNumber());
  const [lpId, setLpId] = useState(head(lps)?.id ?? "");

  return (
    <Stack spacing={3} padding={2} alignItems={"center"} maxWidth={400}>
      <Typography>{getEventLabel(input.kind)}</Typography>
      <Typography variant="caption">
        Adds a funding event from an LP, which also represents an agreement being created with that lp for the
        opportunity.
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-name-label">Action</InputLabel>
        <Select
          defaultValue=""
          labelId="demo-multiple-name-label"
          label={"LP"}
          id="demo-multiple-name"
          value={lpId}
          fullWidth
          size="small"
          onChange={(newValue) => {
            if (newValue.target.value) setLpId(newValue.target.value);
          }}
        >
          {lps.map((lp) => (
            <MenuItem key={lp.id} value={lp.id}>
              {`${lps.find((x) => x.id === lp.id)?.company} (${lps.find((x) => x.id === lp.id)?.email})`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Funding date"
          format="dd/MM/yyyy"
          value={fundingDate}
          minDate={input.minDate}
          maxDate={new Date()}
          onChange={(e) => {
            if (e) setFundingDate(e);
          }}
          slotProps={{ textField: { size: "small", fullWidth: true } }}
        />
      </LocalizationProvider>
      <TextField
        id="fundingAmount"
        label={"Max amount of liquidity"}
        fullWidth
        size="small"
        variant="outlined"
        onWheel={(evt) => {
          (evt.target as HTMLElement).blur(); // disable edit by scroll
        }}
        value={amount}
        onChange={(evt) => {
          const newAmount = +evt.target.value;
          setAmount(newAmount);
        }}
        helperText={`Min=0.01, Max=${input.maxAmountSatoshi / SATOSHIS_TO_BTC.toNumber()}.`}
        error={amount < 0.01 || amount > input.maxAmountSatoshi / SATOSHIS_TO_BTC.toNumber()}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">BTC</InputAdornment>,
        }}
      />
      <Button
        variant="contained"
        sx={{ width: "fit-content" }}
        disabled={amount < 0.01 || amount > input.maxAmountSatoshi / SATOSHIS_TO_BTC.toNumber()}
        onClick={() => {
          onUpdate({
            kind: "add-funding",
            fundingDate,
            amountSatoshi: amount * SATOSHIS_TO_BTC.toNumber(),
            lpId,
            lpName: `${lps.find((x) => x.id === lpId)?.company} (${lps.find((x) => x.id === lpId)?.email})`,
          });
        }}
      >
        Add
      </Button>
    </Stack>
  );
};

export const MinerUnderDeliveryBuilder = ({ onUpdate }: { onUpdate: (event: MinerUnderDeliveryDayEvent) => void }) => {
  const [percentage, setPercentage] = useState(10);
  return (
    <Stack spacing={2} padding={2} alignItems={"center"} maxWidth={400}>
      <Typography>{getEventLabel("miner-under-delivery-day")}</Typography>
      <Typography variant="caption">
        Simulates a day where the miner delivers only part of their expected value.
      </Typography>

      <TextField
        label={"What percentage of the expected value does the miner deliver?"}
        fullWidth
        size="small"
        variant="outlined"
        onWheel={(evt) => {
          (evt.target as HTMLElement).blur(); // disable edit by scroll
        }}
        value={percentage}
        onChange={(evt) => {
          const newAmount = +evt.target.value;
          setPercentage(newAmount);
        }}
        helperText={"Min=1, Max=99."}
        error={percentage < 1 || percentage > 99}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />

      <Button
        sx={{ width: "fit-content" }}
        variant="contained"
        disabled={percentage < 1 || percentage > 99}
        onClick={() => onUpdate({ kind: "miner-under-delivery-day", percentage })}
      >
        Add
      </Button>
    </Stack>
  );
};

export const AddNeededCollateralBuilder = ({ onUpdate }: { onUpdate: (event: AddNeededCollateralEvent) => void }) => {
  return (
    <Stack spacing={2} padding={2} alignItems={"center"} maxWidth={400}>
      <Typography>{getEventLabel("add-needed-collateral")}</Typography>
      <Typography variant="caption">
        Adds a collateral funding by the miner for the opportunity immediately, for whatever amount is needed.
      </Typography>
      <Button
        sx={{ width: "fit-content" }}
        variant="contained"
        onClick={() => onUpdate({ kind: "add-needed-collateral" })}
      >
        Add
      </Button>
    </Stack>
  );
};

export const PassTimeWithDeliveryAsExpectedBuilder = ({
  input,
  onUpdate,
}: {
  input: PassTimeWithDeliveryAsExpectedContext;
  onUpdate: (event: PassTimeWithDeliveryAsExpectedEvent) => void;
}) => {
  const [until, setUntil] = useState(input.minDate);
  return (
    <Stack spacing={2} padding={2} alignItems={"center"} maxWidth={400}>
      <Typography>{getEventLabel(input.kind)}</Typography>
      <Stack>
        <Typography variant="caption">
          Passes time during which everything goes perfectly, miners deliver the expected amount and we distribute
          rewards correctly.
        </Typography>
        <Typography variant="caption">
          If you pass time until after the end of the opportunity it will result in the opportunity being settled
          successfully.
        </Typography>
      </Stack>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Until"
          format="dd/MM/yyyy"
          value={until}
          minDate={input.minDate}
          maxDate={new Date()}
          onChange={(e) => {
            if (e) setUntil(e);
          }}
          slotProps={{ textField: { size: "small", fullWidth: true } }}
        />
      </LocalizationProvider>
      <Button
        sx={{ width: "fit-content" }}
        variant="contained"
        onClick={() => onUpdate({ kind: "pass-time-with-delivery-as-expected", until: until })}
      >
        Add
      </Button>
    </Stack>
  );
};
