import Decimal from "decimal.js";
import { roundToSignificantDigits, roundToSignificantDigitsDiv1000 } from "../../../app/helper/utils";
import { ReactComponent as BlockchainIcon } from "../../../assets/images/blockchain-icon.svg";
import { ReactComponent as BlockchainLiquidityIcon } from "../../../assets/images/blockchain-liquidity-icon.svg";
import heroCurrentBtcPrice from "../../../assets/images/hero-lp-opportunity-3.png";
import { BlurredValue } from "../../atoms/BlurredValue";
import HeroBox from "../../atoms/HeroBox";
import Loader from "../../atoms/Loader";
import ProgressPercentBar from "../../atoms/ProgressPercentBar";
import { toBtc } from "../../../pages/Loans/commons/utils";
import { format } from "date-fns";

const reachOutTooltip = "Reach out to learn more";

export const HeroLoanDetailsLP = ({
  annualizedReturn,
  principalAmountSatoshis,
  totalReturn,
  startDate,
  endDate,
  isFetching,
}: {
  annualizedReturn?: number;
  principalAmountSatoshis?: number;
  totalReturn?: number;
  startDate?: Date;
  endDate?: Date;
  isFetching: boolean;
}) => {
  return (
    <div className="hero-lp-list">
      <div className="container-custom mx-auto !py-16">
        <div className="grid grid-cols-3 gap-4 pb-4">
          <HeroBox title="Annualized Return" backgroundImage={heroCurrentBtcPrice}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <div className="crypto-value">
                {annualizedReturn ?? <BlurredValue tooltip={reachOutTooltip}>⛝⛝</BlurredValue>} %
              </div>
            )}
          </HeroBox>
          <HeroBox title="Total return" titleIcon={<BlockchainIcon className="w-5 mr-2" />}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <div className="w-full">
                <div className="grid grid-cols-2 gap-5">
                  <div>
                    <p>
                      <small className="text-[11px]">Principal Amount</small> <br />
                      <>
                        {principalAmountSatoshis ? (
                          <strong>{toBtc(principalAmountSatoshis).toPrecision(4)}</strong>
                        ) : (
                          <BlurredValue tooltip={reachOutTooltip}>⛝⛝</BlurredValue>
                        )}{" "}
                        <sup>BTC</sup>
                      </>
                    </p>
                  </div>
                  <div>
                    <p>
                      <small className="text-[11px]">Total Return</small> <br />
                      <>
                        {totalReturn ? (
                          <strong>{toBtc(totalReturn).toPrecision(4)}</strong>
                        ) : (
                          <BlurredValue tooltip={reachOutTooltip}>⛝⛝</BlurredValue>
                        )}{" "}
                        <sup>BTC</sup>
                      </>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </HeroBox>
          <HeroBox title="Timeline" titleIcon={<BlockchainLiquidityIcon className="w-5 mr-2" />}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <div className="w-full">
                <div className="grid grid-cols-2 gap-5">
                  <div>
                    <p>
                      <small className="text-[11px]">Start Date</small> <br />
                      <>
                        {startDate ? (
                          <strong>{format(startDate, "dd MMM yyyy")}</strong>
                        ) : (
                          <BlurredValue tooltip={reachOutTooltip}>⛝⛝ ⛝⛝⛝ ⛝⛝⛝⛝</BlurredValue>
                        )}
                        <sup></sup> {/* Hacky but forces spacing to be the same as other hero boxes */}
                      </>
                    </p>
                  </div>
                  <div>
                    <p>
                      <small className="text-[11px]">End Date</small> <br />
                      <>
                        {endDate ? (
                          <strong>{format(endDate, "dd MMM yyyy")}</strong>
                        ) : (
                          <BlurredValue tooltip={reachOutTooltip}>⛝⛝ ⛝⛝⛝ ⛝⛝⛝⛝</BlurredValue>
                        )}
                        <sup></sup> {/* Hacky but forces spacing to be the same as other hero boxes */}
                      </>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </HeroBox>
        </div>
      </div>
    </div>
  );
};
