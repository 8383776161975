import { KymAsicContainer } from "../../pages/KYM/KymAsic";
import { KymCapacityAndSitesContainer } from "../../pages/KYM/KymCapacityAndSites";
import { KymEnergyContainer } from "../../pages/KYM/KymEnergy";
import { KymFinancials } from "../../pages/KYM/KymFinancials";
import { KymHostingContainer } from "../../pages/KYM/KymHosting";
import { KYM2_ASIC, KYM2_ENERGY, KYM2_FINANCIALS, KYM2_HOSTING, KYM2_MINING_SITES } from "./routes";

export const KYM2_TABS = [
  { name: "Financial info", path: KYM2_FINANCIALS, tabComponent: () => <KymFinancials /> },
  { name: "Capacity & sites", path: KYM2_MINING_SITES, tabComponent: () => <KymCapacityAndSitesContainer /> },
  { name: "Energy", path: KYM2_ENERGY, tabComponent: () => <KymEnergyContainer /> },
  { name: "ASIC fleet", path: KYM2_ASIC, tabComponent: () => <KymAsicContainer /> },
  { name: "Hosting", path: KYM2_HOSTING, tabComponent: () => <KymHostingContainer /> },
];
