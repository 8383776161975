import { useQuery } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";

const GET_KYM2_FINANCIALS = "kym2_financials" as const;
const GET_KYM2_HOSTING = "kym2_hosting" as const;
const GET_KYM2_ASIC = "kym2_asic" as const;
const GET_KYM2_ENERGY_PROFILE = "kym2_energy_profile" as const;
const GET_KYM2_CAPACITY_AND_SITES = "kym2_capacity" as const;

export const useQueryGetKym2Financials = () => {
  return useQuery([GET_KYM2_FINANCIALS], () => apig.kym.getKym2Financials());
};

export const useQueryGetKym2Hosting = () => {
  return useQuery([GET_KYM2_HOSTING], () => apig.kym.getKym2Hosting());
};

export const useQueryGetKym2Asic = () => {
  return useQuery([GET_KYM2_ASIC], () => apig.kym.getKym2Asic());
};

export const useQueryGetKym2EnergyProfile = () => {
  return useQuery([GET_KYM2_ENERGY_PROFILE], () => apig.kym.getKym2EnergyProfile());
};

export const useQueryGetKym2CapacityAndSites = () => {
  return useQuery([GET_KYM2_CAPACITY_AND_SITES], () => apig.kym.getKym2CapacityAndSites());
};
