/* eslint-disable */

import { Role as RoleEnum } from "./permission.type";
import { KymRate } from "./user.type";

interface Wallet {
  readonly bitgoWalletId: string;
  readonly address: WalletAddress;
  readonly protocolWallet: ProtocolWallet;
  // userWallet: UserWallet;
  // internalTransactionFrom: InternalTransaction[];
  // internalTransactionTo: InternalTransaction[];
  readonly id: string;
  /** @format date-time */
  readonly createdAt: string;
  /** @format date-time */
  readonly updatedAt: string;
  readonly version: number;
}

interface ProtocolWallet {
  readonly wallet: Wallet;
  readonly walletType: "SynHash Pool" | "Collateral Vault" | "Reward Vault";
  // reservedTransactions: ReservedTransaction[];
  readonly opportunity: Opportunity;
  readonly id: string;
  /** @format date-time */
  readonly createdAt: string;
  /** @format date-time */
  readonly updatedAt: string;
  readonly version: number;
}
interface Opportunity {
  readonly idNum: number;
  readonly miner: Miner;
  readonly description: string;
  readonly dataroomLink?: string;
  readonly collateralStatus: "Sufficient" | "Under sufficient";
  readonly status:
    | "Awaiting Collateral"
    | "Awaiting Funding"
    | "Draft"
    | "Active"
    | "Defaulted"
    | "Cancelled"
    | "Settled"
    | "Closed";
  // miningSites: MiningSite[];
  /** @format date-time */
  readonly creationDate: string;
  /** @format date-time */
  readonly cancelledDate?: string;
  readonly fullHashrateThPerSecond: string;
  readonly requiredCollateralSatoshiPerOutstandingTh: string;
  readonly requiredCollateralAsPercentOfInitialLiquidity?: number;
  readonly durationDays: number;
  readonly maxAmountOfLiquiditySatoshis: string;
  /** @format date-time */
  readonly fundingDeadlineForOtherTranches: string;
  readonly partnershipName?: string;
  /** @format date-time */
  readonly publishDate?: string;
  readonly expectedReturn?: number;
  readonly historicalUptime: number | null;
  readonly energyMix: EnergyMix;
  readonly protocolWallets: ProtocolWallet[];
  // expectedRewards: ExpectedReward[];
  readonly structuringFeePercentage: string;
  readonly managementFeePercentage: string;
  readonly id: string;
  /** @format date-time */
  readonly createdAt: string;
  /** @format date-time */
  readonly updatedAt: string;
  readonly version: number;
}

interface Managers {
  walletManagers: number;
  accessManagers: number;
}

interface BasicInfoDto {
  id: string;
  company?: string;
  homepageUrl?: string | null;
  location?: string;
  userType: "Admin" | "Miner" | "LP";
  onboardingStatus?:
    | "Entrypoint pending"
    | "Entrypoint submitted"
    | "Entrypoint Rejected"
    | "KYC submitted company"
    | "KYC pending"
    | "KYC submitted representative"
    | "KYC Rejected"
    | "KYM submitted general"
    | "KYM pending"
    | "KYM Rejected"
    | "Fully onboarded"
    | "Susppended"
    | "None";
  onboardingStatusAdminPanel?:
    | "None"
    | "Entrypoint Pending"
    | "Entrypoint Approved"
    | "Entrypoint Rejected"
    | "KYC/AML Not Fullfilled"
    | "KYC/AML Pending"
    | "KYC/AML Approved"
    | "KYC/AML Rejected"
    | "KYM Not Fullfilled"
    | "KYM Pending"
    | "KYM Approved"
    | "KYM Rejected"
    | "Onboarded"
    | "Suspended";
  numOfUsers?: number;
  managers: Managers;
  organizationId: string;
  isPubliclyListed: boolean | null;
  annualReport: string | null;
  onboardingUser: ContactDetailsDto;
  primaryContact?: PrimaryContactDto;
}

interface WalletDetailsDto {
  walletAddress: string;
  bitgoWalletId: string;
  bitgoWalletUrl: string;
}

interface LpResponseDto {
  /** @min 0 */
  AUMMillionUSD?: number;
  portionOfBitcoinAssets?: number;
  bitcoinAum?: number;
  projectedAllocation?: "0" | "from1to20" | "from20to50" | "50+";
  projectedBitcoinInvestment: number;
  timeHorizonOfLiquidity?: "lt1" | "1to6" | "6to2" | "gt2";
  liquidityHorizon: "notsure" | "lt1" | "1mto3m" | "3mto6m" | "6mto1y" | "gt1y";
}

export interface MinerResponseDto {
  averageAllInEnergyPrice: number;
  averageAllInEnergyPriceUnit: string;

  miningCapacity?: number;
  /** @min 0 */
  avgEnergyPrice?: number;
  energyCostsFixed?: boolean;
  isHosted?: boolean;
  hostingProvider?: string;
  /**
   * @min 0
   * @max 100
   */
  percentage?: number;
  primaryEnergySource?: string;
  isEncumbered?: boolean;
  percentEncumbrance?: "from1to25" | "from25to50" | "from50to75" | "from75to99" | "full100";
  liquidityAgreementDuration?: "lt6" | "6to12" | "12to18" | "gt18";
  liquidityHorizon: "notsure" | "lt1" | "1mto3m" | "3mto6m" | "6mto1y" | "gt1y";
  financialHealthRate?: "LOW" | "MEDIUM" | "HIGH";
  operationalHealthRate?: "LOW" | "MEDIUM" | "HIGH";
  sustainabilityRate?: "LOW" | "MEDIUM" | "HIGH";
}

interface LpOrganizationResponseDto {
  basicInfo: BasicInfoDto;
  walletDetails?: WalletDetailsDto;
  lpInfo: LpResponseDto;
}

interface MinerInfoResponseDto {
  basicInfo: BasicInfoDto;
  walletDetails?: WalletDetailsDto;
  minerInfo: MinerResponseDto;
}

export interface ResMinerSummaryDto {
  minerId: string;
  onboardingStatus:
    | "Entrypoint pending"
    | "Entrypoint submitted"
    | "Entrypoint Rejected"
    | "KYC submitted company"
    | "KYC pending"
    | "KYC submitted representative"
    | "KYC Rejected"
    | "KYM submitted general"
    | "KYM pending"
    | "KYM Rejected"
    | "Fully onboarded"
    | "Susppended"
    | "None";
  companyName: string;
  asicUptimePercentage?: number;
  financialHealthRate?: "LOW" | "MEDIUM" | "HIGH";
  operationalHealthRate?: "LOW" | "MEDIUM" | "HIGH";
  sustainabilityRate?: "LOW" | "MEDIUM" | "HIGH";
  energyElements: EnergyElementDto[];
}

interface ResMinerDumpDto {
  json?: string;
}

interface ResDownloadUrlDto {
  url?: string;
}

interface EnergyElementDto {
  source: string;
  /**
   * @min 1
   * @max 100
   */
  percentage: number;
}

export interface KymEnergyProfileDto {
  energyElements: EnergyElementDto[];
  /**
   * @min 0
   * @max 100
   */
  renewableEnergyPercentage?: number;
  /** @min 0 */
  averageAllInEnergyPriceUsdPerMWh: number;
  hedgesEnergyPrice: boolean;
  hedgingDescription: string;
  hedgingSignedPpa?: ResUploadedFileDto[];
  curtailmentPrograms?: string;
  minimumPowerTakeOut?: string;
}

interface ResKymEnergyProfileDto {
  organizationId: string;
  payload?: KymEnergyProfileDto;
}

export interface ReqKymEnergyProfileDto {
  energyElements: EnergyElementDto[];
  /**
   * @min 0
   * @max 100
   */
  renewableEnergyPercentage?: number;
  /** @min 0 */
  averageAllInEnergyPriceUsdPerMWh: number;
  hedgesEnergyPrice: boolean;
  hedgingDescription?: string;
  hedgingSignedPpa?: ReqUpdateFilesDto;
  curtailmentPrograms?: string;
  minimumPowerTakeOut?: string;
}

interface ResKymCapacityMiningSite {
  id: string;
  location: string;
  /** @min 0 */
  hashrate: number;
}

export interface ResKymCapacityAndSitesDto {
  organizationId: string;
  /** @min 0 */
  currentInstalledHashrate?: number;
  /** @min 0 */
  estimatedHashrateIn12Months?: number;
  /** @min 0 */
  totalMiningSites?: number;
  miningSites: ResKymCapacityMiningSite[];
  hasThirdPartyProviderHostedMachines: boolean;
  /** @min 0 */
  thirdPartyInstalledHashratePhS?: number;
  /** @min 0 */
  averageHostingPriceUsdPerMwh?: number;
}

interface ReqKymCapacityMiningSite {
  id?: string;
  location: string;
  /** @min 0 */
  hashrate: number;
}

export interface ReqUpdateKymCapacityAndSitesDto {
  /** @min 0 */
  currentInstalledHashrate: number;
  /** @min 0 */
  estimatedHashrateIn12Months?: number;
  /** @min 0 */
  totalMiningSites: number;
  miningSites: ReqKymCapacityMiningSite[];
  hasThirdPartyProviderHostedMachines: boolean;
  /** @min 0 */
  thirdPartyInstalledHashratePhS?: number;
  /** @min 0 */
  averageHostingPriceUsdPerMwh?: number;
}

interface ResUpdateStatus {
  isUpdated: boolean;
}

interface ReqByMinerIdDto {
  minerId: string;
}

interface ResMinerKycApprovalDto {
  kind: "Success" | "WalletIssue";
}

export interface OrganizationEntry {
  email: string;
  createdAt: string;
  id: string;
  company?: string;
  homepageUrl?: string | null;
  location?: string;
  userType: "Admin" | "Miner" | "LP";
  onboardingStatus?:
    | "Entrypoint pending"
    | "Entrypoint submitted"
    | "Entrypoint Rejected"
    | "KYC submitted company"
    | "KYC pending"
    | "KYC submitted representative"
    | "KYC Rejected"
    | "KYM submitted general"
    | "KYM pending"
    | "KYM Rejected"
    | "Fully onboarded"
    | "Susppended"
    | "None";
  onboardingStatusAdminPanel?:
    | "None"
    | "Entrypoint Pending"
    | "Entrypoint Approved"
    | "Entrypoint Rejected"
    | "KYC/AML Not Fullfilled"
    | "KYC/AML Pending"
    | "KYC/AML Approved"
    | "KYC/AML Rejected"
    | "KYM Not Fullfilled"
    | "KYM Pending"
    | "KYM Approved"
    | "KYM Rejected"
    | "Onboarded"
    | "Suspended";
  numOfUsers?: number;
}

interface OrganizationsResponseDto {
  rows: OrganizationEntry[];
  count: number;
}

export interface UpdateTrancheDeliveryDto {
  opportunityId: string;
  isSufficientHashrateAllocated: boolean;
}

export interface ResTimelineTransactionDto {
  kind:
    | "Reward Distribution"
    | "Opportunity Funding"
    | "Liquidity Refund"
    | "Collateral Release"
    | "Collateral Deposit"
    | "Reward Delivery"
    | "Liquidity Delivery"
    | "Excess Reward Release"
    | "Unknown"
    | "Collateral Liquidation";
  id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  approvalStatus: "Approval not required" | "Awaiting approval" | "Approved" | "Rejected";
  amountSatoshi: number;
  feeTakenSatoshi: number;
  fromAddress: string;
  toAddress: string;
  /** @format date-time */
  blockTimestamp?: string;
  txHash?: string;
  IsProcessed?: boolean;
  networkStatus?: "Pending" | "Confirmed";
  rewardLpId?: string;
  rewardLpOrganizationId?: string;
  rewardLpName?: string;
}

export interface ResWalletAddressDto {
  kind:
    | "Opportunity synhash pool"
    | "Opportunity collateral vault"
    | "Opportunity reward vault"
    | "Managed miner wallet"
    | "Miner withdrawal wallet"
    | "Managed LP wallet"
    | "LP withdrawal wallet";
  address: string;
  walletOwnerId: string;
  name: string;
}

interface ResTimelineDto {
  rows: ResTimelineTransactionDto[];
  addresses: ResWalletAddressDto[];
}

interface LiquidityDeliveryResponseDto {
  rows: LiquidityDelivery[];
  count: number;
}

export interface LiquidityDelivery {
  opportunityId: string;
  opportunityIdNum: number;
  minerCompanyName: string;
  requiredHashrateTh: number;
  newRequiredCollateralSatoshis: number;
  collateralBalanceSatoshis: number;
  isSufficientHashrateAllocated: boolean;
}

export enum UserTypes {
  Admin = "Admin",
  Miner = "Miner",
  LP = "LP",
}

export enum OnboardingStatus {
  EntrypointPending = "Entrypoint pending",
  EntrypointSubmitted = "Entrypoint submitted",
  KYCSubmittedCompany = "KYC submitted company",
  KYCPending = "KYC pending",
  KYCSubmittedRepresentative = "KYC submitted representative",
  KYMSubmittedGeneral = "KYM submitted general",
  KYMPending = "KYM pending",
  FullyOnboarded = "Fully onboarded",
  Susppended = "Susppended",
}

export enum OnboardingStatusAdminPanel {
  None = "None",
  EntrypointPending = "Entrypoint Pending",
  EntrypointApproved = "Entrypoint Approved",
  EntrypointRejected = "Entrypoint Rejected",
  KYCAMLNotFullfilled = "KYC/AML Not Fullfilled",
  KYCAMLPending = "KYC/AML Pending",
  KYCAMLApproved = "KYC/AML Approved",
  KYCAMLRejected = "KYC/AML Rejected",
  KYMNotFullfilled = "KYM Not Fullfilled",
  KYMPending = "KYM Pending",
  KYMApproved = "KYM Approved",
  KYMRejected = "KYM Rejected",
  Onboarded = "Onboarded",
  Suspended = "Suspended",
}

export interface Role {
  id: string;
  createdAt: string;
  updatedAt: string;
  version: number;
  role: string;
}

export interface AccessProposal {
  id: string;
  role: RoleEnum;
  user: User;
  proposer: User;
  approvalStatus: PermissionApprovalStatus;
  accessApproval: AccessApproval[];
}

export interface AccessApproval {
  id: string;
  accessProposal: AccessProposal;
  approvedUser: User;
  rejectedByUser: User;
}

export interface User {
  /** @example 1 */
  id?: string;
  /** @example "John" */
  firstName: string;
  /** @example "Doe" */
  lastName: string;
  /**
   * @format email
   * @example "john@email.com"
   */
  email: string;
  /** @example "MVP Workshop" */
  company: string;
  /** @example "Software developer" */
  companyRole: string;
  /** @example "http://example.com" */
  homepageUrl?: string;
  /** @example "Serbia" */
  location: string;
  userType?: UserTypes;
  onboardingStatus?: OnboardingStatus;
  onboardingStatusAdminPanel?: OnboardingStatusAdminPanel;
  /** @example "bc1qqyjfe8ec533qfvjh98mk9h0k2r5gxdz7f0mr35" */
  walletAddress?: string;
  /** @example 3.1 */
  balanceSatoshis?: number;
  /** @example 42345 */
  balanceUsd?: number;
  /** @example 0.5 */
  reservedBalanceSatoshis?: number;
  /** @example 31236 */
  reservedBalanceUsd?: number;
  whitelistedAddresses?: WalletAddress[];
  /** @format date */
  registeredDate?: string;
  maximumSpendableSatoshis?: number;
  permissions?: string[];
  roles?: Role[];
  enterpriseSettings?: OrganizationEnterpriseSettingsDto;
}

enum PermissionApprovalStatus {
  Open = "Open",
  Approved = "Approved",
  Rejected = "Rejected",
}

enum LiquidityDuration {
  Lt6 = "lt6",
  Value6To12 = "6to12",
  Value12To18 = "12to18",
  Gt18 = "gt18",
}

enum LiqudityTimeHorizon {
  Lt1 = "lt1",
  Value1To6 = "1to6",
  Value6To2 = "6to2",
  Gt2 = "gt2",
}

export type Miner = User & {
  /** @example 100 */
  miningCapacity: number;
  isEncumbered?: boolean;
  percentEncumbrance?: "from1to25" | "from25to50" | "from50to75" | "from75to99" | "full100";
  energyMix: EnergyMix;
  avgEnergyPrice: number;
  energyCostsFixed: boolean;
  liquidityAgreementDuration: LiquidityDuration;
  isHosted?: boolean;
  percentage?: number;
  hostingProvider?: string;
  miningSitesSelfHosted?: SelfOperatedMiningSite[];
  miningSitesThirdParty?: ThirdpartyHostingProvider[];
  /** @format date */
  dateOfIncorporation?: string;
  /** @example 2126664.22 */
  totalRevenue?: number;
  /** @example 100 */
  capacityOverSites?: number;
  /** @example 100 */
  operatingMachines?: number;
  /** @example "CEO Name, CFO Name,  COO Name" */
  management?: string;
  /** @example 500 */
  totalSelfMiningCapacity?: number;
  /** @example 30 */
  averageHardwareEfficiency?: number;
  entrypointForm?: any;
  financialHealthRate: "LOW" | "MEDIUM" | "HIGH" | null;
  operationalHealthRate: "LOW" | "MEDIUM" | "HIGH" | null;
  sustainabilityRate: "LOW" | "MEDIUM" | "HIGH" | null;
};

type MinerDto = Miner;

export type LP = User & {
  AUMMillionUSD?: number;
  portionOfBitcoinAssets?: number;
  projectedAllocation?: string;
  timeHorizonOfLiquidity: LiqudityTimeHorizon;
};

type LPDto = LP;

interface ContactDetailsDto {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly cellphone: string;
}

interface PrimaryContactDto {
  primaryContactKind: "signup-user" | "another-person" | "notsure";
  readonly hasPowerOfAttorney: "yes" | "no" | "notsure";
  readonly reference?: ContactDetailsDto;
}

export interface RegisterLpDto {
  readonly user: ContactDetailsDto;
  readonly companyName: string;
  readonly companyRole: string;
  readonly homepageUrl?: string;
  readonly companyCountryOfIncorporation: string;
  readonly isPubliclyListed: boolean;
  readonly primaryContact: PrimaryContactDto;
  /** @min 0 */
  readonly aumUsdMillions?: number;
  /** @min 0 */
  readonly bitcoinAssetsUnderManagement?: number;
  /** @min 0 */
  readonly projectedBitcoinInvestmentSize?: number;
  /** @min 0 */
  readonly timeHorizonOfLiquidity: "notsure" | "lt1" | "1mto3m" | "3mto6m" | "6mto1y" | "gt1y";
}

export interface RegisterMinerDto {
  user: ContactDetailsDto;
  companyName: string;
  companyRole: string;
  homepageUrl?: string;
  companyCountryOfIncorporation: string;
  isPubliclyListed: boolean;
  annualReportUrl?: string;
  primaryContact: PrimaryContactDto;
  primaryEnergySource?: string;
  /** @min 0 */
  miningCapacityPhPerSecond: number;
  /** @min 0 */
  averageEnergyPriceUsdPerMWh?: number;
  timeHorizonOfLiquidity: "notsure" | "lt1" | "1mto3m" | "3mto6m" | "6mto1y" | "gt1y";
}

interface ResOnboardingDto {
  id: string;
}

interface KYM {
  /** @example "Company" */
  companyLegalName?: string;
  /**
   * @format email
   * @example "john@email.com"
   */
  email?: string;
  balanceSheet?: string;
  balanceSheetFiles?: UploadedFiles;
  cashFlowStatement?: string;
  cashFlowStatementFiles?: UploadedFiles;
  incomeStatement?: string;
  incomeStatementFiles?: UploadedFiles;
  auditEngagementLetter?: string;
  auditEngagementLetterFiles?: UploadedFiles;
  outstandingLiabilities?: string;
  outstandingLiabilitiesFiles?: UploadedFiles;
  /** @example "auth0|63ab4fdd670077b1b9aa96af" */
  userId?: string;
}

interface BtcMiningSite {
  /** @example "Site" */
  siteName?: string;
  /** @example "Belgrade" */
  siteActivities?: string;
  /** @example 3 */
  instaledCapacity?: number;
  /** @example 100 */
  capacityEncumberce?: number;
  /** @example "BG Financies" */
  buildOuts?: string;
  /** @format date */
  goliveDate?: string;
  insurance?: string;
}

interface BtcMiningCapacity {
  /** @example 2 */
  installedHashrate?: number;
  /** @example 2 */
  currentSelfminingShare?: number;
  /** @example 2 */
  futureHashrate?: number;
  /** @example 2 */
  futureSelfminingPercentage?: number;
}

export interface EnergyMix {
  /** @default 0 */
  Solar?: number;
  /** @default 0 */
  Wind?: number;
  /** @default 0 */
  Coal?: number;
  /** @default 0 */
  Hydro?: number;
  /** @default 0 */
  Nuclear?: number;
  /** @default 0 */
  "Flared gas"?: number;
  /** @default 0 */
  "Natural gas"?: number;
  /** @default 0 */
  Oil?: number;
  /** @default 0 */
  "Grid mix"?: number;
}

interface PowerPurchaseAgreements {
  signedPPA?: string;
  signedPPAFiles?: UploadedFiles;
  /** @example 12 */
  duration?: number;
  /** @example 2 */
  capacity?: number;
  performanceStandard?: string;
  priceRisks?: string;
  /** @example 2 */
  minPowerTakeout?: number;
  powerBills?: string;
  powerBillsFiles?: UploadedFiles;
}

interface HostingAgreementsMiners {
  hostAgreements?: string;
  hostAgreementsFiles?: UploadedFiles;
  /** @example 20 */
  prepayementReceived?: number;
}

interface HardwareCapacity {
  /** @example 20 */
  mashines38?: number;
  /** @example 30 */
  machines38to68?: number;
  /** @example 50 */
  machines68?: number;
  listOfMachines?: string;
  listOfMachinesFiles?: UploadedFiles;
  hashrateReports?: string;
  hashrateReportsFiles?: UploadedFiles;
  minerUptimeStats?: string;
  minerUptimeStatsFiles?: UploadedFiles;
  invoiceForFutureFleet?: string;
  invoiceForFutureFleetFiles?: UploadedFiles;
}

interface FinancialInformation {
  PandL?: string;
  PandLFiles?: UploadedFiles;
  balanceSheet?: string;
  balanceSheetFiles?: UploadedFiles;
  cashFlowStatement?: string;
  cashFlowStatementFiles?: UploadedFiles;
  auditEngagementLetter?: string;
  auditEngagementLetterFiles?: UploadedFiles;
  outstandingLiabilities?: string;
  outstandingLiabilitiesFiles?: UploadedFiles;
}

interface MiningPoolData {
  /** @example "bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq" */
  miningRewardAddress?: string;
  /** @example "http://example.com" */
  watcherLink?: string;
  screenshot?: string;
  screenshotFiles?: UploadedFiles;
  other?: string;
  otherFiles?: UploadedFiles;
}

interface HostingAgreementsProviders {
  hostAgreements?: string;
  hostAgreementsFiles?: UploadedFiles;
  /** @example 2 */
  capacity?: number;
  /** @example 2 */
  allInHostingCost?: number;
  /** @example 2 */
  timeline?: number;
  /** @example 2 */
  prepayementSent?: number;
  /** @example 2 */
  minPowerTakeout?: number;
  powerBills?: string;
  powerBillsFiles?: UploadedFiles;
}

enum HostedOrSelfHosted {
  Hosted = "Hosted",
  SelfHosted = "Self-hosted",
}

interface MiningSitesCreateOpportunityMissingFields {
  hostedOrSelfHosted?: HostedOrSelfHosted;
  /** @example 35 */
  energyCosts?: number;
  /** @example 100 */
  energyCapacity?: number;
  /** @example 30 */
  averageHardwareEfficiency?: number;
  /** @format date */
  hardwareLiveSinceDate?: string;
  /** @example 200 */
  selfMiningCapacity?: number;
  /** @example "Norway" */
  location?: string;
}

type SelfOperatedMiningSite = {
  /** @example "46f8b692-9be7-11ed-a8fc-0242ac120002" */
  id: string;
  /**
   * @format email
   * @example "demo@example.com"
   */
  email?: string;
  energyMix?: EnergyMix;
} & MiningSitesCreateOpportunityMissingFields &
  BtcMiningSite &
  BtcMiningCapacity &
  PowerPurchaseAgreements &
  HostingAgreementsMiners &
  HardwareCapacity &
  FinancialInformation &
  MiningPoolData;

type ThirdpartyHostingProvider = {
  /** @example "46f8b692-9be7-11ed-a8fc-0242ac120002" */
  id: string;
  energyMix?: EnergyMix;
} & MiningSitesCreateOpportunityMissingFields &
  BtcMiningSite &
  HostingAgreementsProviders &
  HardwareCapacity &
  FinancialInformation &
  MiningPoolData;

type KYMDto = KYM;

export interface ResUploadedFileDto {
  id: string;
  filename: string;
}

export interface ResKymFinancialsDto {
  organizationId: string;
  balanceSheetFiles: ResUploadedFileDto[];
  cashFlowStatementFiles: ResUploadedFileDto[];
  incomeStatementFiles: ResUploadedFileDto[];
  auditEngagementLetterFiles: ResUploadedFileDto[];
  outstandingLiabilitiesFiles: ResUploadedFileDto[];
}

export interface FileIdDto {
  id: string;
}

export interface ReqNewFileDto {
  name: string;
  base64: string;
}

interface ReqUpdateFilesDto {
  existingFiles: FileIdDto[];
  newFiles: ReqNewFileDto[];
}

export interface ReqUpdateKymFinancialsDto {
  balanceSheetFiles: ReqUpdateFilesDto;
  cashFlowStatementFiles: ReqUpdateFilesDto;
  incomeStatementFiles: ReqUpdateFilesDto;
  auditEngagementLetterFiles: ReqUpdateFilesDto;
  outstandingLiabilitiesFiles: ReqUpdateFilesDto;
}

interface ResUpdateDto {
  kind: boolean;
}

export interface UpdateKymHostingDto {
  hostsOtherMiners: boolean;
  totalCurrentHostedCapacityPhPerS?: number;
  expectedCapacityIn12MonthsMPhPerS?: number;
  averageHostingPricePerClientUsdPerMWh?: number;
  priceHedgingStrategy?: string;
}

export interface KymAsicDto {
  /** @min 0 */
  averageFleetEfficiencyJPerTh: number;
  machineListFiles: ResUploadedFileDto[];
  /**
   * @min 0
   * @max 100
   */
  averageAsicPurchasePriceUsdPerTh?: number;
  machineInvoiceFiles: ResUploadedFileDto[];
  /**
   * @min 0
   * @max 100
   */
  asicUptimePercentage?: number;
  minerUptimeStatsFiles: ResUploadedFileDto[];
  /**
   * @min 0
   * @max 100
   */
  capacityEncumbrancePercentage: number;
  hasInsurance: boolean;
  insuranceDescription?: string;
}

interface ResKymAsicDto {
  organizationId: string;
  payload?: KymAsicDto;
}

export interface ReqUpdateKymAsicDto {
  /** @min 0 */
  averageFleetEfficiencyJPerTh: number;
  machineListFiles: ReqUpdateFilesDto;
  /**
   * @min 0
   * @max 100
   */
  averageAsicPurchasePriceUsdPerTh?: number;
  machineInvoiceFiles: ReqUpdateFilesDto;
  /**
   * @min 0
   * @max 100
   */
  asicUptimePercentage?: number;
  minerUptimeStatsFiles: ReqUpdateFilesDto;
  /**
   * @min 0
   * @max 100
   */
  capacityEncumbrancePercentage: number;
  hasInsurance: boolean;
  insuranceDescription?: string;
}

interface OpportunitiesSummary {
  /** @example 0 */
  streamingRewards?: number;
  aggregatedFees24h?: AggregatedFees;
  aggregatedFees30d?: AggregatedFees;
}

interface AggregatedFees {
  /** @example 0.004321 */
  btc?: number;
  /** @example 100 */
  usd?: number;
}

interface PlatformDetails {
  /** @example 400 */
  totalHashrate?: number;
  /** @example 100.03 */
  totalValueLocked?: AggregatedFees;
}

interface OpportunitiesSummaryDto {
  opportunitiesSummary?: OpportunitiesSummary;
  platformDetails?: PlatformDetails;
}

export interface LpAgreementOpportunityDto {
  id: string;
  opportunityId: string;
  opportunityNumId: number;
  approvalStatus:
    | "Awaiting agreement signature"
    | "Awaiting transaction approval"
    | "Pending transaction"
    | "Approved"
    | "Rejected";
  companyName: string;
  amountSatoshis: number;
}

interface LpAgreementOpportunitiesPageDto {
  count: number;
  rows: LpAgreementOpportunityDto[];
}

export type LoanBasic = object;

export type LeaseBasic = object;

export interface PreviewMinerBasic {
  id: string;
  name: string;
  homepageUrl?: string;
  /** @format date-time */
  dateOfIncorporation?: string;
  countryOfIncorporation?: string;
  miningCapacityPh?: number;
  energyMix?: EnergyMixDTO;
  financialHealthRating?: "LOW" | "MEDIUM" | "HIGH";
  operationalHealthRating?: "LOW" | "MEDIUM" | "HIGH";
  sustainabilityRating?: "LOW" | "MEDIUM" | "HIGH";
}
export interface PreviewStreamingOpportunityDataDto {
  id: string;
  miner: PreviewMinerBasic;
  description?: string;
  principalSatoshis?: number;
  principalDesc?: string;
  hashrateThPerSecond?: number;
  hashrateDesc?: string;
  /** @format date-time */
  startDate?: string;
  durationDays?: number;
  durationDesc?: string;
  forecastRewards?: number;
  managementFeePercentage?: number;
  collateralType?: string;
  lpInterestRate?: number;
}

export interface PreviewLoanDataDto {
  id: string;
  miner: PreviewMinerBasic;
  description?: string;
  principalSatoshis?: number;
  principalDesc?: string;
  /** @format date-time */
  startDate?: string;
  durationDays?: number;
  durationDesc?: string;
  totalReturn?: number;
  lpInterestRate?: number;
  collateralType?: string;
}

export interface PreviewLeaseDataDto {
  id: string;
  miner: PreviewMinerBasic;
  description?: string;
  principalSatoshis?: number;
  principalDesc?: string;
  /** @format date-time */
  startDate?: string;
  durationDays?: number;
  durationDesc?: string;
  totalReturn?: number;
  lpInterestRate?: number;
  collateralType?: string;
}

export interface LPOpportunitiesDto {
  streamingOpportunities: OpportunitiesDetails[];
  loanOpportunities: LoanBasic[];
  leaseOpportunities: LeaseBasic[];
  previewStreamingOpportunities: PreviewStreamingOpportunityDataDto[];
  previewLoanOpportunties: PreviewLoanDataDto[];
  previewLeaseOpportunities: PreviewLeaseDataDto[];
  count: number;
}

export interface OpportunityStatusDto {
  status: OpportunityStatus;
  collateralBalance?: {
    /** @example 2.2 */
    current?: number;
    /** @example 2.2 */
    maximum?: number;
    /** @example 22 */
    additionalRequired?: number;
  };
}

export interface ReqProcessDemoOpportunityEvents {
  opportunityId: string;
  payload: object;
}

interface ResDemoFunding {
  id: string;
  lpId: string;
  amountSatoshi: number;
  /** @format date-time */
  fundingDate: string;
  companyName: string;
  email: string;
}

interface ResDemoOpportunityFundings {
  entries: ResDemoFunding[];
}

export interface ResDemoCollateral {
  id: string;
  amountSatoshi: number;
  date: string;
  transactionType: "Collateral Release" | "Collateral Deposit" | "Collateral Liquidation";
}

interface ResDemoOpportunityCollaterals {
  entries: ResDemoCollateral[];
}

interface ResDemoRewardDistribution {
  id: string;
  lpId: string;
  amountSatoshi: number;
  /** @format date-time */
  date: string;
  lpCompanyName: string;
  lpEmail: string;
}

export interface ResDemoReward {
  rewardId: string;
  amountSatoshi: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  status: "Unprocessed" | "Awaiting collateral liquidation" | "Awaiting confirmations" | "Processed" | "Failed";
  distributions: ResDemoRewardDistribution[];
}

interface ResDemoOpportunityRewards {
  entries: ResDemoReward[];
}

export interface ResDemoExcessRewardTransaction {
  id: string;
  amountSatoshi: number;
  date: string;
}

interface ResDemoExcessReward {
  value?: ResDemoExcessRewardTransaction;
}

export enum YesOrNoChoice {
  Yes = "yes",
  No = "no",
}

export enum OpportunityStatus {
  AwaitingCollateral = "Awaiting Collateral",
  AwaitingFunding = "Awaiting Funding",
  Draft = "Draft",
  Active = "Active",
  Defaulted = "Defaulted",
  Cancelled = "Cancelled",
  Settled = "Settled",
  Closed = "Closed",
}

export type EnergyMixDTO = object;

interface LiquidityReceived {
  current: number;
  total: number;
}

export interface StreamingBasic {
  /** @example "46f8b692-9be7-11ed-a8fc-0242ac120002" */
  id: string;
  /** @example 1 */
  idNum: number;
  status?: OpportunityStatus;
  miner: Miner;
  /** @example 1 */
  currentHashrate?: number;
  liquidityReceived?: LiquidityReceived;
  /** @format date */
  fundingDeadline?: string;
  /** @example "30 days" */
  remainingDuration?: string;
  /** @format date */
  activeSince?: string;
  /** @format date */
  settlementDate?: string;
  agreementEndDate?: string;
  isDemo?: boolean;
}

export interface OpportunitiesDto {
  rows: StreamingBasic[];
  count: number;
}

export interface PatchOpportunityStatusDto {
  status:
    | "Awaiting Collateral"
    | "Awaiting Funding"
    | "Draft"
    | "Active"
    | "Defaulted"
    | "Cancelled"
    | "Settled"
    | "Closed";
}

export interface OpportunitiesDetails {
  id: string;
  durationOfAgreement: number;
  opportunityBasic: StreamingBasic;
  expectedReturn: number;
  maxAmountOfLiquidity?: number;
  dataroomLink?: string;
  rewardAddress?: string;
  collateralAddress?: string;
  description: string;
  collateralStatus: "Sufficient" | "Under sufficient";
  collateralBalance?: number;
  /** @format date-time */
  fundingDeadline: string;
  partnership?: boolean;

  maximumCapacity?: number;
  currentDeposits?: number;
  energyMix?: EnergyMix;
  fullHashrateThPerSecond: number;
  requiredCollateralSatoshiPerOutstandingTh?: number | null;

  partnershipName?: string;
  protocolWallets: ProtocolWallet[];
  initialCollateral: number;
  historicalUptime: number | null;
  forecastRewards: number;
  structuringFeePercentage: number;
  managementFeePercentage: number;
  synHashPoolAddress: string;
  opportunityCapacity: number;
}

interface OpportunitiesMinerSummaryDto {
  /** @example 400 */
  allocatedHashRate?: number;
  currentCollateralLocked?: {
    /** @example 15.03 */
    btc?: number;
    /** @example 249566.24 */
    usd?: number;
  };
  allTimeLiquidityReceived?: {
    /** @example 15.23 */
    btc?: number;
    /** @example 265763.5 */
    usd?: number;
  };
}

export interface OrganizationEnterpriseSettingsDto {
  organizationId?: string;
  requiredWhitelistApprovers: number;
  requiredAccessApprovers: number;
}

export type OpportunityCreationDto = {
  sites?: (MiningSitesCreateOpportunityMissingFields & {
    /** @example "46f8b692-9be7-11ed-a8fc-0242ac120001" */
    siteId?: string;
  })[];
} & {
  /** @example "46f8b692-9be7-11ed-a8fc-0242ac120003" */
  minerId: string;
  /** @example 5 */
  requiredHashPowerFor100perc: number;
  /** @example 2 */
  initialCollateral: number;
  /** @example 100 */
  maximumAmountOfLiquidity: number;
  /** @format date-time */
  fundingDeadline: string;
  /** @example 360 */
  durationOfAgreement: number;
  /** @default false */
  partnership?: boolean;
  /** @example "My first partner" */
  partnershipLabel?: string;
  /** @example "Lorem ipsum" */
  description: string;
  structuringFeePercentage: number;
  managementFeePercentage: number;
};

export interface ReqCreateOpportunityV2Dto {
  minerId: string;
  /** @format date-time */
  demoStartDate?: string;
  /**
   * @min 1
   * @max 100
   */
  requiredHashPowerForCompletionPhPerS: number;
  /**
   * @min 1
   * @max 100
   */
  initialCollateralPercentage: number;
  maximumAmountOfLiquidityBtc: number;
  /** @format date-time */
  fundingDeadline: string;
  durationOfAgreementDays: number;
  /**
   * @min 0
   * @max 100
   */
  expectedReturnPercentage: number;
  /**
   * @min 0
   * @max 100
   */
  structuringFeePercentage: number;
  /**
   * @min 0
   * @max 100
   */
  managementFeePercentage: number;
}

interface ResCreateOpportunityV2Dto {
  id: string;
}

export interface ReqUpdateOpportunityV2Dto {
  opportunityId: string;
  /** @min 0 */
  requiredHashPowerForCompletionPhPerS: number;
  /**
   * @min 1
   * @max 100
   */
  initialCollateralPercentage: number;
  maximumAmountOfLiquidityBtc: number;
  /** @format date-time */
  fundingDeadline: string;
  durationOfAgreementDays: number;
  /**
   * @min 0
   * @max 100
   */
  expectedReturnPercentage: number;
  /**
   * @min 0
   * @max 100
   */
  structuringFeePercentage: number;
  /**
   * @min 0
   * @max 100
   */
  managementFeePercentage: number;
}

interface ResOpportunityWalletInfoDto {
  /** @min 0 */
  walletBalance: number;
  collateralAddress?: string;
  rewardAddress?: string;
  synHashPoolAddress?: string;
}

export interface ResOpportunityDetailsV2Dto {
  minerId: string;
  minerCompanyName: string;
  opportunity: ReqUpdateOpportunityV2Dto;
  /** @format date-time */
  creationDate: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  publishDate?: string;
  /** @format date-time */
  activeFromDate?: string;
  /** @format date-time */
  cancellationDate?: string;
  /** @format date-time */
  settlementDate?: string;
  /** @format date-time */
  fullyFundedDate?: string;
  /** @format date-time */
  endDate?: string;
  isDemo?: boolean;
  maxFundingSatoshi: number;
  status:
    | "Awaiting Collateral"
    | "Awaiting Funding"
    | "Draft"
    | "Active"
    | "Defaulted"
    | "Cancelled"
    | "Settled"
    | "Closed";
  collateralStatus: "Sufficient" | "Under sufficient";
  walletInfo: ResOpportunityWalletInfoDto;
}

export interface ReqUpdateOpportunityV2Dto {
  opportunityId: string;
  /** @min 0 */
  requiredHashPowerForCompletionPhPerS: number;
  /**
   * @min 1
   * @max 100
   */
  initialCollateralPercentage: number;
  maximumAmountOfLiquidityBtc: number;
  /** @format date-time */
  fundingDeadline: string;
  durationOfAgreementDays: number;
  /**
   * @min 0
   * @max 100
   */
  expectedReturnPercentage: number;
  /**
   * @min 0
   * @max 100
   */
  structuringFeePercentage: number;
  /**
   * @min 0
   * @max 100
   */
  managementFeePercentage: number;
}

export enum CollateralStatus {
  Sufficient = "Sufficient",
  UnderSufficient = "Under sufficient",
}

export enum TransactionStatus {
  Pending = "Pending",
  AwaitingApproval = "Awaiting approval",
  Confirmed = "Confirmed",
}

interface TransactionCommon {
  /** @example "f96938422f3e000908b0ab28962abf830a1d300a17b4d101e94dce0374877ecb" */
  txId?: string;
  /** @example 1 */
  opportunityId?: number;
  /** @format date-time */
  datetime?: string;
  /** @example "bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq" */
  fromAddr?: string;
  /** @example "bc1qqyjfe8ec533qfvjh98mk9h0k2r5gxdz7f0mr35" */
  toAddr?: string;
  /** @example 0.5 */
  amountBtc: number;
  /** @example 8230 */
  amountUsd: number;
  status: TransactionStatus | TransactionAwaitingApprovalsStatus;
  /** @example "https://www.blockchain.com/explorer/transactions/btc/c71a5ea14ca24608feac3ae2895549b7f943cefa971b8ed06816cd55b1f3a6ab" */
  txLink?: string;
}

type TransactionsDto = TransactionCommon[];

export enum TransactionHistoryMinerOrLpType {
  Withdrawal = "Withdrawal",
  Deposit = "Deposit",
  CollateralReleased = "Collateral Released",
  CollateralDeposited = "Collateral Deposited",
  RewardDelivery = "Reward Delivery",
  LiquidityDelivery = "Liquidity Delivery",
  ExcessRewardReleased = "Excess Reward Released",
  RewardDistribution = "Reward Distribution",
  OpportunityFunding = "Opportunity Funding",
  LiquidityRefund = "Liquidity Refund",
}

enum TransactionHistoryMinerType {
  Withdrawal = "Withdrawal",
  Deposit = "Deposit",
  CollateralRelease = "Collateral Release",
  CollateralDeposited = "Collateral Deposit",
  RewardDelivery = "Reward Delivery",
  LiquidityDelivery = "Liquidity Delivery",
  ExcessRewardRelease = "Excess Reward Release",
}

type TransactionHistoryMiner = TransactionCommon & {
  transactionType: TransactionHistoryMinerType;
};

enum TransactionHistoryLpType {
  Withdrawal = "Withdrawal",
  Deposit = "Deposit",
  RewardDelivery = "Reward Delivery",
  OpportunityFunding = "Opportunity Funding",
  LiquidityRefund = "Liquidity Refund",
}

type TransactionHistoryLP = TransactionCommon & {
  transactionType: TransactionHistoryLpType;
};

type TransactionHistoryMinerOrLp = TransactionCommon & {
  transactionType: TransactionHistoryMinerOrLpType;
  trancheId?: string;
};

export enum TransactionAwaitingApprovalsStatus {
  AwaitingApproval = "Awaiting approval",
  Approved = "Approved",
  Rejected = "Rejected",
  ApprovalNotRequired = "Approval not required",
}

export enum ActionType {
  REJECT = "reject",
  APPROVE = "approve",
}

interface OverviewHistoryTransactionsLpDto {
  count: number;
  rows: TransactionHistoryLP[];
}

interface OrganizationUserDto {
  count: number;
  rows: User[];
}

interface AccessProposalDto {
  count: number;
  rows: AccessProposal[];
}

export interface AcceptProposalDto {
  accessProposalId: string;
  actionType: ActionType;
}

export interface CreateAccessProposalDto {
  role: RoleEnum;
  userId: string;
}

export interface RemoveRoleDto {
  role: RoleEnum;
  userId: string;
}

interface OverviewHistoryTransactionsMinerDto {
  count: number;
  rows: TransactionHistoryMiner[];
}

export interface WithdrawDto {
  /** @example "bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq" */
  address?: string;
  /** @example 70.26 */
  amount?: number;
  note?: string;
}

interface WithdrawInitiatedDto {
  from?: string;
  to?: string;
  /** @format date-time */
  datetime?: string;
  receivingAmountBtc?: number;
  receivingAmountUsd?: number;
  transactionFeeBtc?: number;
  transactionFeeUsd?: number;
  totalWithdrawalBtc?: number;
  totalWithdrawalUsd?: number;
  status?: TransactionStatus;
  note?: string;
  txHash?: string;
  url?: string;
}

export enum WalletAddressWhitelistStatus {
  WaitingForApproval = "Waiting for approval",
  Approved = "Approved",
  Removed = "Removed",
}

export interface WalletAddress {
  id?: string;
  /** @example "bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq" */
  address?: string;
  /** @example "BTC Address 1" */
  name?: string;
  status?: WalletAddressWhitelistStatus;
}

export enum SortFirstActionRequired {
  Yes = "yes",
  No = "no",
}

export enum DataRoomStatus {
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
}

type DataRoom = {
  /** @example "46f8b692-9be7-11ed-a8fc-0242ac120002" */
  id?: string;
  /** @example "http://dataroom.com/mydocument.pdf" */
  linkToDataroom?: string;
  status?: DataRoomStatus;
  lp?: LP;
};

interface DataRoomDto {
  /** @example 0 */
  count?: number;
  rows?: DataRoom[];
}

interface NotificationReceiver {
  /** @example "uuid-123" */
  id?: string;
  /** @example "All miners" */
  value?: string;
}

type NotificationReceiversDto = NotificationReceiver[];

export enum NotificationPriority {
  High = "High",
  Medium = "Medium",
  Low = "Low",
}

export interface Notification {
  id: string;
  /** @example "Notification title" */
  title: string;
  receiver?: NotificationReceiver;
  priority: NotificationPriority;
  actionable: YesOrNoChoice;
  /** @example "http://someactionlink.com" */
  link?: string;
  /** @example "Lorem ipsum" */
  description: string;
  readAt: string | null;
  createdAt: string;
}

export type NotificationDto = Notification;

interface NotificationsDto {
  /** @example 1 */
  count?: number;
  rows?: NotificationDto[];
}

interface UploadedFile {
  name?: string;
  url?: string;
}

type UploadedFiles = UploadedFile[];

interface PortfolioChart {
  /** @example "46f8b692-9be7-11ed-a8fc-0242ac120002" */
  minerId?: string;
  /** @example "46f8b692-9be7-11ed-a8fc-0242ac120002" */
  id?: string;
  /** @example "hsl(39, 70%, 50%)" */
  color?: string;
  /** @example 2023 */
  year?: string;
  data?: {
    /** @example "January" */
    x?: string;
    /** @example 0.5 */
    y?: number;
  }[];
}

interface AdminOpportunityHeroesDto {
  /** @example 1.01 */
  expectedReturn?: number;
  liquidityReceived?: {
    /** @example 0.01 */
    btc?: number;
    /** @example 100 */
    usd?: number;
  };
  hashRateAllocated?: {
    /** @example 40 */
    current?: number;
    /** @example 100 */
    maximum?: number;
  };
}

export enum ProtocolWalletType {
  SynHashPool = "SynHash Pool",
  CollateralVault = "Collateral Vault",
  RewardVault = "Reward Vault",
}

interface OpportunitiesFundedDto {
  /** @example 0 */
  count?: number;
  rows?: {
    /** @example 1 */
    trancheIdNum?: number;
    /** @example "uuid-123" */
    trancheId?: string;
    /** @example "Jonh Wayne" */
    minerName?: string;
    /** @example "uuid-123" */
    minerId?: string;
    /** @format date-time */
    endDate?: string;
    hashPower?: {
      /** @example 40 */
      current?: number;
      /** @example 100 */
      maximum?: number;
    };
    opportunityStatus?: OpportunityStatus;
    /** @example null */
    rewardAddress?: string;
    fundedAmountBtc?: number;
    fundedAmountUsd?: number;
    opportunityId: string;
    opportunityIdNum: number;
  }[];
}

export enum FundingApprovalStatus {
  AwaitingAgreementSignature = "Awaiting agreement signature",
  AwaitingTransactionApproval = "Awaiting transaction approval",
  PendingTransaction = "Pending transaction",
  Approved = "Approved",
  Rejected = "Rejected",
}

export interface OpportunityFunding {
  id?: string;
  createdAt: string;
  lp?: LP;
  opportunity?: Opportunity;
  amountSatoshis?: string;
  approvalStatus?: FundingApprovalStatus;
}

export interface ResLoanPayment {
  /** @format date-time */
  date: string;
  expectedFromMinerSatoshis: number;
  forLPSatoshis: number;
  totalExpectedFromMinerSoFar: number;
  totalForLPSoFar: number;
}

export interface ResLoanPaymentRequestDto {
  id: string;
  amountSatoshi: number;
  toAddress: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  executeAfter: string;
  /** @format date-time */
  executedAt?: string;
  isFailed: boolean;
  /** @format date-time */
  confirmedAt?: string;
  /** @format date-time */
  transactionHash?: string;
  destination: "LP" | "Miner" | "Admin";
}

export interface ResLoanAuditTransactionDto {
  id: string;
  amountSatoshi: number;
  toAddresses: string;
  fromAddresses: string;
  /** @format date-time */
  createdAt: string;
  transactionHash?: string;
}

export interface ResLoanAssociatedValidAddress {
  address: string;
  bitgoWalletId?: string;
  name: string;
  addressType: "LpUserWallet" | "LpApprovedAddress" | "MinerUserWallet" | "MinerApprovedAddress";
}

export interface ResLoanMinerDelivery {
  id: string;
  /** @format date-time */
  createdAt: string;
  fromAddresses: string[];
  status: "Pending" | "Confirmed";
  transactionHash: string;
  amountSatoshi: number;
}

export interface ResLoanPayment {
  /** @format date-time */
  date: string;
  expectedFromMinerSatoshis: number;
  forLPSatoshis: number;
  totalExpectedFromMinerSoFar: number;
}

export interface ResBalanceInfoSatoshi {
  totalDeliveries: number;
  confirmedDeliveries: number;
  totalPaymentsToLp: number;
  confirmedPaymentsToLp: number;
  totalPaymentsToMiner: number;
  confirmedPaymentsToMiner: number;
  totalPaymentsToOther: number;
  confirmedPaymentsToOther: number;
  minAvailableBalance: number;
  recommendedMinerPayment: number;
  recommendedLpPayment: number;
}

export interface ReqLoanPayment {
  date: string;
  expectedFromMinerSatoshis: number;
  forLPSatoshis: number;
}

export interface ResAdminLoanDto {
  id: string;
  minerId: string;
  isDemo: boolean;
  minerName: string;
  principalAmountSatoshi: number;
  durationDays: number;
  daysPerYear: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  cancelledAt?: string;
  /** @format date-time */
  completedAt?: string;
  rewardAddress?: string;
  /** @format date-time */
  startedAt?: string;
  status: "Draft" | "Active" | "UnderDelivered" | "Cancelled" | "Completed";
  expectedPayments: ResLoanPayment[];
  minerDeliveries: ResLoanMinerDelivery[];
  isSigned: boolean;
  lpId: string;
  lpName: string;
  rewardBitgoWalletId?: string;
  outgoingPayments: ResLoanPaymentRequestDto[];
  auditTransactions: ResLoanAuditTransactionDto[];
  addresses: ResLoanAssociatedValidAddress[];
  balanceInfoSatoshi: ResBalanceInfoSatoshi;
  calculationKind:
    | "DAILY_PRINCIPAL_30D_PAYMENT"
    | "DAILY_PRINCIPAL_MONTHLY_PAYMENT"
    | "DAILY_PRINCIPAL_DAILY_PAYMENT"
    | "DAILY_INTEREST_30D_PAYMENT"
    | "DAILY_INTEREST_MONTHLY_PAYMENT"
    | "DAILY_INTEREST_DAILY_PAYMENT"
    | "CUSTOM_SCHEDULE";
  minerAnnualInterestRatePercent: number;
  lpAnnualInterestRatePercent: number;
  customSchedule?: ReqLoanPayment[];
}

export interface ResAdminLoans {
  rows: ResAdminLoanDto[];
}

export interface ReqCreateDraftLoanDto {
  minerId: string;
  lpId: string;
  durationDays: number;
}

export interface IdDto {
  id: string;
}

export interface ReqGenerateDemo {
  loanId: string;
  deliveriesCount: number;
  scenario: "OverDelivery" | "UnderDelivery" | "ExactDelivery" | "Random";
}

export interface ResGenerateDemo {
  status: "NotDemo" | "Done" | "CanNoLongerGenerate";
  count: number;
}

export interface ResLoanBitgoBalance {
  rewardBitgoWalletId?: string;
  address?: string;
  balance: number;
  confirmedBalanceSatoshi: number;
  spendableBalanceSatoshi: number;
}

export interface ReqUpdateDraftLoan {
  id: string;
  isDemo: boolean;
  durationDays: number;
  daysPerYear?: number;
  principalAmountSatoshi: number;
  startDate: string;
  isSigned: boolean;
  calculationKind:
    | "DAILY_PRINCIPAL_30D_PAYMENT"
    | "DAILY_PRINCIPAL_MONTHLY_PAYMENT"
    | "DAILY_PRINCIPAL_DAILY_PAYMENT"
    | "DAILY_INTEREST_30D_PAYMENT"
    | "DAILY_INTEREST_MONTHLY_PAYMENT"
    | "DAILY_INTEREST_DAILY_PAYMENT"
    | "CUSTOM_SCHEDULE";
  minerAnnualInterestRatePercent: number;
  lpAnnualInterestRatePercent: number;
  customSchedule?: ReqLoanPayment[];
}

export interface ReqCreatePaymentRequest {
  loanId: string;
  amountSatoshi: number;
  address: string;
  destination: "LP" | "Miner" | "Admin";
  executionDelaySeconds: number;
}

export interface ResCreatePaymentRequest {
  kind: string;
}

export interface ResDeletePaymentRequest {
  status: "Done" | "TooLate";
}

export interface ResMinerLoanDto {
  id: string;
  isDemo: boolean;
  minerId: string;
  minerName: string;
  principalAmountSatoshi: number;
  durationDays: number;
  daysPerYear: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  cancelledAt?: string;
  /** @format date-time */
  completedAt?: string;
  rewardAddress?: string;
  /** @format date-time */
  startedAt?: string;
  status: "Draft" | "Active" | "UnderDelivered" | "Cancelled" | "Completed";
  expectedPayments: ResLoanPayment[];
  minerDeliveries: ResLoanMinerDelivery[];
  calculationKind:
    | "DAILY_PRINCIPAL_30D_PAYMENT"
    | "DAILY_PRINCIPAL_MONTHLY_PAYMENT"
    | "DAILY_PRINCIPAL_DAILY_PAYMENT"
    | "DAILY_INTEREST_30D_PAYMENT"
    | "DAILY_INTEREST_MONTHLY_PAYMENT"
    | "DAILY_INTEREST_DAILY_PAYMENT"
    | "CUSTOM_SCHEDULE";
  minerAnnualInterestRatePercent: number;
  paymentsToMiner: ResLoanPaymentRequestDto[];
}

export interface ResMinerLoans {
  rows: ResMinerLoanDto[];
}

export interface ResLpLoanDto {
  id: string;
  isDemo: boolean;
  minerId: string;
  minerName: string;
  principalAmountSatoshi: number;
  durationDays: number;
  daysPerYear: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  cancelledAt?: string;
  /** @format date-time */
  completedAt?: string;
  rewardAddress?: string;
  /** @format date-time */
  startedAt?: string;
  status: "Draft" | "Active" | "UnderDelivered" | "Cancelled" | "Completed";
  expectedPayments: ResLoanPayment[];
  minerDeliveries: ResLoanMinerDelivery[];
  calculationKind:
    | "DAILY_PRINCIPAL_30D_PAYMENT"
    | "DAILY_PRINCIPAL_MONTHLY_PAYMENT"
    | "DAILY_PRINCIPAL_DAILY_PAYMENT"
    | "DAILY_INTEREST_30D_PAYMENT"
    | "DAILY_INTEREST_MONTHLY_PAYMENT"
    | "DAILY_INTEREST_DAILY_PAYMENT"
    | "CUSTOM_SCHEDULE";
  lpAnnualInterestRatePercent: number;
  paymentsToLp: ResLoanPaymentRequestDto[];
}

export interface ResLpLoans {
  rows: ResLpLoanDto[];
}

type QueryParamsType = Record<string | number, any>;
type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export interface UpdateFundingApprovalStatusDto {
  id?: string;
  status: FundingApprovalStatus;
}

interface BasicLiquidityProviderDto {
  id: string;
  /** @format date-time */
  fundedAt?: string;
  companyName: string;
  amountSatoshi: number;
}

interface ResBasicLiquidityProviders {
  count: number;
  rows: BasicLiquidityProviderDto[];
}

export interface TransferRequestDto {
  amount: string;
  receiverAddress: string;
  fundingId?: string;
}
interface FeeDto {
  fee: number;
}

export interface ResTransactionDto {
  txId: string;
  idNum?: number;
  opportunityId?: string;
  datetime?: string;
  fromAddr: string;
  toAddr: string;
  amountBtc: number;
  amountUsd: number;
  status: "Pending" | "Confirmed";
  txLink: string;
  transactionType:
    | "Reward Distribution"
    | "Opportunity Funding"
    | "Liquidity Refund"
    | "Collateral Release"
    | "Collateral Deposit"
    | "Reward Delivery"
    | "Liquidity Delivery"
    | "Excess Reward Release"
    | "Unknown"
    | "Collateral Liquidation"
    | "Deposit"
    | "Reconciliation"
    | "Withdrawal";
  fromName: string;
  toName: string;
}

interface OverviewHistoryTransactionsDto {
  count: number;
  rows: ResTransactionDto[];
}

export interface ApproveOrRejectData {
  proposalId: string;
  actionType: ActionType;
}

export interface ReqMinerRatingDto {
  minerId: string;
  financialHealthRate: "LOW" | "MEDIUM" | "HIGH";
  operationalHealthRate: "LOW" | "MEDIUM" | "HIGH";
  sustainabilityRate: "LOW" | "MEDIUM" | "HIGH";
}

interface ResMinerRatingDto {
  kind: boolean;
}

interface BlockDto {
  height: number;
  rewardSatoshis: number;
  totalFeesSatoshis: number;
  timestamp: string;
  medianTimestamp: string;
  difficulty: number;
}

interface ResBlocksDto {
  count: number;
  rows: BlockDto[];
}

interface ComputedHashpriceDto {
  createdAt: string;
  day: string;
  effectiveDifficulty: number;
  totalSubsidySatoshis: number;
  totalFeesSatoshis: number;
  fppsHashpriceSatoshisPerTh: number;
  blockCount: number;
}

interface ResComputedHashpricesDto {
  count: number;
  rows: ComputedHashpriceDto[];
}

export interface OpportunityRewardDto {
  rewardId: string;
  createdAt: string;
  /** @min 0 */
  amountSatoshis: number;
  distributionStatus:
    | "Unprocessed"
    | "Awaiting collateral liquidation"
    | "Awaiting confirmations"
    | "Processed"
    | "Failed";
  day: string;
}

interface ResOpportunityRewardsDto {
  count: number;
  rows: OpportunityRewardDto[];
}

export interface DtoInternalTransaction {
  txId: string;
  createdAt: string;
  approvalStatus: "Approval not required" | "Awaiting approval" | "Approved" | "Rejected";
  amountSatoshis: number;
  feeTakenSatoshis: number;
  txHash?: string;
  fromBitgoWalletId: string;
  toBitgoWalletId: string;
}

interface RewardDetailsDto {
  rewardId: string;
  createdAt: string;
  /** @min 0 */
  amountSatoshis: number;
  distributionStatus:
    | "Unprocessed"
    | "Awaiting collateral liquidation"
    | "Awaiting confirmations"
    | "Processed"
    | "Failed";
  day: string;
  distributions: DtoInternalTransaction[];
  liquidations: DtoInternalTransaction[];
}

interface ResRewardDetails {
  payload?: RewardDetailsDto;
}

interface UserDto {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  createdAt: string;
}

interface ResUsersDto {
  count: number;
  rows: UserDto[];
}

export interface ReqAdminUserDto {
  email: string;
  firstName: string;
  lastName: string;
}

interface ResAdminUserDto {
  id: string;
}

export interface ReqExchangeRatesDto {
  timestamps: number[];
}

interface ExchangeRate {
  ticker: string;
  rate: number;
}

interface ResExchangeRatesFromEur {
  rates: ExchangeRate[];
}

interface ResPredictionBreakdownDto {
  rewards: number;
  fees: number;
  subsidy: number;
  networkHashrate: number;
  feesRate: number;
  difficulty: number;
  blocks: number;
  subsidyRate: number;
}

interface ResPredictionsDto {
  date: string;
  prediction: ResPredictionBreakdownDto;
  fifthPercentile: ResPredictionBreakdownDto;
  ninetyfifthPercentile: ResPredictionBreakdownDto;
}

export interface OpportunityFullDataDto {
  opportunityDetails: OpportunitiesDetails;
}

interface ResPredictionsPayload {
  rows: ResPredictionsDto[];
}

interface ResHistoricalreakdownDto {
  rewards: number;
  fees: number;
  subsidy: number;
  networkHashrate: number;
  feesRate: number;
  difficulty: number;
  blocks: number;
  subsidyRate: number;
  date: string;
}

interface ResHistoricalPayload {
  rows: ResHistoricalreakdownDto[];
}

export enum Sort {
  Asc = "asc",
  Desc = "desc",
}

export interface CollateralRequestDto {
  /** @min 1 */
  amount: string;
}

interface TransactionSendDto {
  txId: string;
  url: string;
}

interface CollateralInfoDto {
  currentBalanceSatoshis: number;
  collateralRequirementSatoshis: number;
  missingCollateralSatoshis: number;
  liquidityDeliveryRequirement?: {
    collateralRequirementSatoshis: number;
    additionalHashrateRequiredThPerSecond: number;
    totalHashrateRequiredThPerSecond: number;
    missingCollateralSatoshis: number;
  };
}

interface OrganizationEnterpriseSettings {
  id: string;
  requiredWhitelistApprovers: number;
  requiredAccessApprovers: number;
}

export interface InviteOrganizationUserDto {
  email: string;
  firstName: string;
  lastName: string;
  isAccessManager?: boolean | string;
  isWalletManager?: boolean | string;
  isOpportunityManager?: boolean | string;
}

export interface WhitelistAddressApproval {
  id: string;
  approvedUser: User;
  rejectedByUser: User;
}

export interface WhitelistProposal {
  id: string;
  address: string;
  name: string;
  proposerUser: User;
  approvalStatus: PermissionApprovalStatus;
  whitelistApproval: WhitelistAddressApproval[];
}

export enum CSVTransactionType {
  ALL = "all",
  DEPOSIT = "deposit",
  WITHDRAWAL = "withdrawal",
  INTERNAL = "internal",
}

interface ReqDataroomAccessDto {
  opportunityId: string;
}

interface ResDataroomAccessDto {
  message: string;
  kind: string;
}

export interface UpdateFunding {
  id: string;
  visibleToOthers: boolean;
}

interface ResEnterpriseSettingDto {
  settingsId?: string;
  requiredWhitelistApprovers?: number;
  requiredAccessApprovers?: number;
  managers?: Managers;
}

class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://api.mock.block.green/v1";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Block Green API for testing frontend
 * @version 1.0.0
 * @baseUrl https://api.mock.block.green/v1
 *
 * This is an example description.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  miner = {
    /**
     * No description
     *
     * @tags opportunity
     * @name GetOpportunitySummary
     * @request GET:/miner/opportunity/{id}/summary
     * @secure
     */
    getOpportunitySummary: (id: string, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/miner/opportunity/${id}/summary`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags opportunity
     * @name GetCollateralInfo
     * @request GET:/opportunity/{id}/collateral
     * @secure
     */
    getCollateralInfo: (id: string, params: RequestParams = {}) =>
      this.request<CollateralInfoDto, any>({
        path: `/opportunity/${id}/collateral-info`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags miner
     * @name PostCollateralToOpportunity
     * @request POST:/miner/opportunities/{id}/collateral
     */
    postCollateralToOpportunity: (id: string, data: CollateralRequestDto, params: RequestParams = {}) =>
      this.request<TransactionSendDto, any>({
        path: `/miner/opportunities/${id}/collateral`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags miner
     * @name GetMinerOpportunityHistoryTxs
     * @request GET:/miner/opportunities/{id}/txhistory
     */
    getOpportunityHistoryTxs: (
      id: string,
      query?: {
        txtype?:
          | "Reward Distribution"
          | "Opportunity Funding"
          | "Liquidity Refund"
          | "Collateral Release"
          | "Collateral Deposit"
          | "Reward Delivery"
          | "Liquidity Delivery"
          | "Excess Reward Release"
          | "Unknown"
          | "Collateral Liquidation";
        offset?: number;
        limit?: number;
        transactionDateTime?: "asc" | "desc";
        status?: "Pending" | "Confirmed" | "Awaiting approval";
        sort?: "asc" | "desc";
      },
      params: RequestParams = {}
    ) =>
      this.request<OverviewHistoryTransactionsDto, any>({
        path: `/miner/opportunities/${id}/txhistory`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Get miner's opportunities
     *
     * @tags miner
     * @name GetMinerOpportunities
     * @request GET:/miner/opportunities
     * @secure
     */
    getMinerOpportunities: (
      query?: {
        offset?: number;
        limit?: number;
        hideSettled?: YesOrNoChoice;
        status?: OpportunityStatus;
        actionFirst?: YesOrNoChoice;
        activeSince?: Sort;
        settlement?: Sort;
      },
      params: RequestParams = {}
    ) =>
      this.request<OpportunitiesDto, any>({
        path: `/miner/opportunities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get miner's opportunities summary
     *
     * @tags miner
     * @name GetMinerOpportunitiesSummary
     * @request GET:/miner/opportunities/summary
     * @secure
     */
    getMinerOpportunitiesSummary: (params: RequestParams = {}) =>
      this.request<OpportunitiesMinerSummaryDto, any>({
        path: `/miner/opportunities/summary`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * @description Get whitelist proposal
     *
     * @tags user
     * @name GetWhitelistProposal
     * @request GET:/user/addresses/whitelist/proposal
     * @secure
     */
    getWhitelistProposal: (
      query?: {
        /** Offset of returning transactions */
        offset?: number;
        /** The numbers of transactions to return */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<WhitelistProposal, any>({
        path: `/user/addresses/whitelist/proposal`,
        method: "GET",
        secure: true,
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Remove role
     *
     * @tags user
     * @name RemoveRole
     * @request DELETE:/user/role
     * @secure
     */
    removeRole: (query?: RemoveRoleDto) =>
      this.request<any, any>({
        path: "/user/role",
        method: "DELETE",
        secure: true,
        query: query,
        format: "json",
      }),

    /**
     * @description Get all access proposal
     *
     * @tags user
     * @name AccessProposal
     * @request GET:/user/organization/access-proposals
     * @secure
     */
    getManagerProposals: (query?: { offset?: number; limit?: number }) =>
      this.request<AccessProposalDto, any>({
        path: "/user/organization/access-proposals",
        method: "GET",
        secure: true,
        query: query,
        format: "json",
      }),

    /**
     * @description Reject or Approve proposal
     *
     * @tags user
     * @name CreateProposal
     * @request POSt:/user/organization/access-proposal
     * @secure
     */
    createProposal: (data: Partial<AccessProposal>) =>
      this.request<any, any>({
        path: "/user/organization/access-proposals",
        method: "POST",
        secure: true,
        body: data,
        format: "json",
        type: ContentType.Json,
      }),

    /**
     * @description Reject or Approve proposal
     *
     * @tags user
     * @name RejectOrApproveProposal
     * @request GET:/user/organization/access-approval
     * @secure
     */
    rejectOrApproveProposal: (data: AcceptProposalDto) =>
      this.request<void, any>({
        path: "/user/organization/access-approval",
        method: "POST",
        secure: true,
        body: data,
        format: "json",
        type: ContentType.Json,
      }),

    /**
     * @description Get total number of all Access Managers
     *
     * @tags user
     * @name GetAccessManagerCount
     * @request GET:/user/organization/access-managers
     * @secure
     */
    getManagerCount: () =>
      this.request<{ [role: string]: number }, any>({
        path: "/user/organization/access-managers",
        method: "GET",
        secure: true,
        format: "json",
      }),

    /**
     * @description Get all Organization Users
     *
     * @tags user
     * @name GetOrganizationUsers
     * @request GET:/user/organization
     * @secure
     */
    getOrganizationUsers: (
      query?: {
        offset?: number;
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<OrganizationUserDto, any>({
        path: "/user/organization",
        method: "GET",
        secure: true,
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Invite Organization User
     *
     * @tags user
     * @name InviteOrganizationUser
     * @request GET:/user/organization
     * @secure
     */
    inviteOrganizationUser: (data: InviteOrganizationUserDto, params: RequestParams = {}) =>
      this.request<OrganizationUserDto, any>({
        path: "/user/organization",
        method: "POST",
        secure: true,
        body: data,
        format: "json",
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Remove Organization User
     *
     * @tags user
     * @name RemoveOrganizationUser
     * @request DELETE:/user/organization
     * @secure
     */
    removeOrganizationUser: (query?: { id?: string }) =>
      this.request<any, any>({
        path: "/user/organization",
        method: "DELETE",
        format: "json",
        type: ContentType.Json,
        query,
        secure: true,
      }),

    /**
     * @description Get all miners
     *
     * @tags user
     * @name GetUserMiners
     * @request GET:/user/miners
     * @secure
     */
    getUserMiners: (params: RequestParams = {}) =>
      this.request<MinerDto[], any>({
        path: `/user/miners`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetUser
     * @summary Get the current user data
     * @request GET:/user
     * @secure
     */
    getUser: (params: RequestParams = {}) =>
      this.request<User, void>({
        path: `/user`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all notifications
     *
     * @tags user
     * @name GetUserNotifications
     * @request GET:/user/notifications
     * @secure
     */
    getUserNotifications: (params: RequestParams = {}) =>
      this.request<Notification[], any>({
        path: "/notification",
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all notifications
     *
     * @tags user
     * @name GetUserNotifications
     * @request GET:/user/notifications
     * @secure
     */
    markNotificationAsRead: (data?: { notifications: string[] }, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: "/notification",
        method: "PATCH",
        secure: true,
        type: ContentType.Json,
        body: data,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name PostUserPassword
     * @summary Change password for the current user
     * @request POST:/user/password
     * @secure
     */
    postUserPassword: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/password`,
        method: "POST",
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name PostUserPassword
     * @summary Initiate opportunity
     * @request POST:/opportunity/initiate-funding
     * @secure
     */
    initiateOpportunityFunding: (data?: { amountSatoshis: string }, params: RequestParams = {}) =>
      this.request<any, any>({
        path: "/opportunity/initiate-funding",
        method: "POST",
        secure: true,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name PostUserPasswordIsValid
     * @summary Check if user entered correct password
     * @request POST:/user/password/valid
     * @secure
     */
    postUserPasswordIsValid: (
      data: {
        /** @example "securePass123" */
        password?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/user/password/valid`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetUserOnboardingStatus
     * @summary Get onboarding status for a provided user
     * @request GET:/user/status
     * @secure
     */
    getUserOnboardingStatus: (
      query?: {
        /** @example "auth0|63ab4fdd670077b1b9aa96af" */
        userId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<OnboardingStatus, any>({
        path: `/user/status`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name PatchUserOnboardingStatus
     * @summary Set onboarding status
     * @request PATCH:/user/status
     * @secure
     */
    patchUserOnboardingStatus: (
      query?: {
        onboardingStatus?: OnboardingStatus;
        /** @example "auth0|63ab4fdd670077b1b9aa96af" */
        userId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/user/status`,
        method: "PATCH",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name PatchUserTypeJustForTest
     * @summary Change user type in order to frontend can be tested
     * @request PATCH:/user/type-just-for-test
     * @secure
     */
    patchUserTypeJustForTest: (
      query?: {
        userType?: UserTypes;
        /** @example "auth0|63ab4fdd670077b1b9aa96af" */
        userId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/user/type-just-for-test`,
        method: "PATCH",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name CreateWhitelistAddress
     * @summary Add address of the current user to whitelist
     * @request PUT:/user/addresses/whitelist
     * @secure
     */
    createWhitelistAddress: (data: WalletAddress, params: RequestParams = {}) =>
      this.request<WalletAddress, any>({
        path: `/user/addresses/whitelist`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetUserWhitelistedAddress
     * @summary Get all user's whitelisted addresses
     * @request GET:/user/addresses/whitelist
     * @secure
     */
    getUserWhitelistedAddress: (
      query?: {
        offset?: number;
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<WalletAddress[], any>({
        path: `/user/addresses/whitelist`,
        method: "GET",
        query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name DeleteUserAddressFromWhitelist
     * @summary Delete user's whitelisted address
     * @request DELETE:/user/addresses/whitelist
     * @secure
     */
    deleteUserAddressFromWhitelist: (
      query?: {
        /**
         * Offset of history transactions
         * @example "bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq"
         */
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/user/addresses/whitelist`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ApproveOrRejectWhitelistProposal
     * @request DELETE:/user/addresses/whitelist
     * @secure
     */
    approveOrRejectWhitelistProposal: (data: ApproveOrRejectData, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/addresses/whitelist`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  entrypoint = {
    /**
     * No description
     *
     * @tags entrypoint
     * @name GetEntrypointMiners
     * @summary Get all miners entrypoint data
     * @request GET:/entrypoint/miners
     * @secure
     */
    getEntrypointMiners: (params: RequestParams = {}) =>
      this.request<MinerDto[], any>({
        path: `/entrypoint/miners`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags entrypoint
     * @name GetEntrypointMinerById
     * @summary Get a miner's entrypoint data
     * @request GET:/entrypoint/miners/{id}
     * @secure
     */
    getEntrypointMinerById: (id: number, params: RequestParams = {}) =>
      this.request<MinerDto, any>({
        path: `/entrypoint/miners/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Registration of liquidity providers, enforced by BG validation. Notifications are sent on success.
     *
     * @tags entrypoint
     * @name RegisterLp
     * @request POST:/entrypoint/registration/lp
     */
    registerLp: (data: RegisterLpDto, params: RequestParams = {}) =>
      this.request<ResOnboardingDto, any>({
        path: `/entrypoint/registration/lp`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Registration of miners, enforced by BG validation. Notifications are sent on success.
     *
     * @tags entrypoint
     * @name RegisterMiner
     * @request POST:/entrypoint/registration/miner
     */
    registerMiner: (data: RegisterMinerDto, params: RequestParams = {}) =>
      this.request<ResOnboardingDto, any>({
        path: `/entrypoint/registration/miner`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags entrypoint
     * @name GetEntrypointLps
     * @summary Get all lps entrypoint data
     * @request GET:/entrypoint/lp
     * @secure
     */
    getEntrypointLps: (params: RequestParams = {}) =>
      this.request<LPDto[], any>({
        path: `/entrypoint/lp`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags entrypoint
     * @name GetEntrypointLpById
     * @summary Get a lp's entrypoint data
     * @request GET:/entrypoint/lp/{id}
     * @secure
     */
    getEntrypointLpById: (id: number, params: RequestParams = {}) =>
      this.request<LPDto, any>({
        path: `/entrypoint/lp/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  admin = {
    /**
     * No description
     *
     * @tags admin
     * @name UpdateOrganizationEnterpriseSettings
     * @request POST:/enterprise-settings/:id
     * @secure
     */
    updateOrganizationEnterpriseSettings: (
      id: string,
      data: OrganizationEnterpriseSettingsDto,
      params: RequestParams = {}
    ) =>
      this.request<OrganizationEnterpriseSettings, any>({
        path: `/enterprise-settings/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetEnterpriseSettingsAndManagers
     * @request GET:/enterprise-settings
     * @secure
     */
    getEnterpriseSettingsAndManagers: (
      query: {
        organizationId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResEnterpriseSettingDto, any>({
        path: `/enterprise-settings`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminLiquidityDelivery
     * @request GET:/admin/liquidity-delivery
     * @secure
     */
    getAdminLiquidityDelivery: (
      query?: {
        offset?: number;
        limit?: number;
        hideSettled?: YesOrNoChoice;
      },
      params: RequestParams = {}
    ) =>
      this.request<LiquidityDeliveryResponseDto, any>({
        path: `/admin/liquidity-delivery`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name PatchTrancheDelivery
     * @request PATCH:/admin/tranche/delivery
     * @secure
     */
    updateTrancheDelivery: (data: UpdateTrancheDeliveryDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/liquidity-delivery`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetOpportunitySummary
     * @request GET:/opportunity/:id/summary
     * @secure
     */
    getOpportunitySummary: (id: string, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/admin/opportunity/${id}/summary`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name PatchTrancheApprovalStatus
     * @request PATCH:/opportunity/tranche/:id
     * @secure
     */
    updateFundingApprovalStatus: (id: string, data: UpdateFundingApprovalStatusDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/opportunity/funding/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetLpAgreements
     * @summary Get all signature requiring approval
     * @request GET:/opportunity/lp-agreements
     * @secure
     */
    getLpAgreements: (
      query?: {
        /** Offset of returning transactions */
        offset?: number;
        /** The numbers of transactions to return */
        limit?: number;
        status?: FundingApprovalStatus;
      },
      params: RequestParams = {}
    ) =>
      this.request<LpAgreementOpportunitiesPageDto, any>({
        path: `/opportunity/lp-agreements`,
        method: "GET",
        query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name getOrganizations
     * @summary Get all users
     * @request GET:/admin/organizations
     * @secure
     */
    getOrganizations: (
      query?: {
        /** Offset of returning transactions */
        offset?: number;
        /** The numbers of transactions to return */
        limit?: number;
        /** Filter by user type */
        userType?: UserTypes;
        /** Transactions that require action will be on top */
        actionFirst?: SortFirstActionRequired;
      },
      params: RequestParams = {}
    ) =>
      this.request<OrganizationsResponseDto, any>({
        path: `/admin/organizations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetLpById
     * @request GET:/admin/organizations/lp/{id}
     * @secure
     */
    getLpById: (id: string, params: RequestParams = {}) =>
      this.request<LpOrganizationResponseDto, any>({
        path: `/admin/organizations/lp/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetMinerById
     * @request GET:/admin/organizations/miner/{id}
     * @secure
     */
    getMinerById: (id: string, params: RequestParams = {}) =>
      this.request<MinerInfoResponseDto, any>({
        path: `/admin/organizations/miner/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetMinerSummary
     * @request GET:/admin/organizations/miner/{id}/summary
     * @secure
     */
    getMinerSummary: (id: string, params: RequestParams = {}) =>
      this.request<ResMinerSummaryDto, any>({
        path: `/admin/organizations/miner/${id}/summary`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name DumpMinerData
     * @request POST:/admin/organizations/miner/{id}/dump
     * @secure
     */
    dumpMinerData: (id: string, params: RequestParams = {}) =>
      this.request<ResMinerDumpDto, any>({
        path: `/admin/organizations/miner/${id}/dump`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GenerateFileSignedUrl
     * @request PUT:/admin/files/{id}
     * @secure
     */
    generateFileSignedUrl: (id: string, params: RequestParams = {}) =>
      this.request<ResDownloadUrlDto, any>({
        path: `/admin/files/${id}`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name ApproveMinerOnboarding
     * @request PATCH:/admin/organizations/onboarding/{id}/approval
     * @secure
     */
    approveMinerOnboarding: (id: string, params: RequestParams = {}) =>
      this.request<ResUpdateStatus, any>({
        path: `/admin/organizations/onboarding/${id}/approval`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name RejectMinerOnboarding
     * @request PATCH:/admin/organizations/{id}/rejection
     * @secure
     */
    rejectMinerOnboarding: (id: string, params: RequestParams = {}) =>
      this.request<ResUpdateStatus, any>({
        path: `/admin/organizations/${id}/rejection`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name ApproveMinerKycAndGenerateWallet
     * @request PATCH:/admin/organizations/miner/approval
     * @secure
     */
    approveMinerKycAndGenerateWallet: (data: ReqByMinerIdDto, params: RequestParams = {}) =>
      this.request<ResMinerKycApprovalDto, any>({
        path: `/admin/organizations/miner/approval`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name patchOrganizationStatus
     * @summary Update user onboarding status
     * @request PATCH:/admin/organizations/{id}
     * @secure
     */
    patchOrganizationStatus: (
      id: string,
      query: {
        approved: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/admin/organizations/${id}`,
        method: "PATCH",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Get all opportunities
     *
     * @tags admin
     * @name GetAdminOpportunities
     * @request GET:/admin/opportunities
     * @secure
     */
    getAdminOpportunities: (
      query?: {
        offset?: number;
        limit?: number;
        hideSettled?: YesOrNoChoice;
        status?: OpportunityStatus;
      },
      params: RequestParams = {}
    ) =>
      this.request<OpportunitiesDto, any>({
        path: `/admin/opportunities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name CreateOpportunityV2
     * @request POST:/admin/opportunities/v2
     * @secure
     */
    createOpportunityV2: (data: ReqCreateOpportunityV2Dto, params: RequestParams = {}) =>
      this.request<ResCreateOpportunityV2Dto, ResDownloadUrlDto>({
        path: `/admin/opportunities/v2`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name UpdateOpportunityV2
     * @request PUT:/admin/opportunities/v2
     * @secure
     */
    updateOpportunityV2: (data: ReqUpdateOpportunityV2Dto, params: RequestParams = {}) =>
      this.request<ResCreateOpportunityV2Dto, any>({
        path: `/admin/opportunities/v2`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminOpportunityV2
     * @request GET:/admin/opportunities/{id}/v2
     * @secure
     */
    getAdminOpportunityV2: (id: string, params: RequestParams = {}) =>
      this.request<ResOpportunityDetailsV2Dto, any>({
        path: `/admin/opportunities/${id}/v2`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name ProcessDemoOpportunityEvents
     * @request PUT:/admin/opportunities/demo
     * @secure
     */
    processDemoOpportunityEvents: (data: ReqProcessDemoOpportunityEvents, params: RequestParams = {}) =>
      this.request<ResCreateOpportunityV2Dto, any>({
        path: `/admin/opportunities/demo`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetDemoOpportunityApprovedFundings
     * @request GET:/admin/opportunities/demo/{id}/fundings
     * @secure
     */
    getDemoOpportunityApprovedFundings: (id: string, params: RequestParams = {}) =>
      this.request<ResDemoOpportunityFundings, any>({
        path: `/admin/opportunities/demo/${id}/fundings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetDemoCollateralTransactions
     * @request GET:/admin/opportunities/demo/{id}/collaterals
     * @secure
     */
    getDemoCollateralTransactions: (id: string, params: RequestParams = {}) =>
      this.request<ResDemoOpportunityCollaterals, any>({
        path: `/admin/opportunities/demo/${id}/collaterals`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetDemoOpportunityRewards
     * @request GET:/admin/opportunities/demo/{id}/rewards
     * @secure
     */
    getDemoOpportunityRewards: (id: string, params: RequestParams = {}) =>
      this.request<ResDemoOpportunityRewards, any>({
        path: `/admin/opportunities/demo/${id}/rewards`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get summary for opportunities
     *
     * @tags admin
     * @name GetAdminOpportunitiesSummary
     * @request GET:/admin/opportunities/summary
     * @secure
     */

    /**
     * No description
     *
     * @tags admin
     * @name GetDemoExcessReward
     * @request GET:/admin/opportunities/demo/{id}/excess-reward
     * @secure
     */
    getDemoExcessReward: (id: string, params: RequestParams = {}) =>
      this.request<ResDemoExcessReward, any>({
        path: `/admin/opportunities/demo/${id}/excess-reward`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    getAdminOpportunitiesSummary: (params: RequestParams = {}) =>
      this.request<OpportunitiesSummaryDto, any>({
        path: `/admin/summary/opportunities`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminOpprotunity
     * @summary Get all opportunities
     * @request GET:/admin/opportunities/{id}
     * @secure
     */
    getAdminOpprotunity: (id: string, params: RequestParams = {}) =>
      this.request<OpportunityFullDataDto, any>({
        path: `/admin/opportunities/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminOpprotunityStatus
     * @summary Get opportunity status
     * @request GET:/admin/opportunities/{id}/status
     * @secure
     */
    getAdminOpprotunityStatus: (id: string, params: RequestParams = {}) =>
      this.request<OpportunityStatusDto, any>({
        path: `/admin/opportunities/${id}/status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name PatchAdminOpprotunityStatus
     * @summary Get opportunity status
     * @request PATCH:/admin/opportunities/{id}/status
     * @secure
     */
    patchAdminOpprotunityStatus: (id: string, data: OpportunityStatusDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/opportunities/${id}/status`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminDataRoomList
     * @summary Get all liquidity providers for managing access to dataroom
     * @request GET:/admin/dataroom
     * @secure
     */
    getAdminDataRoomList: (
      query?: {
        /** Offset of returning transactions */
        offset?: number;
        /** The numbers of transactions to return */
        limit?: number;
        /** Filter by status */
        status?: DataRoomStatus;
        /** Transactions that require action will be on top */
        actionFirst?: SortFirstActionRequired;
      },
      params: RequestParams = {}
    ) =>
      this.request<DataRoomDto, any>({
        path: `/admin/dataroom`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name PatchAdminDataroomAction
     * @summary Approve or deny LP for access to dataroom
     * @request PATCH:/admin/dataroom
     * @secure
     */
    patchAdminDataroomAction: (
      query: {
        /** Opportunity id */
        opportunityId: string;
        /** Offset of returning transactions */
        status: DataRoomStatus;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/admin/dataroom`,
        method: "PATCH",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminNotificationReceivers
     * @summary Get all liquidity providers for managing access to dataroom
     * @request GET:/admin/notifications/receivers
     * @secure
     */
    getAdminNotificationReceivers: (params: RequestParams = {}) =>
      this.request<NotificationReceiversDto, any>({
        path: `/admin/notifications/receivers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name PutAdminNotification
     * @summary Put new Notification
     * @request PUT:/admin/notifications
     * @secure
     */
    putAdminNotification: (data: NotificationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/notifications`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminNotifications
     * @summary Get all notifications
     * @request GET:/admin/notifications
     * @secure
     */
    getAdminNotifications: (
      query?: {
        /** Offset of notifications */
        offset?: number;
        /** The number of notifications to return */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<NotificationsDto, any>({
        path: `/admin/notifications`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAllOpportunityTransactions
     * @request GET:/admin/opportunities/{id}/transactions
     * @secure
     */
    getAllOpportunityTransactions: (id: string, params: RequestParams = {}) =>
      this.request<ResTimelineDto, any>({
        path: `/admin/opportunities/${id}/transactions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetAdminOpportunityHeroes
     * @summary Get opportunity heroes
     * @request GET:/admin/opportunitues/heroes/{opportunityId}
     * @secure
     */
    getAdminOpportunityHeroes: (opportunityId: string, params: RequestParams = {}) =>
      this.request<AdminOpportunityHeroesDto, any>({
        path: `/admin/opportunitues/heroes/${opportunityId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetMinerKym2Financials
     * @request GET:/admin/miner/kym/financials/{orgId}
     * @secure
     */
    getMinerKym2Financials: (orgId: string, params: RequestParams = {}) =>
      this.request<ResKymFinancialsDto, any>({
        path: `/admin/miner/kym/financials/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetMinerKym2Hosting
     * @request GET:/admin/miner/kym/hosting/{orgId}
     * @secure
     */
    getMinerKym2Hosting: (orgId: string, params: RequestParams = {}) =>
      this.request<UpdateKymHostingDto, any>({
        path: `/admin/miner/kym/hosting/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetMinerKym2Asic
     * @request GET:/admin/miner/kym/asic/{orgId}
     * @secure
     */
    getMinerKym2Asic: (orgId: string, params: RequestParams = {}) =>
      this.request<ResKymAsicDto, any>({
        path: `/admin/miner/kym/asic/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetMinerKym2EnergyProfile
     * @request GET:/admin/miner/kym/energy-profile/{orgId}
     * @secure
     */
    getMinerKym2EnergyProfile: (orgId: string, params: RequestParams = {}) =>
      this.request<ResKymEnergyProfileDto, any>({
        path: `/admin/miner/kym/energy-profile/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetMinerKym2CapacityAndSites
     * @request GET:/admin/miner/kym/capacity-sites/{orgId}
     * @secure
     */
    getMinerKym2CapacityAndSites: (orgId: string, params: RequestParams = {}) =>
      this.request<ResKymCapacityAndSitesDto, any>({
        path: `/admin/miner/kym/capacity-sites/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name UpdateMinerRating
     * @request PUT:/admin/miner/rating
     * @secure
     */
    updateMinerRating: (data: ReqMinerRatingDto, params: RequestParams = {}) =>
      this.request<ResMinerRatingDto, any>({
        path: `/admin/miner/rating`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetBlocks
     * @request GET:/admin/blocks
     * @secure
     */
    getBlocks: (
      query: {
        orderBy: "height" | "timestamp" | "difficulty" | "rewardSatoshis";
        orderDirection: "asc" | "desc";
        /** @min 0 */
        offset?: number;
        /**
         * @min 1
         * @max 100
         */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResBlocksDto, any>({
        path: `/admin/blocks`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetComputedHashprices
     * @request GET:/admin/hashprices
     * @secure
     */
    getComputedHashprices: (
      query: {
        /** @min 0 */
        offset?: number;
        /**
         * @min 1
         * @max 100
         */
        limit?: number;
        orderBy:
          | "day"
          | "createdAt"
          | "effectiveDifficulty"
          | "totalSubsidySatoshis"
          | "fppsHashpriceSatoshisPerTh"
          | "blockCount";
        orderDirection: "asc" | "desc";
      },
      params: RequestParams = {}
    ) =>
      this.request<ResComputedHashpricesDto, any>({
        path: `/admin/hashprices`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetOpportunityRewards
     * @request GET:/admin/{opportunityId}/rewards
     * @secure
     */
    getOpportunityRewards: (
      opportunityId: string,
      query: {
        /** @min 0 */
        offset?: number;
        /**
         * @min 1
         * @max 100
         */
        limit?: number;
        orderBy: "createdAt" | "amountSatoshis" | "distributionStatus";
        orderDirection: "asc" | "desc";
      },
      params: RequestParams = {}
    ) =>
      this.request<ResOpportunityRewardsDto, any>({
        path: `/admin/${opportunityId}/rewards`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetRewardDetails
     * @request GET:/admin/rewards/{rewardId}
     * @secure
     */
    getRewardDetails: (rewardId: string, params: RequestParams = {}) =>
      this.request<ResRewardDetails, any>({
        path: `/admin/rewards/${rewardId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetUsers
     * @request GET:/admin/users
     * @secure
     */
    getUsers: (
      query: {
        /** @min 0 */
        offset?: number;
        /**
         * @min 1
         * @max 100
         */
        limit?: number;
        orderBy: "id" | "email" | "createdAt" | "lastName" | "firstName" | "cellphoneNumber";
        orderDirection: "asc" | "desc";
      },
      params: RequestParams = {}
    ) =>
      this.request<ResUsersDto, any>({
        path: `/admin/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name CreateAdminUser
     * @request POST:/admin/users/admin
     * @secure
     */
    createAdminUser: (data: ReqAdminUserDto, params: RequestParams = {}) =>
      this.request<ResAdminUserDto, any>({
        path: `/admin/users/admin`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetExchangeRatesFromEur
     * @request POST:/admin/exchange-rates
     * @secure
     */
    getExchangeRatesFromEur: (data: ReqExchangeRatesDto, params: RequestParams = {}) =>
      this.request<ResExchangeRatesFromEur, any>({
        path: `/admin/exchange-rates`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetPrediction
     * @request GET:/admin/predictions/{range}
     * @secure
     */
    getPrediction: (range: number, params: RequestParams = {}) =>
      this.request<ResPredictionsPayload, any>({
        path: `/admin/predictions/${range}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name GetHistoricalHashrates
     * @request GET:/admin/hashrate/history
     * @secure
     */
    getHistoricalHashrates: (params: RequestParams = {}) =>
      this.request<ResHistoricalPayload, any>({
        path: `/admin/hashrate/history`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  ping = {
    /**
     * @description This operation shows how to override the global security defined\ \ above, as we want to open it up for all users.
     *
     * @name GetPing
     * @summary Server heartbeat operation
     * @request GET:/ping
     */
    getPing: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/ping`,
        method: "GET",
        ...params,
      }),
  };
  kym = {
    /**
     * No description
     *
     * @tags kym
     * @name GetKyms
     * @summary Get all KYM registration form data
     * @request GET:/kym
     * @secure
     */
    getKyms: (params: RequestParams = {}) =>
      this.request<KYMDto[], any>({
        path: `/kym`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name GetKymById
     * @summary Get a single KYM registration form data
     * @request GET:/kym/{userId}
     * @secure
     */
    getKymById: (userId: string, params: RequestParams = {}) =>
      this.request<KYMDto, any>({
        path: `/kym/${userId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name GetKym2Financials
     * @request GET:/kym/v2/financials
     * @secure
     */
    getKym2Financials: (params: RequestParams = {}) =>
      this.request<ResKymFinancialsDto, any>({
        path: `/kym/v2/financials`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name UpdateKym2Financials
     * @request PUT:/kym/v2/financials
     * @secure
     */
    updateKym2Financials: (data: ReqUpdateKymFinancialsDto, params: RequestParams = {}) =>
      this.request<ResUpdateDto, any>({
        path: `/kym/v2/financials`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name GetKym2Hosting
     * @request GET:/kym/v2/hosting
     * @secure
     */
    getKym2Hosting: (params: RequestParams = {}) =>
      this.request<UpdateKymHostingDto, any>({
        path: `/kym/v2/hosting`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name UpdateKym2Hosting
     * @request PUT:/kym/v2/hosting
     * @secure
     */
    updateKym2Hosting: (data: UpdateKymHostingDto, params: RequestParams = {}) =>
      this.request<ResUpdateDto, any>({
        path: `/kym/v2/hosting`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name GetKym2Asic
     * @request GET:/kym/v2/asic
     * @secure
     */
    getKym2Asic: (params: RequestParams = {}) =>
      this.request<ResKymAsicDto, any>({
        path: `/kym/v2/asic`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name UpdateKym2Asic
     * @request PUT:/kym/v2/asic
     * @secure
     */
    updateKym2Asic: (data: ReqUpdateKymAsicDto, params: RequestParams = {}) =>
      this.request<ResUpdateDto, any>({
        path: `/kym/v2/asic`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name GetKym2EnergyProfile
     * @request GET:/kym/v2/energy-profile
     * @secure
     */
    getKym2EnergyProfile: (params: RequestParams = {}) =>
      this.request<ResKymEnergyProfileDto, any>({
        path: `/kym/v2/energy-profile`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name UpdateKym2EnergyProfile
     * @request PUT:/kym/v2/energy-profile
     * @secure
     */
    updateKym2EnergyProfile: (data: ReqKymEnergyProfileDto, params: RequestParams = {}) =>
      this.request<ResUpdateDto, any>({
        path: `/kym/v2/energy-profile`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name GetKym2CapacityAndSites
     * @request GET:/kym/v2/capacity-sites
     * @secure
     */
    getKym2CapacityAndSites: (params: RequestParams = {}) =>
      this.request<ResKymCapacityAndSitesDto, any>({
        path: `/kym/v2/capacity-sites`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kym
     * @name UpdateKym2CapacityAndSites
     * @request PUT:/kym/v2/capacity-sites
     * @secure
     */
    updateKym2CapacityAndSites: (data: ReqUpdateKymCapacityAndSitesDto, params: RequestParams = {}) =>
      this.request<ResUpdateDto, any>({
        path: `/kym/v2/capacity-sites`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  wallet = {
    /**
     * No description
     *
     * @tags wallet
     * @name GetFee
     * @request GET:/wallet/fee
     */
    getFee: (params: RequestParams = {}) =>
      this.request<FeeDto, any>({
        path: `/wallet/fee`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet
     * @name TransferCoins
     * @request POST:/wallet/transfer
     */
    transferCoins: (data: TransferRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/wallet/transfer`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet
     * @name GetWalletDepositTxs
     * @summary Get lp or miner deposit transactions
     * @request GET:/wallet/deposit
     * @secure
     */
    getWalletDepositTxs: (
      query?: {
        /** Offset of history transactions */
        offset?: number;
        /** The numbers of transactions to return */
        limit?: number;
        /** Sort by date asc or desc */
        sort?: Sort;
      },
      params: RequestParams = {}
    ) =>
      this.request<TransactionsDto, any>({
        path: `/wallet/deposit`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet
     * @name PostWalletWithdrawPreview
     * @request POST:/wallet/withdraw/preview
     * @secure
     */
    postWalletWithdrawPreview: (data: WithdrawDto, params: RequestParams = {}) =>
      this.request<WithdrawInitiatedDto, any>({
        path: `/wallet/withdraw/preview`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet
     * @name PostWalletWithdrawRequest
     * @request POST:/wallet/withdraw/request
     * @secure
     */
    postWalletWithdrawRequest: (data: WithdrawDto, params: RequestParams = {}) =>
      this.request<WithdrawInitiatedDto, any>({
        path: `/wallet/withdraw/request`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet
     * @name GetWithdraw
     * @summary Get wallet withdraw
     * @request GET:/wallet/withdraw/init
     * @secure
     */
    getWithdraw: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/wallet/withdraw/init`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet
     * @name GetWithdrawInitiated
     * @summary Get wallet withdraw
     * @request GET:/wallet/withdraw/init-done
     * @secure
     */
    getWithdrawInitiated: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/wallet/withdraw/init-done`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet
     * @name GetWalletOverviewHistoryTxs
     * @summary Get lp or miner overview of history transactions
     * @request GET:/wallet/overview/txhistory
     * @secure
     */
    getWalletOverviewHistoryTxs: (
      query?: {
        txtype?:
          | "Reward Distribution"
          | "Opportunity Funding"
          | "Liquidity Refund"
          | "Collateral Release"
          | "Collateral Deposit"
          | "Reward Delivery"
          | "Liquidity Delivery"
          | "Excess Reward Release"
          | "Unknown"
          | "Collateral Liquidation"
          | "Deposit"
          | "Reconciliation"
          | "Withdrawal";
        offset?: number;
        limit?: number;
        status?: "Pending" | "Confirmed" | "Awaiting approval";
        transactionDateTime?: "asc" | "desc";
        sort?: "asc" | "desc";
      },
      params: RequestParams = {}
    ) =>
      this.request<OverviewHistoryTransactionsDto, any>({
        path: `/wallet/overview/txhistory`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags wallet
     * @name GetSatoshiToUsdPrice
     * @request GET:/wallet/price/satoshi-usd
     * @secure
     */
    getSatoshiToUsdPrice: (params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/wallet/price/satoshi-usd`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  lp = {
    /**
     * No description
     *
     * @tags lp
     * @name getOpportunitySummary
     * @request GET://lp/opportunity/:id/summary
     * @secure
     */
    getOpportunitySummary: (id: string, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/lp/opportunity/${id}/summary`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetPortfolioSummary
     * @request GET:/lp/portfolio/summary
     * @secure
     */
    getPortfolioSummary: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: "/lp/portfolio/summary",
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetOpportunitiesSummary
     * @request GET:/lp/summary/opportunities
     * @secure
     */
    getOpportunitiesSummary: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: "/lp/summary/opportunities",
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name AccessToDataroom
     * @summary Access to datarroom
     * @request GET:/lp/dataroom
     * @secure
     */
    requestAccessToDataRoom: (data: ReqDataroomAccessDto, params: RequestParams = {}) =>
      this.request<ResDataroomAccessDto, any>({
        path: `/lp/dataroom`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name UpdateFunding
     * @summary Patch Opportunity Funding
     * @request GET:/opportunity/funding
     * @secure
     */
    updateFunding: (body: UpdateFunding, params: RequestParams = {}) =>
      this.request<UpdateFunding, any>({
        path: `/opportunity/funding`,
        method: "PATCH",
        body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetOpportunityFunding
     * @summary Get Opportunity Funding
     * @request GET:/opportunity:id/funding
     * @secure
     */
    getOpportunityFunding: (id: string, params: RequestParams = {}) =>
      this.request<OpportunityFunding, any>({
        path: `/opportunity/${id}/funding`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetLpPortfolioChart
     * @summary Get portfolio chart for lp
     * @request GET:/lp/portfolio/chart
     * @secure
     */
    getLpPortfolioChart: (
      query?: {
        year?: string;
        minerId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PortfolioChart, void>({
        path: `/lp/portfolio/chart`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetLpOpprotunities
     * @summary Get all opportunities
     * @request GET:/lp/opportunities
     * @secure
     */
    getLpOpprotunities: (
      query?: {
        newestFirst?: YesOrNoChoice;
      },
      params: RequestParams = {}
    ) =>
      this.request<LPOpportunitiesDto, any>({
        path: `/lp/opportunities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetLpOpprotunity
     * @summary Get opportunity
     * @request GET:/lp/opportunity/:id
     * @secure
     */
    getLpOpportunity: (id: string, params: RequestParams = {}) =>
      this.request<OpportunityFullDataDto, any>({
        path: `/lp/opportunity/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetLpPreviewStreamingOpportunity
     * @request GET:/lp/preview-streaming-opportunity/{id}
     * @secure
     */
    getLpPreviewStreamingOpportunity: (id: string, params: RequestParams = {}) =>
      this.request<PreviewStreamingOpportunityDataDto, any>({
        path: `/lp/preview-streaming-opportunity/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetLpPreviewLoan
     * @request GET:/lp/preview-loan/{id}
     * @secure
     */
    getLpPreviewLoan: (id: string, params: RequestParams = {}) =>
      this.request<PreviewLoanDataDto, any>({
        path: `/lp/preview-loan/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags lp
     * @name GetLpPreviewLease
     * @request GET:/lp/preview-lease/{id}
     * @secure
     */
    getLpPreviewLease: (id: string, params: RequestParams = {}) =>
      this.request<PreviewLeaseDataDto, any>({
        path: `/lp/preview-lease/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags opportunity
     * @name GetLiquidityProvidersV2
     * @request GET:/opportunity/{id}/lp/v2
     * @secure
     */
    getLiquidityProvidersV2: (
      id: string,
      query: {
        offset?: number;
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResBasicLiquidityProviders, any>({
        path: `/opportunity/${id}/lp/v2`,
        method: "GET",
        secure: true,
        format: "json",
      }),

    /**
     * No description
     *
     * @tags lp
     * @name getLpOpportunitiesFunded
     * @summary Get all funded opportunities tranches
     * @request GET:/lp/opportunities/funded
     * @secure
     */
    getLpOpportunitiesFunded: (
      query?: {
        /** Offset of returning transactions */
        offset?: number;
        /** The numbers of transactions to return */
        limit?: number;
        /** Sort by date asc or desc */
        sort?: Sort;
        minerId?: string;
        hideSettled?: YesOrNoChoice;
      },
      params: RequestParams = {}
    ) =>
      this.request<OpportunitiesFundedDto, any>({
        path: `/lp/opportunities/funded`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };

  csv = {
    /**
     * No description
     *
     * @name GetCsvData
     * @request GET:/csv/transaction
     */
    getCsvData: (
      query: { csvTransactionType: CSVTransactionType; opportunityId?: string },
      params: RequestParams = {}
    ) =>
      this.request<{ kind: "error" | "success"; url: string }, any>({
        path: `/csv/transaction`,
        method: "GET",
        secure: true,
        format: "json",
        query: query,
        ...params,
      }),
  };

  loans = {
    /**
     * No description
     *
     * @tags loans
     * @name GetAdminAllLoans
     * @request GET:/loans/admin/loans
     * @secure
     */
    getAdminAllLoans: (params: RequestParams = {}) =>
      this.request<ResAdminLoans, any>({
        path: `/loans/admin/loans`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name CreateAdminDraftLoan
     * @request POST:/loans/admin/loans
     * @secure
     */
    createAdminDraftLoan: (data: ReqCreateDraftLoanDto, params: RequestParams = {}) =>
      this.request<IdDto, any>({
        path: `/loans/admin/loans`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name GenerateDemoLoanData
     * @request PUT:/loans/admin/loans/demo
     * @secure
     */
    generateDemoLoanData: (data: ReqGenerateDemo, params: RequestParams = {}) =>
      this.request<ResGenerateDemo, any>({
        path: `/loans/admin/loans/demo`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name GetAdminLoanDetails
     * @request GET:/loans/admin/loan/{id}
     * @secure
     */
    getAdminLoanDetails: (id: string, params: RequestParams = {}) =>
      this.request<ResAdminLoanDto, any>({
        path: `/loans/admin/loan/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name GetAdminLoanBitgoBalance
     * @request GET:/loans/admin/loan/{id}/bitgo-balance
     * @secure
     */
    getAdminLoanBitgoBalance: (id: string, params: RequestParams = {}) =>
      this.request<ResLoanBitgoBalance, any>({
        path: `/loans/admin/loan/${id}/bitgo-balance`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name UpdateAdminDraftLoan
     * @request PUT:/loans/admin/loans/draft
     * @secure
     */
    updateAdminDraftLoan: (data: ReqUpdateDraftLoan, params: RequestParams = {}) =>
      this.request<IdDto, any>({
        path: `/loans/admin/loans/draft`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name CreateAdminPaymentRequest
     * @request POST:/loans/admin/loans/payments
     * @secure
     */
    createAdminPaymentRequest: (data: ReqCreatePaymentRequest, params: RequestParams = {}) =>
      this.request<ResCreatePaymentRequest, any>({
        path: `/loans/admin/loans/payments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name CancelAdminLoan
     * @request PUT:/loans/admin/loan/{id}/cancel
     * @secure
     */
    cancelAdminLoan: (id: string, params: RequestParams = {}) =>
      this.request<IdDto, any>({
        path: `/loans/admin/loan/${id}/cancel`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name CompleteAdminLoan
     * @request PUT:/loans/admin/loan/{id}/complete
     * @secure
     */
    completeAdminLoan: (id: string, params: RequestParams = {}) =>
      this.request<IdDto, any>({
        path: `/loans/admin/loan/${id}/complete`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name DeleteAdminPaymentRequest
     * @request DELETE:/loans/admin/loans/payments/{id}
     * @secure
     */
    deleteAdminPaymentRequest: (id: string, params: RequestParams = {}) =>
      this.request<ResDeletePaymentRequest, any>({
        path: `/loans/admin/loans/payments/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name GetMinerLoans
     * @request GET:/loans/miner/loans
     * @secure
     */
    getMinerLoans: (params: RequestParams = {}) =>
      this.request<ResMinerLoans, any>({
        path: `/loans/miner/loans`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name GetMinerLoan
     * @request GET:/loans/miner/loans/{id}/details
     * @secure
     */
    getMinerLoan: (id: string, params: RequestParams = {}) =>
      this.request<ResMinerLoanDto, any>({
        path: `/loans/miner/loans/${id}/details`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name GetLpLoans
     * @request GET:/loans/lp/loans
     * @secure
     */
    getLpLoans: (params: RequestParams = {}) =>
      this.request<ResLpLoans, any>({
        path: `/loans/lp/loans`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loans
     * @name GetLpLoan
     * @request GET:/loans/lp/loans/{id}/details
     * @secure
     */
    getLpLoan: (id: string, params: RequestParams = {}) =>
      this.request<ResLpLoanDto, any>({
        path: `/loans/lp/loans/${id}/details`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
