import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import toast from "react-hot-toast";
import { useMutationAdminGenerateFileUrl } from "../../../app/query/useMutationAdmin";
import { useQueryGetMinerKymFinancialsById } from "../../../app/query/useQueryGetAdmin";
import { DisplayFileList } from "../../../components/atoms/FileInput/ChipFile";
import { MuiFullscreenLoadingBackdrop } from "../../../components/atoms/MuiCircularPercentage/MuiLoadingBackdrop";
import { buildChipFileItem } from "../../KYM/commons";

const UploadGridRow = (props: React.HTMLAttributes<HTMLLIElement>) => (
  <Grid container mb={2} padding={2} boxShadow={2} border={1} borderRadius={1} borderColor={"ThreeDFace"}>
    {props.children}
  </Grid>
);

export const ReadonlyMinerKymFinancials = ({ organizationId }: { organizationId: string }) => {
  const { data: financials, isFetching: isLoadingFinancials } = useQueryGetMinerKymFinancialsById(organizationId);
  const { mutateAsync: generateFileUrl, isLoading: isGettingFile } = useMutationAdminGenerateFileUrl();

  const handleDownload = async (id: string) => {
    try {
      const result = await generateFileUrl(id);
      if (result.data.url) {
        window.open(result.data.url, "_blank", "noopener,noreferrer");
      } else {
        toast.error("Was not able to find the requested file.");
      }
    } catch (err) {
      toast.error("There has been an issue getting the file.");
    }
  };

  const sections = [
    {
      title: "Balance sheet files",
      files: financials?.data.balanceSheetFiles.map(buildChipFileItem) ?? [],
    },
    {
      title: "Income statement files",
      files: financials?.data.incomeStatementFiles.map(buildChipFileItem) ?? [],
    },
    {
      title: "Cash flow statement files",
      files: financials?.data.cashFlowStatementFiles.map(buildChipFileItem) ?? [],
    },
    {
      title: "Audit engagement letter files",
      files: financials?.data.auditEngagementLetterFiles.map(buildChipFileItem) ?? [],
    },
    {
      title: "Outstanding liabilities",
      files: financials?.data.outstandingLiabilitiesFiles.map(buildChipFileItem) ?? [],
    },
  ];

  if (isLoadingFinancials) {
    return (
      <Stack>
        <Skeleton width={"100%"} height={300} />
      </Stack>
    );
  } else {
    return (
      <Stack justifyItems={"center"} alignItems={"center"}>
        <MuiFullscreenLoadingBackdrop isOpen={isGettingFile} />

        {sections.map((section) => {
          return (
            <UploadGridRow key={section.title}>
              <Grid container item md>
                <Box>
                  <Typography noWrap variant="subtitle1">
                    {section.title}
                  </Typography>
                  {section.files.length ? (
                    <DisplayFileList
                      id={section.title}
                      files={section.files}
                      onDownload={handleDownload}
                    ></DisplayFileList>
                  ) : (
                    <Typography variant="caption" color="text.secondary">
                      No files uploaded by miner
                    </Typography>
                  )}
                </Box>
              </Grid>
            </UploadGridRow>
          );
        })}
      </Stack>
    );
  }
};
