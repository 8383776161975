import { ScrollRestoration } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/images/logo.svg";

import "./style.scss";

interface DisclaimerLayoutProps {
  children: React.ReactNode;
}

const DisclaimerLayout = ({ children }: DisclaimerLayoutProps) => {
  return (
    <div className="p-5 xl:pt-24 xl:pb-12 disclaimer-bck min-h-[100vh]">
      <div className="disclaimer-layout p-5 md:p-10 xl:mx-auto">
        <div className="flex flex-col justify-center items-center mb-6">
          <div>
            <Logo width={60} />
          </div>
        </div>
        <main>{children}</main>
        <ScrollRestoration />
      </div>
    </div>
  );
};

export default DisclaimerLayout;
