import { Box, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { format, isAfter, isBefore } from "date-fns";
import { findLast, last, partition, sortBy, sum, sumBy } from "lodash";
import { ResLoanMinerDelivery, ResLoanPayment } from "../../../app/model/api";
import { DatagridExportToolbar } from "./DatagridExportToolbar";
import { formatWithCommas, toFormattedBtc } from "./utils";
import { formatInTimeZone } from "date-fns-tz";

const ID_TOTAL = "Total";

type PaymentWithDelivery = {
  id: string;
  date: string;
  soFarActualSat?: number;
  soFarActualBtc?: number;
  soFarExpectedSat: number;
  soFarExpectedBtc: number;
  sinceLastActualSat?: number;
  sinceLastActualBtc?: number;
  sinceLastExpectedSat: number;
  sinceLastExpectedBtc: number;
  deltaSat?: number;
  deltaBtc?: number;
  index: number | undefined;
};

const COLUMNS = (unit: "SAT" | "BTC"): GridColDef<PaymentWithDelivery>[] => [
  {
    field: "index",
    headerName: "No.",
    sortable: true,
    display: "flex",
    renderCell: (params) => (params.row.index ? `#${params.row.index}` : null),
  },
  {
    field: "date",
    headerName: "Expected at",
    sortable: true,
    width: 130,
    display: "flex",
    renderCell: (params) =>
      params.row.date === ID_TOTAL ? ID_TOTAL : formatInTimeZone(params.row.date, "UTC", "dd MMM yyyy"),
  },
  // we need to do it this way (different columns for sat/btc) to have consistent values on the CSV export
  unit === "SAT"
    ? {
        field: "sinceLastExpectedSat",
        align: "right",
        headerAlign: "right",
        headerName: `Expected amount (${unit})`,
        sortable: true,
        flex: 1,
        display: "flex",
        renderCell: (params) => formatWithCommas(params.row.sinceLastExpectedSat),
      }
    : {
        field: "sinceLastExpectedBtc",
        align: "right",
        headerAlign: "right",
        headerName: `Expected amount (${unit})`,
        sortable: true,
        flex: 1,
        display: "flex",
        renderCell: (params) => params.row.sinceLastExpectedBtc,
      },
  unit === "SAT"
    ? {
        field: "soFarExpectedSat",
        align: "right",
        headerAlign: "right",
        headerName: `Expected cumulative (${unit})`,
        sortable: true,
        flex: 1,
        display: "flex",
        renderCell: (params) => formatWithCommas(params.row.soFarExpectedSat),
      }
    : {
        field: "soFarExpectedBtc",
        align: "right",
        headerAlign: "right",
        headerName: `Expected cumulative (${unit})`,
        sortable: true,
        flex: 1,
        display: "flex",
        renderCell: (params) => params.row.soFarExpectedBtc,
      },
  unit === "SAT"
    ? {
        field: "sinceLastActualSat",
        align: "right",
        headerAlign: "right",
        headerName: `Actual amount (${unit})`,
        sortable: true,
        flex: 1,
        display: "flex",
        renderCell: (params) =>
          params.row.sinceLastActualSat !== undefined ? formatWithCommas(params.row.sinceLastActualSat) : "-",
      }
    : {
        field: "sinceLastActualBtc",
        align: "right",
        headerAlign: "right",
        headerName: `Actual amount (${unit})`,
        sortable: true,
        flex: 1,
        display: "flex",
        renderCell: (params) => params.row.sinceLastActualBtc ?? "-",
      },
  unit === "SAT"
    ? {
        field: "soFarActualSat",
        align: "right",
        headerAlign: "right",
        headerName: `Actual cumulative (${unit})`,
        sortable: true,
        flex: 1,
        display: "flex",
        renderCell: (params) =>
          params.row.soFarActualSat !== undefined ? formatWithCommas(params.row.soFarActualSat) : "-",
      }
    : {
        field: "soFarActualBtc",
        align: "right",
        headerAlign: "right",
        headerName: `Actual cumulative (${unit})`,
        sortable: true,
        flex: 1,
        display: "flex",
        renderCell: (params) => params.row.soFarActualBtc ?? "-",
      },
  unit === "SAT"
    ? {
        field: "deltaSat",
        align: "right",
        headerAlign: "right",
        headerName: `Delta to date (${unit})`,
        sortable: true,
        flex: 1,
        display: "flex",
        renderCell: (params) => (
          <Typography color={params.row.deltaSat === undefined ? "black" : params.row.deltaSat < 0 ? "red" : "green"}>
            {params.row.deltaSat !== undefined ? formatWithCommas(params.row.deltaSat) : "-"}
          </Typography>
        ),
      }
    : {
        field: "deltaBtc",
        align: "right",
        headerAlign: "right",
        headerName: `Delta to date (${unit})`,
        sortable: true,
        flex: 1,
        display: "flex",
        renderCell: (params) => (
          <Typography color={params.row.deltaBtc === undefined ? "black" : params.row.deltaBtc < 0 ? "red" : "green"}>
            {params.row.deltaBtc ?? "-"}
          </Typography>
        ),
      },
];

export const LoanExpectedScheduleTable = ({
  expected,
  actuals,
  height,
  unit,
}: {
  expected: { date: string; expected: number; expectedSoFar: number }[];
  actuals: { createdAt: string; amountSatoshi: number }[];
  height: string | number;
  unit: "SAT" | "BTC";
}) => {
  const now = new Date();
  const sortedExpected = sortBy(expected, (x) => new Date(x.date));

  const rows: PaymentWithDelivery[] = sortedExpected.map((exp, index) => {
    const inFuture = isAfter(new Date(exp.date), now);
    const previousIsInFuture = index > 0 && isAfter(new Date(sortedExpected[index - 1].date), now);

    const soFarActualSat = previousIsInFuture
      ? undefined
      : sum(
          actuals
            .filter(
              // if it's the last entry include all deliveries, even ones after the end date
              (a) => index === expected.length - 1 || new Date(a.createdAt) <= new Date(exp.date)
            )
            .map((x) => +x.amountSatoshi)
        );
    const soFarActualBtc = soFarActualSat !== undefined ? +toFormattedBtc(soFarActualSat) : undefined;
    const deltaSat = !inFuture && soFarActualSat !== undefined ? soFarActualSat - exp.expectedSoFar : undefined;
    const deltaBtc = deltaSat !== undefined ? +toFormattedBtc(deltaSat) : undefined;

    const sinceLastActualSat = previousIsInFuture
      ? undefined
      : sum(
          actuals
            .filter(
              (md) =>
                (index === 0 || isAfter(new Date(md.createdAt), new Date(sortedExpected[index - 1].date))) &&
                // if it's the last entry include all deliveries, even ones after the end date
                (index === sortedExpected.length - 1 || isBefore(new Date(md.createdAt), new Date(exp.date)))
            )
            .map((x) => +x.amountSatoshi)
        );
    const sinceLastActualBtc = sinceLastActualSat !== undefined ? +toFormattedBtc(sinceLastActualSat) : undefined;

    return {
      id: exp.date,
      date: exp.date,
      index: index + 1,

      soFarActualSat,
      soFarActualBtc,

      soFarExpectedSat: exp.expectedSoFar,
      soFarExpectedBtc: +toFormattedBtc(exp.expectedSoFar),

      sinceLastActualSat,
      sinceLastActualBtc,

      sinceLastExpectedSat: exp.expected,
      sinceLastExpectedBtc: +toFormattedBtc(exp.expected),

      deltaSat,
      deltaBtc,
    };
  });

  const lastEntry = last(sortBy(rows, (x) => new Date(x.date)));
  const lastEntryWitihDelta = findLast(rows, (x) => x.deltaSat !== undefined);
  const soFarActualSat = sum(actuals.map((x) => +x.amountSatoshi));
  const totalRow: PaymentWithDelivery[] = lastEntry
    ? [
        {
          id: ID_TOTAL,
          date: ID_TOTAL,
          index: undefined,
          soFarExpectedSat: lastEntry.soFarExpectedSat,
          soFarExpectedBtc: lastEntry.soFarExpectedBtc,

          sinceLastExpectedSat: sumBy(rows, (x) => +x.sinceLastExpectedSat),
          sinceLastExpectedBtc: +toFormattedBtc(sumBy(rows, (x) => +x.sinceLastExpectedSat)),

          soFarActualSat: soFarActualSat,
          soFarActualBtc: +toFormattedBtc(soFarActualSat),

          sinceLastActualSat: soFarActualSat,
          sinceLastActualBtc: +toFormattedBtc(soFarActualSat),

          deltaSat: lastEntryWitihDelta?.deltaSat,
          deltaBtc: lastEntryWitihDelta?.deltaBtc,
        },
      ]
    : [];

  return (
    <Box gap={1} width={"100%"}>
      <Box sx={{ height }} width={"100%"}>
        <DataGridPro
          rows={rows}
          pinnedRows={{ bottom: totalRow }}
          rowCount={rows.length}
          columns={COLUMNS(unit)}
          pagination
          paginationMode="client"
          sortingMode="client"
          disableColumnFilter={false}
          pageSizeOptions={[25, 50, 100]}
          sx={{
            bgcolor: "white",
            boxShadow: 3,
            padding: 1,
            maxHeight: height,
            width: "100%",
          }}
          slots={{
            noRowsOverlay: () => (
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
                height={"100%"}
                sx={{ minHeight: "60px" }}
              >
                <Typography>No payment requests found.</Typography>
              </Stack>
            ),
            toolbar: DatagridExportToolbar,
          }}
        ></DataGridPro>
      </Box>
    </Box>
  );
};
