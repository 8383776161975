import { useMutation } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import { AcceptProposalDto, CreateAccessProposalDto, InviteOrganizationUserDto, RemoveRoleDto } from "../model/api";

const inviteOrganizationUser = async (data: InviteOrganizationUserDto) => {
  return await apig.user.inviteOrganizationUser(data);
};

const removeOrganizationUser = async (id: string) => {
  return await apig.user.removeOrganizationUser({ id });
};

const rejectOrApproveProposal = async (data: AcceptProposalDto) => {
  return await apig.user.rejectOrApproveProposal(data);
};

const createProposal = async (data: CreateAccessProposalDto) => {
  return await apig.user.createProposal(data);
};

const removeRole = async (data: RemoveRoleDto) => {
  return await apig.user.removeRole(data);
};

const useMutationInviteOrganizationUser = () => {
  return useMutation((data: InviteOrganizationUserDto) => inviteOrganizationUser(data));
};

const useMutationRemoveOrganizationUser = () => {
  return useMutation((id: string) => removeOrganizationUser(id));
};

const useMutationRejectOrApproveProposal = () => {
  return useMutation((data: AcceptProposalDto) => rejectOrApproveProposal(data));
};

const useMutationCreateProposal = () => {
  return useMutation((data: CreateAccessProposalDto) => createProposal(data));
};

const useMutationRemoveRole = () => {
  return useMutation((data: RemoveRoleDto) => removeRole(data));
};

export {
  useMutationInviteOrganizationUser,
  useMutationRemoveOrganizationUser,
  useMutationCreateProposal,
  useMutationRejectOrApproveProposal,
  useMutationRemoveRole,
};
