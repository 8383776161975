import OrganizationEnterpriseSettings from "../../components/organisms/OrganizationEnterpriseSettings";
import { MinerBasicInfo } from "../../pages/Admin/MinerDetails/MinerBasicInfo";
import { MinerKymDetails } from "../../pages/Admin/MinerDetails/MinerKymDetails";
import { MinerOpportunityManagement } from "../../pages/Admin/MinerDetails/Opportunity/MinerOpportunityContainer";
import {
  MINER_ORGANIZATION_BASIC_INFO_ROUTE,
  MINER_ORGANIZATION_ENTERPRISE_SETTINGS_ROUTE,
  MINER_ORGANIZATION_KYM,
  MINER_ORGANIZATION_OPPORTUNITY,
} from "./routes";

export const KYM2_MINER_DETAILS_TABS = [
  {
    name: "Miner onboarding info",
    path: MINER_ORGANIZATION_BASIC_INFO_ROUTE,
    tabComponent: ({ minerId }: { minerId: string; organizationId: string }) => <MinerBasicInfo minerId={minerId} />,
  },
  {
    name: "Enterprise settings",
    path: MINER_ORGANIZATION_ENTERPRISE_SETTINGS_ROUTE,
    tabComponent: ({ organizationId }: { minerId: string; organizationId: string }) => (
      <OrganizationEnterpriseSettings organizationId={organizationId} />
    ),
  },
  {
    name: "KYM info",
    path: MINER_ORGANIZATION_KYM,
    tabComponent: ({ organizationId }: { minerId: string; organizationId: string }) => (
      <MinerKymDetails organizationId={organizationId} />
    ),
  },
  {
    name: "Miner opportunity",
    path: MINER_ORGANIZATION_OPPORTUNITY,
    tabComponent: ({ minerId }: { minerId: string; organizationId: string }) => (
      <MinerOpportunityManagement minerId={minerId} />
    ),
  },
];
