import "./style.scss";

interface ProgressPercentBarProps {
  value: number;
  showPercentage?: boolean;
}

const ProgressPercentBar = ({ value, showPercentage = true }: ProgressPercentBarProps) => {
  return (
    <div className="progress-percent-bar flex gap-3 items-center">
      <div className="relative w-full h-[8px] bg-gray-100">
        <div
          className="percent-bar-active absolute inset-y-0 left-0"
          style={{ width: `${value > 100 ? 100 : value}%` }}
        ></div>
      </div>
      {showPercentage && <div>{value || 0}%</div>}
    </div>
  );
};

export default ProgressPercentBar;
