import { useParams } from "react-router-dom";

import HeroBox from "../../atoms/HeroBox";
import Loader from "../../atoms/Loader";
import ProgressPercentBar from "../../atoms/ProgressPercentBar";

import {
  amountFormat,
  amountFormatSatoshisToBTC,
  roundToSignificantDigits,
  roundToSignificantDigitsDiv1000,
} from "../../../app/helper/utils";
import { useGetMinerOpportunitySummary } from "../../../app/query/useQueryGetMiner";

import { ReactComponent as HashRateIcon } from "../../../assets/images/hashrate-icon.svg";
import heroCurrentBtcPrice from "../../../assets/images/hero-lp-opportunity-3.png";

import Decimal from "decimal.js";
import "./style.scss";

const HeroOpportunityDetailsMiner = () => {
  const { id } = useParams();
  const { data, isFetching } = useGetMinerOpportunitySummary(id as string);
  const progresBarValue = Number(
    roundToSignificantDigits(
      new Decimal(data?.hashrateAllocated?.current || 0)
        .div(data?.hashrateAllocated?.maximum || 1)
        .mul(100)
        .toNumber(),
      1
    )
  );

  return (
    <div className="hero-details-miner">
      <div className="container-custom mx-auto !py-16">
        <div className="grid grid-cols-3 gap-4 pb-4">
          <HeroBox title="Liquidity received" backgroundImage={heroCurrentBtcPrice}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <div className="crypto-value">
                {amountFormatSatoshisToBTC(data?.liquidityReceived?.btc || 0)} <sup className="font-light">BTC</sup>{" "}
                <span className="font-light">({amountFormat(data?.liquidityReceived?.usd || 0)})</span>
              </div>
            )}
          </HeroBox>
          <HeroBox title="Collateral balance" backgroundImage={heroCurrentBtcPrice}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <div className="crypto-value">
                {amountFormatSatoshisToBTC(data?.collateralBalance?.btc || 0)} <sup className="font-light">BTC</sup>{" "}
                <span className="font-light">({amountFormat(data?.collateralBalance?.usd || 0)})</span>
              </div>
            )}
          </HeroBox>
          <HeroBox title="hashrate allocated" titleIcon={<HashRateIcon className="w-6 mr-1" />}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <div className="w-full">
                <div className="grid grid-cols-2 gap-5">
                  <div>
                    <p>
                      <small className="text-[11px]">Hashrate Allocated</small> <br />
                      <strong>{roundToSignificantDigitsDiv1000(data.hashrateAllocated.current, 1)}</strong>{" "}
                      <sup>PH/s</sup>
                    </p>
                  </div>
                  <div>
                    <p>
                      <small className="text-[11px]">Maximum Capacity</small> <br />
                      <strong>{roundToSignificantDigitsDiv1000(data.hashrateAllocated.maximum, 1)}</strong>{" "}
                      <sup>PH/s</sup>
                    </p>
                  </div>
                </div>
                <ProgressPercentBar value={progresBarValue} />
              </div>
            )}
          </HeroBox>
        </div>
      </div>
    </div>
  );
};

export default HeroOpportunityDetailsMiner;
