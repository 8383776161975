import { useMutation, useQuery } from "@tanstack/react-query";

import { apig } from "../config/query/clientgen";
import { Sort, WithdrawDto } from "../model/api";

interface QueryProps {
  offset?: number;
  limit?: number;
}

interface SortProps {
  sort?: Sort;
}

interface QueryDepositProps extends QueryProps, SortProps {}

interface QueryHistoryProps extends QueryProps, SortProps {
  txtype?:
    | "Reward Distribution"
    | "Opportunity Funding"
    | "Liquidity Refund"
    | "Collateral Release"
    | "Collateral Deposit"
    | "Reward Delivery"
    | "Liquidity Delivery"
    | "Excess Reward Release"
    | "Unknown"
    | "Collateral Liquidation"
    | "Deposit"
    | "Reconciliation"
    | "Withdrawal";
  status?: "Pending" | "Confirmed" | "Awaiting approval";
}

const getWalletOverviewHistoryTxs = async (params: QueryHistoryProps) => {
  return (await apig.wallet.getWalletOverviewHistoryTxs(params)).data;
};

const getWalletDepositTxs = async (params: QueryDepositProps): Promise<any> => {
  return (await apig.wallet.getWalletDepositTxs(params)).data;
};

const postWalletWithdrawPreview = async (params: WithdrawDto): Promise<any> => {
  return (await apig.wallet.postWalletWithdrawPreview(params)).data;
};

const postWalletWithdrawRequest = async (params: WithdrawDto): Promise<any> => {
  return (await apig.wallet.postWalletWithdrawRequest(params)).data;
};

const getFee = async () => {
  return (await apig.wallet.getFee()).data;
};

const getSatoshiToUsdPrice = async () => {
  return (await apig.wallet.getSatoshiToUsdPrice()).data;
};

const useQueryGetWalletOverviewHistory = (params: QueryHistoryProps) => {
  return useQuery(["wallet_overview_history", params], () => getWalletOverviewHistoryTxs(params), {
    refetchOnMount: "always",
    cacheTime: 0,
  });
};

const useQueryGetWalletDeposit = (params: QueryDepositProps) => {
  return useQuery(["wallet_deposit", params], () => getWalletDepositTxs(params), {
    refetchOnMount: "always",
    cacheTime: 0,
  });
};

const useMutationPostWalletWithdrawPreview = () => {
  return useMutation((data: WithdrawDto) => postWalletWithdrawPreview(data));
};

const useMutationPostWalletWithdrawRequest = () => {
  return useMutation((data: WithdrawDto) => postWalletWithdrawRequest(data));
};

const useGetQueryWalletFee = () => {
  return useQuery(["wallet_fee"], () => getFee());
};

const useGetQuerySatoshiPriceInUsd = () => {
  return useQuery(["price_satoshi_usd"], () => getSatoshiToUsdPrice());
};

export {
  useGetQuerySatoshiPriceInUsd,
  useGetQueryWalletFee,
  useMutationPostWalletWithdrawPreview,
  useMutationPostWalletWithdrawRequest,
  useQueryGetWalletDeposit,
  useQueryGetWalletOverviewHistory,
};
