import {
  Box,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

export const VerticalInfoTable = ({
  title,
  entries,
  explainer,
}: {
  title: string;
  entries: { name: string; value: string | JSX.Element }[];
  explainer?: null | JSX.Element;
}) => {
  return (
    <Card variant="outlined" sx={{ width: "100%", border: 0 }}>
      <CardContent sx={{ padding: 0 }}>
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
        {explainer ? <Box padding={1}>{explainer}</Box> : null}
        <TableContainer component={Paper} sx={{ border: 0.2, borderColor: "lightgray", boxShadow: 3 }}>
          <Table>
            <TableBody>
              {entries.map((entry) => (
                <TableRow key={"row-wallet-info" + entry.name}>
                  <TableCell align="left" size="small">
                    <Typography variant="subtitle2" fontWeight={"fontWeightBold"}>
                      {entry.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" size="small">
                    {entry.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
