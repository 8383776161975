import { ScrollRestoration } from "react-router-dom";

interface OnboardingLayoutProps {
  children: React.ReactNode;
}

export const OnboardingLayout = ({ children }: OnboardingLayoutProps) => {
  return (
    <div>
      <main>{children}</main>
      <ScrollRestoration />
    </div>
  );
};
