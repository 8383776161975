const config = {
  className: "",
  duration: 5000,
  style: {
    border: "1px solid #713200",
    padding: "16px",
  },
  success: {
    duration: 5000,
    theme: {
      primary: "#31df8b",
      secondary: "black",
    },
  },
  error: {
    duration: 5000,
    theme: {
      primary: "#fc7a53",
      secondary: "black",
    },
    style: {
      color: "#fc7a53",
    },
  },
};

export default config;
