import { useAuth0 } from "@auth0/auth0-react";
import { Skeleton } from "@mui/material";

export const Logout = () => {
  const { logout } = useAuth0();

  logout({ returnTo: import.meta.env.VITE_HOST_DEV });

  return (
    <>
      <Skeleton height={"100vh"} width={"100%"} animation="pulse"></Skeleton>
    </>
  );
};
