import { Box, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { ResAdminLoanDto, ResLoanAssociatedValidAddress } from "../../../app/model/api";
import { ExplainerAccordion } from "../commons/ExplainerAccordion";
import { TruncatedCell } from "../commons/TruncatedCopyCell";

const COLUMNS: GridColDef<ResLoanAssociatedValidAddress>[] = [
  {
    field: "address",
    headerName: "Address",
    sortable: true,
    flex: 1,
    display: "flex",
    renderCell: (params) =>
      params.row.address ? <TruncatedCell fullText={params.row.address}>{params.row.address}</TruncatedCell> : null,
  },
  {
    field: "bitgoWalletId",
    headerName: "Bitgo wallet id",
    sortable: true,
    flex: 1,
    display: "flex",
  },
  {
    field: "name",
    headerName: "Description",
    sortable: true,
    flex: 1,
    display: "flex",
  },
  {
    field: "addressType",
    headerName: "Type",
    sortable: true,
    flex: 1,
    display: "flex",
  },
];

export const LoanAssociatedAddressesTable = ({
  loan,
  height,
}: {
  loan: ResAdminLoanDto;
  height?: string | number | undefined;
}) => {
  const rows = (loan.addresses ?? []).map((x) => ({
    id: x.address,
    ...x,
  }));

  return !rows.length ? null : (
    <Box pt={2}>
      <Typography variant="h6">Loan associated addresses</Typography>
      <ExplainerAccordion
        title={"More details"}
        explainers={[
          "These are either the addresses of the users on our platform or their external whitelisted addresses.",
          "Ideally, these are the only addresses to be used in creating a payment request, with the exception of draining completed wallets to the assigned BlockGreen wallet.",
        ]}
      />
      <Box sx={{ height }} width={"100%"}>
        <DataGridPro
          rows={rows}
          rowCount={rows.length}
          columns={COLUMNS}
          pagination
          paginationMode="client"
          sortingMode="client"
          disableColumnFilter={false}
          pageSizeOptions={[25, 50, 100]}
          sx={{
            bgcolor: "white",
            boxShadow: 3,
            padding: 1,
            maxHeight: height,
            width: "100%",
          }}
          slots={{
            noRowsOverlay: () => (
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
                height={"100%"}
                sx={{ minHeight: "60px" }}
              >
                <Typography>No associated addresses found.</Typography>
              </Stack>
            ),
          }}
        ></DataGridPro>
      </Box>
    </Box>
  );
};
