import { Box } from "@mui/material";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";

export const DatagridExportToolbar = () => {
  return (
    <GridToolbarContainer>
      <Box display="flex" width="100%" alignItems={"center"} justifyContent={"space-between"}>
        <Box />
        <GridToolbarExport />
      </Box>
    </GridToolbarContainer>
  );
};
