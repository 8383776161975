import { Stack } from "@mui/material";
import {
  ROUTE_ADMIN_OPPORTUNITY_DETAILS,
  ROUTE_ADMIN_OPPORTUNITY_LIQUIDITY,
  ROUTE_ADMIN_OPPORTUNITY_REWARDS,
  ROUTE_ADMIN_OPPORTUNITY_SIMULATOR,
  ROUTE_ADMIN_OPPORTUNITY_TIMELINE,
} from ".";
import { HeroOpportunityDetails } from "../../components/organisms/HeroOpportunityDetails/HeroOpportunityDetails";
import LiquidityProviderTable from "../../components/organisms/LiquidityProviderTable";
import { TransactionHistoryTable } from "../../components/organisms/TransactionHistoryTable";
import { AdminOpportunityEdit } from "../../pages/Admin/MinerDetails/Opportunity/AdminOpportunityEdit";
import { AdminOpportunityRewards } from "../../pages/Admin/MinerDetails/Opportunity/AdminOpportunityRewards";
import { AdminOpportunitySimulator } from "../../pages/Admin/MinerDetails/Opportunity/simulation/AdminOpportunitySimulator";
import { AdminOpportunityTimeline } from "../../pages/Admin/MinerDetails/Opportunity/AdminOpportunityTimeline";

export const KYM2_ADMIN_OPPORTUNITY_TABS: { name: string; path: string; tabComponent: (id: string) => JSX.Element }[] =
  [
    {
      name: "Opportunity details",
      path: ROUTE_ADMIN_OPPORTUNITY_DETAILS,
      tabComponent: (opportunityId: string) => <AdminOpportunityEdit opportunityId={opportunityId} />,
    },
    {
      name: "Timeline",
      path: ROUTE_ADMIN_OPPORTUNITY_TIMELINE,
      tabComponent: (opportunityId: string) => <AdminOpportunityTimeline opportunityId={opportunityId} />,
    },
    {
      name: "Liquidity info",
      path: ROUTE_ADMIN_OPPORTUNITY_LIQUIDITY,
      tabComponent: (_opportunityId: string) => (
        <Stack spacing={3}>
          <HeroOpportunityDetails />
          <LiquidityProviderTable />
          <TransactionHistoryTable />
        </Stack>
      ),
    },
    {
      name: "Rewards",
      path: ROUTE_ADMIN_OPPORTUNITY_REWARDS,
      tabComponent: (opportunityId: string) => <AdminOpportunityRewards opportunityId={opportunityId} />,
    },
    {
      name: "Opportunity simulator",
      path: ROUTE_ADMIN_OPPORTUNITY_SIMULATOR,
      tabComponent: (opportunityId: string) => <AdminOpportunitySimulator opportunityId={opportunityId} />,
    },
  ];
