import { useNavigate } from "react-router-dom";

import Button from "../../atoms/Button";
import HeroBox from "../../atoms/HeroBox";
import SectionTitle from "../../atoms/SectionTitle";

import { ReactComponent as BitGoIcon } from "../../../assets/images/bitgo-icon.svg";
import { ReactComponent as DepositIcon } from "../../../assets/images/deposit-icon.svg";
import heroReservedBck from "../../../assets/images/hero-bck-1.png";
import heroBalanceBck from "../../../assets/images/hero-bck-2.png";
import { ReactComponent as WithdrawIcon } from "../../../assets/images/withdraw-icon.svg";

import { WALLET_DEPOSIT_ROUTE, WALLET_WITHDRAW_ROUTE } from "../../../app/constants";
import { amountFormat, amountFormatSatoshisToBTC } from "../../../app/helper/utils";
import { UserTypes } from "../../../app/model/api";
import { useQueryGetUserOnMount } from "../../../app/query/useQueryGetUser";

import Loader from "../../atoms/Loader";
import "./style.scss";

const Hero = () => {
  const navigate = useNavigate();
  const { data: user, isFetching: isFetchingUser } = useQueryGetUserOnMount();

  return (
    <div className={`hero ${user?.userType === UserTypes.Miner ? "!bg-miner-wallet" : "!bg-lp-wallet"}  `}>
      <SectionTitle
        title=""
        subtitle="Powered By BitGo"
        prefixChildren={<BitGoIcon></BitGoIcon>}
        backgroundColor="rgba(25, 28, 36, 0.7)"
      >
        <div className="ml-auto flex gap-5">
          <Button
            icon={<DepositIcon />}
            label="Deposit"
            backgroundColor="#8f49fd"
            className="w-[128px] text-white"
            onClick={() => navigate(WALLET_DEPOSIT_ROUTE)}
          />
          <Button
            icon={<WithdrawIcon />}
            label="Withdraw"
            backgroundColor="#fff"
            className="!text-[#8f49fd] w-[150px]"
            onClick={() => navigate(WALLET_WITHDRAW_ROUTE)}
          />
        </div>
      </SectionTitle>

      <div className="container-custom mx-auto !py-10 ">
        <div className="grid grid-cols-2 gap-4 justify-center">
          <HeroBox title="your balance" backgroundImage={heroBalanceBck}>
            {isFetchingUser ? (
              <Loader className="!absolute z-10" />
            ) : (
              <>
                <div className="crypto-value">{amountFormatSatoshisToBTC(user?.balanceSatoshis || 0)}</div>
                <sup>BTC</sup>
                <div className="fiat-value">{amountFormat(Number(user?.balanceUsd))}</div>
              </>
            )}
          </HeroBox>
          <HeroBox title="reserved balance" backgroundImage={heroReservedBck}>
            {isFetchingUser ? (
              <Loader className="!absolute z-10" />
            ) : (
              <>
                <div className="crypto-value">{amountFormatSatoshisToBTC(user?.reservedBalanceSatoshis || 0)}</div>
                <sup>BTC</sup>
                <div className="fiat-value">{amountFormat(Number(user?.reservedBalanceUsd))}</div>
              </>
            )}
          </HeroBox>
        </div>
      </div>
    </div>
  );
};

export default Hero;
