import moment from "moment";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import Modal from "../Modal";

import { amountFormat, amountFormatSatoshisToBTC, copyToClipboard, reduceHash } from "../../../app/helper/utils";
import { ReactComponent as CopyIcon } from "../../../assets/images/copy-icon.svg";
import { ReactComponent as TxIcon } from "../../../assets/images/txLink-icon.svg";

type Data = {
  date?: string;
  fromAddress: string;
  toAddress: string;
  depoosit: number;
  txHash: string;
  txUrl: string;
  fee: number;
};

interface TransferModalProps {
  data: Data;
  open: boolean;
  disableSubmitButton?: boolean;
  onClose: () => void;
}

const TransferConfirmationModal = ({ open, data, onClose }: TransferModalProps) => {
  const handleCopy = (text: string) => {
    copyToClipboard(text);
    toast.success("Address copied to clipboard");
  };

  return (
    <Modal open={open} onClose={() => onClose()}>
      <div className="px-1 py-6">
        <div className="text-center mb-8">
          <h1 className="text-dark text-[33px] font-pp-neue-machina font-semibold leading-[120%]">
            Transaction Successful
          </h1>
        </div>
        <div>
          <div className="flex justify-between items-center mb-4">
            <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
              Status
            </div>
            <div className="text-dark text-[14px]">Confirmed</div>
          </div>
          <div className="flex justify-between items-center mb-4">
            <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
              Date & Time
            </div>
            <div className="text-dark text-[14px]">
              {moment().format("DD MMM YYYY")} | {moment().format("LT")}
            </div>
          </div>
          <div className="flex justify-between items-center mb-4">
            <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
              From
            </div>
            <div className="text-dark text-[14px]">{data.fromAddress || "-"}</div>
          </div>
          <div className="flex justify-between items-center mb-4">
            <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
              To
            </div>
            <div className="text-dark text-[14px]">{data.toAddress || "-"}</div>
          </div>
          <div className="flex justify-between items-center mb-4">
            <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
              Deposit Amount
            </div>
            <div className="text-dark text-[14px]">{`${amountFormat(data.depoosit, "BTC")} BTC`}</div>
          </div>
          <div className="flex justify-between items-center mb-4">
            <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
              Bitcoin Network Fee
            </div>
            <div className="text-dark text-[14px]">{`${amountFormatSatoshisToBTC(data.fee)} BTC`}</div>
          </div>
          <div className="flex justify-between items-center">
            <div className="font-pp-neue-machina font-semibold uppercase text-black text-[13px] tracking-[.03em]">
              Tx Hash
            </div>
            <div className="text-dark text-[14px] flex gap-2 items-center">
              <span>{data?.txHash ? reduceHash(data?.txHash, 8) : ""}</span>
              <button
                disabled={!!data?.txHash}
                className="bg-gray-100 px-2 py-1 cursor-pointer"
                onClick={() => (data?.txHash ? handleCopy(data.txHash) : {})}
              >
                <CopyIcon className="w-4" />
              </button>
              <Link className="bg-gray-100 px-2 py-1" to={data.txUrl} target="_blank">
                <TxIcon className="w-4" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TransferConfirmationModal;
