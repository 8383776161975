import "./style.scss";

interface HeroBoxTextProps {
  backgroundImage?: string;
  text?: Element;
}

const HeroBoxText = ({ backgroundImage }: HeroBoxTextProps) => {
  const customStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
  };

  return (
    <div className="hero-box-text px-[26px] py-[30px]" style={customStyle}>
      <ul>
        <li>
          Send <strong>only BTC</strong> to this deposit address
        </li>
        <li>Please send a small test transaction first</li>
        <li>Transactions are considered confirmed after 6 blocks</li>
        <li>
          Deposit hasn't arrived?{" "}
          <a target="_blank" href="mailto:support@block.green" className="hover:no-underline" rel="noreferrer">
            Contact us
          </a>
        </li>
      </ul>
    </div>
  );
};

export default HeroBoxText;
