import { Fragment, useState, memo, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { ERROR_INPUT_CLASS, ERROR_LABEL_CLASS, ERROR_MESSAGE_CLASS } from "../../../app/constants";

import "./style.scss";

interface SelectProps {
  name: string;
  label?: string;
  value?: any;
  placeholder?: string;
  className?: string;
  error?: string;
  disabled?: boolean;
  options: Array<{ value: string | number; label: string }>;

  onChange?: (name: string, value: string | number | boolean) => void;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Select = ({
  label,
  name,
  value,
  className,
  placeholder,
  error,
  options,
  onChange,
  disabled = false,
}: SelectProps) => {
  const [selected, setSelected] = useState<{ label: string; value: string | number | "devider" }>({
    label: "",
    value: "",
  });

  const handleSelect = (selectedValue: any) => {
    const option = options.find((item) => item.value === selectedValue);
    if (option) {
      setSelected(option);
      onChange && onChange(name, selectedValue);
    }
  };

  useEffect(() => {
    if (!value) return;
    setSelected(value);
  }, [value]);

  return (
    <Listbox
      value={selected?.value}
      data-test={`select-${name}`}
      onChange={(value) => (!disabled ? handleSelect(value) : {})}
    >
      {({ open }) => (
        <div className={`custom-form-group ${className || ""}`}>
          {label && <Listbox.Label className={`flex gap-2 ${error ? ERROR_LABEL_CLASS : ""}`}>{label}</Listbox.Label>}

          <div className="relative">
            <Listbox.Button
              data-test={`button-${name}`}
              className={`custom-select min-h-[47px] bg-white ${disabled ? "cursor-not-allowed" : ""} ${
                error ? ERROR_INPUT_CLASS : ""
              }`}
              disabled={disabled}
            >
              <span>{selected?.label !== "" ? selected?.label : placeholder}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={!disabled ? open : false}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={
                  "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                }
              >
                {options.map((item, key) => {
                  if (item.value === "devider") {
                    return (
                      <li key={`devider_${key}`} className="px-3 my-2">
                        <div className="h-[2px] w-full bg-purple"></div>
                      </li>
                    );
                  } else {
                    return (
                      <Listbox.Option
                        key={item.value}
                        className={({ active }) =>
                          classNames(
                            active ? "text-white bg-[#8f49fd]" : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                          )
                        }
                        value={item?.value}
                      >
                        {({ selected, active }) => {
                          return (
                            <>
                              <span
                                className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}
                              >
                                {item?.label}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-[#8f49fd]",
                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          );
                        }}
                      </Listbox.Option>
                    );
                  }
                })}
              </Listbox.Options>
            </Transition>
          </div>
          {error && (
            <p data-test={`errorLabel-${name}`} className={ERROR_MESSAGE_CLASS}>
              {error}
            </p>
          )}
        </div>
      )}
    </Listbox>
  );
};

export default memo(Select, (prev, next) => {
  return (
    prev?.error === next?.error &&
    prev?.options.length === next?.options.length &&
    prev?.value === next?.value &&
    prev?.disabled === next?.disabled
  );
});
