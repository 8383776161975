import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Card,
  CardContent,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
import toast from "react-hot-toast";
import { useMutationAdminGenerateFileUrl } from "../../../app/query/useMutationAdmin";
import { useQueryGetMinerKymAsicById } from "../../../app/query/useQueryGetAdmin";
import { DisplayFileList } from "../../../components/atoms/FileInput/ChipFile";
import { MuiFullscreenLoadingBackdrop } from "../../../components/atoms/MuiCircularPercentage/MuiLoadingBackdrop";
import { buildChipFileItem } from "../../KYM/commons";
import { StyledTableRow } from "../commons";

export const ReadonlyMinerAsic = ({ organizationId }: { organizationId: string }) => {
  const { data, isFetching, error } = useQueryGetMinerKymAsicById(organizationId);
  const { mutateAsync: generateFileUrl, isLoading: isGettingFile } = useMutationAdminGenerateFileUrl();

  const handleDownload = async (id: string) => {
    try {
      const result = await generateFileUrl(id);
      if (result.data.url) {
        window.open(result.data.url, "_blank", "noopener,noreferrer");
      } else {
        toast.error("Was not able to find the requested file.");
      }
    } catch (err) {
      toast.error("There has been an issue getting the file.");
    }
  };
  const asicInfo = data?.data.payload;

  const machineFiles = asicInfo?.machineListFiles?.map(buildChipFileItem) ?? [];
  const machineInvoices = asicInfo?.machineInvoiceFiles?.map(buildChipFileItem) ?? [];
  const uptimeStats = asicInfo?.minerUptimeStatsFiles?.map(buildChipFileItem) ?? [];

  return isFetching ? (
    <Skeleton width={"100%"} height={300} />
  ) : error ? (
    <Stack>
      <Alert severity="warning">
        There has been an issue loading the miner data. Please refresh the page to try again.
      </Alert>
    </Stack>
  ) : (
    <Grid container item xs={12}>
      <MuiFullscreenLoadingBackdrop isOpen={isGettingFile} />
      <Card variant="outlined" sx={{ width: "100%", border: 0 }}>
        <CardContent>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <StyledTableRow key={"row-avg-efficiency"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Average efficiency for fleet
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {asicInfo?.averageFleetEfficiencyJPerTh == undefined ? (
                      <CloseIcon color="error" />
                    ) : (
                      `${asicInfo?.averageFleetEfficiencyJPerTh} J/TH`
                    )}
                  </TableCell>
                </StyledTableRow>

                <StyledTableRow key={"row-machine-files"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Machine list
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {asicInfo?.machineListFiles.length ? (
                      <DisplayFileList
                        id={"dfl-machinelist"}
                        files={machineFiles}
                        onDownload={handleDownload}
                      ></DisplayFileList>
                    ) : (
                      <Typography variant="caption" color="text.secondary">
                        No files uploaded by miner
                      </Typography>
                    )}
                  </TableCell>
                </StyledTableRow>

                <StyledTableRow key={"row-asic-price"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Average ASIC purchase price
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {asicInfo?.averageAsicPurchasePriceUsdPerTh == undefined ? (
                      <CloseIcon color="error" />
                    ) : (
                      `${asicInfo?.averageAsicPurchasePriceUsdPerTh} USD/TH`
                    )}
                  </TableCell>
                </StyledTableRow>

                <StyledTableRow key={"row-machine-invoices"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Machine invoices
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {asicInfo?.machineInvoiceFiles.length ? (
                      <DisplayFileList
                        id={"dfl-machineinvoices"}
                        files={machineInvoices}
                        onDownload={handleDownload}
                      ></DisplayFileList>
                    ) : (
                      <Typography variant="caption" color="text.secondary">
                        No files uploaded by miner
                      </Typography>
                    )}
                  </TableCell>
                </StyledTableRow>

                <StyledTableRow key={"row-asic-price"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Average ASIC 12-month up time
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {asicInfo?.asicUptimePercentage == undefined ? (
                      <CloseIcon color="error" />
                    ) : (
                      `${asicInfo?.asicUptimePercentage} %`
                    )}
                  </TableCell>
                </StyledTableRow>

                <StyledTableRow key={"row-machine-invoices"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Uptime stats files
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {uptimeStats.length ? (
                      <DisplayFileList
                        id={"dfl-uptimeStats"}
                        files={uptimeStats}
                        onDownload={handleDownload}
                      ></DisplayFileList>
                    ) : (
                      <Typography variant="caption" color="text.secondary">
                        No files uploaded by miner
                      </Typography>
                    )}
                  </TableCell>
                </StyledTableRow>

                <StyledTableRow key={"row-encumbrance"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Capacity encumbrance
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {asicInfo?.capacityEncumbrancePercentage == undefined ? (
                      <CloseIcon color="error" />
                    ) : (
                      `${asicInfo?.capacityEncumbrancePercentage} %`
                    )}
                  </TableCell>
                </StyledTableRow>

                <StyledTableRow key={"row-encumbrance"}>
                  <TableCell align="left" width={250}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      Has fire & property insurance
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {asicInfo?.hasInsurance == undefined ? (
                      <CloseIcon color="error" />
                    ) : asicInfo.hasInsurance ? (
                      "Yes"
                    ) : (
                      "No"
                    )}
                  </TableCell>
                </StyledTableRow>
                {asicInfo?.hasInsurance ? (
                  <StyledTableRow key={"row-encumbrance"}>
                    <TableCell align="left" width={250}>
                      <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                        Fire & property insurance
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {asicInfo?.insuranceDescription == undefined ? (
                        <CloseIcon color="error" />
                      ) : (
                        asicInfo.insuranceDescription
                      )}
                    </TableCell>
                  </StyledTableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};
