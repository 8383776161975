import Decimal from "decimal.js";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import { TableAmountCell } from "../../atoms/TableAmountCell";

import { ERROR_DEFAULT_MESSAGE, SATOSHIS_TO_BTC } from "../../../app/constants";
import { downloadFile, reduceHash } from "../../../app/helper/utils";
import { CSVTransactionType, ResTransactionDto } from "../../../app/model/api";
import { useGetQueryOpportunityHistory } from "../../../app/query/useGetQueryOpportunity";
import { useMutationExportCsv } from "../../../app/query/useMutationCsv";

import { LoadingButton } from "@mui/lab";
import { Box, FormControl, InputLabel, Link, MenuItem, Select, Skeleton, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { isCustomError } from "../../../app/helper/errors";
import { ReactComponent as TxIcon } from "../../../assets/images/txLink-icon.svg";

type TransactionTypeFilter =
  | "all"
  | "Reward Distribution"
  | "Opportunity Funding"
  | "Liquidity Refund"
  | "Collateral Release"
  | "Collateral Deposit"
  | "Reward Delivery"
  | "Liquidity Delivery"
  | "Excess Reward Release"
  | "Collateral Liquidation";

const APPROVAL_ADMIN_TRANSACTION_TYPES: { value: TransactionTypeFilter; label: string }[] = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "Collateral Deposit",
    label: "Collateral Deposit",
  },
  {
    value: "Opportunity Funding",
    label: "Opportunity Funding",
  },
  {
    value: "Reward Distribution",
    label: "Reward Distribution",
  },
  {
    value: "Liquidity Refund",
    label: "Liquidity Refund",
  },
  {
    value: "Collateral Release",
    label: "Collateral Release",
  },
  {
    value: "Liquidity Delivery",
    label: "Liquidity Delivery",
  },
  {
    value: "Excess Reward Release",
    label: "Excess Reward Release",
  },
  {
    value: "Reward Delivery",
    label: "Reward Delivery",
  },
];

type RowPagination = {
  readonly page: number;
  readonly pageSize: number;
};

export const TransactionHistoryTable = () => {
  const { id } = useParams();
  const [paginationModel, setPaginationModel] = useState<RowPagination>({
    page: 0,
    pageSize: 25,
  });
  const handlePaginationChange = (input: RowPagination) => {
    if (input.pageSize !== paginationModel.pageSize) {
      setPaginationModel({
        page: 0,
        pageSize: input.pageSize,
      });
    } else if (input.page !== paginationModel.page) {
      setPaginationModel(input);
    }
  };

  const [transactionHistoryFilter, setTransactionHistoryFilter] = useState<TransactionTypeFilter>("all");

  const { data, isFetching } = useGetQueryOpportunityHistory(String(id), {
    offset: paginationModel.pageSize * paginationModel.page,
    limit: paginationModel.pageSize,
    ...(transactionHistoryFilter !== "all" ? { txtype: transactionHistoryFilter } : null),
  });
  const rows =
    data?.rows.map((x) => ({
      id: x.txId,
      ...x,
      datetime: x.datetime ? format(new Date(x.datetime), "dd MMM yyyy HH:mm") : undefined,
    })) ?? [];
  const { mutateAsync: exportCsv, isLoading: isExporting } = useMutationExportCsv();

  const handleDownloadCsv = async () => {
    try {
      const { url } = await exportCsv({ csvTransactionType: CSVTransactionType.INTERNAL, opportunityId: id });
      downloadFile(url);
    } catch (error: unknown) {
      if (isCustomError(error)) toast.error(error.error.message);
      else toast.error(ERROR_DEFAULT_MESSAGE);
    }
  };

  const columns: GridColDef<ResTransactionDto>[] = useMemo(() => {
    return [
      {
        field: "status",
        headerName: "Status",
        sortable: false,
        disableColumnMenu: true,
        width: 120,
        display: "flex",
      },
      {
        field: "addresses",
        headerName: "Addresses",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: "flex",
        renderCell: (params) => {
          return (
            <Box>
              <div>
                <b>From:</b> {params.row.fromName} (
                <span className="text-[#BC92FF]">{reduceHash(params.row.fromAddr ?? "")}</span>)
              </div>
              <div>
                <b>To:</b> {params.row.toName} (<span className="text-[#BC92FF]">{reduceHash(params.row.toAddr)}</span>)
              </div>
            </Box>
          );
        },
      },
      {
        field: "transactionType",
        headerName: "Type",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: "flex",
      },
      {
        field: "datetime",
        headerName: "Date & time",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: "flex",
      },
      {
        field: "amount",
        headerName: "Amount",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: "flex",
        renderCell: (params) => (
          <TableAmountCell
            type={params.row.transactionType}
            amountBtc={new Decimal(params.row.amountBtc).div(SATOSHIS_TO_BTC).toNumber()}
            amountUsd={params.row.amountUsd}
          />
        ),
      },
      {
        field: "txLink",
        headerName: "Tx link",
        sortable: false,
        disableColumnMenu: true,
        align: "right",
        headerAlign: "right",
        width: 150,
        display: "flex",
        renderCell: (params) => {
          return (
            <Box maxWidth={"100%"} display="flex" justifyContent={"flex-end"} alignItems={"center"}>
              <Link href={params.row.txLink} target="_blank">
                <TxIcon width={20} />
              </Link>
            </Box>
          );
        },
      },
    ];
  }, []);

  return (
    <Stack spacing={1}>
      <Box display="flex" alignItems={"center"} gap={1}>
        <Typography variant="h5">Transaction history</Typography>
        <LoadingButton loading={isExporting || isFetching} onClick={handleDownloadCsv}>
          Export all transactions
        </LoadingButton>
      </Box>
      <FormControl sx={{ width: 250 }}>
        <InputLabel size="small" id="select-type">
          Transaction type
        </InputLabel>
        <Select
          labelId="select-type"
          label={"Transaction type"}
          id="select-type"
          size="small"
          value={transactionHistoryFilter}
          onChange={(newValue) => {
            setTransactionHistoryFilter(newValue.target.value as TransactionTypeFilter);
            setPaginationModel({
              ...paginationModel,
              page: 0,
            });
          }}
        >
          {APPROVAL_ADMIN_TRANSACTION_TYPES.map((action) => (
            <MenuItem key={"key-select-type" + action.value} value={action.value}>
              {action.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {isFetching ? (
        <Stack alignItems={"center"} justifyContent={"center"} width={"100%"} height={"100%"} spacing={1}>
          <Skeleton width="100%" height={50} variant="rectangular" />
          <Skeleton width="100%" height={50} variant="rectangular" />
          <Skeleton width="100%" height={50} variant="rectangular" />
        </Stack>
      ) : null}
      {!rows.length && !isFetching && (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          height={"100%"}
          sx={{ minHeight: "60px" }}
        >
          <Typography variant="h4" color="text.secondary">
            No entries found.
          </Typography>
        </Stack>
      )}
      {!!rows.length && !isFetching && (
        <Box sx={{ flexGrow: 1 }}>
          <DataGridPro
            rows={rows}
            rowCount={data?.count ?? 0}
            columns={columns}
            loading={isFetching}
            pagination
            paginationMode="server"
            sortingMode="server"
            disableColumnFilter={true}
            pageSizeOptions={[25, 50, 100]}
            rowHeight={67}
            rowSelection={false}
            paginationModel={paginationModel}
            initialState={{
              pagination: { paginationModel },
            }}
            onPaginationModelChange={(input) => {
              if (input.pageSize === 25 || input.pageSize === 50 || input.pageSize === 100)
                handlePaginationChange({
                  page: input.page,
                  pageSize: input.pageSize,
                });
            }}
            sx={{
              bgcolor: "white",
              boxShadow: 3,
              height: "50vh",
              width: "100%",
              padding: 1,
            }}
          ></DataGridPro>
        </Box>
      )}
    </Stack>
  );
};
