import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import { Alert, Box, Button } from "@mui/material";
import { DLD_POWER_OF_ATTORNEY } from "../../app/helper/utils";

export const PowerOfAttorneyAlert = () => {
  return (
    <Alert
      severity="warning"
      sx={{ display: "flex", alignItems: "center" }}
      icon={
        <Button
          variant="contained"
          startIcon={<CloudDownloadIcon fontSize="small"></CloudDownloadIcon>}
          sx={{ height: 40 }}
        >
          <a href={DLD_POWER_OF_ATTORNEY} target="_blank" download rel="noreferrer">
            DOWNLOAD
          </a>
        </Button>
      }
    >
      <Box display="flex" alignItems={"center"}>
        An authorized individual is required to conduct an online identification process and approve the ownership
        structure. Please complete the enclosed Power of Attorney template and send by email to support@block.green.
      </Box>
    </Alert>
  );
};
