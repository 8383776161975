import { TableRow, styled } from "@mui/material";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    border: 0,
    height: 35,
  },
  "&:nth-of-type(even)": {
    border: 0,
    height: 35,
  },
}));
