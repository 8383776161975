import { Link } from "@mui/material";
import moment from "moment";
import { amountFormatSatoshisToBTC } from "../../../app/helper/utils";
import { OpportunitiesDetails } from "../../../app/model/api";
import { TruncatedCell } from "../../../pages/Loans/commons/TruncatedCopyCell";
import { getAddressUrl } from "../../../pages/Loans/commons/utils";

interface BoxCompanyInfoProps {
  opportunityDetails: OpportunitiesDetails;
}

const BoxOpportunityInfo = ({ opportunityDetails }: BoxCompanyInfoProps) => {
  const {
    rewardAddress,
    collateralAddress,
    collateralStatus,
    collateralBalance,
    structuringFeePercentage,
    managementFeePercentage,
    synHashPoolAddress,
    opportunityBasic: { remainingDuration, settlementDate, agreementEndDate },
  } = opportunityDetails;

  return (
    <div>
      <div className="flex justify-between items-center bg-white mb-5 px-6 py-6">
        <p>
          <strong>SynHashPool address</strong>
        </p>
        <p>
          {
            <TruncatedCell fullText={synHashPoolAddress}>
              <Link target="_blank" href={getAddressUrl(synHashPoolAddress)}>
                {synHashPoolAddress}
              </Link>
            </TruncatedCell>
          }
        </p>
      </div>
      <div className="flex justify-between items-center bg-white mb-5 px-6 py-6">
        <p>
          <strong>Reward address</strong>
        </p>
        <p>
          {rewardAddress ? (
            <TruncatedCell fullText={rewardAddress}>
              <Link target="_blank" href={getAddressUrl(rewardAddress)}>
                {rewardAddress}
              </Link>
            </TruncatedCell>
          ) : (
            "-"
          )}
        </p>
      </div>
      <div className="flex justify-between items-center bg-white mb-5 px-6 py-6">
        <p>
          <strong>Collateral address</strong>
        </p>
        <p>
          {collateralAddress ? (
            <TruncatedCell fullText={collateralAddress}>
              <Link target="_blank" href={getAddressUrl(collateralAddress)}>
                {collateralAddress}
              </Link>
            </TruncatedCell>
          ) : (
            "-"
          )}
        </p>
      </div>
      <div className="flex justify-between items-center bg-white mb-5 px-6 py-6">
        <p>
          <strong>Collateral status</strong>
        </p>
        <div className="status-box confirmed-status inline-block">{collateralStatus}</div>
      </div>
      <div className="flex justify-between items-center bg-white mb-5 px-6 py-6">
        <p>
          <strong>Collateral balance</strong>
        </p>
        <p>{amountFormatSatoshisToBTC(collateralBalance || 0)} BTC</p>
      </div>
      <div className="flex justify-between items-center bg-white mb-5 px-6 py-6">
        <p>
          <strong>Management fee</strong>
        </p>
        <p>{managementFeePercentage || 0} %</p>
      </div>
      <div className="flex justify-between items-center bg-white mb-5 px-6 py-6">
        <p>
          <strong>Structuring fee</strong>
        </p>
        <p>{structuringFeePercentage || 0} %</p>
      </div>
      <div className="flex justify-between items-center bg-white mb-5 px-6 py-6">
        <p>
          <strong>Agreement end date</strong>
        </p>
        <p>{agreementEndDate ? moment(agreementEndDate).format("DD MMM YYYY") : "-"}</p>
      </div>
      <div className="flex justify-between items-center bg-white mb-5 px-6 py-6">
        <p>
          <strong>Days remaining</strong>
        </p>
        <p>{remainingDuration ?? "-"}</p>
      </div>
      <div className="flex justify-between items-center bg-white mb-5 px-6 py-6">
        <p>
          <strong>Settlement date</strong>
        </p>
        <p>{settlementDate ? moment(settlementDate).format("DD MMM YYYY") : "-"}</p>
      </div>
    </div>
  );
};

export default BoxOpportunityInfo;
