import { useMutation } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import { UpdateFunding } from "../model/api";

const updateFunding = async (payload: UpdateFunding) => {
  return await apig.lp.updateFunding(payload);
};

const useMutationFunding = () => {
  return useMutation((payload: UpdateFunding) => updateFunding(payload));
};

export { useMutationFunding };
