import Decimal from "decimal.js";
import { SATOSHIS_TO_BTC } from "../../../app/constants";
import { ReqUpdateDraftLoan } from "../../../app/model/api";

export const formatWithCommas = (input: string | number) => (+input).toLocaleString();

export const toBtc = (input: Decimal.Value) => new Decimal(input).div(SATOSHIS_TO_BTC).toNumber();

export const toSats = (btc: Decimal.Value) => new Decimal(btc).mul(SATOSHIS_TO_BTC).toNumber();

export const toFormattedBtc = (satoshi: number | string) => (+toBtc(satoshi).toFixed(6)).toString();

export const formatBtcAmount = (amountSatoshis: Decimal.Value) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "BTC",
    notation: "compact",
    maximumSignificantDigits: 4,
    minimumFractionDigits: 0,
  })
    .format(new Decimal(amountSatoshis).div(SATOSHIS_TO_BTC).toNumber())
    // Put "BTC" at the end, keeping the sign if it exists
    .replace(/^(-)?BTC\s*(.+)$/, "$1$2 BTC");

export const formatUsdAmount = (amount: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    notation: "standard",
    maximumSignificantDigits: 4,
    maximumFractionDigits: 0,
  }).format(amount);

export function checkExhaustive(_x: never): never {
  throw new Error("Exhaustive check failed");
}
export const calculationKindName = (_type: ReqUpdateDraftLoan["calculationKind"]) => {
  switch (_type) {
    case "DAILY_PRINCIPAL_30D_PAYMENT":
      return "Principal 30d monthly";
    case "DAILY_PRINCIPAL_MONTHLY_PAYMENT":
      return "Principal actual monthly";
    case "DAILY_PRINCIPAL_DAILY_PAYMENT":
      return "Principal daily";
    case "DAILY_INTEREST_30D_PAYMENT":
      return "Interest 30d monthly";
    case "DAILY_INTEREST_MONTHLY_PAYMENT":
      return "Interest actual monthly";
    case "DAILY_INTEREST_DAILY_PAYMENT":
      return "Interest daily";
    case "CUSTOM_SCHEDULE":
      return "Custom schedule";
    default:
      checkExhaustive(_type);
  }
};

const TRANSACTION_URL = {
  mainnet: "https://mempool.space/tx/",
  testnet: "https://mempool.space/testnet/tx/",
} as const;

export function getTransactionUrl(transactionHash: string) {
  const network = import.meta.env.VITE_ENVIRONMENT === "production" ? "mainnet" : "testnet";
  return TRANSACTION_URL[network] + transactionHash;
}

const ADDRESS_URL = {
  mainnet: "https://mempool.space/address/",
  testnet: "https://mempool.space/testnet/address/",
} as const;

export function getAddressUrl(address: string) {
  const network = import.meta.env.VITE_ENVIRONMENT === "production" ? "mainnet" : "testnet";
  return ADDRESS_URL[network] + address;
}

export const DUST_SATOSHIS = 2730;
