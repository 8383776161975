import "./assets/App.css";

import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

const FONTS = {
  lato: "Lato",
  latoBold: "Lato Bold",
};

const bodyFont = FONTS.lato;
const headingFont = FONTS.latoBold;

export const buildGlobalTheme = () => {
  const theme = createTheme({
    typography: {
      fontFamily: "Lato",
      fontSize: 16,
      button: {
        textTransform: "none",
      },
    },
    palette: {
      primary: {
        main: "#8f49fd",
      },
      secondary: {
        main: grey[600],
      },
    },
  });

  theme.typography.h5 = {
    ...theme.typography.subtitle1,
    fontSize: "1.4rem",
    fontFamily: headingFont,
  };

  theme.typography.h6 = {
    ...theme.typography.subtitle1,
    fontSize: "1.2rem",
    fontFamily: headingFont,
  };

  theme.typography.subtitle1 = {
    ...theme.typography.subtitle1,
    fontSize: "1.00rem",
    fontFamily: headingFont,
  };

  theme.typography.subtitle2 = {
    ...theme.typography.subtitle2,
    fontSize: "0.95rem",
    fontFamily: headingFont,
  };

  theme.typography.body1 = {
    ...theme.typography.body1,
    fontSize: "0.95rem",
    fontFamily: bodyFont,
  };

  theme.typography.body2 = {
    ...theme.typography.body2,
    fontSize: "0.90rem",
    fontFamily: bodyFont,
  };
  return theme;
};
