import { Wallet } from "./Wallet";
import {
  WALLET_DEPOSIT_ROUTE,
  WALLET_ROUTE,
  WALLET_WITHDRAW_ROUTE,
  WHITELIST_ADDRESS_ROUTE,
} from "../../app/constants/routes";
import Deposit from "../Deposit";
import WhiteListAddress from "../WhitelistAddress";
import Withdraw from "../Withdraw";

export const WALLET_SECTIONS = [
  { name: "Overview", path: WALLET_ROUTE, tabComponent: () => <Wallet /> },
  { name: "Deposit", path: WALLET_DEPOSIT_ROUTE, tabComponent: () => <Deposit /> },
  { name: "Withdraw", path: WALLET_WITHDRAW_ROUTE, tabComponent: () => <Withdraw /> },
  { name: "Whitelist address manager", path: WHITELIST_ADDRESS_ROUTE, tabComponent: () => <WhiteListAddress /> },
];
