import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";

import { TableAmountCell } from "../../components/atoms/TableAmountCell";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import Decimal from "decimal.js";
import {
  ERROR_DEFAULT_MESSAGE,
  OPPORTUNITY_DETAILS_ROUTE,
  SATOSHIS_TO_BTC,
  SUCCESS_UPDATE_TRANCHE_STATUS_MESSAGE,
} from "../../app/constants";
import { FundingApprovalStatus, LpAgreementOpportunityDto, TransactionHistoryMinerOrLpType } from "../../app/model/api";
import { useMutationUpdateFundingStatus } from "../../app/query/useMutationAdmin";
import { useGetLpAgreements } from "../../app/query/useQueryGetAdmin";
import { useGetQuerySatoshiPriceInUsd } from "../../app/query/useQueryGetWallet";

const LP_AGREEMENTS_STATUSES = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "Approved",
    label: "Approved",
  },
  {
    value: "Rejected",
    label: "Rejected",
  },
  {
    value: "AwaitingAgreementSignature",
    label: "Awaiting agreement signature",
  },
  {
    value: "AwaitingTransactionApproval",
    label: "Awaiting transaction approval",
  },
];

type RowPagination = {
  readonly page: number;
  readonly pageSize: number;
};

export const DocumentApproval = () => {
  const queryClient = useQueryClient();
  const [paginationModel, setPaginationModel] = useState<RowPagination>({
    page: 0,
    pageSize: 25,
  });
  const { data: satoshisToUsd } = useGetQuerySatoshiPriceInUsd();

  const [status, setStatus] = useState<FundingApprovalStatus | "all">("all");

  const {
    data,
    refetch: refetchLpAgreements,
    isLoading,
  } = useGetLpAgreements({
    offset: paginationModel.pageSize * paginationModel.page,
    limit: paginationModel.pageSize,
    status: status === "all" ? undefined : status,
  });
  const { mutateAsync, isLoading: isLoadingUpdateStatus } = useMutationUpdateFundingStatus();

  const handlePaginationChange = (input: RowPagination) => {
    if (input.pageSize !== paginationModel.pageSize) {
      setPaginationModel({
        page: 0,
        pageSize: input.pageSize,
      });
    } else if (input.page !== paginationModel.page) {
      setPaginationModel(input);
    }
  };

  const columns: GridColDef<LpAgreementOpportunityDto>[] = useMemo(() => {
    const handleUpdateStatus = async (id: string, status: FundingApprovalStatus) => {
      try {
        await mutateAsync({ id, status });
        queryClient.invalidateQueries({ queryKey: ["lp_agreements"] });
        setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
        refetchLpAgreements();
        toast.success(SUCCESS_UPDATE_TRANCHE_STATUS_MESSAGE);
      } catch (error) {
        toast.error(ERROR_DEFAULT_MESSAGE);
      }
    };
    return [
      {
        field: "opportunityIdNum",
        headerName: "Opportunity",
        sortable: false,
        disableColumnMenu: true,
        width: 120,
        display: "flex",
        renderCell: (params) => {
          return (
            <Link color="primary" href={OPPORTUNITY_DETAILS_ROUTE.replace(":id", params.row.id)}>
              #{params.row.opportunityNumId}
            </Link>
          );
        },
      },
      {
        field: "approvalStatus",
        headerName: "Status",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: "flex",
      },
      {
        field: "companyName",
        headerName: "Company name",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: "flex",
      },
      {
        field: "amount",
        headerName: "Amount",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: "flex",
        renderCell: (params) => (
          <Box display="flex" alignItems={"center"} height={"100%"}>
            <TableAmountCell
              type={TransactionHistoryMinerOrLpType.OpportunityFunding}
              amountBtc={new Decimal(params.row.amountSatoshis).div(SATOSHIS_TO_BTC).toNumber()}
              amountUsd={new Decimal(params.row.amountSatoshis).mul(satoshisToUsd ?? 0).toNumber()}
            />
          </Box>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        disableColumnMenu: true,
        align: "right",
        headerAlign: "right",
        width: 200,
        display: "flex",
        renderCell: (params) => {
          return (
            <Box maxWidth={"100%"} display="flex" justifyContent={"flex-end"} alignItems={"center"} gap={1} pt={1.5}>
              <IconButton
                color="primary"
                size="small"
                sx={{ padding: 0 }}
                disabled={
                  isLoading ||
                  isLoadingUpdateStatus ||
                  params.row.approvalStatus !== FundingApprovalStatus.AwaitingAgreementSignature
                }
                onClick={() =>
                  params.row.approvalStatus === FundingApprovalStatus.AwaitingAgreementSignature
                    ? handleUpdateStatus(params.row.id, FundingApprovalStatus.AwaitingTransactionApproval)
                    : {}
                }
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                color="error"
                size="small"
                sx={{ padding: 0 }}
                disabled={
                  isLoading ||
                  isLoadingUpdateStatus ||
                  params.row.approvalStatus !== FundingApprovalStatus.AwaitingAgreementSignature
                }
                onClick={() =>
                  params.row.approvalStatus === FundingApprovalStatus.AwaitingAgreementSignature
                    ? handleUpdateStatus(params.row.id, FundingApprovalStatus.Rejected)
                    : {}
                }
              >
                <CancelIcon />
              </IconButton>
            </Box>
          );
        },
      },
    ];
  }, [
    isLoading,
    isLoadingUpdateStatus,
    mutateAsync,
    paginationModel.pageSize,
    queryClient,
    refetchLpAgreements,
    satoshisToUsd,
  ]);

  return (
    <Stack alignItems={"center"} width={"100%"}>
      <Stack sx={{ background: "white", width: "100%", maxWidth: 1600, padding: 3, pl: 6, pr: 6 }} spacing={2}>
        <Box>
          <Typography variant="body1" color="text.secondary" textAlign={"justify"}>
            The following LPs have requested to fund an opportunity.
          </Typography>
          <Typography variant="body1" color="text.secondary" textAlign={"justify"}>
            Please send them the docsend link with an agreement for this opportunity and funding amount. Once the
            signature has been received come back here and approve the funding.
          </Typography>
        </Box>
        <FormControl sx={{ width: 250 }}>
          <InputLabel id="select-status">Status</InputLabel>
          <Select
            defaultValue=""
            labelId="select-status"
            label={"Status"}
            id="select-status"
            value={status}
            onChange={(newValue) => {
              if (newValue.target.value === "all") setStatus("all");
              else setStatus(FundingApprovalStatus[newValue.target.value as keyof typeof FundingApprovalStatus]);
              setPaginationModel({
                page: 0,
                pageSize: paginationModel.pageSize,
              });
            }}
          >
            {LP_AGREEMENTS_STATUSES.map((action) => (
              <MenuItem key={"key-select-status" + action.value} value={action.value}>
                {action.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isLoading ? (
          <Stack alignItems={"center"} justifyContent={"center"} width={"100%"} height={"100%"} spacing={1}>
            <Skeleton width="100%" height={50} variant="rectangular" />
            <Skeleton width="100%" height={50} variant="rectangular" />
            <Skeleton width="100%" height={50} variant="rectangular" />
          </Stack>
        ) : null}
        {!data?.rows.length && !isLoading && (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            height={"100%"}
            sx={{ minHeight: "60px" }}
          >
            <Typography variant="h4" color="text.secondary">
              No entries found.
            </Typography>
          </Stack>
        )}
        {!!data?.rows.length && (
          <Box sx={{ height: "65vh", display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGridPro
                rows={data.rows}
                rowCount={data?.count ?? 0}
                columns={columns}
                loading={isLoading}
                pagination
                paginationMode="server"
                sortingMode="server"
                disableColumnFilter={true}
                pageSizeOptions={[25, 50, 100]}
                rowHeight={67}
                paginationModel={paginationModel}
                initialState={{
                  pagination: { paginationModel },
                }}
                onPaginationModelChange={(input) => {
                  if (input.pageSize === 25 || input.pageSize === 50 || input.pageSize === 100)
                    handlePaginationChange({
                      page: input.page,
                      pageSize: input.pageSize,
                    });
                }}
                sx={{
                  bgcolor: "white",
                  boxShadow: 3,
                  height: "65vh",
                  width: "100%",
                  padding: 1,
                }}
              ></DataGridPro>
            </Box>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
