import { useState } from "react";
import toast from "react-hot-toast";

import Input from "../../components/atoms/Input";
import Select from "../../components/atoms/Select";
import TextArea from "../../components/atoms/TextArea";
import Button from "../../components/atoms/Button";
import Form from "../../components/organisms/Form";

import {
  ACTIONABLE,
  PRIORITY,
  ERROR_DEFAULT_MESSAGE,
  BROADCAST_NOTIFICATION_SCHEMA,
  ERROR_FORM_VALIDATION_MESSAGE,
  SUCCESS_SEND_NOTIFICATION_MESSAGE,
} from "../../app/constants";
import { getError } from "../../app/helper/utils";
import { useQueryGetAdminNotificationReceivers } from "../../app/query/useQueryGetAdmin";
import { useMutationAdminSendNotification } from "../../app/query/useMutationAdmin";
import { YesOrNoChoice } from "../../app/model/api";

const BroadcastManager = () => {
  const { mutateAsync } = useMutationAdminSendNotification();
  const { data: receivers, isFetching } = useQueryGetAdminNotificationReceivers();

  const [formFieldsValue, setFormFieldsValue] = useState<any>({});
  const [shouldReset, setShouldReset] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const handleSubmit = (data: any) => {
    BROADCAST_NOTIFICATION_SCHEMA.validate(Object.assign(data, formFieldsValue), { abortEarly: false })
      .then(async (valid: any) => {
        try {
          const receiver = receivers?.find((item) => item.id === valid.receiver);

          await mutateAsync({ ...valid, receiver });
          toast.success(SUCCESS_SEND_NOTIFICATION_MESSAGE);
          setShouldReset(true);
          setFormFieldsValue({});
        } catch (error) {
          toast.error(ERROR_DEFAULT_MESSAGE);
        }
      })
      .catch((err) => {
        const errors = err.inner.reduce((prev: any, current: any) => {
          return { ...prev, [current.path]: current.message };
        }, {});

        setErrors(errors);
        toast.error(ERROR_FORM_VALIDATION_MESSAGE);
      });
  };

  const handleSetError = (name: string, value: any) => {
    setErrors((prev: any) => {
      if (value) {
        return { ...prev, [name]: null };
      }

      return prev;
    });
  };

  const handleOnChangeSelect = (name: string, value: any) => {
    setShouldReset(false);
    setFormFieldsValue((prev: any) => {
      return { ...prev, [name]: value };
    });
    handleSetError(name, value);
  };

  const handleFormChange = (name: string, value: any) => {
    setShouldReset(false);
    setFormFieldsValue((prev: any) => {
      return { ...prev, [name]: value };
    });
    handleSetError(name, value);
  };

  return (
    <div>
      <Form onSubmit={(data) => handleSubmit(data)} onChange={(name, value) => handleFormChange(name, value)}>
        <div className="container-custom mx-auto">
          <div className="mt-16 mb-12">
            <div className="grid grid-flow-col auto-cols-max gap-5">
              <div className="w-[280px]">
                <h4 className="font-semibold font-pp-neue-machina">Title</h4>
              </div>
              <div className="w-[512px]">
                <Input
                  placeholder="Title of the Notification"
                  className="w-full"
                  name="title"
                  value={shouldReset ? "" : formFieldsValue?.title || ""}
                  error={getError(errors, "title")}
                />
              </div>
            </div>
            <hr className="my-5 h-px !bg-gray-200 border-0 dark:bg-gray-700" />

            <div className="grid grid-flow-col auto-cols-max gap-5">
              <div className="w-[280px]">
                <h4 className="font-semibold font-pp-neue-machina">Receiver</h4>
              </div>
              <div className="w-[512px]">
                <Select
                  label=""
                  placeholder="Select receiver"
                  name="receiver"
                  className=""
                  options={
                    receivers ? receivers?.map((item) => ({ label: item?.value || "", value: item?.id || "" })) : []
                  }
                  value={
                    shouldReset
                      ? { label: "", value: "" }
                      : receivers?.find((item) => item.value === formFieldsValue?.receiver)
                  }
                  onChange={(name, value) => handleOnChangeSelect(name, value)}
                  error={getError(errors, "receiver")}
                />
              </div>
            </div>
            <hr className="my-5 h-px !bg-gray-200 border-0 dark:bg-gray-700" />

            <div className="grid grid-flow-col auto-cols-max gap-5">
              <div className="w-[280px]">
                <h4 className="font-semibold font-pp-neue-machina">Priority</h4>
              </div>
              <div className="w-[512px]">
                <Select
                  label=""
                  name="priority"
                  className=""
                  options={PRIORITY}
                  value={
                    shouldReset
                      ? { label: "", value: "" }
                      : PRIORITY?.find((item) => item.value === formFieldsValue?.priority)
                  }
                  onChange={(name, value) => handleOnChangeSelect(name, value)}
                  error={getError(errors, "priority")}
                />
              </div>
            </div>
            <hr className="my-5 h-px !bg-gray-200 border-0 dark:bg-gray-700" />

            <div className="grid grid-flow-col auto-cols-max gap-5">
              <div className="w-[280px]">
                <h4 className="font-semibold font-pp-neue-machina">Actionable</h4>
              </div>
              <div className="w-[512px]">
                <Select
                  onChange={(name, value) => handleOnChangeSelect(name, value)}
                  label=""
                  name="actionable"
                  className=""
                  options={ACTIONABLE}
                  value={
                    shouldReset
                      ? { label: "", value: "" }
                      : ACTIONABLE?.find((item) => item.value === formFieldsValue?.actionable)
                  }
                  error={getError(errors, "actionable")}
                />
              </div>
            </div>
            <hr className="my-5 h-px !bg-gray-200 border-0 dark:bg-gray-700" />
            {formFieldsValue?.actionable === YesOrNoChoice.Yes && (
              <>
                <div className="grid grid-flow-col auto-cols-max gap-5">
                  <div className="w-[280px]">
                    <h4 className="font-semibold font-pp-neue-machina">Link to</h4>
                  </div>
                  <div className="w-[512px]">
                    <Input
                      placeholder="Link to the action"
                      className="w-full"
                      name="link"
                      value={shouldReset ? "" : formFieldsValue?.link || ""}
                      error={getError(errors, "link")}
                    />
                  </div>
                </div>
                <hr className="my-5 h-px !bg-gray-200 border-0 dark:bg-gray-700" />
              </>
            )}

            <div className="grid grid-flow-col auto-cols-max gap-5 ">
              <div className="w-[280px]">
                <h4 className="font-semibold font-pp-neue-machina">Description</h4>
              </div>
              <div className="w-[512px]">
                <TextArea
                  name="description"
                  value={shouldReset ? "" : formFieldsValue?.description || ""}
                  placeholder="Enter a description..."
                  className="mb-4"
                  error={getError(errors, "description")}
                />
              </div>
            </div>

            <div className="grid grid-flow-col auto-cols-max gap-5 mb-4">
              <div className="w-[280px] "></div>
              <Button
                type="submit"
                label="Send Notification"
                disabled={isFetching}
                className="w-[200px] text-white text-base font-medium self-center"
                backgroundColor="#8F49FD"
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default BroadcastManager;
