import { TabContext, TabPanel } from "@mui/lab";
import { Box, Container, Tab, Tabs } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { WALLET_SECTIONS } from "./walletSections";

export const WalletTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentTab = location.pathname;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  return (
    <Box width={"100%"}>
      <Container sx={{ background: "white" }}>
        <TabContext value={currentTab}>
          <Box display="flex">
            <Tabs
              value={currentTab}
              onChange={handleChange}
              aria-label="wallet-tabs"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {WALLET_SECTIONS.map((entry) => (
                <Tab
                  sx={{
                    fontWeight: "fontWeightBold",
                  }}
                  key={"tb-wallet-" + entry.name}
                  label={entry.name}
                  value={entry.path}
                />
              ))}
            </Tabs>
          </Box>
          {WALLET_SECTIONS.map((entry) => (
            <TabPanel key={"tbpanel-wallet-" + entry.name} value={entry.path}>
              {entry.tabComponent()}
            </TabPanel>
          ))}
        </TabContext>
      </Container>
    </Box>
  );
};
