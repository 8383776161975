import { Box, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { formatInTimeZone } from "date-fns-tz";
import { ResLoanAuditTransactionDto } from "../../../app/model/api";
import { DatagridExportToolbar } from "../commons/DatagridExportToolbar";
import { ExplainerAccordion } from "../commons/ExplainerAccordion";
import { formatWithCommas, toFormattedBtc } from "../commons/utils";

const COLUMNS = (unit: "SAT" | "BTC"): GridColDef<ResLoanAuditTransactionDto & { amountBtc: number }>[] => [
  {
    field: "createdAt",
    headerName: "Created at",
    sortable: true,
    flex: 1,
    display: "flex",
    renderCell: (params) => formatInTimeZone(params.row.createdAt, "UTC", "dd MMM yyyy"),
  },
  {
    field: "fromAddresses",
    headerName: "From",
    sortable: true,
    flex: 1,
    display: "flex",
  },
  {
    field: "toAddresses",
    headerName: "To",
    sortable: true,
    flex: 1,
    display: "flex",
  },
  {
    field: "transactionHash",
    headerName: "Transaction hash",
    sortable: true,
    flex: 1,
    display: "flex",
  },
  unit === "SAT"
    ? {
        field: "amountSatoshi",
        headerName: "Amount (SAT)",
        sortable: true,
        flex: 1,
        display: "flex",
        align: "right",
        headerAlign: "right",
        renderCell: (params) => formatWithCommas(params.row.amountSatoshi),
      }
    : {
        field: "amountBtc",
        headerName: "Amount (BTC)",
        sortable: true,
        flex: 1,
        display: "flex",
        align: "right",
        headerAlign: "right",
        renderCell: (params) => params.row.amountBtc,
      },
];

export const AuditTransactionsTable = ({
  transactions,
  height,
  unit,
}: {
  transactions: ResLoanAuditTransactionDto[];
  height: string | number;
  unit: "SAT" | "BTC";
}) => {
  const rows = transactions.map((x) => ({ ...x, amountBtc: +toFormattedBtc(x.amountSatoshi) }));
  return (
    <Box gap={1} width={"100%"}>
      <Typography variant="h6">Audit transactions</Typography>
      <ExplainerAccordion
        title={"More details"}
        explainers={[
          "These are all transactions from or to the loan reward wallet, as retrieved from Bitgo. These transactions are not actively used in any automatic calculation or decision.",
          "They are here for reconciliation purposes, to allow us to view any transaction that was executed outside our platform, e.g. directly through bitgo.",
        ]}
      />
      <Box sx={{ height }} width={"100%"}>
        <DataGridPro
          rows={rows}
          rowCount={transactions.length}
          columns={COLUMNS(unit)}
          pagination
          paginationMode="client"
          sortingMode="client"
          disableColumnFilter={false}
          pageSizeOptions={[25, 50, 100]}
          sx={{
            bgcolor: "white",
            boxShadow: 3,
            padding: 1,
            maxHeight: height,
            width: "100%",
          }}
          slots={{
            noRowsOverlay: () => (
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
                height={"100%"}
                sx={{ minHeight: "60px" }}
              >
                <Typography>No transaction found in bitgo for loan.</Typography>
              </Stack>
            ),
            toolbar: DatagridExportToolbar,
          }}
        ></DataGridPro>
      </Box>
    </Box>
  );
};
