import { useMutation } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import {
  ReqKymEnergyProfileDto,
  ReqUpdateKymAsicDto,
  ReqUpdateKymCapacityAndSitesDto,
  ReqUpdateKymFinancialsDto,
  UpdateKymHostingDto,
} from "../model/api";

export const useMutationKymFinancials = () => {
  return useMutation((data: ReqUpdateKymFinancialsDto) => apig.kym.updateKym2Financials(data));
};

export const useMutationKymHosting = () => {
  return useMutation((data: UpdateKymHostingDto) => apig.kym.updateKym2Hosting(data));
};

export const useMutationKymAsic = () => {
  return useMutation((data: ReqUpdateKymAsicDto) => apig.kym.updateKym2Asic(data));
};

export const useMutationKymEnergyProfile = () => {
  return useMutation((data: ReqKymEnergyProfileDto) => apig.kym.updateKym2EnergyProfile(data));
};

export const useMutationKymCapacityAndSites = () => {
  return useMutation((data: ReqUpdateKymCapacityAndSitesDto) => apig.kym.updateKym2CapacityAndSites(data));
};
