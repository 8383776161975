type Auth0User = {
  name?: string;
  given_name?: string;
  family_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  email_verified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  address?: string;
  updated_at?: string;
  sub?: string;
  [key: string]: any;
};

enum KymRate {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum Permission {
  CREATE_WITHDRAWAL = "create:withdrawal",
  PREVIEW_WITHDRAWAL = "preview:withdrawal",
  CREATE_WHITELIST_PROPOSAL = "create:whitelist-proposal",
  UPDATE_WHITELIST_PROPOSAL = "update:whitelist-proposal",
  CREATE_COLLATERAL_DEPOSIT = "create:collateral-deposit",
  CREATE_OPPORTUNITY_FUNDING = "create:opportunity-funding",
  GET_OPPORTUNITY_FUNDING = "get:opportunity-funding",
  CREATE_ACCESS_PROPOSAL = "create:access-proposal",
  UPDATE_ACCESS_PROPOSAL = "update:access-proposal",
}

export { KymRate };
export type { Auth0User };
