import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import HelpIcon from "@mui/icons-material/Help";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGridPro,
  FooterPropsOverrides,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridSlots,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { addDays, differenceInDays, subDays } from "date-fns";
import Decimal from "decimal.js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { allowDemoHandling } from "../../../app/config/auth0/config";
import { ReqLoanPayment, ReqUpdateDraftLoan, ResAdminLoanDto } from "../../../app/model/api";
import { calculationKindName, formatWithCommas, toBtc, toSats } from "../commons/utils";
import { useAdminUpdateDraftLoan } from "../loanMutations";

const CALCULATION_KINDS: { id: ReqUpdateDraftLoan["calculationKind"]; name: string }[] = [
  { id: "DAILY_PRINCIPAL_30D_PAYMENT", name: calculationKindName("DAILY_PRINCIPAL_30D_PAYMENT") },
  { id: "DAILY_PRINCIPAL_MONTHLY_PAYMENT", name: calculationKindName("DAILY_PRINCIPAL_MONTHLY_PAYMENT") },
  { id: "DAILY_PRINCIPAL_DAILY_PAYMENT", name: calculationKindName("DAILY_PRINCIPAL_DAILY_PAYMENT") },
  { id: "DAILY_INTEREST_30D_PAYMENT", name: calculationKindName("DAILY_INTEREST_30D_PAYMENT") },
  { id: "DAILY_INTEREST_MONTHLY_PAYMENT", name: calculationKindName("DAILY_INTEREST_MONTHLY_PAYMENT") },
  { id: "DAILY_INTEREST_DAILY_PAYMENT", name: calculationKindName("DAILY_INTEREST_DAILY_PAYMENT") },
  { id: "CUSTOM_SCHEDULE", name: calculationKindName("CUSTOM_SCHEDULE") },
];

interface CustomScheduleEntry {
  id: number;
  date: Date;
  expectedFromMinerBtc: number;
  forLPBtc: number;
  isNew?: boolean;
}

const toCustomScheduleEntry = (req: ReqLoanPayment): CustomScheduleEntry => {
  const date = new Date(req.date);
  return {
    id: date.getTime(),
    date,
    expectedFromMinerBtc: toBtc(req.expectedFromMinerSatoshis),
    forLPBtc: toBtc(req.forLPSatoshis),
  };
};

interface FormData {
  readonly startDate: Date;
  readonly isSigned: boolean;
  readonly durationDays: number;
  readonly daysPerYear?: number;
  readonly principalAmountSatoshi: number;
  readonly minerAnnualInterestRatePercent: number;
  readonly lpAnnualInterestRatePercent: number;
  readonly calculationKind: ReqUpdateDraftLoan["calculationKind"];
  readonly isDemo: boolean;
  readonly customSchedule?: CustomScheduleEntry[];
}

const ERR_DURATION = "This has to be a value between 30 and 1000.";
const ERR_DAYS_PER_YEAR = "This has to be a value between 1 and 365.";
const ERR_AMOUNT = "This has to be a positive integer.";
const ERR_INTEREST = "This has to be a positive number between 0.0001% and 25%.";

const SchemaUpdateDraftLoan = yup
  .object({})
  .shape({
    durationDays: yup
      .number()
      .typeError(ERR_DURATION)
      .positive(ERR_DURATION)
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(30, ERR_DURATION)
      .max(1000, ERR_DURATION)
      .required(ERR_DURATION),
    daysPerYear: yup
      .number()
      .typeError(ERR_DAYS_PER_YEAR)
      .positive(ERR_DAYS_PER_YEAR)
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(1, ERR_DAYS_PER_YEAR)
      .max(365, ERR_DAYS_PER_YEAR)
      .when("calculationKind", {
        is: (value: string) => value !== "CUSTOM_SCHEDULE",
        then: (schema) => schema.required(ERR_DAYS_PER_YEAR),
        otherwise: (schema) => schema.optional(),
      }),
    minerAnnualInterestRatePercent: yup
      .number()
      .typeError(ERR_INTEREST)
      .positive(ERR_INTEREST)
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(0.0001, ERR_INTEREST)
      .max(25, ERR_INTEREST)
      .required(ERR_INTEREST),
    lpAnnualInterestRatePercent: yup
      .number()
      .typeError(ERR_INTEREST)
      .positive(ERR_INTEREST)
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(0.0001, ERR_INTEREST)
      .max(25, ERR_INTEREST)
      .required(ERR_INTEREST),
    principalAmountSatoshi: yup
      .number()
      .typeError(ERR_AMOUNT)
      .positive(ERR_AMOUNT)
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(1, ERR_AMOUNT)
      .required(ERR_AMOUNT),
    startDate: yup
      .date()
      .typeError("This must be a valid date!")
      .max(addDays(new Date(), 60), "This value can't be more than 60 days into the future.")
      .required(),
    isSigned: yup.boolean(),
    calculationKind: yup.string().required(),
    customSchedule: yup
      .array()
      .of(
        yup.object().shape({
          date: yup.date().required("Payment date is required"),
          expectedFromMinerBtc: yup.number().min(0, "Miner amount may not be negative").required("Amount is required"),
          forLPBtc: yup.number().min(0, "LP amount may not be negative").required("Amount is required"),
        })
      )
      .when("calculationKind", {
        is: "CUSTOM_SCHEDULE",
        then: (schema) =>
          schema
            .test("chronological-order", "Payment dates must be in chronological order", function (value) {
              if (!value) return true;
              for (let i = 1; i < value.length; i++) {
                const first = value[i - 1].date;
                const second = value[i].date;
                if (!first || !second) {
                  continue;
                }
                if (second <= first) {
                  return false;
                }
              }
              return true;
            })
            .test(
              "total-miner-payments",
              "Total miner payments must be greater than or equal to principal",
              function (value) {
                if (!value) return true;
                const totalMinerPayments = value.reduce(
                  (sum, entry) => sum.plus(entry.expectedFromMinerBtc ?? 0),
                  new Decimal(0)
                );
                return totalMinerPayments.greaterThanOrEqualTo(toBtc(this.parent.principalAmountSatoshi));
              }
            )
            .test(
              "miner-vs-lp-payments",
              "Total miner payments must be greater than or equal to total LP payments",
              function (value) {
                if (!value) return true;
                const totalMinerPayments = value.reduce(
                  (sum, entry) => sum.plus(entry.expectedFromMinerBtc ?? 0),
                  new Decimal(0)
                );
                const totalLPPayments = value.reduce((sum, entry) => sum.plus(entry.forLPBtc ?? 0), new Decimal(0));
                return totalMinerPayments.greaterThanOrEqualTo(totalLPPayments);
              }
            )
            .required("Custom schedule is required for CUSTOM_SCHEDULE loan type"),
        otherwise: (schema) => schema.optional(),
      }),
  })
  .required();

export const UpdateLoanBasicDetails = ({ loan, onUpdate }: { loan: ResAdminLoanDto; onUpdate: () => void }) => {
  const { mutateAsync, isLoading: isSaving } = useAdminUpdateDraftLoan();

  const [isDemo, setIsDemo] = useState<boolean>(loan.isDemo);
  const [customScheduleRows, setCustomScheduleRows] = useState<CustomScheduleEntry[]>(
    loan.customSchedule?.map((payment) => toCustomScheduleEntry(payment)) ?? []
  );
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [isEditModeError, setIsEditModeError] = useState(false);

  const isAnyRowInEditMode = useCallback((): boolean => {
    return Object.values(rowModesModel).some((modelItem) => modelItem.mode === GridRowModes.Edit);
  }, [rowModesModel]);

  useEffect(() => {
    setIsEditModeError(isAnyRowInEditMode());
  }, [rowModesModel, isAnyRowInEditMode]);

  const {
    formState: { errors, isDirty },
    handleSubmit,
    watch,
    register,
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(SchemaUpdateDraftLoan),
    defaultValues: {
      principalAmountSatoshi: loan.principalAmountSatoshi,
      isSigned: loan.isSigned,
      durationDays: loan.durationDays,
      daysPerYear: loan.daysPerYear,
      startDate: loan.startedAt ? new Date(loan.startedAt) : new Date(),
      minerAnnualInterestRatePercent: loan.minerAnnualInterestRatePercent,
      lpAnnualInterestRatePercent: loan.lpAnnualInterestRatePercent,
      isDemo: loan.isDemo,
      calculationKind: loan.calculationKind,
      customSchedule: [],
    },
  });

  useEffect(() => {
    register("customSchedule");
  }, [register]);

  useEffect(() => {
    setValue("customSchedule", customScheduleRows, { shouldValidate: true, shouldDirty: true });
  }, [customScheduleRows, setValue]);
  const hasEdits = isDirty || isDemo !== loan.isDemo;

  const startDate = watch("startDate");
  const isSigned = watch("isSigned");

  const principalAmountSatoshiFieldValue = watch("principalAmountSatoshi");
  const satoshiValue = isNaN(Number(principalAmountSatoshiFieldValue)) ? 0 : Number(principalAmountSatoshiFieldValue);

  const satoshi = formatWithCommas(satoshiValue);
  const btc = toBtc(satoshiValue);
  const conversion = `${satoshi} SAT = ${btc} BTC`;

  const handleUpdate = async (data: FormData) => {
    if (isAnyRowInEditMode()) {
      toast.error("Please save all rows before submitting the form.");
      return;
    }

    try {
      const input: ReqUpdateDraftLoan = {
        id: loan.id,
        isDemo: data.isDemo,
        durationDays: data.durationDays,
        startDate: data.startDate.toISOString(),
        isSigned: data.isSigned,
        principalAmountSatoshi: data.principalAmountSatoshi,
        calculationKind: data.calculationKind,
        minerAnnualInterestRatePercent: data.minerAnnualInterestRatePercent,
        lpAnnualInterestRatePercent: data.lpAnnualInterestRatePercent,
        daysPerYear: data.daysPerYear,
        customSchedule:
          data.calculationKind === "CUSTOM_SCHEDULE"
            ? data.customSchedule?.map((entry) => ({
                date: entry.date.toISOString(),
                expectedFromMinerSatoshis: toSats(entry.expectedFromMinerBtc),
                forLPSatoshis: toSats(entry.forLPBtc),
              }))
            : undefined,
      };
      await mutateAsync(input);
      toast.success("Update successful.");
    } catch (err) {
      toast.error("There was an issue updating the loan");
    } finally {
      onUpdate();
    }
  };

  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: "Payment Date",
      type: "date",
      width: 150,
      editable: true,
    },
    {
      field: "expectedFromMinerBtc",
      headerName: "Expected from Miner (BTC)",
      type: "number",
      width: 200,
      editable: true,
    },
    {
      field: "forLPBtc",
      headerName: "Owed to LP (BTC)",
      type: "number",
      width: 200,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem key={"save-" + id} icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} />,
            <GridActionsCellItem
              key={"cancel-" + id}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={"edit-" + id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            key={"delete-" + id}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = (newRow: CustomScheduleEntry) => {
    const updatedRow = { ...newRow, isNew: false };
    setCustomScheduleRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setCustomScheduleRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = customScheduleRows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setCustomScheduleRows(customScheduleRows.filter((row) => row.id !== id));
    }
  };

  const calculationKind = watch("calculationKind");

  const calculatedDuration = useMemo(() => {
    if (calculationKind === "CUSTOM_SCHEDULE" && customScheduleRows.length > 0) {
      const lastPaymentDate = new Date(Math.max(...customScheduleRows.map((entry) => entry.date.getTime())));
      return differenceInDays(lastPaymentDate, startDate);
    }
    return undefined;
  }, [calculationKind, customScheduleRows, startDate]);

  useEffect(() => {
    if (calculatedDuration !== undefined) {
      setValue("durationDays", calculatedDuration, { shouldValidate: true });
    }
  }, [calculatedDuration, setValue]);

  return loan.status !== "Draft" ? null : (
    <Box width={"100%"}>
      <form onSubmit={handleSubmit(handleUpdate)} encType="multipart/form-data">
        <Stack minWidth={250} width={"100%"} spacing={1} alignItems={"flex-start"}>
          <Typography variant="h6">Update draft loan parameters</Typography>
          <Box
            width="100%"
            maxWidth={calculationKind === "CUSTOM_SCHEDULE" ? "100%" : "600px"}
            boxShadow={3}
            padding={2}
          >
            <Grid container spacing={2}>
              <Grid
                container
                item
                spacing={2}
                xs={12}
                lg={calculationKind === "CUSTOM_SCHEDULE" ? 6 : 12}
                height="fit-content"
              >
                {allowDemoHandling && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Is demo loan"
                      control={
                        <Checkbox
                          checked={isDemo}
                          onChange={(_e, value) => {
                            setIsDemo(value);
                            setValue("isDemo", value, { shouldDirty: true });
                          }}
                        />
                      }
                    />
                  </Grid>
                )}
                {isDemo && (
                  <Alert color="warning">
                    Demo loans are not driven by automatic checks, instead by manually added events.
                  </Alert>
                )}
                <Grid item xs={12}>
                  <TextField
                    id="principalAmountSatoshi"
                    label={"Principal amount"}
                    placeholder="Principal amount"
                    {...register("principalAmountSatoshi")}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!errors.principalAmountSatoshi}
                    helperText={
                      errors.principalAmountSatoshi ? <Box>{errors.principalAmountSatoshi.message}</Box> : conversion
                    }
                    onWheel={(evt) => {
                      (evt.target as HTMLElement).blur(); // disable edit by scroll
                    }}
                    type="number"
                    InputProps={{
                      inputProps: {
                        step: "1",
                      },
                      endAdornment: <InputAdornment position="end">satoshi</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Loan start date"
                      format="dd/MM/yyyy"
                      value={startDate}
                      minDate={subDays(new Date(), allowDemoHandling ? 3600 : 60)}
                      maxDate={addDays(new Date(), 60)}
                      onChange={(e) => {
                        setValue("startDate", e ?? subDays(new Date(), 120), { shouldDirty: true });
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          error: !!errors.startDate,
                          helperText: errors.startDate && errors.startDate.message,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="durationDays"
                    label={"Duration"}
                    placeholder="Duration"
                    {...register("durationDays")}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!errors.durationDays}
                    helperText={errors.durationDays && <Box>{errors.durationDays.message}</Box>}
                    onWheel={(evt) => {
                      (evt.target as HTMLElement).blur(); // disable edit by scroll
                    }}
                    type="number"
                    InputProps={{
                      inputProps: {
                        step: "1",
                      },
                      endAdornment: <InputAdornment position="end">days</InputAdornment>,
                    }}
                    disabled={calculationKind === "CUSTOM_SCHEDULE"}
                  />
                </Grid>
                {calculationKind !== "CUSTOM_SCHEDULE" && (
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={11}>
                        <TextField
                          id="daysPerYear"
                          label={"Days per year"}
                          placeholder="Days per year"
                          {...register("daysPerYear")}
                          defaultValue={360}
                          size="small"
                          fullWidth
                          variant="outlined"
                          error={!!errors.daysPerYear}
                          helperText={errors.daysPerYear && <Box>{errors.daysPerYear.message}</Box>}
                          onWheel={(evt) => {
                            (evt.target as HTMLElement).blur(); // disable edit by scroll
                          }}
                          type="number"
                          InputProps={{
                            inputProps: {
                              step: "1",
                            },
                            endAdornment: <InputAdornment position="end">days</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={1} pl={2}>
                        <Tooltip
                          color="primary"
                          title="Length of a year for calculating daily / monthly interest rates. Common values are 360 or 365"
                        >
                          <HelpIcon fontSize="small" />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel size="small" id="select-minerid">
                      Loan type
                    </InputLabel>
                    <Select
                      labelId="select-type"
                      label={"Loan type"}
                      id="select-type"
                      size="small"
                      fullWidth
                      value={calculationKind}
                      {...register("calculationKind")}
                    >
                      {CALCULATION_KINDS.map((kind) => (
                        <MenuItem key={"key-select-type" + kind.id} value={kind.id}>
                          {kind.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="minerAnnualInterestRatePercent"
                    label={"Annual miner interest"}
                    placeholder="Annual miner interest"
                    {...register("minerAnnualInterestRatePercent")}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!errors.minerAnnualInterestRatePercent}
                    helperText={
                      errors.minerAnnualInterestRatePercent && (
                        <Box>{errors.minerAnnualInterestRatePercent.message}</Box>
                      )
                    }
                    onWheel={(evt) => {
                      (evt.target as HTMLElement).blur(); // disable edit by scroll
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="lpAnnualInterestRatePercent"
                    label={"Annual LP interest"}
                    placeholder="Annual LP interest"
                    {...register("lpAnnualInterestRatePercent")}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!errors.lpAnnualInterestRatePercent}
                    helperText={
                      errors.lpAnnualInterestRatePercent && <Box>{errors.lpAnnualInterestRatePercent.message}</Box>
                    }
                    onWheel={(evt) => {
                      (evt.target as HTMLElement).blur(); // disable edit by scroll
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </Grid>
                {calculationKind === "CUSTOM_SCHEDULE" && (
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      Miner and LP interest rates are not validated against the entered custom schedule data.
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="start"
                      control={
                        <Box pl={2}>
                          <Switch size="small" color="primary" {...register("isSigned")} />
                        </Box>
                      }
                      label="Is contract signed?"
                      labelPlacement="end"
                    />
                  </FormControl>
                  {isSigned && (
                    <Alert color="warning" variant="filled">
                      Marking this loan as signed will officially start it and no more edits will be possible.
                    </Alert>
                  )}
                </Grid>
              </Grid>
              {calculationKind === "CUSTOM_SCHEDULE" && (
                <Grid item xs={12} lg={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Custom Payment Schedule
                  </Typography>
                  <DataGridPro
                    disableColumnSorting
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnPinning
                    disableColumnReorder
                    rows={customScheduleRows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slots={{
                      toolbar: EditToolbar as GridSlots["toolbar"],
                      footer: CustomFooter as GridSlots["footer"],
                    }}
                    slotProps={{
                      toolbar: { setCustomScheduleRows, setRowModesModel },
                      footer: { customScheduleRows } as FooterPropsOverrides,
                    }}
                    sx={{
                      bgcolor: "white",
                      boxShadow: 3,
                      display: "grid",
                    }}
                    autoHeight
                  />
                  {errors.customSchedule?.message && (
                    <Typography color="error" variant="body2" pt={1}>
                      {errors.customSchedule.message}
                    </Typography>
                  )}
                  {Array.isArray(errors.customSchedule) &&
                    errors.customSchedule.map(
                      (
                        error: null | { forLPBtc?: { message: string }; expectedFromMinerBtc?: { message: string } },
                        index
                      ) => (
                        <>
                          {error?.expectedFromMinerBtc && (
                            <Typography color="error" variant="body2" pt={1}>
                              Row {index + 1}: {error.expectedFromMinerBtc.message}
                            </Typography>
                          )}
                          {error?.forLPBtc && (
                            <Typography color="error" variant="body2" pt={1}>
                              Row {index + 1}: {error.forLPBtc.message}
                            </Typography>
                          )}
                        </>
                      )
                    )}
                  {isEditModeError && (
                    <Typography color="error" variant="body2" pt={1}>
                      Please save all rows before submitting the form.
                    </Typography>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <Tooltip
                  title={
                    isEditModeError
                      ? "Please save all rows before submitting."
                      : hasEdits
                      ? ""
                      : "Please fill in the form to save."
                  }
                >
                  <span>
                    <LoadingButton
                      size="small"
                      type="submit"
                      loading={isSaving}
                      disabled={!hasEdits || isEditModeError || isSaving || Object.keys(errors).length > 0}
                      variant="contained"
                    >
                      Save
                    </LoadingButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};
interface EditToolbarProps {
  setCustomScheduleRows: React.Dispatch<React.SetStateAction<CustomScheduleEntry[]>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}

function EditToolbar(props: EditToolbarProps) {
  const { setCustomScheduleRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = Date.now();
    const newRow: CustomScheduleEntry = {
      id,
      date: new Date(),
      expectedFromMinerBtc: 0,
      forLPBtc: 0,
      isNew: true,
    };
    setCustomScheduleRows((oldRows) => [...oldRows, newRow]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "date" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add payment
      </Button>
    </GridToolbarContainer>
  );
}

const CustomFooter = ({ customScheduleRows }: { customScheduleRows: CustomScheduleEntry[] }) => {
  const totalMinerBtc = useMemo(
    () => customScheduleRows.reduce((sum, row) => sum.plus(row.expectedFromMinerBtc || 0), new Decimal(0)),
    [customScheduleRows]
  );

  const totalLPBtc = useMemo(
    () => customScheduleRows.reduce((sum, row) => sum.plus(row.forLPBtc || 0), new Decimal(0)),
    [customScheduleRows]
  );

  return (
    <Box
      sx={{
        padding: "10px",
        display: "flex",
        justifyContent: "space-between",
        fontWeight: "bold",
      }}
    >
      <div>Total Miner: {formatWithCommas(totalMinerBtc.toPrecision(4))} BTC</div>
      <div>Total LP: {formatWithCommas(totalLPBtc.toPrecision(4))} BTC</div>
    </Box>
  );
};
