import { OpportunityStatus, YesOrNoChoice } from "../model/api";

import Decimal from "decimal.js";
import { RegisterLpDto } from "../../app/model/api";
import CoalIcon from "../../assets/images/coal-icon.png";
import WasteGasIcon from "../../assets/images/flared-waste-gas-icon.png";
import HydroIcon from "../../assets/images/hydro-icon.png";
import { default as GeothermalIcon, default as NuclearIcon } from "../../assets/images/nuclear-icon.png";
import OilIcon from "../../assets/images/oil-icon.png";
import OtherIcon from "../../assets/images/other-icon.png";
import OtherNonRenewableIcon from "../../assets/images/other-non-renewable-icon.png";
import SolarIcon from "../../assets/images/solar-icon.png";
import WindIcon from "../../assets/images/wind-icon.png";

const ERROR_INPUT_CLASS = "!bg-red-50 !border !border-red-500 !text-red-900 !placeholder-red-700";
const ERROR_MESSAGE_CLASS = "!text-sm !text-red-600 !dark:text-red-500";
const ERROR_LABEL_CLASS = "!text-red-700 !dark:text-red-500";
const ERROR_ICON_CLASS = "!text-red-600 !dark:text-red-500";
const SATOSHIS_TO_BTC = new Decimal(100000000);

type HorizonOption = {
  readonly label: string;
  readonly value: number;
  readonly id: RegisterLpDto["timeHorizonOfLiquidity"];
};
const GRANULAR_TIME_HORIZON_OF_LIQUIDITY: HorizonOption[] = [
  {
    label: "Not sure",
    id: "notsure",
    value: 0,
  },
  {
    label: "Less than 1 month",
    id: "lt1",
    value: 1,
  },
  {
    label: "1 - 3 months",
    id: "1mto3m",
    value: 2,
  },
  {
    label: "3 - 6 months",
    id: "3mto6m",
    value: 3,
  },
  {
    label: "6 months - 1 year",
    id: "6mto1y",
    value: 4,
  },
  {
    label: "More than 1 year",
    id: "gt1y",
    value: 5,
  },
];

export type EnergyEntry = {
  readonly value: string;
  readonly label: string;
};

const ENERGY_MIX: EnergyEntry[] = [
  { value: "Solar", label: "Solar" },
  { value: "Wind", label: "Wind" },
  { value: "Hydro", label: "Hydro" },
  { value: "Nuclear", label: "Nuclear" },
  { value: "Flared gas", label: "Flared gas" },
  { value: "Natural gas", label: "Natural gas (not flared gas)" },
  { value: "Oil", label: "Oil" },
  { value: "Coal", label: "Coal" },
];

const SELECT_COUNTRY = [
  { label: "Afghanistan", value: "Afghanistan" },
  { label: "Åland Islands", value: "Åland Islands" },
  { label: "Albania", value: "Albania" },
  { label: "Algeria", value: "Algeria" },
  { label: "American Samoa", value: "American Samoa" },
  { label: "Andora", value: "Andora" },
  { label: "Angola", value: "Angola" },
  { label: "Anguilla", value: "Anguilla" },
  { label: "Antarctica", value: "Antarctica" },
  { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
  { label: "Argentina", value: "Argentina" },
  { label: "Armenia", value: "Armenia" },
  { label: "Aruba", value: "Aruba" },
  { label: "Australia", value: "Australia" },
  { label: "Austria", value: "Austria" },
  { label: "Azerbaijan", value: "Azerbaijan" },
  { label: "Bahamas", value: "Bahamas" },
  { label: "Bahrain", value: "Bahrain" },
  { label: "Bangladesh", value: "Bangladesh" },
  { label: "Barbados", value: "Barbados" },
  { label: "Belarus", value: "Belarus" },
  { label: "Belgium", value: "Belgium" },
  { label: "Belize", value: "Belize" },
  { label: "Benin", value: "Benin" },
  { label: "Bermuda", value: "Bermuda" },
  { label: "Bhutan", value: "Bhutan" },
  { label: "Bolivia", value: "Bolivia" },
  { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  { label: "Botswana", value: "Botswana" },
  { label: "Bouvet Island", value: "Bouvet Island" },
  { label: "Brazil", value: "Brazil" },
  { label: "British Indian Ocean Territory", value: "British Indian Ocean Territory" },
  { label: "Brunei Darussalam", value: "Brunei Darussalam" },
  { label: "Bulgaria", value: "Bulgaria" },
  { label: "Burkina Faso", value: "Burkina Faso" },
  { label: "Burundi", value: "Burundi" },
  { label: "Cambodia", value: "Cambodia" },
  { label: "Cameroon", value: "Cameroon" },
  { label: "Canada", value: "Canada" },
  { label: "Cape Verde", value: "Cape Verde" },
  { label: "Cayman Islands", value: "Cayman Islands" },
  { label: "Central African Republic", value: "Central African Republic" },
  { label: "Chad", value: "Chad" },
  { label: "Chile", value: "Chile" },
  { label: "China", value: "China" },
  { label: "Christmas Island", value: "Christmas Island" },
  { label: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands" },
  { label: "Colombia", value: "Colombia" },
  { label: "Comoros", value: "Comoros" },
  { label: "Congo", value: "Congo" },
  { label: "Congo, The Democratic Republic of the", value: "Congo, The Democratic Republic of the" },
  { label: "Cook Islands", value: "Cook Islands" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "Côte d'Ivoire", value: "Côte d'Ivoire" },
  { label: "Croatia", value: "Croatia" },
  { label: "Cuba", value: "Cuba" },
  { label: "Cyprus", value: "Cyprus" },
  { label: "Czech Republic", value: "Czech Republic" },
  { label: "Denmark", value: "Denmark" },
  { label: "Djibouti", value: "Djibouti" },
  { label: "Dominica", value: "Dominica" },
  { label: "Dominican Republic", value: "Dominican Republic" },
  { label: "Ecuador", value: "Ecuador" },
  { label: "Egypt", value: "Egypt" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Equatorial Guinea", value: "Equatorial Guinea" },
  { label: "Eritrea", value: "Eritrea" },
  { label: "Estonia", value: "Estonia" },
  { label: "Ethiopia", value: "Ethiopia" },
  { label: "Falkland Islands (Malvinas)", value: "Falkland Islands (Malvinas)" },
  { label: "Faroe Islands", value: "Faroe Islands" },
  { label: "Fiji", value: "Fiji" },
  { label: "Finland", value: "Finland" },
  { label: "France", value: "France" },
  { label: "French Guiana", value: "French Guiana" },
  { label: "French Polynesia", value: "French Polynesia" },
  { label: "French Southern Territories", value: "French Southern Territories" },
  { label: "Gabon", value: "Gabon" },
  { label: "Gambia", value: "Gambia" },
  { label: "Georgia", value: "Georgia" },
  { label: "Germany", value: "Germany" },
  { label: "Ghana", value: "Ghana" },
  { label: "Gibraltar", value: "Gibraltar" },
  { label: "Greece", value: "Greece" },
  { label: "Greenland", value: "Greenland" },
  { label: "Grenada", value: "Grenada" },
  { label: "Guadeloupe", value: "Guadeloupe" },
  { label: "Guam", value: "Guam" },
  { label: "Guatemala", value: "Guatemala" },
  { label: "Guernsey", value: "Guernsey" },
  { label: "Guinea", value: "Guinea" },
  { label: "Guinea-Bissau", value: "Guinea-Bissau" },
  { label: "Guyana", value: "Guyana" },
  { label: "Haiti", value: "Haiti" },
  { label: "Heard Island and Mcdonald Islands", value: "Heard Island and Mcdonald Islands" },
  { label: "Holy See (Vatican City State)", value: "Holy See (Vatican City State)" },
  { label: "Honduras", value: "Honduras" },
  { label: "Hong Kong", value: "Hong Kong" },
  { label: "Hungary", value: "Hungary" },
  { label: "Iceland", value: "Iceland" },
  { label: "India", value: "India" },
  { label: "Indonesia", value: "Indonesia" },
  { label: "Iran, Islamic Republic Of", value: "Iran, Islamic Republic Of" },
  { label: "Iraq", value: "Iraq" },
  { label: "Ireland", value: "Ireland" },
  { label: "Isle of Man", value: "Isle of Man" },
  { label: "Israel", value: "Israel" },
  { label: "Italy", value: "Italy" },
  { label: "Jamaica", value: "Jamaica" },
  { label: "Japan", value: "Japan" },
  { label: "Jersey", value: "Jersey" },
  { label: "Jordan", value: "Jordan" },
  { label: "Kazakhstan", value: "Kazakhstan" },
  { label: "Kenya", value: "Kenya" },
  { label: "Kiribati", value: "Kiribati" },
  { label: "Korea, Democratic People's Republic of", value: "Korea, Democratic People's Republic of" },
  { label: "Korea, Republic of", value: "Korea, Republic of" },
  { label: "Kuwait", value: "Kuwait" },
  { label: "Kyrgyzstan", value: "Kyrgyzstan" },
  { label: "Lao People'S Democratic Republic", value: "Lao People'S Democratic Republic" },
  { label: "Latvia", value: "Latvia" },
  { label: "Lebanon", value: "Lebanon" },
  { label: "Lesotho", value: "Lesotho" },
  { label: "Liberia", value: "Liberia" },
  { label: "Libyan Arab Jamahiriya", value: "Libyan Arab Jamahiriya" },
  { label: "Liechtenstein", value: "Liechtenstein" },
  { label: "Lithuania", value: "Lithuania" },
  { label: "Luxembourg", value: "Luxembourg" },
  { label: "Macao", value: "Macao" },
  { label: "Macedonia, The Former Yugoslav Republic of", value: "Macedonia, The Former Yugoslav Republic of" },
  { label: "Madagascar", value: "Madagascar" },
  { label: "Malawi", value: "Malawi" },
  { label: "Malaysia", value: "Malaysia" },
  { label: "Maldives", value: "Maldives" },
  { label: "Mali", value: "Mali" },
  { label: "Malta", value: "Malta" },
  { label: "Marshall Islands", value: "Marshall Islands" },
  { label: "Martinique", value: "Martinique" },
  { label: "Mauritania", value: "Mauritania" },
  { label: "Mauritius", value: "Mauritius" },
  { label: "Mayotte", value: "Mayotte" },
  { label: "Mexico", value: "Mexico" },
  { label: "Micronesia, Federated States of", value: "Micronesia, Federated States of" },
  { label: "Moldova, Republic of", value: "Moldova, Republic of" },
  { label: "Monaco", value: "Monaco" },
  { label: "Mongolia", value: "Mongolia" },
  { label: "Montenegro", value: "Montenegro" },
  { label: "Montserrat", value: "Montserrat" },
  { label: "Morocco", value: "Morocco" },
  { label: "Mozambique", value: "Mozambique" },
  { label: "Myanmar", value: "Myanmar" },
  { label: "Namibia", value: "Namibia" },
  { label: "Nauru", value: "Nauru" },
  { label: "Nepal", value: "Nepal" },
  { label: "Netherlands", value: "Netherlands" },
  { label: "Netherlands Antilles", value: "Netherlands Antilles" },
  { label: "New Caledonia", value: "New Caledonia" },
  { label: "New Zealand", value: "New Zealand" },
  { label: "Nicaragua", value: "Nicaragua" },
  { label: "Niger", value: "Niger" },
  { label: "Nigeria", value: "Nigeria" },
  { label: "Niue", value: "Niue" },
  { label: "Norfolk Island", value: "Norfolk Island" },
  { label: "Northern Mariana Islands", value: "Northern Mariana Islands" },
  { label: "Norway", value: "Norway" },
  { label: "Oman", value: "Oman" },
  { label: "Pakistan", value: "Pakistan" },
  { label: "Palau", value: "Palau" },
  { label: "Palestinian Territory, Occupied", value: "Palestinian Territory, Occupied" },
  { label: "Panama", value: "Panama" },
  { label: "Papua New Guinea", value: "Papua New Guinea" },
  { label: "Paraguay", value: "Paraguay" },
  { label: "Peru", value: "Peru" },
  { label: "Philippines", value: "Philippines" },
  { label: "Pitcairn", value: "Pitcairn" },
  { label: "Poland", value: "Poland" },
  { label: "Portugal", value: "Portugal" },
  { label: "Puerto Rico", value: "Puerto Rico" },
  { label: "Qatar", value: "Qatar" },
  { label: "Reunion", value: "Reunion" },
  { label: "Romania", value: "Romania" },
  { label: "Russian Federation", value: "Russian Federation" },
  { label: "RWANDA", value: "RWANDA" },
  { label: "Saint Helena", value: "Saint Helena" },
  { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { label: "Saint Lucia", value: "Saint Lucia" },
  { label: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
  { label: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines" },
  { label: "Samoa", value: "Samoa" },
  { label: "San Marino", value: "San Marino" },
  { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
  { label: "Saudi Arabia", value: "Saudi Arabia" },
  { label: "Senegal", value: "Senegal" },
  { label: "Serbia", value: "Serbia" },
  { label: "Seychelles", value: "Seychelles" },
  { label: "Sierra Leone", value: "Sierra Leone" },
  { label: "Singapore", value: "Singapore" },
  { label: "Slovakia", value: "Slovakia" },
  { label: "Slovenia", value: "Slovenia" },
  { label: "Solomon Islands", value: "Solomon Islands" },
  { label: "Somalia", value: "Somalia" },
  { label: "South Africa", value: "South Africa" },
  { label: "South Georgia and the South Sandwich Islands", value: "South Georgia and the South Sandwich Islands" },
  { label: "Spain", value: "Spain" },
  { label: "Sri Lanka", value: "Sri Lanka" },
  { label: "Sudan", value: "Sudan" },
  { label: "Suriname", value: "Suriname" },
  { label: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
  { label: "Swaziland", value: "Swaziland" },
  { label: "Sweden", value: "Sweden" },
  { label: "Switzerland", value: "Switzerland" },
  { label: "Syrian Arab Republic", value: "Syrian Arab Republic" },
  { label: "Taiwan, Province of China", value: "Taiwan, Province of China" },
  { label: "Tajikistan", value: "Tajikistan" },
  { label: "Tanzania, United Republic of", value: "Tanzania, United Republic of" },
  { label: "Thailand", value: "Thailand" },
  { label: "Timor-Leste", value: "Timor-Leste" },
  { label: "Togo", value: "Togo" },
  { label: "Tokelau", value: "Tokelau" },
  { label: "Tonga", value: "Tonga" },
  { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
  { label: "Tunisia", value: "Tunisia" },
  { label: "Turkey", value: "Turkey" },
  { label: "Turkmenistan", value: "Turkmenistan" },
  { label: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
  { label: "Tuvalu", value: "Tuvalu" },
  { label: "Uganda", value: "Uganda" },
  { label: "Ukraine", value: "Ukraine" },
  { label: "United Arab Emirates", value: "United Arab Emirates" },
  { label: "United Kingdom", value: "United Kingdom" },
  { label: "United States", value: "United States" },
  { label: "United States Minor Outlying Islands", value: "United States Minor Outlying Islands" },
  { label: "Uruguay", value: "Uruguay" },
  { label: "Uzbekistan", value: "Uzbekistan" },
  { label: "Vanuatu", value: "Vanuatu" },
  { label: "Venezuela", value: "Venezuela" },
  { label: "Viet Nam", value: "Viet Nam" },
  { label: "Virgin Islands, British", value: "Virgin Islands, British" },
  { label: "Virgin Islands, U.S.", value: "Virgin Islands, U.S." },
  { label: "Wallis and Futuna", value: "Wallis and Futuna" },
  { label: "Western Sahara", value: "Western Sahara" },
  { label: "Yemen", value: "Yemen" },
  { label: "Zambia", value: "Zambia" },
  { label: "Zimbabwe", value: "Zimbabwe" },
];

const ENTRY_MINER_PROGRESS_BAR = ["ENTRY FORM", "KYC/AML", "KYM", "SUCCESSFUL ONBOARDING"];
const ENTRY_LP_PROGRESS_BAR = ["ENTRY FORM", "KYC/AML", "SUCCESSFUL ONBOARDING"];

const KYC_HEADER = [
  {
    name: "Company Details",
    active: false,
    completed: false,
  },
  {
    name: "Legal Representatives of the company",
    active: false,
    completed: false,
  },
];

const KYM_HEADER = [
  {
    name: "General & Financial Information",
    active: false,
    completed: false,
  },
  {
    name: "Mining Site Information",
    active: false,
    completed: false,
  },
];

const ADMIN_OPPORTUNITIES_STATUS = [
  {
    value: "All",
    label: "All",
  },
  {
    value: OpportunityStatus.Settled,
    label: "Settled",
  },
  {
    value: OpportunityStatus.Active,
    label: "Active",
  },
  {
    value: OpportunityStatus.Defaulted,
    label: "Defaulted",
  },
  {
    value: OpportunityStatus.AwaitingCollateral,
    label: "Awaiting Collateral",
  },
  {
    value: OpportunityStatus.Cancelled,
    label: "Cancelled",
  },
  {
    value: OpportunityStatus.AwaitingFunding,
    label: "Awaiting Funding",
  },
  {
    value: OpportunityStatus.Draft,
    label: "Draft",
  },
];

const ENERGY_MIX_WITH_ICONS: any = {
  Solar: {
    icon: SolarIcon,
    color: "rgba(252, 123, 86, 0.1)",
  },
  Hydro: {
    icon: HydroIcon,
    color: "rgba(141, 172, 216, 0.1)",
  },
  Nuclear: {
    icon: NuclearIcon,
    color: "rgba(92, 217, 161, 0.1)",
  },
  Wind: {
    icon: WindIcon,
    color: "#F4F9FE",
  },
  Coal: {
    icon: CoalIcon,
    color: "#F4F9FE",
  },
  Geothermal: {
    icon: GeothermalIcon,
    color: "#F4F9FE",
  },
  Oil: {
    icon: OilIcon,
    color: "#F4F9FE",
  },
  "Waste Gas": {
    icon: WasteGasIcon,
    color: "#F4F9FE",
  },
  "Other Non Renewable": {
    icon: OtherNonRenewableIcon,
    color: "#F4F9FE",
  },
  "Other Renewable": {
    icon: OtherNonRenewableIcon,
    color: "#F4F9FE",
  },
  "Flared Gas": {
    icon: WasteGasIcon,
    color: "#F4F9FE",
  },
  "Flared gas": {
    // TODO Bug - Duplicated, difference is G and g
    icon: WasteGasIcon,
    color: "#F4F9FE",
  },
  "Grid mix": {
    icon: OtherNonRenewableIcon,
    color: "#F4F9FE",
  },
  "Natural gas": {
    icon: WasteGasIcon,
    color: "#F4F9FE",
  },
  Other: {
    icon: OtherIcon,
    color: "#F4F9FE",
  },
};

const DURATION_MAPPER = {
  "1to6": "From 1 to 6 months",
  lt6: "Less then 6 months",
  "6to12": "From 6 to 12 months",
  "12to18": "From 12 to 18 months",
  Gt18: "Greater then 18 months",
};

const ACTIONABLE = [
  {
    label: "Yes",
    value: YesOrNoChoice.Yes,
  },
  {
    label: "No",
    value: YesOrNoChoice.No,
  },
];

const PRIORITY = [
  {
    label: "High",
    value: "High",
  },
  {
    label: "Medium",
    value: "Medium",
  },
  {
    label: "Low",
    value: "Low",
  },
];

const YEAR_OPTIONS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "",
    value: "devider",
  },
  {
    label: "2023",
    value: "2023",
  },
  {
    label: "2022",
    value: "2022",
  },
  {
    label: "2021",
    value: "2021",
  },
  {
    label: "2020",
    value: "2020",
  },
  {
    label: "2019",
    value: "2019",
  },
  {
    label: "2018",
    value: "2018",
  },
];

const MONTHS_OPTIONS = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Current",
    value: "Current",
  },
  {
    label: "",
    value: "devider",
  },
  {
    label: "January",
    value: "January",
  },
  {
    label: "February",
    value: "February",
  },
  {
    label: "March",
    value: "March",
  },
  {
    label: "April",
    value: "April",
  },
  {
    label: "May",
    value: "May",
  },
  {
    label: "June",
    value: "June",
  },
  {
    label: "July",
    value: "July",
  },
  {
    label: "August",
    value: "August",
  },
  {
    label: "September",
    value: "September",
  },
  {
    label: "October",
    value: "October",
  },
  {
    label: "November",
    value: "November",
  },
  {
    label: "December",
    value: "December",
  },
];

export {
  ACTIONABLE,
  ADMIN_OPPORTUNITIES_STATUS,
  DURATION_MAPPER,
  ENERGY_MIX,
  ENERGY_MIX_WITH_ICONS,
  ENTRY_LP_PROGRESS_BAR,
  ENTRY_MINER_PROGRESS_BAR,
  ERROR_ICON_CLASS,
  ERROR_INPUT_CLASS,
  ERROR_LABEL_CLASS,
  ERROR_MESSAGE_CLASS,
  GRANULAR_TIME_HORIZON_OF_LIQUIDITY,
  KYC_HEADER,
  KYM_HEADER,
  MONTHS_OPTIONS,
  PRIORITY,
  SATOSHIS_TO_BTC,
  SELECT_COUNTRY,
  YEAR_OPTIONS,
};

export const isApiError = (error: unknown): error is { error: { message: string } } => {
  const result =
    !!error &&
    typeof error === "object" &&
    "error" in error &&
    !!error.error &&
    typeof error.error === "object" &&
    "message" in error.error;
  return result;
};
