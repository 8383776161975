import React, {
  ChangeEvent,
  Children,
  cloneElement,
  FormEvent,
  Fragment,
  isValidElement,
  ReactNode,
  useState,
} from "react";

interface FormProps {
  children: JSX.Element[] | JSX.Element;
  className?: string;
  formData?: any;

  onSubmit(payload: any, e: any): any;
  onChange?: (name: string, value: string | number | boolean) => void;
}

const Form = ({ children, onSubmit, onChange, formData = {} }: FormProps) => {
  const [payload, setPayload] = useState(formData);

  const onHandleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(payload, e);
  };

  const onHandleChangeWithChildren = (event: ChangeEvent<HTMLInputElement>) => {
    setPayload((prev: any) => {
      return {
        ...prev,
        [event?.target?.name]: event?.target?.value,
      };
    });
    onChange && onChange(event?.target?.name, event?.target?.value);
  };

  const onHandleChangeWithoutChildren = (name: string, value: string | number | boolean) => {
    setPayload((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    onChange && onChange(name, value);
  };

  const cloneThroughFragments = (children: ReactNode): ReactNode => {
    return Children.map(children, (c) => {
      if (isValidElement(c)) {
        if (c.type === Fragment) {
          return cloneThroughFragments(c.props.children);
        }

        return cloneElement(c, {
          ...c.props,
          onChange: c?.props?.children ? onHandleChangeWithChildren : onHandleChangeWithoutChildren,
        });
      }

      return c;
    });
  };

  return (
    <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => onHandleSubmit(e)} encType="multipart/form-data">
      {cloneThroughFragments(children)}
    </form>
  );
};

export default Form;
