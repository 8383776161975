import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Skeleton,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { UpdateKymHostingDto } from "../../app/model/api";
import { useMutationKymHosting } from "../../app/query/useKymMutations";
import { useQueryGetKym2Hosting } from "../../app/query/useKymQueries";

interface HostingFormData {
  readonly hostsOtherMiners: boolean;
  readonly totalCapacity?: number;
  readonly expectedAmountIn12Months?: number;
  readonly averagePrice?: number;
  readonly hedgingStrategy?: string;
}

const YupDependingOnHosting = yup.number().when("hostsOtherMiners", {
  is: (hostsOtherMiners: boolean) => hostsOtherMiners,
  then: yup
    .number()
    .typeError("This value has to be a positive number!")
    .required("This value has to be a positive number!")
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive("This value has to be a positive number!"),
});
const YupFormSchema = yup
  .object({})
  .shape({
    hostsOtherMiners: yup.bool().required(),
    totalCapacity: YupDependingOnHosting,
    averagePrice: YupDependingOnHosting,
    hedgingStrategy: yup.string().nullable(),
    expectedAmountIn12Months: yup
      .number()
      .positive("This value has to be a positive number!")
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
  })
  .required();

export const KymHostingContainer = () => {
  const { refetch: reloadHostingData, data: hosting, isFetching: isLoadingHosting } = useQueryGetKym2Hosting();

  const [destroyKey, setDestroyKey] = React.useState<boolean>(false);

  const onRefetch = () => {
    setDestroyKey(!destroyKey); // force the component to be rebuilt
    reloadHostingData();
  };

  return isLoadingHosting || !hosting?.data ? (
    <Stack>
      <Skeleton width={"100%"} height={300} />
    </Stack>
  ) : (
    <KymHosting key={destroyKey.toString()} input={hosting.data} triggerRefetch={onRefetch}></KymHosting>
  );
};

const KymHosting = ({ input, triggerRefetch }: { input: UpdateKymHostingDto; triggerRefetch: () => void }) => {
  const {
    register,
    unregister,
    watch,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<HostingFormData>({
    resolver: yupResolver(YupFormSchema),
    defaultValues: {
      hostsOtherMiners: input.hostsOtherMiners,
      totalCapacity: input.totalCurrentHostedCapacityPhPerS,
      expectedAmountIn12Months: input.expectedCapacityIn12MonthsMPhPerS,
      averagePrice: input.averageHostingPricePerClientUsdPerMWh,
      hedgingStrategy: input.priceHedgingStrategy,
    },
  });

  const { mutateAsync: saveHosting, isLoading: isSavingHosting } = useMutationKymHosting();

  const hostsOtherMiners: boolean = watch("hostsOtherMiners");
  React.useEffect(() => {
    // we need to manually register/unregister these for validation
    if (hostsOtherMiners) {
      register("totalCapacity");
      register("expectedAmountIn12Months");
      register("averagePrice");
      register("hedgingStrategy");
    } else {
      unregister("totalCapacity");
      unregister("expectedAmountIn12Months");
      unregister("averagePrice");
      unregister("hedgingStrategy");
    }
  }, [hostsOtherMiners, register, unregister]);

  const onSubmit = async (data: HostingFormData) => {
    const request: UpdateKymHostingDto = {
      hostsOtherMiners: data.hostsOtherMiners,
      totalCurrentHostedCapacityPhPerS:
        !data.totalCapacity?.toString() || data.totalCapacity == undefined ? undefined : +data.totalCapacity,
      expectedCapacityIn12MonthsMPhPerS:
        !data.expectedAmountIn12Months?.toString() || data.expectedAmountIn12Months == undefined
          ? undefined
          : +data.expectedAmountIn12Months,
      averageHostingPricePerClientUsdPerMWh:
        !data.averagePrice || data.averagePrice == undefined ? undefined : +data.averagePrice,
      priceHedgingStrategy: data.hedgingStrategy,
    };

    try {
      const result = await saveHosting(request);
      if (result.data.kind === false) {
        toast.error("There has been an issue while trying to save your changes. Please try again.");
      } else {
        toast.success("Your updates have been saved successfully.");
        triggerRefetch();
      }
    } catch (err) {
      toast.error("There has been an issue while trying to save your changes. Please try again.");
    }
  };

  return (
    <Box width={"100%"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Box>
            <Box>
              <Grid container item alignItems={"center"}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={<Switch color="primary" {...register("hostsOtherMiners")} checked={hostsOtherMiners} />}
                    label="Are you operating as a third-party host for other Bitcoin miners?"
                    labelPlacement="end"
                  />
                </FormControl>
              </Grid>
              {hostsOtherMiners && (
                <>
                  <Grid direction="row" container spacing={2} mt={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="totalCapacity"
                        label={"Total current hosted capacity"}
                        placeholder="5"
                        {...register("totalCapacity")}
                        fullWidth
                        type="number"
                        variant="outlined"
                        error={!!errors.totalCapacity}
                        helperText={<>{errors.totalCapacity && <Box>{errors.totalCapacity.message}</Box>}</>}
                        onWheel={(evt) => {
                          (evt.target as HTMLElement).blur(); // disable edit by scroll
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">PH/s</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="expectedAmountIn12Months"
                        label={"[Optional] Expected hosted capacity in 12 months"}
                        placeholder="23"
                        {...register("expectedAmountIn12Months")}
                        fullWidth
                        type="number"
                        variant="outlined"
                        error={!!errors.expectedAmountIn12Months}
                        helperText={
                          errors.expectedAmountIn12Months && <Box>{errors.expectedAmountIn12Months.message}</Box>
                        }
                        onWheel={(evt) => {
                          (evt.target as HTMLElement).blur(); // disable edit by scroll
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">PH/s</InputAdornment>,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid direction="row" container spacing={2} mt={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="averagePrice"
                        label={"Average all-in hosting price charged to client"}
                        placeholder="5"
                        {...register("averagePrice")}
                        fullWidth
                        type="number"
                        variant="outlined"
                        error={!!errors.averagePrice}
                        helperText={errors.averagePrice && <Box>{errors.averagePrice.message}</Box>}
                        onWheel={(evt) => {
                          (evt.target as HTMLElement).blur(); // disable edit by scroll
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">USD/MWh</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="hedgingStrategy"
                        label={"[Optional] Energy price hedging strategy"}
                        {...register("hedgingStrategy")}
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={5}
                        error={!!errors.hedgingStrategy}
                        onWheel={(evt) => {
                          (evt.target as HTMLElement).blur(); // disable edit by scroll
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Box>

          <Box mt={2} gap={2} display="flex" alignItems={"center"} justifyContent={"flex-end"}>
            <LoadingButton
              type="submit"
              disabled={!isDirty}
              loading={isSavingHosting}
              variant="contained"
              sx={{ height: 40 }}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
