import CloseIcon from "@mui/icons-material/Close";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import {
  AppBar,
  Box,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { OpportunityRewardDto } from "../../../../app/model/api";
import { useGetAdminOpportunityRewards } from "../../../../app/query/useQueryGetAdmin";
import { AdminRewardDetails } from "./AdminRewardDetails";

type RowPagination = {
  readonly page: number;
  readonly pageSize: 25 | 50 | 100;
};

export const AdminOpportunityRewards = ({ opportunityId }: { opportunityId: string }) => {
  const [rewardId, setRewardId] = React.useState<string | undefined>(undefined);

  const [paginationModel, setPaginationModel] = useState<RowPagination>({
    page: 0,
    pageSize: 25,
  });

  const { data, isFetching } = useGetAdminOpportunityRewards(opportunityId, {
    limit: paginationModel.pageSize,
    offset: paginationModel.page * paginationModel.pageSize,
    orderBy: "createdAt",
    orderDirection: "desc",
  });

  const totalCount = data?.data.count;
  const rows = data?.data.rows.map((x) => ({ id: x.rewardId, ...x, timestamp: new Date(x.createdAt) }));

  const handlePaginationChange = (input: RowPagination) => {
    if (input.pageSize !== paginationModel.pageSize) {
      setPaginationModel({
        page: 0,
        pageSize: input.pageSize,
      });
    } else if (input.page !== paginationModel.page) {
      setPaginationModel(input);
    }
  };
  const gridColumns: GridColDef<OpportunityRewardDto>[] = [
    {
      field: "timestamp",
      headerName: "Created at",
      type: "dateTime",
      display: "flex",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    { field: "day", headerName: "Day", flex: 1, display: "flex", sortable: false, disableColumnMenu: true },
    {
      field: "amountSatoshis",
      headerName: "Amount (SAT)",
      flex: 1,
      display: "flex",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "distributionStatus",
      headerName: "Distribution status",
      flex: 1,
      display: "flex",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "",
      width: 50,
      sortable: false,
      align: "center",
      display: "flex",
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton onClick={() => setRewardId(params.row.rewardId)} color="info">
          <FileOpenIcon />
        </IconButton>
      ),
    },
  ];
  return (
    <Box width={"100%"}>
      {rewardId ? (
        <Dialog open={!!rewardId} onClose={() => setRewardId(undefined)} fullWidth={true} maxWidth="md">
          <AppBar color="default" sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => setRewardId(undefined)} aria-label="close">
                <CloseIcon />
              </IconButton>

              <Typography noWrap>Reward #{rewardId}</Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Box display="flex">
              <AdminRewardDetails rewardId={rewardId} />
            </Box>
          </DialogContent>
        </Dialog>
      ) : null}
      <Container sx={{ background: "white", maxWidth: 1800, pt: 3, pb: 3 }}>
        {!isFetching && (
          <DataGridPro
            rows={rows ?? []}
            rowCount={totalCount}
            columns={gridColumns}
            pagination
            paginationMode="server"
            sortingMode="server"
            pageSizeOptions={[25, 50, 100]}
            paginationModel={paginationModel}
            initialState={{
              pagination: { paginationModel },
            }}
            onPaginationModelChange={(input) => {
              if (input.pageSize === 25 || input.pageSize === 50 || input.pageSize === 100)
                handlePaginationChange({
                  page: input.page,
                  pageSize: input.pageSize,
                });
            }}
            sx={{
              bgcolor: "white",
              boxShadow: 3,
              height: "50vh",
              padding: 1,
            }}
            slots={{
              noRowsOverlay: () => (
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={"100%"}
                  height={"100%"}
                  sx={{ minHeight: "60px" }}
                >
                  <Typography>No rewards found.</Typography>
                </Stack>
              ),
            }}
          ></DataGridPro>
        )}

        {isFetching && (
          <Box width="100%" pl={2} pr={2}>
            <Skeleton width={"100%"} height={50}></Skeleton>
            <Skeleton width={"100%"} height={50}></Skeleton>
            <Skeleton width={"100%"} height={50}></Skeleton>
            <Skeleton width={"100%"} height={50}></Skeleton>
            <Skeleton width={"100%"} height={50}></Skeleton>
          </Box>
        )}
      </Container>
    </Box>
  );
};
