import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { IconButton } from "@mui/material";
import classNames from "classnames";
import { FC, useState } from "react";

const NotionForm: FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <div
        className={classNames("fixed z-50 right-4 bottom-[60px] py-4 rounded-[4px] bg-white shadow-lg", {
          block: open,
          hidden: !open,
        })}
      >
        <iframe
          width={500}
          height={750}
          src="https://noteforms.com/forms/user-feedback-5o3eth?notionforms=1&utm_source=notionforms"
          frameBorder="0"
        ></iframe>
      </div>
      <IconButton
        className="!fixed right-4 bottom-4 !bg-purple !text-white"
        onClick={() => setOpen((prev: boolean) => !prev)}
      >
        <ChatBubbleIcon />
      </IconButton>
    </>
  );
};

export default NotionForm;
