import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, FormControl, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ROUTES_LOANS } from "../../../app/constants";
import { isCustomError } from "../../../app/helper/errors";
import { SortFirstActionRequired } from "../../../app/model/api";
import { useQueryAdminGetOrganizations } from "../../../app/query/useQueryGetAdmin";
import { useAdminCreateDraftLoan } from "../loanMutations";

export const AdminPageCreateLoan = () => {
  const navigate = useNavigate();
  const [selectedMinerId, setSelectedMinerId] = useState<string | undefined>();
  const [selectedLpId, setSelectedLpId] = useState<string | undefined>();

  const { data: orgs, isFetching } = useQueryAdminGetOrganizations({
    actionFirst: SortFirstActionRequired.No,
  });

  const { mutateAsync, isLoading: isSaving } = useAdminCreateDraftLoan();

  const isLoadingSomething = isFetching || isSaving;

  const viableMiners = orgs?.rows
    .filter((x) => x.userType === "Miner" && x.onboardingStatus === "Fully onboarded")
    .map((x) => ({ id: x.id, name: x.company ? `${x.company} (${x.email})` : x.email }));

  const viableLps = orgs?.rows
    .filter((x) => x.userType === "LP" && x.onboardingStatus === "Fully onboarded")
    .map((x) => ({ id: x.id, name: x.company ? `${x.company} (${x.email})` : x.email }));

  const onHandleCreate = async () => {
    if (selectedLpId && selectedMinerId) {
      try {
        const result = await mutateAsync({ lpId: selectedLpId, minerId: selectedMinerId, durationDays: 50 });
        console.log(selectedLpId, selectedMinerId);
        if (result.data.id) {
          navigate(ROUTES_LOANS.Admin.LoanDetails.replace(":id", result.data.id));
        }
      } catch (err) {
        if (isCustomError(err)) toast.error("Error creating draft loan: " + err.error.message);
        else toast.error("Error creating draft loan.");
      }
    }
  };

  return (
    <Box display="flex" justifyContent={"center"}>
      <Box width="100%" maxWidth={800} bgcolor={"white"} padding={2}>
        <Stack spacing={2} alignItems={"flex-start"}>
          <Typography variant="h5">Create a new draft loan</Typography>
          {!!viableMiners?.length && (
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                disabled={isLoadingSomething}
                value={selectedMinerId && viableMiners.find((x) => x.id === selectedMinerId)}
                onChange={(_event, newValue) => {
                  newValue ? setSelectedMinerId(newValue.id) : setSelectedMinerId(undefined);
                }}
                getOptionLabel={(option) => (option ? option.name : "")}
                id="combo-box-miners"
                options={viableMiners}
                renderInput={(params) => <TextField {...params} size="small" label="Select the miner" />}
              />
            </FormControl>
          )}
          {!!viableLps?.length && (
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                disabled={isLoadingSomething}
                value={selectedLpId && viableLps.find((x) => x.id === selectedLpId)}
                onChange={(_event, newValue) => {
                  newValue ? setSelectedLpId(newValue.id) : setSelectedLpId(undefined);
                }}
                getOptionLabel={(option) => (option ? option.name : "")}
                id="combo-box-lps"
                options={viableLps}
                renderInput={(params) => <TextField {...params} size="small" label="Select the LP" />}
              />
            </FormControl>
          )}
          <LoadingButton
            onClick={onHandleCreate}
            loading={isSaving}
            disabled={isLoadingSomething || !selectedLpId || !selectedMinerId}
            variant="contained"
          >
            Create draft loan
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
};
