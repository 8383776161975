import { Box, Skeleton, Stack, Typography } from "@mui/material";
import Loader from "../../../components/atoms/Loader";
import { LoanDeliveriesTable } from "../commons/LoanDeliveriesTable";
import { LoanTitle } from "../commons/LoanTitle";
import { useAdminGetLoan } from "../loanQueries";
import { LoanAssociatedAddressesTable } from "./LoanAssociatedAddressesTable";
import { LoanPaymentRequestsTable } from "../commons/LoanPaymentRequestsTable";
import { AuditTransactionsTable } from "./AuditTransactionsTable";
import { ExplainerAccordion } from "../commons/ExplainerAccordion";
import { UnitSelector } from "../commons/UnitSelector";
import { useState } from "react";

export const ADMIN_EXPLAINER_PAYMENT_REQUESTS = (
  <ExplainerAccordion
    title="More details"
    explainers={[
      "These are payment requests created in the application. They are executed through Bitgo, and we also retrieve the confirmation information for them.",
      "There are three types of payments, based on the destination: LP payments which are reward distributions, Miner payments which are excess reward returns and Admin payments which are supposed to be used for the admin management costs.",
      "A payment is picked up for execution after the initial waiting time. If the execution fails, it will be retried a few times, with an exponential backoff. If all attempts fail, the payment request is marked as failed and will not be picked up for execution again",
      "Failed payment requests should not be considered in calculations of totals.",
      "Once the execution succeeds, we also watch Bitgo for confirmation updates.",
    ]}
  />
);

export const AdminPageLoanAudit = ({ id }: { id: string }) => {
  const { data, isFetching } = useAdminGetLoan(id ?? "");
  const [unit, setUnit] = useState<"BTC" | "SAT">("BTC");

  const loan = data?.data;

  if (!loan) {
    return <Loader />;
  } else {
    return (
      <Stack width="100%" spacing={1} pt={3}>
        <Box display="flex" width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
          <LoanTitle id={loan.id} status={loan.status} isDemo={loan.isDemo} />
          <UnitSelector initial={unit} onChange={setUnit} />
        </Box>
        {isFetching && <Skeleton width="100%" height={"30vh"} variant="rounded" />}
        <LoanAssociatedAddressesTable loan={loan} />
        <LoanPaymentRequestsTable
          unit={unit}
          title="Payment requests"
          includeExecutionDetails={true}
          outgoingPayments={loan.outgoingPayments}
          height="30vh"
          explainer={ADMIN_EXPLAINER_PAYMENT_REQUESTS}
        />
        <Typography variant="h6" gutterBottom>
          Actual deliveries
        </Typography>
        <LoanDeliveriesTable minerDeliveries={loan.minerDeliveries} height={"30vh"} unit={unit} />
        <AuditTransactionsTable transactions={loan.auditTransactions} height={"30vh"} unit={unit} />
      </Stack>
    );
  }
};
