import classNames from "classnames";
import { formatInTimeZone } from "date-fns-tz";
import Decimal from "decimal.js";
import { Link } from "react-router-dom";
import { ENERGY_MIX_WITH_ICONS, OPPORTUNITIES_DETAILS_LP_ROUTE } from "../../../../app/constants";
import {
  amountFormat,
  amountFormatSatoshisToBTC,
  calculatePriceBtcThSecoundDays,
  roundToSignificantDigits,
  roundToSignificantDigitsDiv1000,
} from "../../../../app/helper/utils";
import { EnergyMix, OpportunitiesDetails } from "../../../../app/model/api";
import { ReactComponent as CalendarIcon } from "../../../../assets/images/calendar.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/images/info.svg";
import MinerAxeIcon from "../../../../assets/images/miner-axe.png";
import { ReactComponent as WavesIcon } from "../../../../assets/images/waves.svg";
import ProgressPercentBar from "../../../atoms/ProgressPercentBar";
import "./style.scss";

export type OpportunityItemProps = OpportunitiesDetails;

export const StreamingItem = ({ data }: { data: OpportunityItemProps }) => {
  const showMoreEnergyMix = false;

  const {
    opportunityBasic,
    partnership,
    fundingDeadline,
    expectedReturn,
    energyMix,
    durationOfAgreement,
    maxAmountOfLiquidity,
    fullHashrateThPerSecond,
  } = data;
  const { miner, currentHashrate, liquidityReceived } = opportunityBasic;

  const getEnergyMix = () => {
    if (!energyMix || (energyMix && Object.keys(energyMix).length === 0)) return [];

    return Object.keys(energyMix)
      .map((item) => {
        const isItemExist = ENERGY_MIX_WITH_ICONS.hasOwnProperty(item);

        return {
          icon: ENERGY_MIX_WITH_ICONS[isItemExist ? item : "Other"]?.icon,
          value: energyMix[item as keyof EnergyMix],
          name: isItemExist ? item : "Other",
          color: ENERGY_MIX_WITH_ICONS[isItemExist ? item : "Other"].color,
        };
      })
      .filter((item) => Number(item.value) > 0);
  };

  const energyMixElements = getEnergyMix();

  const priceBtcPhSecondDays = calculatePriceBtcThSecoundDays({
    maxAmountOfLiquidity,
    fullHashrateThPerSecond,
    durationOfAgreementDays: durationOfAgreement,
  });
  return (
    <Link to={OPPORTUNITIES_DETAILS_LP_ROUTE.replace(":id", String(opportunityBasic.id))}>
      <div className="opportunity-item-streaming h-full">
        <div className="opportunity-item-streaming-head">
          <div className="px-4 py-3">
            <div className="flex justify-between mb-12">
              <div className="flex">
                <div className="bg-white p-1 mr-1">
                  <CalendarIcon />
                </div>
                <div className="bg-white px-1 ">{durationOfAgreement} days</div>
              </div>
              <div className="flex">
                <div className="bg-white p-1 mr-1">
                  <WavesIcon />
                </div>
                <div className="bg-white px-1 ">Hashrate streaming</div>
              </div>
            </div>

            <div className="flex">
              <div className="bg-white pt-1 pr-1 mr-1">
                <img src={MinerAxeIcon} alt="Miner Axe Icon" />
              </div>
              <div className="miner-name">{miner.company}</div>
            </div>
          </div>
        </div>

        <div className="opportunity-item-streaming-body">
          {partnership && (
            <div className="opportunity-partnership">
              <div className="tooltip-content">
                Partnership description
                <div className="tooltip-arrow"></div>
              </div>
              <div className="flex">
                Partnership <InfoIcon className="ml-2" />
              </div>
            </div>
          )}

          <div className="px-4 pt-9 pb-6">
            <p className="uppercase text-[13px]">
              <strong>Start date</strong>
            </p>
            <p className="mb-5">{formatInTimeZone(fundingDeadline, "UTC", "dd MMM yyyy")}</p>

            {energyMixElements.length ? (
              <>
                <p className="uppercase text-[13px]">
                  <strong>Energy Mix</strong>
                </p>

                <div className={classNames("relative mb-4 h-[95px]")}>
                  <div
                    className={classNames({
                      "h-auto absolute z-10 bg-white pb-5 w-full": showMoreEnergyMix,
                    })}
                  >
                    <div
                      className={classNames("opportunity-energy-mix", {
                        "h-[95px]": !showMoreEnergyMix,
                      })}
                    >
                      {getEnergyMix().map((item, index) => {
                        return (
                          <div key={index}>
                            <div
                              className="flex items-center gap-[8px] px-[8px] py-[6px]"
                              style={{ backgroundColor: `${item.color}` }}
                            >
                              <img src={item.icon} alt={item.name} className="w-5" />
                              <h5 className="text-sm">{item.name}</h5>
                              <div className="percent bg-white text-sm py-[2px] px-[6px]">{item.value}%</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <hr className="mb-6 h-0 border-1 border-[#EAECF0]" />

            <div className="grid grid-cols-2 gap-y-5 mb-5">
              <div>
                <p className="uppercase text-[13px]">
                  <strong>Expected Return</strong>
                </p>
                <p>{expectedReturn ? `${expectedReturn}%` : "-"}</p>
              </div>
              <div>
                <p className="uppercase text-[13px]">
                  <strong>Price per PH/s/day</strong>
                </p>
                <p>{amountFormat(priceBtcPhSecondDays, "BTC")} BTC/PH/s/day</p>
              </div>
            </div>

            <p className="uppercase text-[13px] mb-2">
              <strong>Capacity</strong>
            </p>

            <div className="grid grid-cols-2 mb-5">
              <div>
                <p className="text-[11px]">Current Hashrate Allocated</p>
                <p>
                  {roundToSignificantDigits(Number(currentHashrate), 1)} <sup>PH/s</sup>
                </p>
              </div>
              <div>
                <p className="text-[11px]">Maximum Capacity</p>
                <p>
                  {roundToSignificantDigitsDiv1000(Number(fullHashrateThPerSecond), 1)} <sup>PH/s</sup>
                </p>
              </div>
            </div>

            <div>
              <div className="flex justify-between mb-2">
                <p className="text-[11px]">Current Deposits</p>
                <p>
                  {liquidityReceived?.current ? amountFormatSatoshisToBTC(liquidityReceived?.current) : 0}{" "}
                  <sup>BTC</sup>
                </p>
              </div>
              <ProgressPercentBar
                value={Number(
                  roundToSignificantDigits(
                    new Decimal(liquidityReceived?.current || 0)
                      .div(liquidityReceived?.total || 0)
                      .mul(100)
                      .toNumber()
                  )
                )}
                showPercentage
              />

              <div className="flex justify-between mt-4">
                <p className="text-[11px]">Maximum Capacity</p>
                <p>
                  {liquidityReceived?.total ? amountFormatSatoshisToBTC(liquidityReceived?.total) : 0} <sup>BTC</sup>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
