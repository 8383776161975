import "./style.scss";

interface SectionTitleProps {
  title: string | JSX.Element;
  subtitle?: string;
  backgroundColor?: string;
  children?: JSX.Element[] | JSX.Element;
  prefixChildren?: JSX.Element[] | JSX.Element;
}

const SectionTitle = ({
  title,
  subtitle,
  prefixChildren,
  children,
  backgroundColor = "#191C24",
}: SectionTitleProps) => {
  const customStyle = {
    backgroundColor,
  };

  return (
    <div className="section-title" style={customStyle}>
      <div className="container-custom mx-auto flex items-center">
        {prefixChildren}
        <div>
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default SectionTitle;
