import "./style.scss";

interface HeroBoxProps {
  title: string;
  titleIcon?: JSX.Element;
  backgroundImage?: string;
  children: JSX.Element[] | JSX.Element;
}

const HeroBox = ({ title, titleIcon, backgroundImage, children }: HeroBoxProps) => {
  const customStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
  };

  return (
    <div className="p-5 bg-white hero-box relative" style={customStyle}>
      <div className="hero-box-title">
        {titleIcon && titleIcon}
        {title}
      </div>
      <div className="flex gap-2 bg-white px-1 pt-1">{children}</div>
    </div>
  );
};

export default HeroBox;
