import { Box, Chip, Typography } from "@mui/material";
import { ResAdminLoanDto } from "../../../app/model/api";
import { checkExhaustive } from "../../Admin/MinerDetails/Opportunity/simulation/simulationTimelineItems";

export const getStatusChip = (status: ResAdminLoanDto["status"]) => {
  switch (status) {
    case "Active":
      return <Chip color="info" label={status} />;
    case "Cancelled":
      return <Chip color="warning" label={status} />;
    case "Completed":
      return <Chip color="success" label={status} />;
    case "Draft":
      return <Chip color="default" label={status} />;
    case "UnderDelivered":
      return <Chip color="error" label={"Under delivered"} />;
    default:
      checkExhaustive(status);
  }
};

export const LoanTitle = ({
  id,
  status,
  isDemo,
}: {
  id: string;
  status: ResAdminLoanDto["status"];
  isDemo: boolean;
}) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      {isDemo ? <Chip label="Demo loan" variant="filled" color="primary" /> : null}
      <Typography variant="h5" sx={{ textDecoration: "underline" }}>
        Loan #{id}
      </Typography>
      {getStatusChip(status)}
    </Box>
  );
};
