import { Grid, Typography } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import { ReadonlyMinerKymFinancials } from "./ReadonlyMinerKymFinancials";
import { ReadonlyMinerCapacityAndSites } from "./ReadonlyMinerCapacityAndSites";
import { ReadonlyMinerEnergyProfile } from "./ReadonlyMinerEnergyProfile";
import { ReadonlyMinerAsic } from "./ReadonlyMinerAsic";
import { ReadonlyMinerHosting } from "./ReadonlyMinerHosting";

export const MinerKymDetails = ({ organizationId }: { organizationId: string }) => {
  const [isFinancialsOpen, setIsFinancialsOpen] = React.useState<boolean>(true);
  const [isCapacityOpen, setIsCapacityOpen] = React.useState<boolean>(false);
  const [isEnergyOpen, setIsEnergyOpen] = React.useState<boolean>(false);
  const [isAsicOpen, setIsAsicOpen] = React.useState<boolean>(false);
  const [isHostingOpen, setIsHostingOpen] = React.useState<boolean>(false);

  const handleChange = (section: "financials" | "capacity" | "energy" | "asic" | "hosting") => {
    switch (section) {
      case "financials":
        setIsFinancialsOpen(!isFinancialsOpen);
        return;
      case "capacity":
        setIsCapacityOpen(!isCapacityOpen);
        return;
      case "energy":
        setIsEnergyOpen(!isEnergyOpen);
        return;
      case "asic":
        setIsAsicOpen(!isAsicOpen);
        return;
      case "hosting":
        setIsHostingOpen(!isHostingOpen);
        return;
    }
  };

  return (
    <Grid container item xs={12}>
      <Accordion
        expanded={isFinancialsOpen}
        onChange={() => handleChange("financials")}
        sx={{ width: "100%", boxShadow: 4, flexDirection: "row-reverse" }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography variant="subtitle1">Financials</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReadonlyMinerKymFinancials organizationId={organizationId} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={isCapacityOpen}
        onChange={() => handleChange("capacity")}
        sx={{ width: "100%", boxShadow: 4 }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography variant="subtitle1">Capacity & sites</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReadonlyMinerCapacityAndSites organizationId={organizationId} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={isEnergyOpen}
        onChange={() => handleChange("energy")}
        sx={{ width: "100%", boxShadow: 4 }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography variant="subtitle1">Energy profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReadonlyMinerEnergyProfile organizationId={organizationId} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={isAsicOpen}
        onChange={() => handleChange("asic")}
        sx={{ width: "100%", boxShadow: 4 }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography variant="subtitle1">ASIC fleet</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReadonlyMinerAsic organizationId={organizationId} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={isHostingOpen}
        onChange={() => handleChange("hosting")}
        sx={{ width: "100%", boxShadow: 4 }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography variant="subtitle1">Hosting</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReadonlyMinerHosting organizationId={organizationId} />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
