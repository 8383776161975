import HeroBox from "../../atoms/HeroBox";
import Loader from "../../atoms/Loader";

import { amountFormat, getSiPrefixedNumber } from "../../../app/helper/utils";
import { useGetQueryOpportunitiesSummary } from "../../../app/query/useQueryGetLP";
import heroCurrentBtcPrice from "../../../assets/images/hero-bck-1.png";
import heroTotalNetworkHashRate from "../../../assets/images/hero-bck-2.png";

import "./style.scss";

const HeroOpportunitiesList = () => {
  const { data, isFetching } = useGetQueryOpportunitiesSummary();

  const { baseNumber, prefix } = getSiPrefixedNumber(data?.totalNetworkHashrate || 0);
  return (
    <div className="hero-lp-list">
      <div className="container-custom mx-auto !py-16">
        <div className="grid grid-cols-2 gap-4 pb-4">
          <HeroBox title="Current BTC price" backgroundImage={heroCurrentBtcPrice}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <div className="crypto-value">{amountFormat(data?.currentBtcPrice)}</div>
            )}
          </HeroBox>
          <HeroBox title="Total network hashrate" backgroundImage={heroTotalNetworkHashRate}>
            {isFetching ? (
              <Loader className="!absolute z-10" />
            ) : (
              <div className="crypto-value">
                {baseNumber} <span className="text-gray-400 font-normal">{prefix}H/s</span>
              </div>
            )}
          </HeroBox>
        </div>
      </div>
    </div>
  );
};

export default HeroOpportunitiesList;
