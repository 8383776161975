import classNames from "classnames";
import { useState } from "react";

import { ERROR_ICON_CLASS, ERROR_LABEL_CLASS, ERROR_MESSAGE_CLASS } from "../../../app/constants";

import "./style.scss";

interface InputProps {
  name: string;
  label?: string;
  type?: "text" | "password" | "number" | "date";
  error?: string;
  className?: string;
  placeholder?: string;
  step?: number | "any";
  maxValue?: string | number;
  disabled?: boolean;
  isTypeEmail?: boolean;
  isOptional?: boolean;
  value?: any;
  currency?: any;
  unit?: "%" | "BTC" | "USD" | "Million" | "PH/s" | "MW" | "J/TH" | "PH";
  tooltip?: { content: string; arrowPosition: "left" | "right" | "center" };
  onChange?: (name: string, value: string | number | boolean) => void;
}

const Input = ({
  name,
  label,
  value,
  error,
  className,
  placeholder,
  maxValue = "999999999999999999999",
  type = "text",
  step = 1,
  tooltip,
  disabled = false,
  isTypeEmail = false,
  isOptional = false,
  onChange,
  currency,
  unit,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...props
}: InputProps) => {
  const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const handleHover = () => {
    if (disabled && tooltip?.content) {
      setTooltipVisible(true);
    }
  };

  const handleHoverLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <div className={`custom-form-group ${className || ""}`}>
      {label && (
        <label className={`flex gap-2 font-pp-neue-machina uppercase !font-semibold ${error ? ERROR_LABEL_CLASS : ""}`}>
          {label} {isOptional && <span className="text-[#667085]">(optional)</span>}
        </label>
      )}
      <div className="relative" onMouseEnter={handleHover} onMouseLeave={handleHoverLeave}>
        <div className="flex absolute inset-y-0 left-0 z-10 items-center pl-3 pointer-events-none icon-holder">
          {currency && currency}
          {isTypeEmail && (
            <svg
              aria-hidden="true"
              className={`w-5 h-5 ${error ? ERROR_ICON_CLASS : "text-gray-500 dark:text-gray-400"}`}
              fill={`${error ? "currentColor" : "none"}`}
              viewBox="0 0 20 20"
              stroke={`${error ? "none" : "currentColor"}`}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
            </svg>
          )}
        </div>
        {type === "date" ? (
          <input
            name={name}
            type={type}
            placeholder={placeholder}
            className={classNames("custom-input w-full p-2.5 text-[#191c24]", {
              "pl-10": isTypeEmail,
              "cursor-not-allowed": disabled,
              ERROR_INPUT_CLASS: error,
            })}
            disabled={disabled}
            value={value}
            onChange={(e) => onChange && onChange(e.target.name, e.target.value)}
          />
        ) : (
          <input
            name={name}
            type={type}
            placeholder={placeholder}
            className={classNames("custom-input w-full p-2.5 text-[#191c24]", {
              "cursor-not-allowed": disabled,
              "pl-10": isTypeEmail || currency,
            })}
            disabled={disabled}
            max={maxValue}
            min={0}
            value={value}
            step={step}
            onChange={(e) => onChange && onChange(e.target.name, e.target.value)}
            onWheel={(evt) => {
              (evt.target as HTMLElement).blur(); // disable edit by scroll
            }}
          />
        )}
        {unit && (
          <div className="absolute inset-y-0 right-0 flex items-center my-[4px] px-[10px] border-l-[1.5px] leading-[13px] border-[#bdccd4] text-[#bdccd4] z-1">
            {unit}
          </div>
        )}
        {disabled && isTooltipVisible && tooltip?.content ? (
          <div
            className={classNames("tooltip-content", {
              "block mx-auto my-0 right-0 left-[-15px] !bottom-[60px]": isTooltipVisible,
            })}
          >
            {tooltip?.content}
            <div
              className={classNames("arrow-down", {
                "mx-auto": tooltip?.arrowPosition === "center",
                "ml-auto mr-[5px]": tooltip?.arrowPosition === "right",
              })}
            ></div>
          </div>
        ) : null}
      </div>
      {error && (
        <p data-test={`errorLabel-${name}`} className={ERROR_MESSAGE_CLASS}>
          {error}
        </p>
      )}
    </div>
  );
};

export default Input;
