import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Chip, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { allowDemoHandling } from "../../../../app/config/auth0/config";
import {
  useGetAdminAllOpportunityTransactions,
  useGetAdminOpportunityDetails,
} from "../../../../app/query/useQueryGetAdmin";
import { buildTimeline } from "./AdminOpportunityTimeline.utils";
import { MinerOpportunityFormReadonly } from "./MinerOpportunityFormReadonly";

export const AdminOpportunityTimeline = ({ opportunityId }: { opportunityId: string }) => {
  const [destroyKey, setDestroyKey] = React.useState<boolean>(false);

  const { data, isFetching: isFetchingDetails, refetch } = useGetAdminOpportunityDetails(opportunityId ?? "");
  const payload = data?.data;

  const {
    data: transactionData,
    isFetching: isFetchingTransactions,
    refetch: refetchTransactions,
  } = useGetAdminAllOpportunityTransactions(opportunityId ?? "");

  const transactions = useMemo(() => {
    const rows = transactionData?.data.rows ?? [];
    const addresses = transactionData?.data.addresses ?? [];
    if (payload) return buildTimeline(payload, rows, addresses);
    else return [];
  }, [transactionData, payload]);

  const isFetching = isFetchingDetails || isFetchingTransactions;

  const onRefetch = () => {
    refetch();
    refetchTransactions();
    setDestroyKey(!destroyKey); // force the component to be rebuilt
  };

  return isFetching || !payload ? (
    <Stack width={"100%"}>
      <Skeleton width="100%" height={100} variant="rounded"></Skeleton>
      <Skeleton width="100%" height={200} variant="rounded"></Skeleton>
    </Stack>
  ) : (
    <Stack width={"100%"} key={destroyKey.toString()} mt={1} spacing={1}>
      <Box display="flex" alignItems={"center"}>
        {payload.isDemo && allowDemoHandling && (
          <Chip label="Simulated opportunity." variant="filled" color="warning" />
        )}

        <IconButton onClick={onRefetch} size="small" color="success">
          <RefreshIcon fontSize="small" />
        </IconButton>
      </Box>
      <MinerOpportunityFormReadonly opportunity={payload.opportunity} />

      <Box sx={{ height: "60vh", display: "flex", flexDirection: "column" }}>
        <Box sx={{ flexGrow: 1 }}>
          <DataGridPro
            rows={transactions}
            rowCount={transactions.length}
            columns={[
              {
                field: "date",
                headerName: "Date",
                sortable: true,
                disableColumnMenu: true,
                renderCell: (params) => format(params.row.date, "dd MMM yyyy"),
                width: 125,
                display: "flex",
              },
              {
                field: "icon",
                headerName: "",
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => params.row.icon,
                width: 50,
                display: "flex",
              },
              {
                field: "description",
                headerName: "Description",
                sortable: false,
                disableColumnMenu: true,
                flex: 1,
                align: "right",
                headerAlign: "right",
                display: "flex",
              },
              {
                field: "amount",
                headerName: "Amount",
                sortable: false,
                disableColumnMenu: true,
                width: 200,
                align: "right",
                headerAlign: "right",
                display: "flex",
              },
              {
                field: "from",
                headerName: "From",
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => params.row.from,
                flex: 1,
                display: "flex",
              },
              {
                field: "to",
                headerName: "To",
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => params.row.to,
                flex: 1,
                display: "flex",
              },
              {
                field: "txStatus",
                headerName: "Transaction status",
                sortable: false,
                disableColumnMenu: true,
                align: "right",
                headerAlign: "right",
                flex: 1,
                display: "flex",
              },
            ]}
            pagination
            paginationMode="client"
            sortingMode="client"
            pageSizeOptions={[25, 50, 100]}
            sx={{
              bgcolor: "white",
              boxShadow: 3,
              maxHeight: "60vh",
            }}
            autoHeight={false}
            slots={{
              noRowsOverlay: () => (
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={"100%"}
                  height={"100%"}
                  sx={{ minHeight: "60px" }}
                >
                  <Typography>No events.</Typography>
                </Stack>
              ),
            }}
          ></DataGridPro>
        </Box>
      </Box>
    </Stack>
  );
};
