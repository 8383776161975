import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";

const ChipFile = ({
  name,
  isNew,
  onDownload,
  onDelete,
}: {
  name: string;
  isNew?: boolean;
  onDownload?: () => Promise<void>;
  onDelete?: () => void;
}) => {
  return (
    <Tooltip title={name}>
      <Chip
        variant="filled"
        color="primary"
        onDelete={onDelete}
        sx={{ maxWidth: 175, margin: 0.2 }}
        clickable={false}
        icon={
          onDownload && !isNew ? (
            <IconButton onClick={onDownload} color="primary" size="small">
              <CloudDownloadIcon fontSize="small" />
            </IconButton>
          ) : undefined
        }
        label={
          <Typography variant="caption">
            {isNew ? <FiberNewIcon fontSize="small" sx={{ mr: 1 }} /> : <></>}
            {name}
          </Typography>
        }
      ></Chip>
    </Tooltip>
  );
};

export type NewFileChipItem = {
  readonly kind: "new";
  readonly id: string;
  readonly file: File;
};

export type ExistingFileChipItem = {
  readonly kind: "existing";
  readonly id: string;
  readonly name: string;
};

export type ChipFileItem = NewFileChipItem | ExistingFileChipItem;

export const DisplayFileList = ({
  id,
  onDownload,
  onDelete,
  files,
}: {
  id: string;
  files: ChipFileItem[] | undefined;
  onDownload?: (id: string) => Promise<void>;
  onDelete?: (id: string) => void;
}) => {
  const getName = (input: ChipFileItem) => (input.kind === "new" ? input.file.name : input.name);

  return (
    <Box display="flex" flexWrap={"wrap"}>
      {files?.map((entry) => (
        <ChipFile
          key={"tooltip-fl-" + id + getName(entry)}
          name={getName(entry)}
          onDelete={onDelete ? () => onDelete(entry.id) : undefined}
          isNew={entry.kind === "new"}
          onDownload={onDownload && (() => onDownload(entry.id))}
        />
      ))}
    </Box>
  );
};
