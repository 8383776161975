import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as yup from "yup";

import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import Loader from "../../atoms/Loader";
import Form from "../Form";

import { ERROR_DEFAULT_MESSAGE } from "../../../app/constants";
import { getError } from "../../../app/helper/utils";
import { useMutationOrganizationEnterpriseSettings } from "../../../app/query/useMutationAdmin";
import { useGetOrganizationEnterpriseSettings } from "../../../app/query/useQueryGetAdmin";
import { isCustomError } from "../../../app/helper/errors";

interface EnterpriseSettingsData {
  accessApproval: string;
  walletApproval: string;
}

const OrganizationEnterpriseSettings = ({ organizationId }: { organizationId: string }) => {
  const [errors, setErrors] = useState<any>({});
  const [enterpriseSettingsData, setEnterpriseSettingsData] = useState<EnterpriseSettingsData>({
    accessApproval: "",
    walletApproval: "",
  });

  const {
    data: organizationEnterpriseSettings,
    isLoading: isLoadingOrganizationEnterpriseSettings,
    isFetching: isFetchingOrganizationEnterpriseSettings,
    refetch: refetchEnterpriseSettings,
  } = useGetOrganizationEnterpriseSettings({ organizationId });

  const {
    mutateAsync: mutateOrganizationEnterpriseSettings,
    isLoading: isLoadingOrganizationEnterpriseSettingsMutation,
  } = useMutationOrganizationEnterpriseSettings();

  const handleSubmit = <T extends Record<string, string>>(data: T) => {
    if (organizationEnterpriseSettings?.managers) {
      const validatePayload = yup.object().shape({
        accessApproval: yup
          .number()
          .integer("Value must be integer")
          .min(1)
          .max(
            organizationEnterpriseSettings?.managers.accessManagers,
            `Enterprise only has ${organizationEnterpriseSettings?.managers.accessManagers} access managers. Cannot set required approvals above this`
          )
          .typeError("Field is required!"),
        walletApproval: yup
          .number()
          .integer("Value must be integer")
          .min(1)
          .max(
            organizationEnterpriseSettings?.managers.walletManagers,
            `Enterprise only has ${organizationEnterpriseSettings?.managers.walletManagers} access managers. Cannot set required approvals above this`
          )
          .typeError("Field is required!"),
      });

      validatePayload
        .validate({ ...data, ...enterpriseSettingsData }, { abortEarly: false })
        .then(async (valid) => {
          if (!organizationEnterpriseSettings?.settingsId)
            toast.error("Unable to update, enterprise settings not setup for this org.");
          else
            try {
              await mutateOrganizationEnterpriseSettings({
                id: organizationEnterpriseSettings?.settingsId as string,
                payload: {
                  organizationId: organizationId,
                  requiredAccessApprovers: Number(valid.accessApproval),
                  requiredWhitelistApprovers: Number(valid.walletApproval),
                },
              });
              refetchEnterpriseSettings();
              toast.success("You have set enterprise settings");
            } catch (error: unknown) {
              if (isCustomError(error)) toast.error(error.error.message);
              else toast.error(ERROR_DEFAULT_MESSAGE);
            }
        })
        .catch((err: any) => {
          const errors = err.inner.reduce((prev: any, current: any) => {
            return { ...prev, [current.path]: current.message };
          }, {});

          setErrors(errors);
          toast.error("Error validating form - please correct incorrect fields!");
        });
    }
  };

  useEffect(() => {
    if (
      organizationEnterpriseSettings?.requiredAccessApprovers &&
      organizationEnterpriseSettings.requiredWhitelistApprovers
    ) {
      setEnterpriseSettingsData({
        accessApproval: String(organizationEnterpriseSettings.requiredAccessApprovers),
        walletApproval: String(organizationEnterpriseSettings.requiredWhitelistApprovers),
      });
    }
  }, [organizationEnterpriseSettings]);

  return (
    <div className="container-custom mx-auto !pb-5">
      <div className="p-7 bg-white relative h-[259px]">
        {isFetchingOrganizationEnterpriseSettings || isLoadingOrganizationEnterpriseSettings ? (
          <Loader className="h-[259px] absolute" />
        ) : (
          <Form
            formData={enterpriseSettingsData}
            onSubmit={(data) => handleSubmit(data)}
            onChange={(name, value) => {
              setEnterpriseSettingsData((prev: EnterpriseSettingsData) => {
                return { ...prev, [name]: value };
              });
              setErrors((prev: any) => {
                if (value) {
                  return { ...prev, [name]: null };
                }

                return prev;
              });
            }}
          >
            <div className="flex gap-4">
              <div>
                <h3 className="m-0 uppercase text-sm tracking-wide font-pp-neue-machina font-bold">
                  Required access change approvers
                </h3>
                <p className="text-light-gray">
                  Required number of users who must approve permission changes. Users must have the Access Manager role.
                </p>
                <div className="mt-4">
                  <Input
                    disabled={
                      !organizationEnterpriseSettings?.settingsId ||
                      organizationEnterpriseSettings?.managers?.accessManagers === 1
                    }
                    tooltip={{
                      content: "Enterprise only has one access manager, cannot update value",
                      arrowPosition: "center",
                    }}
                    label=""
                    value={enterpriseSettingsData.accessApproval}
                    className="mb-6"
                    type="number"
                    name="accessApproval"
                    error={getError(errors, "accessApproval")}
                  />
                </div>
              </div>
              <div>
                <h3 className="m-0 uppercase text-sm tracking-wide font-pp-neue-machina font-bold">
                  Required wallet whitelist approvers
                </h3>
                <p className="text-light-gray">
                  Required number of users who must approve new withdrawal addresses. Users must have the Wallet Manager
                  role.
                </p>
                <div className="mt-4">
                  <Input
                    disabled={
                      !organizationEnterpriseSettings?.settingsId ||
                      organizationEnterpriseSettings?.managers?.walletManagers === 1
                    }
                    tooltip={{
                      content: "Enterprise only has one wallet manager, cannot update value",
                      arrowPosition: "center",
                    }}
                    label=""
                    className="mb-6"
                    type="number"
                    value={enterpriseSettingsData.walletApproval}
                    name="walletApproval"
                    error={getError(errors, "walletApproval")}
                  />
                </div>
              </div>
            </div>
            <Button
              disabled={isLoadingOrganizationEnterpriseSettingsMutation}
              notAllowed={
                !organizationEnterpriseSettings?.settingsId ||
                (organizationEnterpriseSettings?.managers?.accessManagers === 1 &&
                  organizationEnterpriseSettings?.managers?.walletManagers === 1)
              }
              type="submit"
              label="Submit"
              backgroundColor="#8f49fd"
              className="text-white"
            />
          </Form>
        )}
      </div>
    </div>
  );
};

export default OrganizationEnterpriseSettings;
