import "./style.scss";

interface LoaderProps {
  className?: string;
}

const Loader = ({ className }: LoaderProps) => {
  return (
    <div className={`loader-wrapper ${className || ""}`}>
      <div className="w-12 h-12 rounded-full animate-spin border-y-8 border-solid border-purple border-t-transparent shadow-md"></div>
    </div>
  );
};

export default Loader;
