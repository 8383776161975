export const HOME_ROUTE = "/";
export const ONNBOARDING_ROUTE = "/onboarding";
export const ENTRYFORM_MINER_ROUTE = "/entryform/miner";
export const ENTRYFORM_LP_ROUTE = "/entryform/lp";
export const KYC_GENERAL_ROUTE = "/kyc/general";
export const KYC_LEGAL_ROUTE = "/kyc/legal";

export const THANK_YOU_ROUTE = "/thank-you";

export const ROUTE_LP_PORTFOLIO = "/portfolio";
export const WALLET_ROUTE = "/wallet";
export const WALLET_WITHDRAW_ROUTE = "/wallet/withdraw";
export const WALLET_DEPOSIT_ROUTE = "/wallet/deposit";
export const OPPORTUNITY_DETAILS_ROUTE = "/opportunities/:id";
export const OPPORTUNITIES_DETAILS_LP_ROUTE = "/opportunities/:id";
export const PREVIEW_STREAMING_DETAILS_LP_ROUTE = "/p-streaming/:id";
export const PREVIEW_LOAN_DETAILS_LP_ROUTE = "/p-loan/:id";
export const PREVIEW_LEASE_DETAILS_LP_ROUTE = "/p-lease/:id";
export const ACCOUNT_ROUTE = "/account";
export const LOGOUT = "/logout";
export const WHITELIST_ADDRESS_ROUTE = "/whitelist-address";
export const ORGANIZATION_ROUTE = "/organizations";
export const ORGANIZATION_DETAILS_ROUTE = "/organizations/:id";
export const MINER_ORGANIZATION_BASIC_INFO_ROUTE = "/miner/:id/basic";
export const MINER_ORGANIZATION_ENTERPRISE_SETTINGS_ROUTE = "/miner/:id/enterprise-settings";
export const MINER_ORGANIZATION_KYM = "/miner/:id/kym";
export const MINER_ORGANIZATION_OPPORTUNITY = "/miner/:id/opportunity";
export const BROADCAST_MANAGER_ROUTE = "/broadcast-manager";
export const ADMIN_DOCUMENT_APPROVAL_ROUTE = "/lp-agreements";
export const DISCLAIMER_ROUTE = "/disclaimer";
export const LIQUIDITY_DELIVERY_ROUTE = "/liquidity-delivery";
export const ROUTE_ENTERPRISE_SETTINGS = "/enterprise/settings";
export const ROUTE_ENTERPRISE_MEMBERS = "/enterprise-members";
export const KYM2_FINANCIALS = "/kym/financials";
export const KYM2_MINING_SITES = "/kym/sites";
export const KYM2_ENERGY = "/kym/energy";
export const KYM2_ASIC = "/kym/asic";
export const KYM2_HOSTING = "/kym/hosting";

export const ROUTE_ADMIN_OPPORTUNITY_DETAILS = "/opportunities/:id";
export const ROUTE_ADMIN_OPPORTUNITY_TIMELINE = "/opportunities/:id/timeline";
export const ROUTE_ADMIN_OPPORTUNITY_LIQUIDITY = "/opportunities/:id/liquidity";
export const ROUTE_ADMIN_OPPORTUNITY_REWARDS = "/opportunities/:id/rewards";
export const ROUTE_ADMIN_OPPORTUNITY_SIMULATOR = "/opportunities/:id/simulator";
export const ROUTE_ADMIN_REWARDS_BLOCKS = "/tools/blocks";
export const ROUTE_ADMIN_USER_MANAGEMENT = "/tools/user-management";
export const ROUTE_ADMIN_COMPUTED_HASHPRICES = "/tools/hashprices";

export const RoutesHashRatePrediction = {
  Default: "/analytics/hashrate-model",
};

export const PUBLIC_ROUTE: string[] = [
  ENTRYFORM_MINER_ROUTE,
  ENTRYFORM_LP_ROUTE,
  ONNBOARDING_ROUTE,
  DISCLAIMER_ROUTE,
  LOGOUT,
];
const KYC_ROUTE_LAYOUT: string[] = [KYC_GENERAL_ROUTE, KYC_LEGAL_ROUTE];
export const KYC_ROUTE: string[] = [...KYC_ROUTE_LAYOUT];

export const ROUTES_LOANS = {
  Admin: {
    Loans: "/admin/loans",
    CreateLoan: "/admin/new-loan",
    LoanDetails: "/admin/loan/:id/basic",
    LoanPaymentRequestPage: "/admin/loan/:id/payment-request",
    AuditPage: "/admin/loan/:id/audit",
  },
  Miner: {
    Loans: "/m/loans",
    LoanDetails: "/m/loan/:id/basic",
  },
  LP: {
    Loans: "/lp/loans",
    LoanDetails: "/lp/loan/:id/basic",
  },
};
