import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { MinerResponseDto, ReqMinerRatingDto, ResMinerSummaryDto } from "../../../../app/model/api";
import { useMutationMinerRating } from "../../../../app/query/useMutationAdmin";
import { MuiFullscreenLoadingBackdrop } from "../../../../components/atoms/MuiCircularPercentage/MuiLoadingBackdrop";

interface FormData {
  readonly financialHealthRate?: MinerResponseDto["financialHealthRate"];
  readonly operationalHealthRate?: MinerResponseDto["operationalHealthRate"];
  readonly sustainabilityRate?: MinerResponseDto["sustainabilityRate"];
}

const VALUES: MinerResponseDto["financialHealthRate"][] = ["LOW", "MEDIUM", "HIGH"];

const YupFormSchema = yup
  .object({})
  .shape({
    financialHealthRate: yup.string().oneOf(VALUES).required("This is a required input!"),
    operationalHealthRate: yup.string().oneOf(VALUES).required("This is a required input!"),
    sustainabilityRate: yup.string().oneOf(VALUES).required("This is a required input!"),
  })
  .required();

export const MinerRating = ({
  minerId,
  miner,
  triggerRefetch,
}: {
  minerId: string;
  miner: ResMinerSummaryDto;
  triggerRefetch: () => void;
}) => {
  const {
    formState: { errors, isDirty },
    control,
    handleSubmit,
  } = useForm<FormData>({
    resolver: yupResolver(YupFormSchema),
    defaultValues: {
      financialHealthRate: miner.financialHealthRate,
      operationalHealthRate: miner.operationalHealthRate,
      sustainabilityRate: miner.sustainabilityRate,
    },
  });

  const { mutateAsync: saveRating, isLoading: isSavingRating } = useMutationMinerRating();

  const onSubmit = async (data: FormData) => {
    if (data.sustainabilityRate && data.financialHealthRate && data.operationalHealthRate) {
      const request: ReqMinerRatingDto = {
        minerId: minerId,
        sustainabilityRate: data.sustainabilityRate,
        financialHealthRate: data.financialHealthRate,
        operationalHealthRate: data.operationalHealthRate,
      };

      try {
        const result = await saveRating(request);
        if (result.data.kind === false) {
          toast.error("There has been an issue while trying to save your changes. Please try again.");
        } else {
          toast.success("Your updates have been saved successfully.");
          triggerRefetch();
        }
      } catch (err) {
        toast.error("There has been an issue while trying to save your changes. Please try again.");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <Grid container item xs={12} md={12} spacing={1} alignItems={"center"}>
        <MuiFullscreenLoadingBackdrop isOpen={isSavingRating} />
        <Grid container item xs={12} md={3}>
          <FormControl fullWidth error={!!errors.financialHealthRate}>
            <InputLabel id="lbl-financial">Financial health</InputLabel>
            <Controller
              name="financialHealthRate"
              control={control}
              render={({ field }) => (
                <Select
                  labelId="lbl-financial"
                  id="select-financial"
                  label="Financial health"
                  defaultValue=""
                  {...field}
                >
                  {VALUES.map((x) => {
                    return (
                      <MenuItem key={"financialHealthRate" + x} value={x}>
                        {x}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            ></Controller>
            <FormHelperText>{errors.financialHealthRate?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid container item xs={12} md={3}>
          <FormControl fullWidth error={!!errors.operationalHealthRate}>
            <InputLabel id="lbl-operational">Operational health</InputLabel>
            <Controller
              name="operationalHealthRate"
              control={control}
              render={({ field }) => (
                <Select
                  labelId="lbl-operational"
                  id="select-operational"
                  label="Operational health"
                  defaultValue=""
                  {...field}
                >
                  {VALUES.map((x) => {
                    return (
                      <MenuItem key={"operationalHealthRate" + x} value={x}>
                        {x}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            ></Controller>
            <FormHelperText>{errors.operationalHealthRate?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid container item xs={12} md={3}>
          <FormControl fullWidth error={!!errors.sustainabilityRate}>
            <InputLabel id="lbl-sustainability">Sustainability health</InputLabel>

            <Controller
              name="sustainabilityRate"
              control={control}
              render={({ field }) => (
                <Select
                  labelId="lbl-sustainability"
                  id="select-sustainability"
                  label="Sustainability health"
                  defaultValue=""
                  {...field}
                >
                  {VALUES.map((x) => {
                    return (
                      <MenuItem key={"sustainabilityRate" + x} value={x}>
                        {x}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            ></Controller>
            <FormHelperText>{errors.sustainabilityRate?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid container item xs={12} md={2} justifyContent={"flex-start"}>
          <Tooltip title={isDirty ? "" : "You need to make a change before saving."}>
            <span>
              <LoadingButton
                size="large"
                type="submit"
                loading={isSavingRating}
                disabled={!isDirty}
                variant="contained"
              >
                Save
              </LoadingButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
};
