import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import { createRoot } from "react-dom/client";
import { LicenseInfo } from "@mui/x-license";

import { providerConfig } from "./app/config/auth0/config";

import App from "./App";
import { getQueryClient } from "./app/config/query/client";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE_KEY || "missing-key");

root.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
      <QueryClientProvider client={getQueryClient()}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Auth0Provider>
  </React.StrictMode>
);
