import {
  Alert,
  Card,
  Grid,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { DtoInternalTransaction } from "../../../../app/model/api";
import { useQueryAdminGetRewardDetails } from "../../../../app/query/useQueryGetAdmin";

interface Column {
  id:
    | "createdAt"
    | "txHash"
    | "approvalStatus"
    | "amountSatoshis"
    | "feeTakenSatoshis"
    | "fromBitgoWalletId"
    | "toBitgoWalletId";
  label: string;
  minWidth?: number;
  width?: number;
  align?: "right" | "center";
}

const columns: readonly Column[] = [
  { id: "createdAt", label: "Created at" },
  { id: "txHash", label: "Transaction hash", minWidth: 140 },
  { id: "approvalStatus", label: "Approval status", minWidth: 150 },
  {
    id: "amountSatoshis",
    label: "Amount (SAT)",
  },
  {
    id: "feeTakenSatoshis",
    label: "Fees (SAT)",
  },
  {
    id: "fromBitgoWalletId",
    label: "From bitgo wallet",
  },
  {
    id: "toBitgoWalletId",
    label: "To bitgo wallet",
  },
];

const ROWS_PER_PAGE = 10;

const formatDate = (input: string | Date | number) => {
  const date = new Date(input);
  return format(date, "dd/MM/yy HH:mm");
};

export const AdminRewardDetails = ({ rewardId }: { rewardId: string }) => {
  const { data, isFetching, error } = useQueryAdminGetRewardDetails(rewardId);

  const rewardDetails = data?.data.payload;

  return isFetching ? (
    <Skeleton width={"100%"} height={300} />
  ) : error ? (
    <Stack>
      <Alert severity="warning">
        There has been an issue loading the miner data. Please refresh the page to try again.
      </Alert>
    </Stack>
  ) : !rewardDetails ? (
    <Stack>
      <Alert severity="warning">Reward not found.</Alert>
    </Stack>
  ) : (
    <Grid container item xs={12}>
      <Card variant="outlined" sx={{ width: "100%", border: 0 }}>
        <Typography variant="subtitle1">Distributions</Typography>
        <TransactionTable rows={rewardDetails.distributions ?? []} />
        <Typography variant="subtitle1">Liquidations</Typography>
        <TransactionTable rows={rewardDetails.liquidations ?? []} />
      </Card>
    </Grid>
  );
};

const TransactionTable = ({ rows }: { rows: DtoInternalTransaction[] }) => {
  const [page, setPage] = React.useState(0);

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ backgroundColor: "red" }}>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length ? (
              rows.slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE).map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.txId}>
                    <TableCell align={"left"}>
                      <Tooltip title={row.createdAt}>
                        <Typography noWrap>{formatDate(row.createdAt)}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align={"left"}>
                      <Tooltip title={row.txHash}>
                        <Typography noWrap>{row.txHash}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align={"left"}>
                      <Tooltip title={row.approvalStatus}>
                        <Typography noWrap>{row.approvalStatus}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align={"left"}>
                      <Tooltip title={row.amountSatoshis}>
                        <Typography noWrap>{row.amountSatoshis}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align={"left"}>
                      <Tooltip title={row.feeTakenSatoshis}>
                        <Typography noWrap>{row.feeTakenSatoshis}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align={"left"}>
                      <Tooltip title={row.fromBitgoWalletId}>
                        <Typography noWrap>{row.fromBitgoWalletId}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align={"left"}>
                      <Tooltip title={row.fromBitgoWalletId}>
                        <Typography noWrap>{row.toBitgoWalletId}</Typography>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length ? (
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={rows.length}
          rowsPerPage={10}
          page={page}
          onPageChange={(e, page) => {
            setPage(page);
          }}
        />
      ) : null}
    </>
  );
};
