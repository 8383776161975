import { Card, CardContent, CardHeader, Typography } from "@mui/material";

export const AmountHeroCard = ({
  title,
  amount,
  secondaryAmount,
}: {
  readonly title: string;
  readonly amount: string;
  readonly secondaryAmount?: string | undefined;
}) => {
  return (
    <Card
      sx={{
        width: "100%",
        minWidth: 230,
        maxWidth: 350,
        height: secondaryAmount ? 100 : 75,
        bgcolor: "#3e4f52",
        borderRadius: 4,
      }}
    >
      <CardHeader
        sx={{ mt: -1 }}
        title={
          <Typography color="white" variant="body2">
            {title}
          </Typography>
        }
      />
      <CardContent sx={{ mt: -3 }}>
        <Typography variant="h6" color={"white"}>
          {amount}
        </Typography>
        {secondaryAmount && (
          <Typography variant="body2" color={"white"}>
            {secondaryAmount}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
