import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Card,
  CardContent,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
import { useQueryGetMinerKymCapacityAndSitesById } from "../../../app/query/useQueryGetAdmin";
import { StyledTableRow } from "../commons";

export const ReadonlyMinerCapacityAndSites = ({ organizationId }: { organizationId: string }) => {
  const { data: capacityAndSites, isFetching, error } = useQueryGetMinerKymCapacityAndSitesById(organizationId);

  return isFetching ? (
    <Skeleton width={"100%"} height={300} />
  ) : error ? (
    <Stack>
      <Alert severity="warning">
        There has been an issue loading the miner data. Please refresh the page to try again.
      </Alert>
    </Stack>
  ) : (
    <Grid container item xs={12}>
      <Grid container item xs={12} md={6}>
        <Card variant="outlined" sx={{ width: "100%", border: 0 }}>
          <CardContent>
            <Typography variant="h6" noWrap>
              Installed hashrate
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <StyledTableRow key={"row-current-hashrate"}>
                    <TableCell align="left" width={300}>
                      <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                        Installed hashrate today
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {capacityAndSites?.data.currentInstalledHashrate == undefined ? (
                        <CloseIcon color="error" />
                      ) : (
                        `${capacityAndSites?.data.currentInstalledHashrate} PH/s`
                      )}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={"row-estimated-hashrate"}>
                    <TableCell align="left" width={300}>
                      <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                        Estimated hashrate in 12 months
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {capacityAndSites?.data.estimatedHashrateIn12Months == undefined ? (
                        <CloseIcon color="error" />
                      ) : (
                        `${capacityAndSites?.data.estimatedHashrateIn12Months} PH/s`
                      )}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={"row-estimated-hashrate"}>
                    <TableCell align="left" width={300}>
                      <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                        Total operated mining sites
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {capacityAndSites?.data.totalMiningSites == undefined ? (
                        <CloseIcon color="error" />
                      ) : (
                        capacityAndSites?.data.totalMiningSites
                      )}
                    </TableCell>
                  </StyledTableRow>
                  {capacityAndSites?.data.miningSites.map((site) => (
                    <StyledTableRow key={"row-estimated-hashrate"}>
                      <TableCell align="left" width={300}>
                        <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                          Mining site - {site.location}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {capacityAndSites?.data.totalMiningSites == undefined ? (
                          <CloseIcon color="error" />
                        ) : (
                          `${site.hashrate} PH/s`
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
      <Grid container item xs={12} md={6}>
        <Card variant="outlined" sx={{ width: "100%", border: 0 }}>
          <CardContent>
            <Typography variant="h6" noWrap>
              3rd party provider machines
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <StyledTableRow key={"row-3rd"}>
                    <TableCell align="left" width={300}>
                      <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                        Uses 3rd party provider as well
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {capacityAndSites?.data.hasThirdPartyProviderHostedMachines == undefined ? (
                        <CloseIcon color="error" />
                      ) : capacityAndSites?.data.hasThirdPartyProviderHostedMachines ? (
                        "Yes"
                      ) : (
                        "No"
                      )}
                    </TableCell>
                  </StyledTableRow>
                  {capacityAndSites?.data.hasThirdPartyProviderHostedMachines ? (
                    <>
                      <StyledTableRow key={"row-estimated-hashrate"}>
                        <TableCell align="left" width={300}>
                          <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                            Overall installed hashrate
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {capacityAndSites?.data.thirdPartyInstalledHashratePhS == undefined ? (
                            <CloseIcon color="error" />
                          ) : (
                            `${capacityAndSites?.data.thirdPartyInstalledHashratePhS} PH/s`
                          )}
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow key={"row-estimated-hashrate"}>
                        <TableCell align="left" width={300}>
                          <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                            Overall installed hashrate
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {capacityAndSites?.data.averageHostingPriceUsdPerMwh == undefined ? (
                            <CloseIcon color="error" />
                          ) : (
                            `${capacityAndSites?.data.averageHostingPriceUsdPerMwh} USD/MWh`
                          )}
                        </TableCell>
                      </StyledTableRow>
                    </>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
