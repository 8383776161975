const ERROR_DEFAULT_MESSAGE = "Something went wrong!";
const ERROR_FORM_VALIDATION_MESSAGE = "Invalid inputs - form validation failed";
const APPROVE_USER_MESSAGE = "You have successfully approved the user";
const SUCCESS_SEND_NOTIFICATION_MESSAGE = "Successfully sent a notification to the user";
const SUCCESS_UPDATE_TRANCHE_STATUS_MESSAGE = "You have successfully updated the tranche status";
const REJECT_USER_MESSAGE = "Successfully rejected the user";
const SHOW_MINER_ACTION_MESSAGE = "Show opportunities requiring action";
const HIDE_SETTLED_OPPORTUNITIES_MESSAGE = "Hide settled opportunities";
const ERROR_INITIATE_OPPORTUNITY_MESSAGE = "Insufficient fund";
const ERROR_INITIATE_OPPORTUNITY_LESS_THEN_ZERO_MESSAGE = "Your amount can not be less than zero";
const SUCCESS_INITIATE_OPPORTUNITY_MESSAGE =
  "Your funding request has been received. You will receive the agreement for this opportunity by email within 2 business days";

export {
  APPROVE_USER_MESSAGE,
  ERROR_DEFAULT_MESSAGE,
  ERROR_FORM_VALIDATION_MESSAGE,
  ERROR_INITIATE_OPPORTUNITY_LESS_THEN_ZERO_MESSAGE,
  ERROR_INITIATE_OPPORTUNITY_MESSAGE,
  HIDE_SETTLED_OPPORTUNITIES_MESSAGE,
  REJECT_USER_MESSAGE,
  SHOW_MINER_ACTION_MESSAGE,
  SUCCESS_INITIATE_OPPORTUNITY_MESSAGE,
  SUCCESS_SEND_NOTIFICATION_MESSAGE,
  SUCCESS_UPDATE_TRANCHE_STATUS_MESSAGE,
};
