import { useMutation } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import { ApproveOrRejectData, LP, Miner, RegisterLpDto, RegisterMinerDto, WalletAddress } from "../model/api";

interface Notifications {
  notifications: string[];
}

interface InitiateOpportunity {
  amountSatoshis: string;
  opportunityId: string;
}

const changePassword = async (): Promise<any> => {
  return await apig.user.postUserPassword();
};

const createWhitelistAddress = async (data: WalletAddress): Promise<any> => {
  return await apig.user.createWhitelistAddress(data);
};

const removeWhitelist = async (id: string): Promise<any> => {
  return await apig.user.deleteUserAddressFromWhitelist({ id });
};

const markNotificationAsRead = async (notifications: Notifications): Promise<any> => {
  return await apig.user.markNotificationAsRead(notifications);
};

const initiateOpportunity = async (data: InitiateOpportunity): Promise<any> => {
  return await apig.user.initiateOpportunityFunding(data);
};

const approveOrRejectWhitelistProposal = async (data: ApproveOrRejectData) => {
  return await apig.user.approveOrRejectWhitelistProposal(data);
};

const useMutationRegisterLP = () => {
  return useMutation((data: RegisterLpDto) => apig.entrypoint.registerLp(data));
};

const useMutationRegisterMiner = () => {
  return useMutation((data: RegisterMinerDto) => apig.entrypoint.registerMiner(data));
};

const useMutationChangePassword = () => {
  return useMutation(() => changePassword());
};

const useMutationWhitelist = () => {
  return useMutation((data: WalletAddress) => createWhitelistAddress(data));
};

const useMutationRemoveWhitelist = () => {
  return useMutation((id: string) => removeWhitelist(id));
};

const useMutationUserNotification = () => {
  return useMutation((notifications: Notifications) => markNotificationAsRead(notifications));
};

const useMutationInitiateOpportunity = () => {
  return useMutation((data: InitiateOpportunity) => initiateOpportunity(data));
};

const useMutationApproveOrRejectWhitelistProposal = () => {
  return useMutation((data: ApproveOrRejectData) => approveOrRejectWhitelistProposal(data));
};

export {
  useMutationRegisterLP,
  useMutationRegisterMiner,
  useMutationChangePassword,
  useMutationWhitelist,
  useMutationRemoveWhitelist,
  useMutationUserNotification,
  useMutationInitiateOpportunity,
  useMutationApproveOrRejectWhitelistProposal,
};
