import { TabContext, TabPanel } from "@mui/lab";
import { Box, Container, Tab, Tabs } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { KYM2_TABS } from "../../app/constants";

export const KymTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentTab = location.pathname;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  return (
    <Box width={"100%"}>
      <Container sx={{ background: "white", boxShadow: 1 }}>
        <TabContext value={currentTab}>
          <Box display="flex" alignItems={"Center"}>
            <Tabs
              textColor="primary"
              indicatorColor="primary"
              value={currentTab}
              onChange={handleChange}
              aria-label="mining-details-tabs"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {KYM2_TABS.map((entry) => (
                <Tab
                  key={"tb-mining-" + entry.name}
                  label={entry.name}
                  value={entry.path}
                  sx={{ fontWeight: "fontWeightBold" }}
                />
              ))}
            </Tabs>
          </Box>
          {KYM2_TABS.map((entry) => (
            <TabPanel key={"tbpanel-kym-" + entry.name} value={entry.path}>
              {entry.tabComponent()}
            </TabPanel>
          ))}
        </TabContext>
      </Container>
    </Box>
  );
};
