import MuiPhoneNumber from "mui-phone-number";
import { Control, Controller, UseFormRegister } from "react-hook-form";

import { Box, Grid, TextField } from "@mui/material";

interface ContactDetailsErrors {
  readonly firstname?: string;
  readonly lastname?: string;
  readonly email?: string;
  readonly phone?: string;
}

// TODO move to molecules
export const ContactDetails = ({
  register,
  prefix,
  errors,
  control,
}: {
  register: UseFormRegister<any>;
  control: Control<any, any>;
  prefix: string;
  errors: ContactDetailsErrors;
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid direction="row" container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            placeholder="Jane"
            fullWidth
            variant="outlined"
            label="First name"
            {...register(prefix + "firstname", { required: true })}
            error={!!errors.firstname}
            helperText={<>{errors.firstname && <Box>{errors.firstname}</Box>}</>}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={"Last name"}
            placeholder="Smith"
            fullWidth
            variant="outlined"
            {...register(prefix + "lastname", { required: true })}
            error={!!errors.lastname}
            helperText={<>{errors.lastname && <Box>{errors.lastname}</Box>}</>}
          />
        </Grid>
      </Grid>
      <Grid direction="row" container spacing={2} mt={1}>
        <Grid item xs={12} md={6}>
          <TextField
            label={"Email"}
            placeholder="example@example.com"
            fullWidth
            variant="outlined"
            {...register(prefix + "email", { required: true })}
            error={!!errors.email}
            helperText={<>{errors.email && <Box>{errors.email}</Box>}</>}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={prefix + "phone"}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <MuiPhoneNumber
                fullWidth
                defaultCountry={"us"}
                name={prefix + "phone"}
                variant="outlined"
                onChange={onChange}
                error={!!error?.message}
                helperText={<>{error?.message && <Box>{error.message}</Box>}</>}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
