import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableRow } from "../../Admin";

export const HorizontalInfoTable = ({
  title,
  entries,
}: {
  title: string;
  entries: { name: string; value: string | JSX.Element }[];
}) => {
  return (
    <Box>
      <Typography variant="h6" noWrap>
        {title}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <StyledTableRow key={"th-op-info-0"}>
              {entries.map((x) => (
                <TableCell key={"tch" + x.name} size="small" align="center">
                  {x.name}
                </TableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <TableRow key={"row-dates-info-1"}>
              {entries.map((x) => (
                <TableCell key={"tcc" + x.name} size="small" align="center">
                  {x.value}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
