import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { DISCLAIMER_ROUTE } from "../../app/constants";
import { YesOrNoChoice } from "../../app/model/api";
import { useQueryGetLpOpportunityList } from "../../app/query/useQueryGetLP";
import SectionTitle from "../../components/atoms/SectionTitle";
import HeroOpportunitiesList from "../../components/organisms/HeroOpportunitiesList";
import { PreviewLeaseItem } from "../../components/organisms/OpportunityItems/PreviewLeaseItem";
import { PreviewLoanItem } from "../../components/organisms/OpportunityItems/PreviewLoanItem";
import { PreviewStreamingItem } from "../../components/organisms/OpportunityItems/PreviewStreamingItem";
import { StreamingItem } from "../../components/organisms/OpportunityItems/StreamingItem";

export const LpOpportunities = () => {
  const { data } = useQueryGetLpOpportunityList({
    newestFirst: YesOrNoChoice.Yes,
  });

  return (
    <div>
      <HeroOpportunitiesList />
      <SectionTitle title="Opportunity list" />
      <div className="container-custom mx-auto flex items-center !pt-[14px] text-[#667085]">
        <Typography variant="body1" className="font-pp-neue-machina">
          Information displayed is strictly confidential and is intended for use only within this platform; also, kindly
          check the{" "}
          <Link className="text-purple" to={DISCLAIMER_ROUTE} target="_blank">
            disclaimer
          </Link>{" "}
          for further details.
        </Typography>
      </div>

      <div>
        <div className="container-custom mx-auto !pt-4 !pb-16">
          {/* TODO: fix sorting */}
          {/* <div className="flex justify-between items-center mb-5">
            <button className="bg-white px-4 py-3 flex" onClick={handleActionFirst}>
              {newestFirst === YesOrNoChoice.Yes ? (
                <SortUpIcon className="w-4 mr-2" />
              ) : (
                <SortDownIcon className="w-4 mr-2" />
              )}
              {newestFirst === YesOrNoChoice.Yes ? "Newest first" : "Oldest first"}
            </button>
          </div> */}

          <div className="grid grid-cols-3 gap-5">
            {/* TODO: sort correctly across types */}
            {data?.streamingOpportunities?.map((item, key) => {
              return <StreamingItem key={key} data={item} />;
            })}
            {data?.previewStreamingOpportunities?.map((item, key) => {
              return <PreviewStreamingItem key={key} data={item} />;
            })}
            {data?.previewLoanOpportunties?.map((item, key) => {
              return <PreviewLoanItem key={key} data={item} />;
            })}
            {data?.previewLeaseOpportunities?.map((item, key) => {
              return <PreviewLeaseItem key={key} data={item} />;
            })}
          </div>
          {data &&
          data?.streamingOpportunities?.length === 0 &&
          data?.previewStreamingOpportunities?.length === 0 &&
          data?.previewLoanOpportunties?.length === 0 &&
          data?.previewLeaseOpportunities?.length === 0 ? (
            <div className="bg-white items-center justify-center flex h-[150px]">
              <h4>No results.</h4>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
