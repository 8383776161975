import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Button, Link } from "@mui/material";
import Decimal from "decimal.js";
import moment from "moment";
import { useParams } from "react-router-dom";
import { ENERGY_MIX_WITH_ICONS, SATOSHIS_TO_BTC } from "../../app/constants";
import {
  amountFormatSatoshisToBTC,
  generateMailtoLink,
  normalizeUrl,
  roundToSignificantDigits,
} from "../../app/helper/utils";
import { useGetQueryPreviewLoan } from "../../app/query/useQueryGetLP";
import { BlurredValue } from "../../components/atoms/BlurredValue";
import { ScoreIcon } from "../../components/atoms/ScoreIcon";
import SectionTitle from "../../components/atoms/SectionTitle";
import { HeroLoanDetailsLP } from "../../components/organisms/HeroLoanDetailsLP";
import "./style.scss";

const reachOutTooltip = "Reach out to learn more";

export const PreviewLoanDetailsLP = () => {
  const { id } = useParams();

  const { data: opportunityData, isFetching } = useGetQueryPreviewLoan(id as string);

  const {
    miner,
    description,
    principalSatoshis,
    principalDesc,
    startDate,
    durationDays,
    durationDesc,
    totalReturn,
    lpInterestRate,
    collateralType,
  } = opportunityData || {};

  const getEnergyMix = () => {
    if (!miner?.energyMix || (miner.energyMix && Object.keys(miner.energyMix).length === 0)) return [];

    return Object.keys(miner.energyMix)
      .map((item) => {
        const isItemExist = ENERGY_MIX_WITH_ICONS.hasOwnProperty(item);

        return {
          icon: ENERGY_MIX_WITH_ICONS[isItemExist ? item : "Other"]?.icon,
          value: miner?.energyMix?.[item as keyof typeof miner.energyMix],
          name: isItemExist ? item : "Other",
          color: ENERGY_MIX_WITH_ICONS[isItemExist ? item : "Other"].color,
        };
      })
      .filter((item) => Number(item.value) > 0);
  };

  const energyMixItems = getEnergyMix();
  const hasSomeKymScore = miner?.financialHealthRating || miner?.operationalHealthRating || miner?.sustainabilityRating;

  return (
    <div>
      <HeroLoanDetailsLP
        annualizedReturn={lpInterestRate}
        principalAmountSatoshis={principalSatoshis}
        totalReturn={totalReturn}
        startDate={startDate ? new Date(startDate) : undefined}
        endDate={startDate ? moment(startDate).add(durationDays, "days").toDate() : undefined}
        isFetching={isFetching}
      />
      <SectionTitle title="Opportunity Details" />
      <div className="container-custom mx-auto !pt-14 !pb-16">
        <div className="grid grid-cols-3 gap-5">
          <div className="col-span-2">
            {description && (
              <div className="bg-white mb-6">
                <SectionTitle title="Description" />
                <div className="p-6 opportunity-info-box">
                  <div>
                    <p>{description}</p>
                  </div>
                </div>
              </div>
            )}
            <div className="bg-white mb-6">
              <SectionTitle title="Opportunity information" />
              <div className="p-6 opportunity-info-box">
                <div className="grid grid-cols-2 gap-6 mb-6">
                  <div>
                    <p className="mb-2 uppercase">
                      <strong>Miner Name</strong>
                    </p>
                    <p>{miner?.name || ""}</p>
                  </div>
                  <div>
                    <p className="mb-2 uppercase">
                      <strong>Miner homepage</strong>
                    </p>
                    {miner?.homepageUrl?.includes(".") ? (
                      <Link target="_blank" href={normalizeUrl(miner?.homepageUrl)}>
                        {miner?.homepageUrl}
                      </Link>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div>
                    <p className="mb-2 uppercase">
                      <strong>Funding deadline</strong>
                    </p>
                    <p>{startDate ? moment(startDate).format("DD MMM YYYY HH:mm") : "-"}</p>
                  </div>
                  <div>
                    <p className="mb-2 uppercase">
                      <strong>Duration</strong>
                    </p>
                    <p>{durationDays ? `${durationDays} days` : durationDesc ?? "TBC"}</p>
                  </div>
                  <div>
                    <p className="mb-2 uppercase">
                      <strong>Principal</strong>
                    </p>
                    <p>
                      {principalSatoshis ? (
                        <>
                          {amountFormatSatoshisToBTC(principalSatoshis)} <sup>BTC</sup>
                        </>
                      ) : (
                        principalDesc ?? "TBC"
                      )}
                    </p>
                  </div>
                  <div>
                    <p className="mb-2 uppercase">
                      <strong>Collateral type</strong>
                    </p>
                    <p>{collateralType ?? "-"}</p>
                  </div>
                  <div>
                    <p className="mb-2 uppercase">
                      <strong>Total return</strong>
                    </p>
                    <p>
                      {totalReturn ? (
                        roundToSignificantDigits(new Decimal(totalReturn).div(SATOSHIS_TO_BTC).toNumber(), 2)
                      ) : (
                        <BlurredValue tooltip={reachOutTooltip}>⛝⛝</BlurredValue>
                      )}{" "}
                      <sup>BTC</sup>
                    </p>
                  </div>
                  <div>
                    <p className="mb-2 uppercase">
                      <strong>Annualized interest rate</strong>
                    </p>
                    <p>{lpInterestRate ?? <BlurredValue tooltip={reachOutTooltip}>⛝⛝</BlurredValue>} %</p>
                  </div>
                </div>
                {energyMixItems?.length > 0 && (
                  <div>
                    <p className="mb-2 uppercase">
                      <strong>Energy Mix</strong>
                    </p>
                    <div className="flex gap-3 mb-6">
                      {energyMixItems?.map((item, index) => (
                        <div key={index}>
                          <div
                            className="flex items-center gap-[8px] px-[8px] py-[6px]"
                            style={{ backgroundColor: `${item.color}` }}
                          >
                            <img src={item.icon} alt={item.name} className="w-5" />
                            <h5 className="text-sm">{item.name}</h5>
                            <div className="percent bg-white text-sm py-[2px] px-[6px]">{item.value}%</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="bg-white mb-6">
              <SectionTitle title="Company information" />
              <div className="p-6 opportunity-info-box">
                <div className="grid grid-cols-2 gap-7">
                  <div>
                    <p className="mb-2 uppercase">
                      <strong>Company name</strong>
                    </p>
                    <p>{miner?.name || "-"}</p>
                  </div>
                  {miner?.dateOfIncorporation && (
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Date of incorporation</strong>
                      </p>
                      <p> {moment(miner?.dateOfIncorporation).format("DD MMM YYYY")}</p>
                    </div>
                  )}
                  {miner?.countryOfIncorporation && (
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Country of incorporation</strong>
                      </p>
                      <p>{miner?.countryOfIncorporation}</p>
                    </div>
                  )}
                  {miner?.miningCapacityPh && (
                    <div>
                      <p className="mb-2 uppercase">
                        <strong>Total deployed hashrate</strong>
                      </p>
                      <p>{miner?.miningCapacityPh} PH/s</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1">
            {hasSomeKymScore && (
              <div className="bg-white mb-5">
                <SectionTitle
                  title={
                    <div>
                      Risk Scores <span className="text-[14px] tracking-[0] normal-case">(Know-Your-Miner)</span>
                    </div>
                  }
                />
                <div className="p-6">
                  <div className="flex flex-col gap-4">
                    {miner?.financialHealthRating && (
                      <div className="flex justify-between gap-4 pb-4 border-b-[1px] border-b-[#BDCCD4]">
                        <p className="uppercase font-semibold">Financial health</p>
                        <div className="flex gap-2">
                          <ScoreIcon value={miner.financialHealthRating} />
                        </div>
                      </div>
                    )}
                    {miner?.operationalHealthRating && (
                      <div className="flex justify-between gap-4 pb-4 border-b-[1px] border-b-[#BDCCD4]">
                        <p className="uppercase font-semibold">Operational health</p>
                        <div className="flex gap-2">
                          <ScoreIcon value={miner?.operationalHealthRating} />
                        </div>
                      </div>
                    )}
                    {miner?.sustainabilityRating && (
                      <div className="flex justify-between gap-4 pb-4 border-b-[1px] border-b-[#BDCCD4]">
                        <p className="uppercase font-semibold">Sustainability</p>
                        <div className="flex gap-2">
                          <ScoreIcon value={miner?.sustainabilityRating} />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mt-6">
                    <p>
                      These scores are based on Block Green's assessment of the miner, based on publicly available
                      information and information provided by the miner. Register your interest to learn more and
                      perform your own due dilligence.
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="bg-white mb-5">
              <SectionTitle title="Register interest" />
              <div className="p-6">
                <div className="mb-6">
                  <p>Reach out to learn more about this opportunity.</p>
                </div>
                <Button
                  className="btn-custom w-full !w-[320px] text-purple font-normal"
                  style={{
                    backgroundColor: "#F9F5FF",
                    border: "2px solid #F9F5FF",
                  }}
                  target="_blank"
                  href={generateMailtoLink(
                    "support@block.green",
                    `${miner?.name} opportunity`,
                    `Dear Block Green team,\n\nI am writing to express my interest in opportunity ${id} with ${miner?.name}.`
                  )}
                  startIcon={<MailOutlineIcon />}
                >
                  Register interest
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
