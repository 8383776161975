import { ERROR_INPUT_CLASS, ERROR_LABEL_CLASS, ERROR_MESSAGE_CLASS } from "../../../app/constants";

interface TextAreaProps {
  name: string;
  label?: string;
  value?: string;
  error?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  isOptional?: boolean;
  rows?: number;

  onChange?: (name: string, value: string | number | boolean) => void;
}

const TextArea = ({
  className,
  name,
  value,
  label,
  error,
  placeholder,
  onChange,
  disabled = false,
  isOptional = false,
  rows = 5,
}: TextAreaProps) => {
  return (
    <div className={`custom-form-group ${className || ""}`}>
      {label && (
        <label className={`flex gap-2 ${error ? ERROR_LABEL_CLASS : ""}`}>
          {label} {isOptional && <span className="text-[#667085]">(optional)</span>}
        </label>
      )}
      <textarea
        name={name}
        className={`custom-input w-full ${error ? ERROR_INPUT_CLASS : ""} p-2.5`}
        rows={rows}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange && onChange(e.target.name, e.target.value)}
      ></textarea>
      {error && (
        <p data-test={`errorLabel-${name}`} className={ERROR_MESSAGE_CLASS}>
          {error}
        </p>
      )}
    </div>
  );
};

export default TextArea;
