import { Tooltip } from "@mui/material";
import { ReactElement, useRef } from "react";
import { ButtonCopyToClipboard } from "./ButtonCopyToClipboard"; // Assuming this component exists

export const TruncatedCell = ({
  children,
  fullText,
}: {
  children: ReactElement[] | ReactElement | string;
  fullText: string;
}) => {
  const cellRef = useRef(null);

  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%", overflow: "hidden" }}>
      <Tooltip title={fullText} arrow>
        <div ref={cellRef} style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {children}
        </div>
      </Tooltip>
      <ButtonCopyToClipboard text={fullText} />
    </div>
  );
};
