interface ProgressBarProps {
  current: string | number;
  dataList: Array<string>;
  className?: string;
}

import "./style.scss";

const ProgressBar = ({ current, dataList, className }: ProgressBarProps) => {
  const completedBaselineWidth = () => {
    if (dataList.length === 4) {
      switch (current) {
        case 1:
          return "0";
        case 2:
          return "35";
        case 3:
          return "67";
        default:
          return "100";
      }
    }

    if (dataList.length === 3) {
      switch (current) {
        case 1:
          return "0";
        case 2:
          return "51";
        default:
          return "100";
      }
    }
  };

  return (
    <div className="relative">
      <div className="basic-baseline"></div>
      <div className={`completed-baseline custom-width-${completedBaselineWidth()}`}></div>
      <div className={`flex justify-between ${className || ""}`}>
        {dataList.map((item, key) => {
          return (
            <div key={item} className={`step flex flex-col items-center ${key + 1 <= +current ? "completed" : ""}`}>
              <div className={`step-point ${key + 1 <= +current ? "completed" : ""}`}></div>
              <div className="step-name">{item}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ProgressBar;
