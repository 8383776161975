import classNames from "classnames";
import { useState } from "react";

import "./style.scss";

interface ButtonProps {
  disabled?: boolean;
  notAllowed?: boolean;
  variant?: "contained" | "outline";
  type?: "button" | "submit";
  backgroundColor?: string;
  color?: string;
  className?: string;
  label?: string;
  icon?: any;
  tooltip?: { content: string; arrowPosition: "left" | "right" | "center" };
  onClick?: () => void;
}

const Button = ({
  backgroundColor,
  color,
  label,
  onClick,
  className,
  icon,
  tooltip,
  type = "button",
  disabled = false,
  notAllowed = false,
  variant = "contained",
  ...props
}: ButtonProps) => {
  const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const handleHover = () => {
    if (notAllowed) {
      setTooltipVisible(true);
    }
  };

  const handleHoverLeave = () => {
    setTooltipVisible(false);
  };

  const buttonStyle = {
    backgroundColor: `${variant === "outline" ? "transparent" : backgroundColor}`,
    border: `2px solid ${backgroundColor}`,
    color: `${variant === "outline" ? backgroundColor : color ? color : ""}`,
  };

  return (
    <div className="relative w-full flex" onMouseOver={handleHover} onMouseOut={handleHoverLeave}>
      <button
        data-test={`button-${label}`}
        type={type}
        disabled={disabled || notAllowed}
        className={`btn-custom w-full ${className || ""}`}
        style={buttonStyle}
        onClick={onClick}
        {...props}
      >
        {disabled ? (
          <div className="flex justify-center items-center">
            <div className="w-6 h-6 rounded-full animate-spin border-y-8 border-solid border-white-500 border-t-transparent shadow-md"></div>
          </div>
        ) : (
          <div className={classNames({ "flex justify-center items-center gap-4": !!icon })}>
            {icon && icon}
            {label && label}
          </div>
        )}
      </button>
      {notAllowed && tooltip ? (
        <div
          className={classNames("tooltip-content", {
            "block mx-auto my-0 right-0 left-[50%] !bottom-[45px] transform -translate-x-1/2": isTooltipVisible,
          })}
        >
          {tooltip?.content}
          <div
            className={classNames("arrow-down", {
              "mx-auto": tooltip?.arrowPosition === "center",
              "ml-auto mr-[5px]": tooltip?.arrowPosition === "right",
            })}
          ></div>
        </div>
      ) : null}
    </div>
  );
};

export default Button;
