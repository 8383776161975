import { useEffect, useState } from "react";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import KYCHeader from "../../components/molecules/KYCHeader";
import { KYC_HEADER, KYM_HEADER, KYC_ROUTE } from "../../app/constants";
import { OnboardingStatus, User } from "../../app/model/api";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

import "./style.scss";

const KYCLayout = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const user = queryClient.getQueryState<User>(["user"]);
  const [steps, setSteps] = useState<any>([]); // TODO Replace any with regular type
  const [isSiteExist, setIsSiteExist] = useState<boolean>(false);

  useEffect(() => {
    const header =
      user?.data?.onboardingStatus &&
      [OnboardingStatus.EntrypointSubmitted, OnboardingStatus.KYCSubmittedCompany].includes(
        user?.data?.onboardingStatus
      )
        ? KYC_HEADER.map((item, key) => {
            if (key === 0) {
              return {
                ...item,
                active: user?.data?.onboardingStatus === OnboardingStatus.EntrypointSubmitted,
                completed: user?.data?.onboardingStatus === OnboardingStatus.KYCSubmittedCompany,
              };
            } else if (key === 1) {
              return { ...item, active: user?.data?.onboardingStatus === OnboardingStatus.KYCSubmittedCompany };
            }
          })
        : KYM_HEADER.map((item, key) => {
            if (key === 0) {
              return {
                ...item,
                active: user?.data?.onboardingStatus === OnboardingStatus.KYCSubmittedRepresentative,
                completed: user?.data?.onboardingStatus === OnboardingStatus.KYMSubmittedGeneral,
              };
            } else if (key === 1) {
              return { ...item, active: user?.data?.onboardingStatus === OnboardingStatus.KYMSubmittedGeneral };
            }
          });

    setSteps(header);
  }, [location.pathname]);

  return (
    <div>
      <KYCHeader steps={steps} />
      <div
        className={classNames("p-5  xl:pt-8", {
          "kym-site-holder": isSiteExist,
          "xl:pb-12": !isSiteExist,
          "entryform-miner-bck": KYC_ROUTE.includes(location.pathname),
        })}
      >
        <div className="entity-form-layout p-5 md:p-10 xl:mx-auto">
          <div className="flex flex-col justify-center items-center mb-6">
            <div>
              <Logo />
            </div>
            <h1 className="mt-6">Welcome</h1>
            <p>Please enter your details.</p>
          </div>
          <main>
            <Outlet context={{ setIsSiteExist }} />
          </main>
        </div>
      </div>
      <ScrollRestoration />
    </div>
  );
};

export default KYCLayout;
