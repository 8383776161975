import { formatInTimeZone } from "date-fns-tz";
import { Link } from "react-router-dom";
import { PREVIEW_STREAMING_DETAILS_LP_ROUTE } from "../../../../app/constants";
import { amountFormatSatoshisToBTC, roundToSignificantDigitsDiv1000 } from "../../../../app/helper/utils";
import { PreviewStreamingOpportunityDataDto } from "../../../../app/model/api";
import { ReactComponent as CalendarIcon } from "../../../../assets/images/calendar.svg";
import MinerAxeIcon from "../../../../assets/images/miner-axe.png";
import { ReactComponent as WavesIcon } from "../../../../assets/images/waves.svg";
import "./style.scss";

export const PreviewStreamingItem = ({ data }: { data: PreviewStreamingOpportunityDataDto }) => {
  const {
    miner,
    id,
    durationDays,
    durationDesc,
    collateralType,
    startDate,
    lpInterestRate,
    principalSatoshis,
    principalDesc,
    hashrateThPerSecond,
    hashrateDesc,
  } = data;

  return (
    <Link to={PREVIEW_STREAMING_DETAILS_LP_ROUTE.replace(":id", String(id))}>
      <div className="opportunity-item-streaming h-full">
        <div className="opportunity-item-streaming-head">
          <div className="px-4 py-3">
            <div className="flex justify-between mb-12">
              <div className="flex">
                <div className="bg-white p-1 mr-1">
                  <CalendarIcon />
                </div>
                <div className="bg-white px-1 ">{durationDays ? `${durationDays} days` : durationDesc ?? "TBC"}</div>
              </div>
              <div className="flex">
                <div className="bg-white p-1 mr-1">
                  <WavesIcon />
                </div>
                <div className="bg-white px-1 ">Hashrate streaming</div>
              </div>
            </div>

            <div className="flex">
              <div className="bg-white pt-1 pr-1 mr-1">
                <img src={MinerAxeIcon} alt="Miner Axe Icon" />
              </div>
              <div className="miner-name">{miner.name}</div>
            </div>
          </div>
        </div>

        <div className="opportunity-item-streaming-body">
          <div className="px-4 pt-9 pb-6">
            <div className="grid grid-cols-2 mb-5">
              <div>
                <p className="uppercase text-[13px]">
                  <strong>Start date</strong>
                </p>
                <p>{startDate ? formatInTimeZone(startDate, "UTC", "dd MMM yyyy") : "TBC"}</p>
              </div>
              <div>
                <p className="uppercase text-[13px]">
                  <strong>Collateral</strong>
                </p>
                <p>{collateralType}</p>
              </div>
            </div>

            <hr className="mb-6 h-0 border-1 border-[#EAECF0]" />

            <div className="grid grid-cols-2 gap-y-5 mb-5">
              <div>
                <p className="uppercase text-[13px]">
                  <strong>Expected Return</strong>
                </p>
                <p>{lpInterestRate ? `${lpInterestRate}%` : "TBC"}</p>
              </div>
              <div>
                <p className="uppercase text-[13px]">
                  <strong>Hashrate</strong>
                </p>
                <p>
                  {hashrateThPerSecond ? (
                    <>
                      {roundToSignificantDigitsDiv1000(Number(hashrateThPerSecond), 1)} <sup>PH/s</sup>
                    </>
                  ) : (
                    hashrateDesc ?? "TBC"
                  )}
                </p>
              </div>
              <div>
                <p className="uppercase text-[13px]">
                  <strong>Principal volume</strong>
                </p>
                <p>
                  {principalSatoshis ? (
                    <>
                      {amountFormatSatoshisToBTC(principalSatoshis)} <sup>BTC</sup>
                    </>
                  ) : (
                    principalDesc ?? "TBC"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
