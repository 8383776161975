import { useQuery } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import { Sort } from "../model/api";

interface QueryProps {
  offset?: number;
  limit?: number;
  transactionDateTime?: Sort;
  txtype?:
    | "Reward Distribution"
    | "Opportunity Funding"
    | "Liquidity Refund"
    | "Collateral Release"
    | "Collateral Deposit"
    | "Reward Delivery"
    | "Liquidity Delivery"
    | "Excess Reward Release"
    | "Unknown"
    | "Collateral Liquidation";
  status?: "Pending" | "Confirmed" | "Awaiting approval";
}

const getOpportunityHistoryTxs = async (id: string, query: QueryProps) => {
  return (await apig.miner.getOpportunityHistoryTxs(id, query)).data;
};

const getCollateralInfo = async (id: string) => {
  return (await apig.miner.getCollateralInfo(id)).data;
};

const getOpportunitySummary = async (id: string) => {
  return (await apig.admin.getOpportunitySummary(id)).data;
};

const useGetQueryOpportunityHistory = (id: string, query: QueryProps) => {
  return useQuery(["opportunity_transaction_history", id, query], () => getOpportunityHistoryTxs(id, query), {
    refetchOnMount: "always",
  });
};

const useGetQueryCollateralInfo = (id: string) => {
  return useQuery(["collateral_info", id], () => getCollateralInfo(id), {
    refetchOnMount: "always",
  });
};

const useGetQueryOpportunitySummary = (id: string) => {
  return useQuery([`opportunity_${id}_summary`], () => getOpportunitySummary(id), {
    refetchOnMount: "always",
  });
};

export { useGetQueryCollateralInfo, useGetQueryOpportunityHistory, useGetQueryOpportunitySummary };
