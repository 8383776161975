import { useQuery } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import {
  FundingApprovalStatus,
  OpportunityStatus,
  SortFirstActionRequired,
  UserTypes,
  YesOrNoChoice,
} from "../model/api";

interface CommonProps {
  offset?: number;
  limit?: number;
  actionFirst?: SortFirstActionRequired;
}

interface UserListProps extends CommonProps {
  userType?: UserTypes;
}

interface OpportunityListProps extends CommonProps {
  status?: OpportunityStatus;
  hideSettled?: YesOrNoChoice;
}

interface LpAgreementsProps extends CommonProps {
  status?: FundingApprovalStatus;
}

interface OrganizationEnterpriseSettingsQuery {
  organizationId: string;
}

const getOrganizationEnterpriseSettings = async (query: OrganizationEnterpriseSettingsQuery) => {
  return (await apig.admin.getEnterpriseSettingsAndManagers(query)).data;
};

const getOrganizations = async (params: UserListProps) => {
  return (await apig.admin.getOrganizations(params)).data;
};

const getAdminOpportunities = async (params: OpportunityListProps) => {
  return (await apig.admin.getAdminOpportunities(params)).data;
};

const getAdminOpportunitiesSummary = async () => {
  return (await apig.admin.getAdminOpportunitiesSummary()).data;
};

const getAdminNotificationReceivers = async () => {
  return (await apig.admin.getAdminNotificationReceivers()).data;
};

const getLpAgreements = async (params: LpAgreementsProps) => {
  return (await apig.admin.getLpAgreements(params)).data;
};

const getLiquidityDelivery = async (query?: { offset?: number; limit?: number; hideSettled?: YesOrNoChoice }) => {
  return (await apig.admin.getAdminLiquidityDelivery(query)).data;
};

const useQueryAdminGetOrganizations = (params: UserListProps) => {
  return useQuery(["user_list", params], () => getOrganizations(params));
};

const useQueryGetLpById = (lpId: string) => {
  return useQuery(["lp_info", lpId], async () => (await apig.admin.getLpById(lpId)).data, {
    enabled: !!lpId,
    refetchOnMount: "always",
  });
};

const useQueryGetAdminOpportunities = (params: OpportunityListProps) => {
  return useQuery([JSON.stringify(params)], () => getAdminOpportunities(params), {
    refetchOnMount: "always",
  });
};

const useQueryGetAdminOpportunitiesSummary = () => {
  return useQuery(["opportunities_summary"], () => getAdminOpportunitiesSummary(), { refetchOnMount: "always" });
};

const useQueryGetAdminNotificationReceivers = () => {
  return useQuery(["notification_receivers"], () => getAdminNotificationReceivers());
};

const useGetAdminMinerSummary = (minerId: string) => {
  return useQuery([`miner_summary_${minerId}`], () => apig.admin.getMinerSummary(minerId), {
    enabled: !!minerId,
    refetchOnMount: "always",
  });
};

const useGetLpAgreements = (params: LpAgreementsProps) => {
  return useQuery(["lp_agreements", params], () => getLpAgreements(params), { refetchOnMount: "always" });
};

const useGetLiquidityDelivery = (query?: { offset?: number; limit?: number; hideSettled?: YesOrNoChoice }) => {
  return useQuery(["tranche_delivery", query], () => getLiquidityDelivery(query), {
    refetchOnMount: "always",
    cacheTime: 0,
  });
};

const useGetOrganizationEnterpriseSettings = (query: OrganizationEnterpriseSettingsQuery) => {
  return useQuery(["enterprise_settings", query], () => getOrganizationEnterpriseSettings(query), {
    enabled: !!query?.organizationId,
    refetchOnMount: "always",
    cacheTime: 0,
  });
};

const GET_MINER_BASIC_INFO_BY_ID = "admin_miner_info" as const;
const GET_MINER_KYM_FINANCIALS_BY_ID = "admin_miner_kym_financials" as const;
const GET_MINER_KYM_ASIC_BY_ID = "admin_miner_kym_asic" as const;
const GET_MINER_KYM_ENERGY_PROFILE_BY_ID = "admin_miner_kym_energy_profile" as const;
const GET_MINER_KYM_CAPACITY_BY_ID = "admin_miner_kym_capacity_and_sites" as const;
const GET_MINER_KYM_HOSTING_BY_ID = "admin_miner_kym_hosting" as const;

const useQueryGetMinerByMinerId = (minerId: string | undefined) => {
  return useQuery([GET_MINER_BASIC_INFO_BY_ID, minerId], () => apig.admin.getMinerById(minerId ?? ""), {
    enabled: !!minerId,
  });
};

const useQueryGetMinerKymFinancialsById = (orgId: string) => {
  return useQuery([GET_MINER_KYM_FINANCIALS_BY_ID, orgId], () => apig.admin.getMinerKym2Financials(orgId), {
    enabled: !!orgId,
  });
};

const useQueryGetMinerKymAsicById = (orgId: string) => {
  return useQuery([GET_MINER_KYM_ASIC_BY_ID, orgId], () => apig.admin.getMinerKym2Asic(orgId), {
    enabled: !!orgId,
  });
};

const useQueryGetMinerKymEnergyProfileById = (orgId: string) => {
  return useQuery([GET_MINER_KYM_ENERGY_PROFILE_BY_ID, orgId], () => apig.admin.getMinerKym2EnergyProfile(orgId), {
    enabled: !!orgId,
    refetchOnMount: "always",
  });
};

const useQueryGetMinerKymCapacityAndSitesById = (orgId: string) => {
  return useQuery([GET_MINER_KYM_CAPACITY_BY_ID, orgId], () => apig.admin.getMinerKym2CapacityAndSites(orgId), {
    enabled: !!orgId,
  });
};

const useQueryGetMinerKym2HostingById = (orgId: string) => {
  return useQuery([GET_MINER_KYM_HOSTING_BY_ID, orgId], () => apig.admin.getMinerKym2Hosting(orgId), {
    enabled: !!orgId,
  });
};

const useGetAdminOpportunityDetails = (id: string) => {
  return useQuery([`opportunity_details_v2_${id}`], () => apig.admin.getAdminOpportunityV2(id), {
    enabled: !!id,
  });
};

const useGetAdminDemoOpportunityFundings = (id: string) => {
  return useQuery([`demo_op_fundings_${id}`], () => apig.admin.getDemoOpportunityApprovedFundings(id), {
    enabled: !!id,
  });
};

const useGetAdminDemoOpportunityRewards = (id: string) => {
  return useQuery([`demo_op_rewards_${id}`], () => apig.admin.getDemoOpportunityRewards(id), {
    enabled: !!id,
  });
};

const useGetAdminDemoExcessReward = (id: string) => {
  return useQuery([`demo_op_excess_reward_${id}`], () => apig.admin.getDemoExcessReward(id), {
    enabled: !!id,
  });
};
const useGetAdminDemoOpportunityCollaterals = (id: string) => {
  return useQuery([`demo_op_collaterals_${id}`], () => apig.admin.getDemoCollateralTransactions(id), {
    enabled: !!id,
  });
};

const useGetAdminAllOpportunityTransactions = (id: string) => {
  return useQuery([`useGetAdminAllOpportunityTransactions_${id}`], () => apig.admin.getAllOpportunityTransactions(id), {
    enabled: !!id,
  });
};

const useGetAdminBlocks = (query: {
  orderBy: "height" | "timestamp" | "difficulty" | "rewardSatoshis";
  orderDirection: "asc" | "desc";
  /** @min 0 */
  offset?: number;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
}) => {
  return useQuery([query], () => apig.admin.getBlocks(query));
};

const useGetAdminComputedHashprices = (query: {
  /** @min 0 */
  offset?: number;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  orderBy:
    | "day"
    | "createdAt"
    | "effectiveDifficulty"
    | "totalSubsidySatoshis"
    | "fppsHashpriceSatoshisPerTh"
    | "blockCount";
  orderDirection: "asc" | "desc";
}) => {
  return useQuery([query], () => apig.admin.getComputedHashprices(query));
};

const useGetAdminOpportunityRewards = (
  opportunityId: string,
  query: {
    /** @min 0 */
    offset?: number;
    /**
     * @min 1
     * @max 10
     */
    limit?: number;
    orderBy: "createdAt" | "amountSatoshis" | "distributionStatus";
    orderDirection: "asc" | "desc";
  }
) => {
  return useQuery([opportunityId, query], () => apig.admin.getOpportunityRewards(opportunityId, query));
};

const useQueryAdminGetRewardDetails = (rewardId: string) => {
  return useQuery([rewardId], () => apig.admin.getRewardDetails(rewardId));
};

const useGetAdminUsers = (query: {
  /** @min 0 */
  offset?: number;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  orderBy: "id" | "email" | "createdAt" | "lastName" | "firstName" | "cellphoneNumber";
  orderDirection: "asc" | "desc";
}) => {
  return useQuery([query], () => apig.admin.getUsers(query));
};

const useGetPrediction = (query: { range: 30 | 60 | 90 }) => {
  return useQuery([query], () => apig.admin.getPrediction(query.range));
};

const useGetHistory = () => {
  return useQuery([], () => apig.admin.getHistoricalHashrates());
};

export {
  useGetAdminAllOpportunityTransactions,
  useGetAdminBlocks,
  useGetAdminComputedHashprices,
  useGetAdminDemoExcessReward,
  useGetAdminDemoOpportunityCollaterals,
  useGetAdminDemoOpportunityFundings,
  useGetAdminDemoOpportunityRewards,
  useGetAdminMinerSummary,
  useGetAdminOpportunityDetails,
  useGetAdminOpportunityRewards,
  useGetAdminUsers,
  useGetHistory,
  useGetLpAgreements,
  useGetOrganizationEnterpriseSettings,
  useGetPrediction,
  useGetLiquidityDelivery as useGetTrancheDelivery,
  useQueryAdminGetOrganizations,
  useQueryAdminGetRewardDetails,
  useQueryGetAdminNotificationReceivers,
  useQueryGetAdminOpportunities,
  useQueryGetAdminOpportunitiesSummary,
  useQueryGetLpById,
  useQueryGetMinerByMinerId,
  useQueryGetMinerKym2HostingById,
  useQueryGetMinerKymAsicById,
  useQueryGetMinerKymCapacityAndSitesById,
  useQueryGetMinerKymEnergyProfileById,
  useQueryGetMinerKymFinancialsById,
};
